import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'alimento-ipv-frontend-select-list',
  templateUrl: './select-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectListComponent),
      multi: true
    }
  ]
})
export class SelectListComponent<T extends { [key: string]: any }> implements OnDestroy, ControlValueAccessor {

  @Input()
  items: T[];

  @Input()
  idField = 'id';

  @Input()
  selectAllKey = "trainings.participantsPopup.allSessions";

  @Input()
  label: (item: T, number: number) => string = (item: T, number: number) => {
    return '' + number;
  };

  @Input()
  containerClass = 'max-h-56';

  @Input()
  subTextKey = 'subText';

  @Input()
  subTextClass = '';

  selectAllControl: FormControl = new FormControl<boolean>(false);
  formGroup: FormGroup;
  private _subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder) {
    this._createFormGroup();
    this.selectAllControl.valueChanges.subscribe(newValue => {
      if (newValue) {
        this.formGroup.get('items').setValue(this.items.map(item => item[this.idField]));
      }
      else {
        this.formGroup.get('items').setValue([]);
      }
    });

    this._subscriptions.push(
      this.formGroup.valueChanges.subscribe(newValue => {
        this.onChange(newValue.items);
        this.onTouched();
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private _createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      items: [[]]
    });

    this._subscriptions.push(
      this.formGroup.get('items').valueChanges.subscribe(newValue => {
        if (newValue) {
          this.selectAllControl.setValue(newValue.length === this.items.length, { emitEvent: false });
        }
      })
    );
  }

  toggle(id: string | undefined, event: Event) {
    const items = this.formGroup.get('items');
    if (items.value.includes(id)) {
      items.setValue(items.value.filter((item: string) => item !== id));
    }
    else {
      items.setValue([...items.value, id]);
    }
    event.stopImmediatePropagation();
  }

  toggleSelectAllControl(event: Event) {
    this.selectAllControl.setValue(!this.selectAllControl.value);
    event.stopImmediatePropagation();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(value: any[]): void {
    if (value) {
      this.formGroup.setValue({ items: value });
    }
    else {
      this.formGroup.reset();
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
