import { Injectable } from '@angular/core';
import { AccountNumber, SearchAccountNumber } from '../../types/enterprise.type';

@Injectable({
  providedIn: 'root'
})
export class AccountNumberMapper {

  constructor() {
  }

  isActive(accountNumber: AccountNumber | SearchAccountNumber): boolean {
    return new Date(accountNumber.startDate) <= new Date() &&
      (!accountNumber.endDate || this._getEndDate(accountNumber.endDate) >= new Date());
  }

  private _getEndDate(endDate: Date): Date {
    const date = new Date(endDate);
    date.setDate(date.getDate() + 1);
    return date;
  }
}
