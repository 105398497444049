import { TrainingTitleTreeItem } from '../../types/reference-data.type';

export function mapTreeSelect(treeSelectValue: any): {
  rubricId: string;
  mainTitleId: string;
  trainingTitleId: string;
} {
  const treeSelectLevel1 = treeSelectValue?.data;
  const treeSelectLevel2 = treeSelectValue?.parent?.data;
  const treeSelectLevel3 = treeSelectValue?.parent?.parent?.data;

  let rubricId;
  let mainTitleId;
  let trainingTitleId;

  if (treeSelectLevel3) {
    rubricId = treeSelectLevel3;
    mainTitleId = treeSelectLevel2;
    trainingTitleId = treeSelectLevel1;
  }
  else if (treeSelectLevel2) {
    rubricId = treeSelectLevel2;
    mainTitleId = treeSelectLevel1;
  }
  else if (treeSelectLevel1) {
    rubricId = treeSelectLevel1;
  }

  return { rubricId, mainTitleId, trainingTitleId };
}

export function getSelectedTree(
  rubricId: string | undefined,
  mainTitleId: string | undefined,
  trainingTitleId: string | undefined,
  trainingTitles: TrainingTitleTreeItem[]
): TrainingTitleTreeItem | null {
  if (rubricId) {
    let selectedTreeItem: TrainingTitleTreeItem;
    const rubricTreeItem = trainingTitles.filter((title) => title.data === rubricId)[0];
    selectedTreeItem = rubricTreeItem;
    trainingTitles = selectedTreeItem.children;

    if (mainTitleId) {
      const mainTitleTreeItem = trainingTitles.filter((title) => title.data === mainTitleId)[0];
      selectedTreeItem = mainTitleTreeItem;
      selectedTreeItem.parent = rubricTreeItem;
      trainingTitles = selectedTreeItem.children;

      if (trainingTitleId) {
        selectedTreeItem = trainingTitles.filter((title) => title.data === trainingTitleId)[0];
        selectedTreeItem.parent = mainTitleTreeItem;
      }
    }

    return selectedTreeItem;
  }

  return null;
}
