import { Component, Input } from '@angular/core';
import { Enterprise } from '../../../types/enterprise.type';

@Component({
  selector: 'alimento-ipv-frontend-enterprises-sme-bonus',
  templateUrl: './sme-bonus.component.html'
})
export class SmeBonusComponent {

  @Input()
  enterprise: Enterprise;

  @Input()
  smeBonusActive = false;
}
