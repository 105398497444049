import { Component, forwardRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import {
  FilterType, IFilter,
  InfiniteScrollDataAdapter,
  PaginatedResponse, SearchFilter,
  SearchFilterType,
  SearchRequest
} from '@alimento-ipv-frontend/ui-lib';
import { first, map, Observable, switchMap, tap } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TrainingProgramComponent } from '../training-program/training-program.component';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { TrainingProgramSearchItem } from '../../../types/searches.type';
import { TrainingProgram } from '../../../types/training-program.type';
import { TrainingProgramService } from '../../../services/training-program.service';
import { SearchesService } from '../../../services/searches.service';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-select-training-program',
  templateUrl: './select-training-program.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectTrainingProgramComponent),
      multi: true
    }
  ]
})
export class SelectTrainingProgramComponent implements ControlValueAccessor, OnChanges {

  @Input()
  branchId: string;

  @Input()
  yearOfRequest: number;

  @Input()
  trainingRubricId: string;

  @Input()
  firstSessionDate?: Date;

  @Input()
  lastSessionDate?: Date;

  search$: InfiniteScrollDataAdapter<TrainingProgramSearchItem>;
  searchFilters: SearchFilter[] = [];
  defaultFromDate: Date;
  defaultToDate: Date;

  activeIndex = 0;
  newTrainingProgram: TrainingProgram;
  dialogVisible = false;
  trainingProgram: TrainingProgram;
  currentSelection: TrainingProgramSearchItem;

  @ViewChild(TrainingProgramComponent)
  trainingProgramComponent: TrainingProgramComponent;
  loading = false;

  disabled = false;


  constructor(private trainingProgramService: TrainingProgramService,
              private searchesService: SearchesService,
              private referenceDataService: ReferenceDataService,
              private translateService: TranslateService,
              private messageService: MessageService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['branchId']?.currentValue || changes['trainingRubricId']?.currentValue) {
      this.newTrainingProgram = {
        branchId: this.branchId,
        rubricId: this.trainingRubricId
      } as TrainingProgram;
    }
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: []
    };
    searchRequest.filters.push({
      type: FilterType.filterBranch,
      values: [this.branchId]
    });

    if (this.yearOfRequest) {
      this.defaultFromDate = new Date("01/01/" + this.yearOfRequest);
      this.defaultToDate = new Date("12/31/" + this.yearOfRequest);
    }

    this.search$ = new InfiniteScrollDataAdapter<TrainingProgramSearchItem>((searchRequest: SearchRequest):
    Observable<PaginatedResponse<TrainingProgramSearchItem>> => {
      return this.searchesService.searchTrainingPrograms(searchRequest);
    }, searchRequest, true);

    this.searchFilters = [
      {
        type: SearchFilterType.searchBar,
        key: FilterType.search
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.rubric',
        key: FilterType.filterRubric,
        data: this.referenceDataService.getTrainingRubrics()
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterRubric,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.date,
        label: this.translateService.instant("trainingPrograms.period"),
        key: 'date'
      }
    ]
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    if (value && (typeof value === 'string')) {
      if (this.trainingProgram?.trainingProgramId !== value) {
        this.trainingProgramService.getTrainingProgram(value)
          .pipe(first())
          .subscribe(trainingProgram => this.trainingProgram = trainingProgram);
      }
    }
    else if (value) {
      this.trainingProgram = value;
    }
    else {
      this.trainingProgram = undefined;
    }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.currentSelection = undefined;
    this.activeIndex = 0;
  }

  selectTrainingProgram() {
    if (this.activeIndex === 0) {
      this.trainingProgram = this.currentSelection;
      this.onChange(this.trainingProgram.trainingProgramId);
      this.onTouched();
      this.closeDialog();
    }
    else {
      this._createTrainingProgram().subscribe({
        next: (result: TrainingProgram) => {
          this.trainingProgram = result;
          this.onChange(result.trainingProgramId);
          this.onTouched();
          this.closeDialog();
        }
      });
    }
  }

  addTrainingProgram() {
    this.dialogVisible = true;
    this._createSearch();
  }

  selectionChange(trainingPrograms: any[]) {
    this.currentSelection = trainingPrograms[0];
  }

  private _createTrainingProgram(): Observable<TrainingProgram> {
    if (!this.trainingProgramComponent.isValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('trainings.sessions.hasErrors')
      });

      return new Observable<any>((observable) => observable.error());
    }

    this.loading = true;
    const data = this.trainingProgramComponent.getData() as TrainingProgram;

    return this.trainingProgramService.createTrainingProgram(data)
      .pipe(
        first(),
        switchMap(result => this.trainingProgramService.getTrainingProgram(result.id).pipe(first())),
        tap({
          next: () => {
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          }
        }));
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  removeTrainingProgram(): void {
    this.trainingProgram = undefined;
    this.onChange(undefined);
    this.onTouched();
  }

  private _getSelectedTrainingProgram(): TrainingProgram {
    if (this.activeIndex === 0) {
      return this.currentSelection;
    }
    else {
      return this.trainingProgramComponent.getData();
    }
  }

  notMatchingRubric(): boolean {
    const trainingProgram = this._getSelectedTrainingProgram();
    return !!this.trainingRubricId && trainingProgram?.rubricId && trainingProgram.rubricId !== this.trainingRubricId;
  }

  sessionDateOutsideTrainingProgram(): boolean {
    const trainingProgram = this._getSelectedTrainingProgram();

    if (trainingProgram && !!this.firstSessionDate && !!this.lastSessionDate) {
      const startDate = trainingProgram?.startDate ? new Date(trainingProgram.startDate) : undefined;
      const endDate = trainingProgram?.endDate ? new Date(trainingProgram.endDate) : undefined;
      return (startDate && (this.firstSessionDate < startDate || this.lastSessionDate < startDate))
        || (endDate && (this.firstSessionDate > endDate || this.lastSessionDate > endDate));
    }
    return false;
  }
}
