<lib-lazy-dropdown
  [data$]='data$'
  [template]='itemTemplate'
  [headerTemplate]='headerTemplate'
  [formControl]='formControl'
  [showSearchIcon]='false'
  [showClear]='true'
  [displayValue]='getSelectedString'
  dropdownIcon='pi pi-search'
  placeholder="{{ 'interim-offices.selectPlaceholder' | translate }}"
  field='name'
>
  <ng-template #headerTemplate>
    <alimento-ipv-frontend-interim-office-card></alimento-ipv-frontend-interim-office-card>
  </ng-template>
  <ng-template #itemTemplate let-item="item">
    <alimento-ipv-frontend-interim-office-card
      [office]='item'>
    </alimento-ipv-frontend-interim-office-card>
  </ng-template>
</lib-lazy-dropdown>
