<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>
<div *ngIf="reimbursementRequest">
  <alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                       [linkedEntity]='reimbursementRequestId'
                                       (hasChanges)='notesHasChanges = $event'
                                       [type]='AllowanceApplicationNote'>
  </alimento-ipv-frontend-notes-sidebar>

  <alimento-ipv-frontend-detail-page-layout
    title="{{ (reimbursementRequestId ? 'reimbursementRequests.request' : 'reimbursementRequests.new') | translate }}"
    [navigationUrl]="['/reimbursement-requests']"
  >
    <ng-container top-action>
      <div>
        <div class="font-bold text-lg" *ngIf="reimbursementRequestId">
          <span class='text-text' *ngIf='branch'>{{ branch.branchName }}</span>
          <span *ngIf="reimbursementRequestId"
                class="alimento-id mx-2 whitespace-nowrap text-text">- {{ reimbursementRequest.trainingAllowanceApplicationAlimentoId }}
          </span>
        </div>
      </div>

      <div class="buttons" *ngIf='!readOnly'>
        <button
          pButton
          pRipple
          type="button"
          label="{{ 'reimbursementRequests.actions.save' | translate }}"
          class="secondary-button uppercase"
          icon="pi pi-save"
          loadingIcon="pi pi-spin pi-spinner"
          (click)="saveReimbursementRequest()"
          [loading]="saving"
        ></button>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        (activeTabChanged)="setActiveTabIndex($event)"
        [activeTabIndex]="activeTabIndex"
        [data]="tabMenuItems"
        [extra]='extraMenuItems'
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <div [hidden]="activeTabIndex !== 0">
        <alimento-ipv-frontend-reimbursement-request
          [branch]='branch'
          [reimbursementRequest]='reimbursementRequest'
          (changes)='hasChanges = true'
        >
        </alimento-ipv-frontend-reimbursement-request>
      </div>

      <div *ngIf="activeTabIndex === 1">
        <alimento-ipv-frontend-header title="{{ 'reimbursementRequests.trainingTitle' | translate }}">
          <div>
            <p-button
              *ngIf="!this.readOnly"
              [routerLink]='["/reimbursement-requests", reimbursementRequestId, "training", "create"]'
              icon="pi pi-plus"
              label="{{ 'reimbursementRequests.actions.createTraining' | translate | uppercase }}"
            ></p-button>
          </div>
        </alimento-ipv-frontend-header>

        <lib-search [data$]='searchTrainingData$'
                    [filters]='searchTrainingFilters'
                    [cardTemplate]='cardTemplate'
                    [inlineFilters]='true'
                    [activeFiltersOnTop]='false'
                    [showSearchFilter]='false'
                    name='reimbursement-request-trainings'
                    searchPlaceholderKey='trainings.searchbarPlaceholder'
                    availableResultsTranslateKey='trainings.nrOfTrainings'
        >
          <ng-template #cardTemplate let-item="item">
            <alimento-ipv-frontend-training-card
              [training]='item'
              tabindex="0"
            >
            </alimento-ipv-frontend-training-card>
          </ng-template>
        </lib-search>
      </div>
    </ng-container>

    <ng-container metadata>
      <alimento-ipv-frontend-reimbursement-status-chip *ngIf='reimbursementRequestId'
        [status]='$any(reimbursementRequest?.statusId)'>
      </alimento-ipv-frontend-reimbursement-status-chip>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</div>
