<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ 'trainings.approveEnrollments.title' | translate }}"
>
  <div *ngIf="dialogVisible" [formGroup]="formGroup">
    <div class='mb-4'>
      <span class='text-lg'>{{'trainings.approveEnrollments.text' | translate}}</span>
    </div>

    <div>
      <div class='mb-4'>
        <span class='text-lg'>{{'trainings.approveEnrollments.selectParticipants' | translate}}</span>
      </div>

      <alimento-ipv-frontend-select-list
        formControlName='enrollmentIds'
        [items]='enrollments()'
        [label]='getEnrollmentsLabel'
        selectAllKey='trainings.approveEnrollments.selectAll'
      >
      </alimento-ipv-frontend-select-list>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex">
     <p-button
        (onClick)="onSubmit()"
        [disabled]='nrOfEnrollments < 1'
        [loading]="loading"
        label="{{ 'trainings.approveEnrollments.button.submit' | translate }} ({{ nrOfEnrollments }})"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'trainings.approveEnrollments.button.cancel' | translate }}"
        styleClass="inverted-button"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
