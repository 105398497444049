import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { Dropdown } from 'primeng/dropdown';
import { CancelTrainingEvent, CancelTrainingReason, Training } from '../../../types/training.type';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-cancel-training-dialog',
  templateUrl: './cancel-training-dialog.component.html'
})
export class CancelTrainingDialogComponent {
  @Input()
  training: Training

  @Input()
  firstSessionDate?: Date;

  @Output()
  cancelTrainingConfirmed = new EventEmitter<CancelTrainingEvent>();

  @ViewChild("focusElement")
  focusElement: Dropdown;

  dialogVisible = false;

  cancelTrainingForm: FormGroup;

  cancelTrainingReasons$: Observable<CancelTrainingReason[]> = this.referenceDataService.getCancelTrainingReasons();
  loading = false;

  constructor(
    private fb: FormBuilder,
    private referenceDataService: ReferenceDataService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    this.cancelTrainingForm = this.fb.group({
      reasonCancelledId: [null, [Validators.required]],
      cancelledComment: [null],
    });
  }

  open() {
    this.dialogVisible = true;
    this.loading = false;
    setTimeout(() => this.focusElement?.focus());
  }

  cancelTraining() {
    if (!this.isDataValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('error.fillInRequiredFields'),
      });
      return;
    }

    this.cancelTrainingConfirmed.emit( {
      data: this.cancelTrainingForm.value,
      setLoading: state => this.loading = state
    });
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.cancelTrainingForm.reset();
  }

  private isDataValid(): boolean {
    validateAllFormFields(this.cancelTrainingForm);
    return this.cancelTrainingForm.valid;
  }
}
