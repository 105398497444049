import { Route } from '@angular/router';
import { CanDeactivateGuard } from '@alimento-ipv-frontend/ui-lib';
import { BranchListComponent } from './pages/branch-list/branch-list.component';
import { BranchDetailComponent } from './pages/branch-detail/branch-detail.component';

export const BranchesRoutes: Route[] = [
  {
    path: '',
    component: BranchListComponent,
    data: { title: 'titles.branches.list' }
  },
  {
    path: ':branchId/detail',
    component: BranchDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.branches.detail' }
  },
  {
    path: 'create',
    component: BranchDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.branches.create' }
  },
];
