import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter, first, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {
  DateMapper,
  dateValid,
  emailPattern,
  NO_BRANCH_ID,
  validateAllFormFields
} from '@alimento-ipv-frontend/ui-lib';
import { EmploymentActionEnum } from '../../../types/person.enum';
import { Employment, EmploymentUpdateEvent, Person, SearchPersonItem } from '../../../types/person.type';
import { Branch } from '../../../types/branch.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { BranchService } from '../../../services/branch.service';
import { PersonService } from '../../../services/person.service';
import { PersonComponent } from '../person/person.component';

@Component({
  selector: 'alimento-ipv-frontend-employment-popup',
  templateUrl: './employment-popup.component.html'
})
export class EmploymentPopupComponent implements OnChanges, OnDestroy {
  @Input()
  employment?: Employment;

  @Input()
  showBranchInfo = true;

  @Input()
  showPersonInfo = false;

  @Input()
  employmentAction: EmploymentActionEnum = EmploymentActionEnum.view;

  @Output()
  formSubmit = new EventEmitter<EmploymentUpdateEvent>();

  @ViewChildren(PersonComponent)
  personComponents: QueryList<PersonComponent>;

  popupVisible = false;
  employmentForm!: FormGroup;
  loading = false;
  workStatuses$ = this.referenceDataService.getWorkStatuses();
  responsibilities$ = this.referenceDataService.getResponsibilities();
  currentBranch?: Branch;
  currentPerson?: Person;
  responsibilitiesReadOnly: string;
  isNoBranch = false;
  searchPersonFormControl: FormControl<any> = new FormControl<any>(undefined);
  personForm: FormGroup;

  private _subscriptions: Subscription[] = [];
  private cancelFetchBranch$: Subject<void>;
  private cancelFetchPerson$: Subject<void>;
  private _titleMap: Map<any, any>;
  formIndex = 0;
  personFormIndex = 0;
  person?: Person;
  showSelectPersonForm = false;

  constructor(
    private fb: FormBuilder,
    private referenceDataService: ReferenceDataService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private branchesService: BranchService,
    private personService: PersonService
  ) {
    this._createTitleMap();
    this.createEmploymentForm();

    this._subscriptions.push(
      this.searchPersonFormControl.valueChanges
        .pipe(
          filter((person) => person),
          switchMap((person: SearchPersonItem) => this.personService.getPerson(person.personId))
        )
        .subscribe((person: Person) => {
          this.person = person;
        })
    );
  }

  ngOnChanges(): void {
    if (this.employmentAction === EmploymentActionEnum.view) {
      this.employmentForm?.disable({ emitEvent: false });
    }
    else {
      this.employmentForm?.enable({ emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  openPopup(employment?: Employment): void {
    this.employment = employment;
    this.fillEmploymentForm();

    if (!this.employment?.personId && this.showPersonInfo) {
      this.formIndex = 0;
      this.showSelectPersonForm = true;
      this._createPersonForm();
    }
    else {
      this.showSelectPersonForm = false;
      this.formIndex = 1;
    }
    this.popupVisible = true;
  }

  closePopup(): void {
    if (!this.employment?.employmentId) {
      this.employmentForm.reset();
    }
    this.personFormIndex = 0;
    this.searchPersonFormControl.reset();

    this.popupVisible = false;
  }

  isDataValid(): boolean {
    validateAllFormFields(this.employmentForm);
    return this.employmentForm.valid;
  }

  getFormData(): Employment {
    const data = JSON.parse(JSON.stringify(this.employmentForm.value));
    data.phoneNumber = data.phoneNumber?.replace(/[./\s]/g, '');
    if (data.branch?.branchId) {
      data.branchId = data.branch?.branchId;
    }
    delete data.branch;

    if (this.showSelectPersonForm && this.personFormIndex === 0) {
      data.personId = this.searchPersonFormControl.value?.personId;
    }

    data.startDate = DateMapper.getDateFromDateTimeAsString(data.startDate);

    return data;
  }

  addOrUpdateEmployment() {
    if (!this.isDataValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('trainings.sessions.hasErrors')
      });
      return;
    }

    this.formSubmit.emit({
      employmentId: this.employment?.employmentId,
      employment: this.getFormData(),
      person: this.showSelectPersonForm ? this.personForm?.value?.personForm : undefined,
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  get phoneNumber() {
    return this.employmentForm.get('phoneNumber');
  }

  private createEmploymentForm() {
    this.employmentForm = this.fb.group({
      branch: ['', Validators.required],
      personId: [null],
      function: [],
      professionalEmail: [null, emailPattern()],
      phoneNumber: [null, Validators.pattern('^[0-9+./ ]+$')],
      workStatusId: [],
      isContactPerson: [false],
      isTeacher: [false],
      responsibilityIds: [[]],
      startDate: [null, [dateValid()]]
    });

    this._subscriptions.push(
      this.employmentForm.get('branch').valueChanges.subscribe((branch) => {
        this.setCurrentBranch(branch?.branchId);
      })
    );

    this._subscriptions.push(
      this.employmentForm
        .get('isContactPerson')
        .valueChanges.pipe(filter((value) => !value))
        .subscribe(() => {
          this.employmentForm.get('responsibilityIds')?.patchValue([]);
        })
    );
  }

  private fillEmploymentForm(): void {
    this.employmentForm.patchValue({
      personId: this.employment?.personId,
      branch: this.employment?.branchId ? {
        branchId: this.employment?.branchId,
        branchName: this.employment?.branchName
      } : null,
      function: this.employment?.function,
      professionalEmail: this.employment?.professionalEmail,
      phoneNumber: this.employment?.phoneNumber,
      workStatusId: this.employment?.workStatus?.data,
      isContactPerson: this.employment?.isContactPerson || false,
      isTeacher: this.employment?.isTeacher || false,
      startDate: this.employment?.startDate ? new Date(this.employment.startDate) : null,
      responsibilityIds: (this.employment?.responsibilities || []).map((responsibility) => responsibility.data)
    }, { emitEvent: false });

    this.responsibilitiesReadOnly = (this.employment?.responsibilities || [])
      .map((responsibility) => responsibility.label).join(', ');

    this.setCurrentBranch(this.employment?.branchId);
    this.setCurrentPerson(this.employment?.personId);
  }

  private setCurrentBranch(branchId: string | undefined): void {
    this.cancelFetchBranch$?.next();

    this.isNoBranch = false;
    if (branchId === NO_BRANCH_ID) {
      this.isNoBranch = true;
      this.currentBranch = undefined;
    }
    else if (branchId) {
      this.cancelFetchBranch$ = new Subject();
      this.branchesService
        .getBranch(branchId)
        .pipe(first(), takeUntil(this.cancelFetchBranch$))
        .subscribe((branch) => {
          this.currentBranch = branch;
        });
    }
    else {
      this.currentBranch = undefined;
    }
  }

  private setCurrentPerson(personId: string | undefined): void {
    this.cancelFetchPerson$?.next();

    if (personId) {
      this.cancelFetchPerson$ = new Subject();
      this.personService
        .getPerson(personId)
        .pipe(first(), takeUntil(this.cancelFetchPerson$))
        .subscribe(person => this.currentPerson = person);
    }
    else {
      this.currentPerson = undefined;
    }
  }

  private _createTitleMap(): void {
    this._titleMap = new Map();
    this._titleMap.set(EmploymentActionEnum.view, { title: 'persons.employments.actions.view', complete: undefined });
    this._titleMap.set(EmploymentActionEnum.create, {
      title: 'persons.employments.actions.add',
      complete: 'persons.employments.actions.save'
    });
    this._titleMap.set(EmploymentActionEnum.edit, {
      title: 'persons.employments.actions.edit',
      complete: 'persons.employments.actions.save'
    });
  }

  get titleObject(): { title: string, complete?: string } {
    return this._titleMap.get(this.employmentAction);
  }

  public clearPersonForm(event: any) {
    if (event.index === 1) {
      this.person = {} as Person;
    }
    else if (event.index === 0) {
      this.searchPersonFormControl.reset();
    }
  }

  private _createPersonForm(): void {
    this.personForm = this.fb.group({
      personForm: []
    });
  }

  goToNextStep(): void {
    let invalid = false;
    if (this.personFormIndex === 0) {
      if (!this.searchPersonFormControl.value) {
        invalid = true;
      }
    }
    else {
      if (!this.personComponents.last.isValid()) {
        invalid = true;
      }
    }

    if (invalid) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('enrollments.hasErrors')
      });
      return;
    }

    this.formIndex = 1;
  }
}
