import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  APP_CONFIG_TOKEN,
  AppConfig,
  PaginatedResponse,
  SearchMapper,
  SearchRequest
} from '@alimento-ipv-frontend/ui-lib';
import { map, Observable } from 'rxjs';
import {
  BaseTraining,
  FinancialDocument, ReimbursementExtraInfo, Reimbursement,
  ReimbursementRequest,
  SearchReimbursementRequestItem, ReimbursementCorrectionInfo
} from '../types/reimbursement-request.type';
import { TRAINING_TYPE } from '../types/reference-data.enum';
import { GlobalMapper } from '../utils/mapper/global.mapper';
import { REIMBURSEMENT_ACTION_ENUM, REIMBURSEMENT_TYPE } from '../types/reimbursement-request.enum';

@Injectable({
  providedIn: 'root'
})
export class ReimbursementRequestService {
  constructor(private http: HttpClient,
              private globalMapper: GlobalMapper,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  searchRequests(searchParams: SearchRequest): Observable<PaginatedResponse<SearchReimbursementRequestItem>> {
    const params = SearchMapper.getParams(searchParams);
    return this.http.post<PaginatedResponse<SearchReimbursementRequestItem>>
    (`${this.config.readApiUrl}/trainingallowanceapplicationsearches`, params);
  }

  getRequest(requestId: string): Observable<ReimbursementRequest> {
    return this.http.get<ReimbursementRequest>(`${this.config.readApiUrl}/trainingallowanceapplication/${requestId}`);
  }

  createRequest(reimbursementRequest: ReimbursementRequest): Observable<{ id: string }> {
    return this.http.post<{
      id: string
    }>(`${this.config.writeApiUrl}/trainingallowanceapplication`, reimbursementRequest);
  }

  updateRequest(reimbursementRequestId: string, reimbursementRequest: ReimbursementRequest): Observable<{
    id: string
  }> {
    return this.http.put<{
      id: string
    }>(`${this.config.writeApiUrl}/trainingallowanceapplication/${reimbursementRequestId}`, reimbursementRequest);
  }

  deleteRequest(reimbursementRequestId: string): Observable<any> {
    return this.http.delete(`${this.config.writeApiUrl}/trainingallowanceapplication/${reimbursementRequestId}`);
  }

  getTypeUrl(type: TRAINING_TYPE): string {
    return type === TRAINING_TYPE.CUSTOM ? 'customtrainings' : 'openexterntrainings';
  }

  getTraining(trainingId: string, type: TRAINING_TYPE): Observable<BaseTraining> {
    return this.http.get<BaseTraining>(`${this.config.readApiUrl}/${this.getTypeUrl(type)}/${trainingId}`)
      .pipe(map(training => {
        training.typeId = type;
        return training;
      }));
  }

  changeTrainingType(trainingId: string, oldType: TRAINING_TYPE, newType: TRAINING_TYPE): Observable<void> {
    return this.http.put<void>(`${this.config.writeApiUrl}/${this.getTypeUrl(oldType)}/${trainingId}/changetype`, {
      trainingTypeId: newType
    });
  }

  createTraining(data: BaseTraining): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(
      `${this.config.writeApiUrl}/${this.getTypeUrl(data.typeId)}`,
      data);
  }

  updateTraining(trainingId: string, data: BaseTraining): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(
      `${this.config.writeApiUrl}/${this.getTypeUrl(data.typeId)}/${trainingId}`,
      data);
  }

  deleteTraining(trainingId: string, type: TRAINING_TYPE): Observable<any> {
    return this.http.delete<any>(`${this.config.writeApiUrl}/${this.getTypeUrl(type)}/${trainingId}`);
  }

  getFinancialDocuments(trainingId: string): Observable<PaginatedResponse<FinancialDocument>> {
    return this.http.get<PaginatedResponse<FinancialDocument>>(`${this.config.readApiUrl}/financialdocuments`, {
      params: {
        trainingId: trainingId
      }
    }).pipe(map(result => {
      result.data = result.data.map(financialDocument => this.globalMapper.setEditedBy(financialDocument));
      return result;
    }));
  }

  getFinancialDocument(documentId: string): Observable<FinancialDocument> {
    return this.http.get<FinancialDocument>(`${this.config.readApiUrl}/financialdocuments/${documentId}`);
  }

  createFinancialDocument(trainingId: string, formData: any): Observable<{ id: string }> {
    formData.append('trainingId', trainingId);
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/financialdocuments`, formData);
  }

  updateFinancialDocument(financialDocumentId: string, data: FinancialDocument): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/financialdocuments/${financialDocumentId}`, data);
  }

  deleteFinancialDocument(financialDocumentId: string): Observable<void> {
    return this.http.delete<void>(`${this.config.writeApiUrl}/financialdocuments/${financialDocumentId}`);
  }

  getReimbursements(trainingId: string): Observable<Reimbursement[]> {
    return this.http.get<Reimbursement[]>(`${this.config.readApiUrl}/reimbursements`, {
      params: {
        trainingId: trainingId
      }
    })
  }

  createReimbursement(reimbursement: Reimbursement): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/reimbursements`, reimbursement);
  }

  updateReimbursement(reimbursementId: string, reimbursement: Reimbursement): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/reimbursements/${reimbursementId}`, reimbursement);
  }

  deleteReimbursement(reimbursementId: string): Observable<void> {
    return this.http.delete<void>(`${this.config.writeApiUrl}/reimbursements/${reimbursementId}`);
  }

  executeReimbursementAction(reimbursementId: string, action: REIMBURSEMENT_ACTION_ENUM, data: any): Observable<any> {
    return this.http.put<{id: string}>(`${this.config.writeApiUrl}/reimbursements/${reimbursementId}/${action.toString().toLowerCase()}`, data);
  }

  createReimbursementCorrection(reimbursementId: string, correction: Reimbursement): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/reimbursements/${reimbursementId}/correction`, correction);
  }

  getReimbursementExtraInfo(trainingId: string, reimbursementId: string): Observable<ReimbursementExtraInfo> {
    let params: any = {
      trainingId: trainingId
    };
    if (reimbursementId) {
      params.reimbursementId = reimbursementId;
    }
    return this.http.get<ReimbursementExtraInfo>(`${this.config.readApiUrl}/reimbursements/possiblereimbursements`, {
      params: params
    }).pipe(map(result => {
      const possibleReimbursement = [];
      if (result.preparationReimbursementMaxAmount) {
        possibleReimbursement.push(REIMBURSEMENT_TYPE.PREPARATION);
      }
      if (result.trainingReimbursementMaxAmount) {
        possibleReimbursement.push(REIMBURSEMENT_TYPE.TRAINING);
      }
      if (result.smeBonusReimbursementAmount) {
        possibleReimbursement.push(REIMBURSEMENT_TYPE.SME_BONUS);
      }
      if (result.reimbursementTransitions?.length > 0) {
        possibleReimbursement.push(REIMBURSEMENT_TYPE.TRANSITION);
      }
      result.possibleReimbursements = possibleReimbursement;
      return result;
    }))
  }

  getReimbursementCorrectionInfo(reimbursementId: string): Observable<ReimbursementCorrectionInfo> {
    return this.http.get<ReimbursementCorrectionInfo>(`${this.config.readApiUrl}/reimbursements/${reimbursementId}/possiblecorrection`);
  }
}
