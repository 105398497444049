import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IconMapper {

  public list: { [key: string]: string } = {
    "weekday": "fas fa-calendar-week text-pink",
  };

  getIcon(name: string): string {
    return this.list[name] || "";
  }
}
