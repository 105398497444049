import { Component, HostListener } from '@angular/core';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'alimento-ipv-frontend-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  loading$ = this.loaderService.loading$;

  constructor(private loaderService: LoaderService) {}

  @HostListener('window:keyup.control.shift.alt.n', ['$event'])
  keyEvent() {
    alert('Gemaakt door Niels in opdracht van Sirus');
  }
}
