import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { NO_BRANCH_ID, TranslationService } from '@alimento-ipv-frontend/ui-lib';
import { Subscription } from 'rxjs';
import { Employment, EmploymentAction } from '../../../types/person.type';
import { EMPLOYMENT_ITEM_VIEW_MODE, EmploymentActionEnum } from '../../../types/person.enum';

@Component({
  selector: 'alimento-ipv-frontend-employment-list-item',
  templateUrl: './employment-list-item.component.html'
})
export class EmploymentListItemComponent implements OnChanges, OnDestroy {
  @Input()
  employment!: Employment;

  @Input()
  readOnly = false;

  @Input()
  viewMode: EMPLOYMENT_ITEM_VIEW_MODE = EMPLOYMENT_ITEM_VIEW_MODE.PERSON;

  @Output()
  actionClicked = new EventEmitter<EmploymentAction>();

  isNoBranch = false;
  actions: MenuItem[] = [];

  private _subscriptions: Subscription[] = [];
  protected readonly EMPLOYMENT_ITEM_VIEW_MODE = EMPLOYMENT_ITEM_VIEW_MODE;

  constructor(private translate: TranslateService,
              private translationService: TranslationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['employment']?.currentValue) {
      this.isNoBranch = this.employment.branchId === NO_BRANCH_ID;
      this._clearSubscriptions();
      this._subscriptions.push(
        this.translationService.languageChange$.subscribe(() => this.actions = this.getActions())
      );
    }
  }

  ngOnDestroy(): void {
    this._clearSubscriptions();
  }

  private _clearSubscriptions(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this._subscriptions = [];
  }

  private _getMenuItem(
    translateKey: string,
    action: EmploymentActionEnum,
    disabled = false,
    icon?: string
  ): MenuItem {
    const menuItem: MenuItem = {
      id: translateKey,
      label: this.translate.instant(translateKey),
      icon: icon,
      disabled,
      command: () => this.actionClicked.emit({ action: action, employment: this.employment })
    };

    if (icon) {
      menuItem.iconStyle = {
        position: 'absolute',
        right: '2px'
      };
    }

    return menuItem;
  }

  getActions(): MenuItem[] {
    const navigateToBranch: MenuItem = {
      id: 'persons.employments.actions.navigateToBranch',
      label: this.translate.instant('persons.employments.actions.navigateToBranch'),
      icon: 'pi pi-external-link',
      iconStyle: {
        position: 'absolute',
        right: '2px'
      },
      command: () => window.open(`/branches/${this.employment.branchId}/detail`, '_blank')
    };
    const navigateToPerson: MenuItem = {
      id: 'persons.employments.actions.navigateToPerson',
      label: this.translate.instant('persons.employments.actions.navigateToPerson'),
      icon: 'pi pi-external-link',
      iconStyle: {
        position: 'absolute',
        right: '2px'
      },
      command: () => window.open(`/persons/${this.employment.personId}/detail`, '_blank')
    };

    if (this.employment.endDate || this.readOnly) {
      const menuItems = [
        this._getMenuItem('persons.employments.actions.view', EmploymentActionEnum.view)
      ];
      if (!this.isNoBranch && this.viewMode === EMPLOYMENT_ITEM_VIEW_MODE.PERSON) {
        menuItems.push(navigateToBranch);
      }
      else if (this.viewMode === EMPLOYMENT_ITEM_VIEW_MODE.BRANCH) {
        menuItems.push(navigateToPerson);
      }
      return menuItems;
    }
    else {
      const stopEmployment = this._getMenuItem(
        'persons.employments.actions.stopEmployment',
        EmploymentActionEnum.stopEmployment,
        false,
        'pi pi-times'
      );
      stopEmployment.styleClass = 'danger';

      const menuItems = [this._getMenuItem('persons.employments.actions.edit', EmploymentActionEnum.edit)];
      if (this.viewMode === EMPLOYMENT_ITEM_VIEW_MODE.PERSON) {
        menuItems.push(this._getMenuItem(
          'persons.employments.actions.setAsMainEmployment',
          EmploymentActionEnum.setAsMainEmployment,
          this.employment.isMainEmployment,
          'pi pi-star'
        ));

        if (!this.isNoBranch) {
          menuItems.push(navigateToBranch);
        }
      }
      else {
        menuItems.push(navigateToPerson);
      }

      menuItems.push(stopEmployment);
      return menuItems;
    }
  }
}
