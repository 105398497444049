import { Component, OnDestroy } from '@angular/core';
import { first, Subscription } from 'rxjs';
import { environment } from '@alimento-ipv-frontend/environments';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  AppMenuItem,
  AuthenticationService,
  FEATURE,
  FeatureFlagService,
  FilterType,
  SearchRequest,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { COMMUNICATION_TO_DO, CommunicationsService } from '@alimento-ipv-frontend/application-lib';

@Component({
  selector: 'alimento-ipv-frontend-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnDestroy {
  appMenuItems: AppMenuItem[] = [];

  private _subscription: Subscription;
  protected readonly environment = environment;

  constructor(private translationService: TranslationService,
              private authenticationService: AuthenticationService,
              private router: Router,
              private communicationService: CommunicationsService,
              private translateService: TranslateService,
              private featureService: FeatureFlagService) {
    this._subscription = this.authenticationService.currentUser
      .subscribe(user => {
        if (user) {
          this.translationService.languageChange$.subscribe(() => {
            this._setMenuItems();
          });
        }
      });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  private _setMenuItems(): void {
    this.appMenuItems = [];
    this.translateService
      .get([''])
      .pipe(first())
      .subscribe(() => {
        const communicationRequest = { filters: [] } as SearchRequest;
        communicationRequest.filters.push({ type: FilterType.toDo, values: [COMMUNICATION_TO_DO] });
        communicationRequest.filters.push({
          type: FilterType.filterSenderId,
          values: [this.authenticationService.getCurrentUserId()]
        });

        this.featureService.initialize().pipe(first()).subscribe(() => {
          this.appMenuItems = [
            {
              icon: 'school',
              label: this.translateService.instant('trainings.menuTitle'),
              onClickAction: (ctrlKey: boolean) => {
                this._click(['/trainings'], ctrlKey);
              }
            },
            {
              icon: 'copy_all',
              label: this.translateService.instant('templates.menuTitle'),
              onClickAction: (ctrlKey: boolean) => {
                this._click(['/training-templates'], ctrlKey);
              }
            },
            {
              icon: 'domain',
              label: this.translateService.instant('enterprises.menuTitle'),
              onClickAction: (ctrlKey: boolean) => {
                this._click(['/enterprises'], ctrlKey);
              }
            },
            {
              icon: 'store',
              label: this.translateService.instant('branches.menuTitle'),
              onClickAction: (ctrlKey: boolean) => {
                this._click(['/branches'], ctrlKey);
              }
            },
            {
              icon: 'person',
              label: this.translateService.instant('persons.menuTitle'),
              onClickAction: (ctrlKey: boolean) => {
                this._click(['/persons'], ctrlKey);
              }
            },
            {
              icon: 'mail',
              label: this.translateService.instant('communications.menuTitle'),
              onClickAction: (ctrlKey: boolean) => {
                this._click(['/communications'], ctrlKey);
              },
              badgeFunction: this.communicationService.getCommunicationsCount(communicationRequest)
            }
          ];

          if (this.featureService.featureOn(FEATURE.OPEN_EXTERN_AND_CUSTOM_TRAININGS)) {
            this.appMenuItems.push({
              icon: 'stacks',
              label: this.translateService.instant('reimbursementRequests.menuTitle'),
              onClickAction: (ctrlKey: boolean) => {
                this._click(['/reimbursement-requests'], ctrlKey);
              }
            });
          }
        });
      });
  }

  private _click(url: string[], ctrlKey: boolean) {
    if (ctrlKey) {
      window.open(url.join("/"), "_blank");
    }
    else {
      this.router.navigate(url);
    }
  }
}
