<div class='mt-4 mx-8'>
  <div class="flex items-center justify-between mb-4">
    <h1 class="uppercase">{{ 'trainings.menuTitle' | translate }}</h1>
    <div *removeIfFeatureOn='FEATURE.CEVORA_TRAINING'>
      <p-button
        *hideIfAllUnauthorized='[Role.ADMIN, Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE]'
        label="{{ 'trainings.actions.add' | translate }}"
        styleClass="uppercase"
        [routerLink]="['open/create']"
      ></p-button>
    </div>

    <div *removeIfFeatureOff='FEATURE.CEVORA_TRAINING'>
      <p-menu #menu [model]='addButtonItems' [popup]='true' appendTo='body'></p-menu>
      <p-button (click)="menu.toggle($event)"
                *hideIfAllUnauthorized='[Role.ADMIN, Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE]'
                icon='pi pi-chevron-down'
                iconPos='right'
                label="{{ 'trainings.actions.newFormation' | translate }}"
                styleClass="uppercase"
      ></p-button>
    </div>
  </div>

  <lib-search [data$]='searchData$'
              [filters]='searchFilters'
              [cardTemplate]='cardTemplate'
              [sortItems]='sortFilters'
              [expandAll]='false'
              name='trainings-list'
              searchPlaceholderKey='trainings.searchbarPlaceholder'
              availableResultsTranslateKey='trainings.menuTitle'
  >
    <ng-template #cardTemplate let-training="item">
      <alimento-ipv-frontend-training-card [training]='training'>
      </alimento-ipv-frontend-training-card>
    </ng-template>
  </lib-search>
</div>

