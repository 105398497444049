import { Component, Input } from '@angular/core';
import { BranchListItem } from '../../../types/searches.type';

@Component({
  selector: 'alimento-ipv-frontend-branch-card',
  templateUrl: './branch-card.component.html'
})
export class BranchCardComponent {
  @Input()
  branch?: BranchListItem;
}
