import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {FeatureFlagService} from "../services/feature-flag.service";
import {Observable, tap} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagGuard implements CanActivate {
  constructor(private featureFlagService: FeatureFlagService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const featureFlag = route.data['featureFlag'];
    if (this.featureFlagService.initialized) {
      return featureFlag ? this.featureFlagService.featureOn(featureFlag) : true;
    }
    else {
      return new Observable<boolean>(observable =>
        this.featureFlagService.initialize().subscribe({
          next: () => {
            observable.next(featureFlag ? this.featureFlagService.featureOn(featureFlag) : true);
            observable.complete();
          },
          error: () => observable.error(false)
        })
      ).pipe(
        tap((canActivate: boolean) => {
          if (!canActivate) {
            this.router.navigate(['featureNotEnabled']);
          }
        })
      );
    }
  }
}
