import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export function conditionallyRequiredValidator(predicate: () => boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (predicate()) {
      return Validators.required(control);
    }
    return null;
  };
}

export function conditionalRequiredValidatorFn(condition: boolean): ValidatorFn {
  return condition ? Validators.required : Validators.nullValidator;
}
