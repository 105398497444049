import {Component, Input} from '@angular/core';
import { InfiniteScrollDataAdapter } from '../../../utils/InfiniteScrollDataAdapter';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-current-filter-info',
  templateUrl: './current-filter-info.component.html'
})
export class CurrentFilterInfoComponent {
  @Input()
  availableResultsTranslateKey = "availableResults";

  @Input()
  data$!: InfiniteScrollDataAdapter<any>;

  @Input()
  loadOnScroll = false;

}
