import { Component, Input } from '@angular/core';

@Component({
  selector: 'alimento-ipv-frontend-training-status-chip',
  templateUrl: './training-status-chip.component.html',
  styleUrls: ['./training-status-chip.component.scss'],
})
export class TrainingStatusChipComponent {
  @Input()
  status!: string;

  @Input()
  trainingOngoing: boolean | null = false;

  @Input()
  isCancelled: boolean | undefined = false;
}
