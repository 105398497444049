import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { APP_CONFIG_TOKEN, AppConfig, PaginatedResponse } from '@alimento-ipv-frontend/ui-lib';
import { TrainingTemplate, TrainingTemplateListItem } from '../types/training-template.type';
import {
  CaseManager,
  LanguageCode,
  TrainingTemplateRubric,
  TrainingTemplateStatus,
  TrainingTemplateType
} from '../types/reference-data.type';
import { TRAINING_TYPE } from '../types/reference-data.enum';

@Injectable({
  providedIn: 'root'
})
export class TrainingTemplateService {
  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getTemplates(templatesParams?: any): Observable<PaginatedResponse<TrainingTemplateListItem>> {
    const params: HttpParams = this.mapToHttpParams(templatesParams);
    return this.http.get<PaginatedResponse<TrainingTemplateListItem>>(`${this.config.readApiUrl}/trainingtemplate`, { params });
  }

  getTemplate(templateId: string): Observable<TrainingTemplate> {
    return this.http.get<TrainingTemplate>(`${this.config.readApiUrl}/trainingtemplate/${templateId}`)
      .pipe(map(result => {
        result.typeId = TRAINING_TYPE.TEMPLATE;
        return result;
      }));
  }

  createTemplate(template: TrainingTemplate): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/trainingtemplate`, template);
  }

  updateTemplate(templateId: string, template: TrainingTemplate): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/trainingtemplate/${templateId}`, template);
  }

  deleteTemplate(templateId: string): Observable<any> {
    return this.http.delete(`${this.config.writeApiUrl}/trainingtemplate/${templateId}`);
  }

  activateTemplate(templateId: string) {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/trainingtemplate/${templateId}/activate`, {});
  }

  private mapToHttpParams(templateParams: any): HttpParams {
    let params: HttpParams = new HttpParams();

    params = templateParams?.first ? params.append('off', templateParams?.first) : params;
    params = templateParams?.row ? params.append('l', templateParams?.rows) : params;

    params = templateParams?.sortField ? params.append('s', templateParams?.sortField) : params;
    params = templateParams?.sortField ? params.append('o', templateParams?.sortOrder === 1 ? 'asc' : 'desc') : params;

    params = templateParams?.filters?.stai?.value ? params.append('stai', templateParams.filters.stai.value) : params;

    params = templateParams?.filters?.sct?.value ? params.append('sct', templateParams.filters.sct.value) : params;

    templateParams?.filters?.fr?.value?.forEach((trainingRubric: TrainingTemplateRubric) => {
      params = params.append('fr', trainingRubric.data);
    });

    templateParams?.filters?.ft?.value?.forEach((trainingType: TrainingTemplateType) => {
      params = params.append('ft', trainingType.data);
    });

    templateParams?.filters?.fs?.value?.forEach((status: TrainingTemplateStatus) => {
      params = params.append('fs', status.data);
    });

    templateParams?.filters?.fl?.value?.forEach((languageCode: LanguageCode) => {
      params = params.append('fl', languageCode.data);
    });

    templateParams?.filters?.fcm?.value?.forEach((caseManager: CaseManager) => {
      params = params.append('fcm', caseManager.data);
    });

    return params;
  }

  createTrainingFromTemplate(trainingTemplateId: string): Observable<string> {
    return this.http
      .post<{ id: string }>(`${this.config.writeApiUrl}/opentraining/fromtemplate`, { trainingTemplateId })
      .pipe(map((response) => response.id));
  }
}
