import { Component, HostListener, OnDestroy, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import {
  CanComponentDeactivate,
  ExtraMenuItem,
  FilterType,
  IFilter,
  InfiniteScrollDataAdapter,
  LeaveConfirmService,
  NOTES_TYPE,
  NotesSidebarComponent,
  PaginatedResponse,
  SearchFilter,
  SearchFilterType,
  SearchRequest,
  TabMenuItem,
  TitleService,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { first, forkJoin, map, Observable, Subscription, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ReimbursementRequestComponent } from '../../components/reimbursement-request/reimbursement-request.component';
import { MessageService } from 'primeng/api';
import { ReimbursementRequest } from '../../../types/reimbursement-request.type';
import { REIMBURSEMENT_REQUEST_STATUS } from '../../../types/reimbursement-request.enum';
import { ReimbursementRequestService } from '../../../services/reimbursement-request.service';
import { Branch } from '../../../types/branch.type';
import { TrainingSearchItem } from '../../../types/training.type';
import { BranchService } from '../../../services/branch.service';
import { TrainingService } from '../../../services/training.service';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { DOCUMENT_SET_TYPE, TRAINING_TYPE } from '../../../types/reference-data.enum';
import { DocumentService } from '../../../services/document.service';

@Component({
  selector: 'alimento-ipv-frontend-reimbursement-request-detail',
  templateUrl: './reimbursement-request-detail.component.html'
})
export class ReimbursementRequestDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  reimbursementRequestId: string;
  branch: Branch;
  reimbursementRequest: ReimbursementRequest;
  documentCount: WritableSignal<number> = signal(undefined);
  trainingsCount: WritableSignal<number> = signal(undefined);
  tabMenuItems: TabMenuItem[];
  extraMenuItems: ExtraMenuItem[];
  activeTabIndex = 0;
  showNotes = false;
  readOnly = false;
  saving = false;
  creatingRequest = false;

  searchTrainingData$: InfiniteScrollDataAdapter<TrainingSearchItem>;
  searchTrainingFilters: SearchFilter[];
  AllowanceApplicationNote = NOTES_TYPE.AllowanceApplicationNote;
  notesHasChanges = false;
  hasChanges = false;

  @ViewChild(NotesSidebarComponent)
  notesSidebarComponent: NotesSidebarComponent;

  @ViewChild(ReimbursementRequestComponent)
  basicInfoComponent: ReimbursementRequestComponent;

  private _subscriptions: Subscription[] = [];
  protected readonly REIMBURSEMENT_REQUEST_STATUS = REIMBURSEMENT_REQUEST_STATUS;

  constructor(
    private translateService: TranslateService,
    private reimbursementService: ReimbursementRequestService,
    private branchService: BranchService,
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
    private titleService: TitleService,
    private location: Location,
    private messageService: MessageService,
    private trainingService: TrainingService,
    private referenceDataService: ReferenceDataService,
    private leaveConfirmationService: LeaveConfirmService,
    private documentService: DocumentService
  ) {
    this._createMenuItems();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (!this.canDeactivate()) {
      $event.returnValue = 'Are you sure';
    }
  }

  ngOnInit() {
    this.route.params.pipe(first())
      .subscribe(params => {
        this.reimbursementRequestId = params['reimbursementRequestId'];
        if (this.reimbursementRequestId) {
          this._fetchReimbursementData();
        }
        else {
          this.reimbursementRequest = {} as ReimbursementRequest;
        }
      });

    this.route.queryParams.pipe(first())
      .subscribe(queryParams => this.activeTabIndex = Number(queryParams['activeTabIndex']) || 0);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  canDeactivate(): Promise<boolean> | boolean {
    if (!this.hasChanges && !this.notesHasChanges) {
      return true;
    }

    const saveActions: Observable<any>[] = [];
    if (this.hasChanges) {
      if (this.reimbursementRequestId) {
        saveActions.push(this._saveReimbursementRequest());
      }
      else {
        saveActions.push(this._createReimbursementRequest());
      }
    }

    if (this.notesHasChanges) {
      saveActions.push(this.notesSidebarComponent.saveChanges());
    }

    return this.leaveConfirmationService.leaveDialog(() => forkJoin(saveActions));
  }

  private _fetchReimbursementData(): void {
    this.reimbursementService.getRequest(this.reimbursementRequestId)
      .pipe(first())
      .subscribe({
        next: reimbursementRequest => {
          this.reimbursementRequest = reimbursementRequest;
          if (!this.branch || this.branch.branchId !== reimbursementRequest.branchId) {
            this._getBranchData();
          }

          this._createMenuItems();
          this._loadDocuments();
          this._loadTrainingsCount();
          this._createTrainingSearch();
        },
        error: () => {
          this.router.navigate(['error'], {
            state: {
              message: this.translateService.instant('error.itemWithIdDoesNotExist', {
                item: this.translateService.instant('reimbursementRequests.request'),
                id: this.reimbursementRequestId
              }),
              redirectUrl: '/reimbursement-requests'
            }
          });
        }
      });
  }

  private _getBranchData(): void {
    this.branchService.getBranch(this.reimbursementRequest.branchId).pipe(first())
      .subscribe({
        next: branch => {
          this.branch = branch;
          this.titleService.setTitle(
            [`${this.translateService.instant('titles.reimbursementRequests.detail')} - ${this.branch.branchName}`]);
        }
      });
  }

  setActiveTabIndex(tabMenuItem: TabMenuItem) {
    this.activeTabIndex = tabMenuItem.index;
    this._setDetailUrl();
  }

  saveReimbursementRequest(): void {
    if (this.reimbursementRequestId) {
      this._saveReimbursementRequest().subscribe();
    }
    else {
      this._createReimbursementRequest().subscribe();
    }
  }

  private _setDetailUrl(): void {
    if (this.reimbursementRequestId) {
      this.location.replaceState(
        this.router
          .createUrlTree(['/reimbursement-requests', this.reimbursementRequestId, 'detail'], {
            queryParams: { activeTabIndex: this.activeTabIndex }
          })
          .toString()
      );
    }
  }

  private _createMenuItems(): void {
    this._subscriptions.push(
      this.translationService.languageChange$.subscribe(() => {
        this.tabMenuItems = [
          { name: 'basic', index: 0, title: this.translateService.instant('reimbursementRequests.basicDataTitle') },
          {
            name: 'training',
            index: 1,
            disabled: !this.reimbursementRequest?.trainingAllowanceApplicationId,
            title: this.translateService.instant('reimbursementRequests.trainingTitle'),
            count: this.trainingsCount
          }
        ];

        this.extraMenuItems = [
          {
            name: 'notes',
            title: this.translateService.instant('branches.notesTitle'),
            disabled: !this.reimbursementRequest?.trainingAllowanceApplicationId,
            command: () => {
              if (this.showNotes) {
                this.notesSidebarComponent.close();
              }
              else {
                this.showNotes = !this.showNotes;
              }
            }
          },
          {
            name: 'documents',
            title: this.translateService.instant('documents'),
            disabled: !this.reimbursementRequest?.trainingAllowanceApplicationId || !this.reimbursementRequest?.documentSetUrl,
            count: this.documentCount,
            command: () => {
              window.open(this.reimbursementRequest?.documentSetUrl, '_blank');
            }
          }
          // {
          //   name: 'history',
          //   title: this.translateService.instant('reimbursementRequests.historyTitle'),
          //   disabled: true,
          //   command: () => {
          //
          //   }
          // }
        ];
      })
    );
  }

  private _loadDocuments(): void {
    if (this.reimbursementRequest?.documentSetUrl) {
        this.documentService.getDocumentCount(this.reimbursementRequestId, DOCUMENT_SET_TYPE.ALLOWANCE_APPLICATION).pipe(first())
        .subscribe(count => {
          this.documentCount.set(count.count);
          if (count.count === -1) {
            this.documentService.createDocumentSet(this.reimbursementRequestId, DOCUMENT_SET_TYPE.ALLOWANCE_APPLICATION).pipe(first())
              .subscribe(documentSet => {
                this.reimbursementRequest.documentSetUrl = documentSet.url;
                this.documentCount.set(0);
                this._createMenuItems();
              });
          }
        });
    }
  }

  private _loadTrainingsCount(): void {
    if (this.reimbursementRequest) {
      const searchRequest: SearchRequest = { filters: [] };
      searchRequest.filters.push({
        type: FilterType.filterTrainingAllowanceApplicationId,
        values: [this.reimbursementRequestId]
      });
      this.trainingService.getTrainingsCount(searchRequest).pipe(first())
        .subscribe(count => {
          this.trainingsCount.set(count);
        });
    }
  }

  private _createTrainingSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: []
    };
    searchRequest.filters.push({
      type: FilterType.filterTrainingAllowanceApplicationId,
      values: [this.reimbursementRequestId]
    });

    this.searchTrainingData$ = new InfiniteScrollDataAdapter<TrainingSearchItem>((searchRequest: SearchRequest):
    Observable<PaginatedResponse<TrainingSearchItem>> => {
      return this.trainingService.searchTrainings(searchRequest);
    }, searchRequest, true);

    this.searchTrainingFilters = [
      {
        type: SearchFilterType.searchBar,
        key: FilterType.search
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.rubric',
        key: FilterType.filterRubric,
        data: this.referenceDataService.getTrainingRubrics()
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterRubric,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.type',
        key: FilterType.filterType,
        data: this.referenceDataService.getTrainingTypes()
          .pipe(map(types => types
            .filter(type => type.data !== TRAINING_TYPE.OPEN_TRAINING)
            .map(type =>
              ({
                type: FilterType.filterType,
                label: type.label,
                value: type.data
              }) as IFilter))
          )
      },
      {
        type: SearchFilterType.date,
        label: 'trainings.sessions.date',
        key: 'date'
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.caseManager',
        key: FilterType.filterCaseManager,
        data: this.referenceDataService.getCaseManagers(true)
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterCaseManager,
              label: type.label,
              value: type.data
            }) as IFilter)))
      }
    ];
  }

  private _createReimbursementRequest(): Observable<any> {
    if (!this.basicInfoComponent.isValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('trainings.sessions.hasErrors')
      });

      return new Observable<{ id: string }>((observable) => observable.error());
    }

    this.creatingRequest = true;
    const data = this.basicInfoComponent.getData() as ReimbursementRequest;

    return this.reimbursementService.createRequest(data)
      .pipe(first(),
        tap({
          next: (response: { id: string }) => {
            this.reimbursementRequestId = response.id;
            this._fetchReimbursementData();
            this._setDetailUrl();
            this.creatingRequest = false;
            this.hasChanges = false;
          },
          error: () => {
            this.creatingRequest = false;
          }
        }));
  }

  private _saveReimbursementRequest(): Observable<any> {
    if (!this.basicInfoComponent.isValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('trainings.sessions.hasErrors')
      });

      return new Observable<{ id: string }>((observable) => observable.error());
    }

    this.saving = true;
    const data = this.basicInfoComponent.getData() as ReimbursementRequest;

    return this.reimbursementService.updateRequest(this.reimbursementRequestId, data)
      .pipe(first(), tap({
        next: () => {
          this.saving = false;
          this.hasChanges = false;
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('saveSuccess')
          });
        },
        error: () => {
          this.saving = false;
        }
      }));
  }
}
