import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class MenuItemUtilsService {
  constructor(private translate: TranslateService) {}

  getMenuItem(translateKey: string, action: () => any, disabled: boolean = false, icon?: string): MenuItem {
    const menuItem: MenuItem = {
      id: translateKey,
      label: this.translate.instant(translateKey),
      icon: icon,
      disabled: disabled,
      command: action,
    };

    if (icon) {
      menuItem.iconStyle = {
        position: 'absolute',
        right: '2px',
      };
    }

    return menuItem;
  }
}
