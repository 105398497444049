import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
  Validators
} from '@angular/forms';
import { first } from 'rxjs';
import { FormComponent } from '@alimento-ipv-frontend/ui-lib';
import { Country } from '../../../types/reference-data.type';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-address',
  templateUrl: './address.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AddressComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: AddressComponent
    },
    { provide: FormComponent, useExisting: AddressComponent }
  ]
})
export class AddressComponent extends FormComponent implements OnChanges, ControlValueAccessor, Validator {
  countries: Country[];

  @Input()
  optional = false;

  @Input()
  requiredFields: string[];

  @Input()
  readOnly = false;

  ALL_REQUIRED_FIELDS = ['street', 'houseNumber', 'city', 'postalCode', 'country'];
  countryReadOnly: string;

  get value(): any {
    return this.formGroup.value;
  }

  set value(value: any) {
    this.formGroup.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  constructor(private fb: FormBuilder, private referenceDataService: ReferenceDataService) {
    super();
    this.formGroup = this.createAddressForm();
    this._setOptionLists();
    this.subscriptions.push(
      this.formGroup.valueChanges.subscribe((value) => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['optional']?.currentValue !== undefined || changes['requiredFields']?.currentValue) {
      this.setValidators();
    }
  }

  override writeValue(value: any) {
    if (value) {
      this.value = value;
      this._setOptionLists();
    }
    else {
      this.formGroup.reset();
    }
  }

  validate() {
    return this.formGroup.valid ? null : { address: { valid: false } };
  }

  override setDisabledState(disabled: boolean): void {
    disabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  private createAddressForm(): FormGroup {
    return this.fb.group({
      street: [''],
      houseNumber: [''],
      mailbox: [''],
      city: [''],
      postalCode: [''],
      country: ['']
    });
  }

  private setValidators(): void {
    this.ALL_REQUIRED_FIELDS.forEach(field => {
      const control = this.formGroup.get(field);
      if (this.requiredFields) {
        control.setValidators(this.requiredFields.includes(field) ? Validators.required : []);
      }
      else {
        control?.setValidators(this.optional ? [] : Validators.required);
      }
      control?.updateValueAndValidity();
    });
    this.formGroup.updateValueAndValidity();
  }

  private _setOptionLists(): void {
    this.referenceDataService.getCountries().pipe(first()).subscribe(countries => {
      this. countries = countries;
      if (this.formGroup.get('country').value) {
        this.countryReadOnly = countries.filter(country => country.data === this.formGroup.get('country').value)[0].label;
      }
    })
  }
}
