<div *ngIf="financialDocument" class="group flex flex-col border-b border-b-grey-2 py-2">
  <div
    (click)="toggleShowExtraInfo()"
    class="flex gap-4 cursor-pointer group-hover:bg-primary group-hover:bg-opacity-[7%]"
  >
    <div>
      <div
        (keyup.enter)="toggleShowExtraInfo()"
        class="flex items-center p-2 m-2 text-primary outline-primary"
        tabindex="0"
      >
        <i class="pi pi-angle-{{ showExtraInfo ? 'up' : 'down' }}"></i>
      </div>
    </div>

    <div class="flex-grow mb-4">
      <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 items-start mt-2 mr-2">
        <span class="text-title">
          {{type}} - {{financialDocument.reference}}
        </span>
        <div class='flex flex-col gap-1'>
          <span>{{ "financialDocuments.totalAmountShort" | translate }}: {{financialDocument.amountWithoutPreparation | currency:'EUR'}}</span>
          <span *ngIf='financialDocument.amountPreparation'>{{ "financialDocuments.preparationAmountShort" | translate }}: {{financialDocument.amountPreparation | currency:'EUR'}}</span>
        </div>

        <div class="lg:justify-end flex items-center flex-nowrap gap-4">
          <span class=''>{{'financialDocuments.byOn' | translate:
            {by: financialDocument.editedBy || ('branches.trainingPlans.noName' | translate),
              on: financialDocument.editedOn ? (financialDocument.editedOn | date : 'dd/MM/yyyy') : ('branches.trainingPlans.noName' | translate)} }}</span>
          <alimento-ipv-frontend-more-actions [actions]="actions"></alimento-ipv-frontend-more-actions>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showExtraInfo" class="session-extra-info grid grid-cols-2 bg-grey-0 mt-4 p-2 pl-12">
    <div class='flex flex-col gap-1 border-r border-r-grey-2'>
      <span>{{financialDocument.remark}}</span>
    </div>

    <div class='ml-4'>
      <lib-documents [files]='financialDocument.files'
                     [showFilesAsList]='true'
                     [canAdd]='false'
                     [canDelete]='false'>
      </lib-documents>
    </div>
  </div>
</div>

