import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { dateValid, FormComponent } from '@alimento-ipv-frontend/ui-lib';
import { first } from 'rxjs';
import { TrainingProgram } from '../../../types/training-program.type';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-training-program',
  templateUrl: './training-program.component.html',
  providers: [
    { provide: FormComponent, useExisting: TrainingProgramComponent }
  ]
})
export class TrainingProgramComponent extends FormComponent implements OnChanges {
  @Input()
  trainingProgram: TrainingProgram;

  rubrics: any[] | undefined;

  rubricReadOnly: string;

  constructor(private formBuilder: FormBuilder, private referenceDataService: ReferenceDataService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['trainingProgram']?.currentValue) {
      this._createForm();
    }
  }

  private _createForm(): void {
    this.formGroup = this.formBuilder.group({
      branchId: [this.trainingProgram.branchId, Validators.required],
      name: [this.trainingProgram.name, Validators.required],
      rubricId: [this.trainingProgram.rubricId, Validators.required],
      startDate: [this.trainingProgram.startDate ? new Date(this.trainingProgram.startDate) : undefined, [Validators.required, dateValid()]],
      endDate: [this.trainingProgram.endDate ? new Date(this.trainingProgram.endDate) : undefined, [dateValid()]]
    });

    this._getRubrics();
  }

  private _getRubrics(): void {
    this.referenceDataService.getTrainingRubrics().pipe(first())
      .subscribe(rubrics => {
        this.rubrics = rubrics;
        if (this.trainingProgram.rubricId) {
          this.referenceDataService.getTrainingRubric(this.trainingProgram.rubricId).pipe(first())
            .subscribe(rubric => this.rubricReadOnly = rubric.label)
        }
      });
  }
}
