<p-dialog
  (blur)="popupVisible = false"
  *ngIf='popupVisible'
  (onHide)="closePopup()"
  [(visible)]="popupVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '40rem' }"
  [closable]="false"
  appendTo="body"
  header="{{
    (trainingPlan?.id ? 'branches.trainingPlans.editTrainingPlan' : 'branches.trainingPlans.new') | translate
  }}"
>
  <div *ngIf="trainingPlan" [formGroup]="formGroup">
    <div class="grid grid-cols-2 gap-4 w-fit">
      <div class="w-[202px]">
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [required]='true'
          [errorTemplate]='dateErrorTemplate'
          formField='validFrom'
          fieldKey='branches.trainingPlans.validFrom'
        >
          <p-calendar
            [showIcon]="true"
            [showTime]="false"
            [showOnFocus]='false'
            [keepInvalid]='true'
            appendTo="body"
            dataType="date"
            dateFormat="dd/mm/yy"
            formControlName="validFrom"
            id="validFrom"
            placeholder="dd/mm/yyyy"
            styleClass="w-full"
          ></p-calendar>

          <ng-template #dateErrorTemplate>
            <small *ngIf='formGroup.get("validFrom")?.hasError("required")'>
              {{ 'trainings.sessions.dateRequired' | translate }}
            </small>
            <small *ngIf='formGroup.get("validFrom").hasError("invalidDate")'>
              {{ 'validation.invalidDate' | translate }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>
      </div>
      <div class="w-[202px]">
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [required]='true'
          [errorTemplate]='dateErrorTemplate2'
          formField='validTo'
          fieldKey='branches.trainingPlans.validTo'
        >
          <p-calendar
            [showIcon]="true"
            [showTime]="false"
            [showOnFocus]='false'
            [keepInvalid]='true'
            appendTo="body"
            dataType="date"
            dateFormat="dd/mm/yy"
            formControlName="validTo"
            id="validTo"
            placeholder="dd/mm/yyyy"
            styleClass="w-full"
          ></p-calendar>

          <ng-template #dateErrorTemplate2>
            <small *ngIf='formGroup.get("validTo")?.hasError("required")'>
              {{ 'trainings.sessions.dateRequired' | translate }}
            </small>
            <small *ngIf='formGroup.get("validTo").hasError("invalidDate")'>
              {{ 'validation.invalidDate' | translate }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>
      </div>
      <div class="w-[202px]">
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [errorTemplate]='dateErrorTemplate3'
          formField='due'
          fieldKey='branches.trainingPlans.due'
        >
          <p-calendar
            [showIcon]="true"
            [showTime]="false"
            [showOnFocus]='false'
            [keepInvalid]='true'
            appendTo="body"
            dataType="date"
            dateFormat="dd/mm/yy"
            formControlName="due"
            id="due"
            placeholder="dd/mm/yyyy"
            styleClass="w-full"
          ></p-calendar>

          <ng-template #dateErrorTemplate3>
            <small *ngIf='formGroup.get("due")?.hasError("required")'>
              {{ 'trainings.sessions.dateRequired' | translate }}
            </small>
            <small *ngIf='formGroup.get("due").hasError("invalidDate")'>
              {{ 'validation.invalidDate' | translate }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>
      </div>
    </div>
    <small *ngIf="formGroup.get('validTo')?.hasError('minValueToLow')" class="p-error block">
      {{ 'branches.trainingPlans.validToToLow' | translate }}
    </small>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [required]='true'
                                      fieldKey='branches.trainingPlans.extra'
    >
      <div class='flex flex-col gap-2'>
        <p-checkbox
          formControlName='moreThan20Employees'
          [label]="'branches.trainingPlans.moreThan20Employees' | translate"
          [binary]='true'
        ></p-checkbox>

        <p-checkbox
          formControlName='validConsultativeBody'
          [label]="'branches.trainingPlans.validConsultativeBody' | translate"
          [binary]='true'
        ></p-checkbox>

        <div class='ml-2 flex flex-col gap-2'>
          <p-checkbox
            formControlName='validReceptionPolicy'
            [label]="'branches.trainingPlans.validReceptionPolicy' | translate"
            [binary]='true'
          ></p-checkbox>

          <p-checkbox
            formControlName='consultedSyndic'
            [label]="'branches.trainingPlans.consultedSyndic' | translate"
            [binary]='true'
          ></p-checkbox>
        </div>
      </div>
    </alimento-ipv-frontend-form-field>

    <div class='mb-2'>
      <lib-documents [(files)]='files' [canDelete]='true' (filesChange)='filesChange($event)'></lib-documents>
      <small *ngIf="formGroup.get('files').dirty && formGroup.get('files')?.errors?.['minLength']" class='p-error block'>
        {{ 'validation.minOneFile' | translate }}
      </small>
    </div>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='comments'
      fieldKey='branches.trainingPlans.comments'
    >
      <textarea
        class="w-full"
        formControlName="comments"
        id="comments"
        pInputTextarea
        rows="2"
      ></textarea>
    </alimento-ipv-frontend-form-field>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex">
      <p-button
        *ngIf="trainingPlan"
        [loading]="loading"
        (onClick)="addOrUpdateTrainingPlan()"
        icon="pi pi-save"
        iconPos="left"
        label="{{ ( trainingPlan.id ? 'branches.trainingPlans.actions.edit' : 'branches.trainingPlans.actions.create') | translate }}"
      ></p-button>
      <p-button
        (onClick)="closePopup()"
        icon="pi pi-times"
        iconPos="left"
        label="{{ 'branches.trainingPlans.cancel' | translate }}"
        styleClass="inverted-button"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
