<div [formGroup]="formGroup">
  <alimento-ipv-frontend-form-field
    [formGroup]='formGroup'
    formField='street'
    fieldKey='persons.street'
    [required]='!optional || requiredFields?.includes("street")'
    [formInputTemplate]='streetTemplate'
  >
    <ng-template #streetTemplate>
      <span *ngIf='readOnly' class='inputHeight'>{{formGroup.get('street').value}}</span>
      <input *ngIf='!readOnly' class="w-full" formControlName="street" id="street" pInputText type="text" />
    </ng-template>
  </alimento-ipv-frontend-form-field>

  <div class="flex gap-4 w-full">
    <div class='max-w-[140px]'>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        formField='houseNumber'
        fieldKey='persons.houseNumber'
        [required]='!optional || requiredFields?.includes("houseNumber")'
        [formInputTemplate]='houseNumberTemplate'
      >
        <ng-template #houseNumberTemplate>
          <span *ngIf='readOnly' class='inputHeight'>{{formGroup.get('houseNumber').value}}</span>
          <input *ngIf='!readOnly' class="w-full" formControlName="houseNumber" id="houseNumber" pInputText type="text" />
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>

    <div class="max-w-[120px]">
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        formField='mailbox'
        fieldKey='persons.mailbox'
        [required]='false'
        [formInputTemplate]='mailboxTemplate'
      >
        <ng-template #mailboxTemplate>
          <span *ngIf='readOnly' class='inputHeight'>{{formGroup.get('mailbox').value}}</span>
          <input *ngIf='!readOnly' class="w-full" formControlName="mailbox" id="mailbox" pInputText type="text" />
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>
  </div>

  <div class="flex gap-4">
    <div class='flex-1'>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        formField='city'
        fieldKey='persons.city'
        [required]='!optional || requiredFields?.includes("city")'
        [formInputTemplate]='cityTemplate'
        [hideError]='true'
        formGroupClass='!pb-0'
      >
        <ng-template #cityTemplate>
          <span *ngIf='readOnly' class='inputHeight'>{{formGroup.get('city').value}}</span>
          <input *ngIf='!readOnly' class="w-full" formControlName="city" id="city" pInputText type="text" />
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>

    <div class="max-w-[136px]">
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        formField='postalCode'
        fieldKey='persons.postalCode'
        [required]='!optional || requiredFields?.includes("postalCode")'
        [formInputTemplate]='postalCodeTemplate'
        [hideError]='true'
        formGroupClass='!pb-0'
      >
        <ng-template #postalCodeTemplate>
          <span *ngIf='readOnly' class='inputHeight'>{{formGroup.get('postalCode').value}}</span>
          <input *ngIf='!readOnly' class="w-full" formControlName="postalCode" id="postalCode" pInputText type="text" />
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>
  </div>

  <div class='pb-4'>
    <ng-container *ngIf="formGroup.get('city')?.dirty || formGroup.get('city')?.touched">
      <small *ngIf="formGroup.get('city')?.errors?.['required']" class="p-error block">
        {{ 'validation.isRequired' | translate : { field: 'persons.city' | translate } }}
      </small>
    </ng-container>
    <ng-container *ngIf="formGroup.get('postalCode')?.dirty || formGroup.get('postalCode')?.touched">
      <small *ngIf="formGroup.get('postalCode')?.errors?.['required']" class="p-error block">
        {{ 'validation.isRequired' | translate : { field: 'persons.postalCode' | translate } }}
      </small>
    </ng-container>
  </div>

  <alimento-ipv-frontend-form-field
    [formGroup]='formGroup'
    [formInputTemplate]='countryTemplate'
    [required]='!optional || requiredFields?.includes("country")'
    formField='country'
    fieldKey='persons.country'
  >
    <ng-template #countryTemplate>
      <span *ngIf='readOnly' class='inputHeight'>{{countryReadOnly}}</span>
      <p-dropdown
        *ngIf="!readOnly"
        [filter]="true"
        [options]="countries"
        appendTo="body"
        formControlName="country"
        id="country"
        optionLabel="label"
        optionValue="data"
        placeholder="{{ 'persons.educationLevelPlaceholder' | translate }}"
        styleClass="w-full"
        [showClear]="true"
      ></p-dropdown>
    </ng-template>
  </alimento-ipv-frontend-form-field>
</div>
