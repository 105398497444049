import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { first } from 'rxjs';
import { REIMBURSEMENT_REQUEST_STATUS } from '../../../types/reimbursement-request.enum';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-reimbursement-status-chip',
  templateUrl: './status-chip.component.html'
})
export class StatusChipComponent implements OnChanges {
  @Input()
  status!: REIMBURSEMENT_REQUEST_STATUS;

  statusLabel: string;

  constructor(private referenceDataService: ReferenceDataService){}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['status']?.currentValue) {
      this.referenceDataService.getReimbursementRequestStatus(this.status).pipe(first())
        .subscribe(data => this.statusLabel = data.label);
    }
    else {
      this.statusLabel = "";
    }
  }

  getStatusClass(): string {
    if (this.status === REIMBURSEMENT_REQUEST_STATUS.ACTIVE) {
      return "text-primary border border-primary ";
    }
    else {
      return "border-grey-3 text-grey-3";
    }
  }

}
