import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { BehaviorSubject, filter, Observable, Subscription, throwError } from 'rxjs';
import {
  AccountInfo,
  AuthenticationResult,
  InteractionStatus,
  RedirectRequest
} from '@azure/msal-browser';
import { environment } from '@alimento-ipv-frontend/environments';
import { Role } from '../types/Roles.enum';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {

  currentUser: BehaviorSubject<AccountInfo> = new BehaviorSubject<AccountInfo>(undefined);
  private _subscriptions: Subscription[] = [];

  constructor(private msalService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) {
    this._subscriptions.push(this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe({
        next: () => {
          const loggedInUser = this.msalService.instance.getAllAccounts()[0];
          this.currentUser.next(loggedInUser);
        },
        error: (error) => {
          console.error(error);
          this.refreshToken();
        }
      }));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  getCurrentUser(): AccountInfo {
    return this.currentUser.value;
  }

  getCurrentUserId(): string {
    return this.currentUser.value.idTokenClaims.oid;
  }

  logout(): void {
    this.msalService.logout();
  }

  login(): void {
    localStorage.clear();
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    }
    else {
      this.msalService.loginRedirect();
    }
  }

  getJwtToken(): Observable<string> {
    return new Observable<any>(observable => {
      this._getSilentToken()
        .subscribe( {
          next: (accessTokenResponse) => {
            if (accessTokenResponse?.accessToken != null) {
              const accessToken = jwtDecode(accessTokenResponse.accessToken) as any;
              observable.next(accessToken);
            }
            else {
              observable.next('');
            }
          },
          error: error => {
            console.error(error);
            this.refreshToken();
          }
        });
    });
  }

  getRoles(): Observable<Role[]> {
    return new Observable<Role[]>(observable => {
      this._getSilentToken()
        .subscribe({
          next: accessTokenResponse => {
            if (accessTokenResponse?.accessToken != null) {
              const accessToken = jwtDecode(accessTokenResponse.accessToken) as any;
              const roles = (accessToken?.roles || []) as Role[];
              observable.next(roles);
            }
            else {
              observable.next([]);
            }
            observable.complete();
          },
          error: error => {
            console.error(error);
            this.refreshToken()
          }
        });
    });
  }

  refreshToken() {
    this._getSilentToken().subscribe({ error: () => this.login() });
  }

  private _getSilentToken(): Observable<AuthenticationResult> {
    const account = this.msalService.instance.getAllAccounts()[0];
    if (!account) {
      this.login();
      return throwError(null);
    }

    const accessTokenRequest = {
      scopes: environment.scopes,
      account: account
    };

    return this.msalService.acquireTokenSilent(accessTokenRequest);
  }

}
