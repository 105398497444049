<lib-lazy-dropdown
  [data$]='data$'
  [template]='itemTemplate'
  [formControl]='formControl'
  [showSearchIcon]='false'
  [showClear]='true'
  [displayValue]='getSelectedString'
  dropdownIcon='pi pi-search'
  placeholder="{{ 'persons.searchPlaceholder' | translate }}"
  field='name'
>
  <ng-template #itemTemplate let-item="item">
    {{getSelectedString(item)}}
  </ng-template>
</lib-lazy-dropdown>
