<div class='mt-4 mx-8'>
  <div class="flex items-center justify-between mb-4">
    <h1 class="title text-text uppercase">{{ 'templates.templates' | translate }}</h1>
    <p-button
      *hideIfAllUnauthorized='[Role.ADMIN, Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE]'
      label="{{ 'templates.actions.add' | translate }}"
      styleClass="uppercase"
      [routerLink]="['create']"
    ></p-button>
  </div>
</div>

<div class="training-template-container">
  <div class="training-template-content">
    <p-table
      #trainingTemplateTable
      [value]="templates"
      [lazy]="true"
      (onLazyLoad)="loadTrainings($event)"
      dataKey="id"
      [rowsPerPageOptions]="[15, 25, 50]"
      [paginator]="true"
      [rows]="15"
      [totalRecords]="totalRecords"
      [loading]="loading"
      [showCurrentPageReport]="true"
      [rowHover]="true"
      [filters]="filters"
      [sortField]="sorting?.field"
      [sortOrder]="sorting?.order"
      [first]='page'
      currentPageReportTemplate="{{ 'primeng.currentPageReportTemplate' | translate }}"
      paginatorPosition="both"
    >
      <ng-template pTemplate="paginatorleft"></ng-template>

      <ng-template pTemplate="header">
        <tr class="table-titles">
          <th pSortableColumn="trainingTemplateAlimentoId" style="width: 8rem">
            Id
            <p-sortIcon field="trainingTemplateAlimentoId"></p-sortIcon>
          </th>
          <th pSortableColumn="customTitle">
            {{ 'templates.customTitle' | translate }}
            <p-sortIcon field="customTitle"></p-sortIcon>
          </th>
          <th pSortableColumn="rubric">
            {{ 'templates.rubric' | translate }}
            <p-sortIcon field="rubric"></p-sortIcon>
          </th>
          <th pSortableColumn="status">
            {{ 'templates.status' | translate }}
            <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th pSortableColumn="caseManager">
            {{ 'templates.caseManager' | translate }}
            <p-sortIcon field="caseManager"></p-sortIcon>
          </th>
          <th pSortableColumn="type">
            {{ 'templates.type' | translate }}
            <p-sortIcon field="type"></p-sortIcon>
          </th>
          <th pSortableColumn="languageCode" style="width: 7rem">
            {{ 'templates.languageCode' | translate }}
            <p-sortIcon field="languageCode"></p-sortIcon>
          </th>
          <th></th>
        </tr>
        <tr class="table-filters">
          <th>
            <p-columnFilter [showMenu]="false" [showClearButton]="false" type="text" field="stai">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-inputNumber [ngModel]='value'
                               [showClear]='true'
                               [useGrouping]="false"
                               (onInput)='onIdInput($event, filter)'
                               (onClear)='filter(null)'
                ></p-inputNumber>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter [showMenu]="false" [showClearButton]="false" type="text" field="sct">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <alimento-ipv-frontend-text-input [ngModel]='value'
                                                  (changeValue)='filter($event)'
                ></alimento-ipv-frontend-text-input>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="fr" [showMenu]="false" [showClearButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  appendTo="body"
                  [options]="(trainingRubrics$ | async)!"
                  (onChange)="filter($event.value)"
                  optionLabel="label"
                  [maxSelectedLabels]="1"
                  [selectedItemsLabel]="'primeng.selectedItemsLabel' | translate"
                  [showClear]="true"
                  (onClear)="filter([])"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="p-multiselect-representative-option">
                      <span class="ml-1">{{ option.label }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="fs" [showMenu]="false" [showClearButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  appendTo="body"
                  [options]="(trainingStatuses$ | async)!"
                  (onChange)="filter($event.value)"
                  optionLabel="label"
                  [maxSelectedLabels]="1"
                  [selectedItemsLabel]="'primeng.selectedItemsLabel' | translate"
                  [showClear]="true"
                  (onClear)="filter([])"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="p-multiselect-representative-option">
                      <alimento-ipv-frontend-training-status-chip
                        [status]="getTemplateStatusKey(option.data)"
                      ></alimento-ipv-frontend-training-status-chip>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="fcm" [showMenu]="false" [showClearButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  appendTo="body"
                  [options]="(caseManagers$ | async)!"
                  (onChange)="filter($event.value)"
                  optionLabel="label"
                  [maxSelectedLabels]="1"
                  [selectedItemsLabel]="'primeng.selectedItemsLabel' | translate"
                  [showClear]="true"
                  (onClear)="filter([])"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="p-multiselect-representative-option">
                      <span class="ml-1">{{ option.label }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="ft" [showMenu]="false" [showClearButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  appendTo="body"
                  [options]="(trainingTypes$ | async)!"
                  (onChange)="filter($event.value)"
                  optionLabel="label"
                  [maxSelectedLabels]="1"
                  [selectedItemsLabel]="'primeng.selectedItemsLabel' | translate"
                  [showClear]="true"
                  (onClear)="filter([])"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="p-multiselect-representative-option">
                      <span class="ml-1">{{ option.label }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <p-columnFilter field="fl" [showMenu]="false" [showClearButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  appendTo="body"
                  [options]="(languageCodes$ | async)!"
                  (onChange)="filter($event.value)"
                  optionLabel="label"
                  [maxSelectedLabels]="1"
                  [selectedItemsLabel]="'primeng.selectedItemsLabel' | translate"
                  [showClear]="true"
                  (onClear)="filter([])"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="p-multiselect-representative-option">
                      <span class="ml-1">{{ option.label }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th>
            <div style="display: flex; justify-content: flex-end">
              <button
                pButton
                class="inverted-button inverted-button--danger"
                icon="pi pi-filter-slash"
                (click)="clearFilters(trainingTemplateTable)"
                [pTooltip]="'clearFilters' | translate"
                tooltipPosition="left"
              ></button>
            </div>
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-template>
        <tr [routerLink]="[template.id, 'detail']" class="p-selectable-row">
          <td class="font-bold">{{ template.trainingTemplateAlimentoId }}</td>
          <td class="font-bold">{{ template.customTitle }}</td>
          <td class="font-bold">{{ template.rubric?.label }}</td>
          <td>
            <alimento-ipv-frontend-training-status-chip
              [status]="getTemplateStatusKey(template.status?.data)"
            ></alimento-ipv-frontend-training-status-chip>
          </td>
          <td>{{ template.caseManager?.label }}</td>
          <td>{{ template.type?.label }}</td>
          <td>{{ template.languageCode | uppercase }}</td>
          <td style="display: flex; justify-content: flex-end">
            <alimento-ipv-frontend-more-actions
              [actions]="actions"
              width="350px"
              [updateSelectedItem]="updateActionItem(template)"
            ></alimento-ipv-frontend-more-actions>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
