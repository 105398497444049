import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { SearchReimbursementRequestItem } from '../../../types/reimbursement-request.type';

@Component({
  selector: 'alimento-ipv-frontend-reimbursement-request-card',
  templateUrl: './reimbursement-request-card.component.html'
})
export class ReimbursementRequestCardComponent implements OnChanges {

  @Input()
  reimbursementRequest: SearchReimbursementRequestItem;

  constructor(private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {

  }
}
