<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ 'trainings.communicateChangesDialog.title' | translate }}"
>
<div *ngIf="dialogVisible" [formGroup]="formGroup">
  <span class='mb-4 block'>{{'trainings.communicateChangesDialog.labels.info' | translate}}</span>

  <div class='mb-4'>
    <span class='text-lg'>{{'trainings.communicateChangesDialog.selectParticipants' | translate}}</span>
  </div>

  <alimento-ipv-frontend-select-list
    formControlName='enrollmentIds'
    [items]='enrollments()'
    [label]='getEnrollmentsLabel'
    selectAllKey='trainings.communicateChangesDialog.selectAll'
  >
  </alimento-ipv-frontend-select-list>

  <span class='mb-4 block'>{{'trainings.communicateChangesDialog.labels.addExtraRemarks' | translate}}</span>

  <alimento-ipv-frontend-form-field
    [formGroup]='formGroup'
    [formInputTemplate]='commentsInput'
    formField='comments'
    fieldKey='trainings.cancelDialog.labels.remarks'
  >
    <ng-template #commentsInput>
      <textarea
        autofocus
        class="w-full"
        formControlName="comments"
        id="comments"
        pInputTextarea
        rows="2"
      ></textarea>
    </ng-template>
  </alimento-ipv-frontend-form-field>
</div>

  <ng-template pTemplate="footer">
    <div class="flex">
      <p-button
        (onClick)="submit()"
        [disabled]='nrOfEnrollments < 1'
        label="{{ 'trainings.communicateChangesDialog.buttons.communicateChanges' | translate }} ({{ nrOfEnrollments }})"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'trainings.communicateChangesDialog.buttons.back' | translate }}"
        styleClass="inverted-button"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
