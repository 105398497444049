<div *ngIf="purchaseEntry" class="hover:bg-primary hover:bg-opacity-[7%] p-4">
  <div class="flex flex-col gap-1 lg:flex-row lg:justify-between lg:gap-4">
    <div class='flex flex-col gap-1'>
      <div class='flex items-center gap-16'>
        <span class='font-bold'>{{"trainings.purchaseEntries.number" | translate}}: {{purchaseEntry.purchaseEntryNumber}}</span>
        <div class='flex items-center gap-1 flex-wrap'>
          <span class='font-bold'>{{"trainings.purchaseEntries.reference" | translate}}:</span>
          <span>{{purchaseEntry.reference}}</span>
          <span *ngIf='purchaseEntry.referenceDate'>- {{purchaseEntry.referenceDate | date: 'dd/MM/yyyy'}}</span>
        </div>
      </div>
      <div class='flex items-center gap-1 flex-wrap'>
        <span class='text-lg'>{{purchaseEntry.supplierBranch.alimentoId}}</span>
        <span>- {{purchaseEntry.supplierBranch.name}}</span>
        <span>- {{purchaseEntry.supplierBranch.address}}</span>
      </div>
    </div>
    <div class='flex flex-col items-end'>
      <span class='font-bold text-primary'>{{status}}</span>
      <span *ngIf='purchaseEntry.paymentDate'>
        {{'reimbursements.paymentDate' | translate:{date: purchaseEntry.paymentDate | date: "dd/MM/yyyy"} }}
      </span>
    </div>
  </div>
</div>

