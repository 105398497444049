<p-calendar
  ngDefaultControl
  [showTime]="false"
  (onBlur)="onBlur.emit($event);"
  (onClose)="onClose.emit($event);"
  (onSelect)="onSelect.emit($event);"
  [dateFormat]="dateFormat"
  placeholder="dd/mm/yy"
  [firstDayOfWeek]="1"
  [formControl]="formControl"
  [disabledDays]="disabledDays"
  [selectOtherMonths]="true"
  [touchUI]="isMobile || isSmall"
  [minDate]="minDate"
  [maxDate]="maxDate"
  [showClear]="showClear"
  appendTo="body"
></p-calendar>
