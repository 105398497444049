import { Inject, Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { first, Observable, of, switchMap, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG_TOKEN, AppConfig } from '@alimento-ipv-frontend/ui-lib';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  validateCompanyNumber(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control.value) {
        return of(null);
      }
      const value = control.value.replace(/[. ]+/g, '');
      return this.http.get<{ valid: boolean }>(`${this.config.readApiUrl}/validations/companynumber/${value}`)
        .pipe(first(), map(response => response.valid ? null : { companyNumberInvalid: true }));
    };
  }

  validateRSZNumber(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control.value) {
        return of(null);
      }
      const value = control.value.replace(/[. ]+/g, '');
      return this.http.get<{ valid: boolean }>(`${this.config.readApiUrl}/validations/rsz/${value}`)
        .pipe(first(), map(response => response.valid ? null : { RSZNumberInvalid: true }));
    };
  }

  validateIBAN(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control.value) {
        return of(null);
      }
      const value = control.value.replace(/[. ]+/g, '');
      return timer(500).pipe(
        switchMap(() => this.http.get<{ valid: boolean }>(`${this.config.readApiUrl}/validations/iban/${value}`)),
        map(response => response.valid ? null : { IBANInvalid: true }),
        first()
      );
    };
  }

  validateVATNumber(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (!control.value) {
        return of(null);
      }
      const value = control.value.replace(/[. ]+/g, '');
      return this.http.get<{ valid: boolean }>(`${this.config.readApiUrl}/validations/vatnumber/${value}`)
        .pipe(first(), map(response => response.valid ? null : { VATNumberInvalid: true }));
    };
  }
}
