<p-accordion *ngIf="showHeader" styleClass='search-facet'>
  <p-accordionTab [selected]="expanded" header="{{title}}">
    <ng-template pTemplate="content">
      <ng-container *ngTemplateOutlet="lazyItems"></ng-container>
    </ng-template>
  </p-accordionTab>
</p-accordion>

<div *ngIf="!showHeader" class="p-5 border-b border-b-grey border-solid border-opacity-20">
  <ng-container *ngTemplateOutlet="lazyItems"></ng-container>
</div>

<ng-template #lazyItems>
  <lib-lazy-dropdown
    #lazyControl
    [data$]="data"
    [selectedItems]="selectedItems"
    (itemSelected)="itemSelected(lazyControl, $event)"
    [addEmptyValue]="addEmptyValue"
    [emptyValue]="emptyValue"
    [emptyLabelKey]="emptyLabelKey"
    dropdownIcon='pi pi-search'
  >
  </lib-lazy-dropdown>

  <div class="flex flex-col pt-4">
    <span *ngFor="let selectedItem of _selectedItems"
          class="p-2 mb-2 bg-primary bg-opacity-10 rounded-md flex items-center justify-between hover:bg-opacity-5">
      <span [innerHTML]="selectedItem.label"></span>
      <i class="font-material hover:text-primary mx-2 cursor-pointer" (click)="removeItem(selectedItem.value)">Close</i>
    </span>
  </div>
</ng-template>
