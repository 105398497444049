<div class="pb-4 {{ formGroupClass }}">
  <label class="block {{ labelClass }}" *ngIf="!labelTemplate" for="{{ fieldKey || formField }}">
    {{ fieldKey || formField | translate }}
    <span *ngIf="!required">({{ 'validation.optional' | translate }})</span>
  </label>
  <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
  <div class="form-input w-full {{ formInputClass }}">
    <ng-container *ngTemplateOutlet="formInputTemplate"></ng-container>
    <ng-content></ng-content>
    <div *ngIf="!hideError" class="p-error block">
      <!--empty:before:content-['\200b']-->
      <ng-container *ngIf="isFieldInvalid() || alwaysShowError">
        <small *ngIf="!errorTemplate">{{
          'validation.isRequired' | translate : { field: fieldKey || formField | translate }
        }}</small>
        <ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
      </ng-container>
    </div>
  </div>
</div>
