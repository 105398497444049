import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { delay, first, forkJoin, Observable, shareReplay } from 'rxjs';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { DataLabelType, FormComponent, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { Dropdown } from 'primeng/dropdown';
import { CaseManager, Counselor, TrainingMethod, TrainingType } from '../../../types/reference-data.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import {
  isEventType,
  isPupilProjectType,
  isTeacherProjectType,
  PUPIL_TRAINING_TYPE,
  TEACHER_TRAINING_TYPE,
  TRAINING_TYPE, TrainingStatusKey
} from '../../../types/reference-data.enum';
import { TrainingProject } from '../../../types/training.type';
import { conditionallyRequiredValidator } from '../../../utils/utils/conditionally-required-validator';

@Component({
  selector: 'alimento-ipv-frontend-training-project',
  templateUrl: './training-project.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TrainingProjectComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: TrainingProjectComponent
    },
    { provide: FormComponent, useExisting: TrainingProjectComponent }
  ]
})
export class TrainingProjectComponent extends FormComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor, Validator {
  @Input()
  firstSessionDate?: string;

  @Input()
  lastSessionDate?: string;

  @Input()
  showRequiredFieldErrors = false;

  @Input()
  trainingType: TRAINING_TYPE;

  @Input()
  trainingStatus: TrainingStatusKey;

  caseManagers: CaseManager[] = [];
  caseManagerBackups: CaseManager[] = [];
  counselors: Counselor[] = [];
  types: TrainingType[] = [];
  subsidyTypes: DataLabelType[];
  subjectTypes: DataLabelType[];
  methods: TrainingMethod[];

  loadingResources: Observable<any>;
  caseManager: CaseManager;
  caseManagerBackup: CaseManager;
  counselor: Counselor;
  type: TrainingType;
  subjectType: DataLabelType;
  method: DataLabelType;
  subsidyType: DataLabelType;
  isActiveProject: boolean;

  @ViewChild('caseManagerField')
  caseManagerField: Dropdown;

  @ViewChild('caseManagerBackupField')
  caseManagerBackupField: Dropdown;

  @ViewChild('counselorField')
  counselorField: Dropdown;

  readonly DUTCH_LANGUAGE_CODE = 'nl';
  static readonly NO_SUBSIDY = '8fc2a4e1-8cbb-448c-a1ba-8b777b320e97';
  static readonly METHOD_PHYSICAL = 'ae12ae27-b4e2-442e-a0d9-722e5bea688b';
  protected readonly isEventType = isEventType;

  constructor(private referenceDataService: ReferenceDataService,
              private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this._fetchReferenceData();
    this._createFormGroup();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.formGroup && changes['showRequiredFieldErrors']) {
      for (const key of Object.keys(this.formGroup.controls)) {
        this.formGroup.get(key)?.updateValueAndValidity({ emitEvent: false });
      }
    }

    if (changes["trainingStatus"]?.currentValue) {
      this.isActiveProject = this.trainingStatus !== TrainingStatusKey.Draft &&
        (isTeacherProjectType(this.trainingType) || isPupilProjectType(this.trainingType));
    }
  }

  public static createFormData(training: TrainingProject): any {
    return {
      typeId: training.typeId,
      languageCode: training.languageCode,
      rubricId: training.rubricId,
      methodId: training.methodId || this.METHOD_PHYSICAL,
      caseManagerId: training.caseManagerId,
      caseManagerBackupId: training.caseManagerBackupId,
      counselorId: training.counselorId,
      maxParticipants: training.maxParticipants,
      subjectId: training.subjectId,
      subsidizedId: training.subsidizedId || this.NO_SUBSIDY,
      educationLevelIds: training.educationLevelIds || [],
      organizationId: training.organizationId
    };
  }

  override writeValue(training: TrainingProject): void {
    if (training) {
      this.formGroup.patchValue({ ...training }, { emitEvent: false });

      this.loadingResources.subscribe(() => {
        this._setReadOnlyValues(training);
        this.formGroup.updateValueAndValidity({ emitEvent: false });
      });
    }
  }

  override isFieldInvalid(field: string): boolean {
    return !this.formGroup.get(field).disabled && !this.formGroup.get(field).valid && this.showRequiredFieldErrors;
  }

  private _fetchReferenceData(): void {
    this.loadingResources = forkJoin([
      this.referenceDataService.getCaseManagers(true).pipe(first()),
      this.referenceDataService.getCounselors(true).pipe(first()),
      this.referenceDataService.getTrainingTypes(false).pipe(first()),
      this.referenceDataService.getTrainingSubsidyTypes().pipe(first()),
      isPupilProjectType(this.trainingType) ?
        this.referenceDataService.getPupilSubjectTypes().pipe(first()) :
        (isTeacherProjectType(this.trainingType) ?
          this.referenceDataService.getTeacherSubjectTypes().pipe(first()) :
          this.referenceDataService.getEducationEventSubjectTypes().pipe(first())),
      this.referenceDataService.getTrainingMethods().pipe(first())
    ]).pipe(shareReplay(1));

    this.loadingResources.subscribe(([caseManagers, counselors, trainingTypes, subsidyTypes, subjectTypes, methods]) => {
      this.caseManagers = JSON.parse(JSON.stringify(caseManagers));
      this.caseManagerBackups = JSON.parse(JSON.stringify(caseManagers));
      this.counselors = counselors;
      this.subsidyTypes = subsidyTypes;
      this.subjectTypes = subjectTypes;
      this.methods = methods;
      this.types = trainingTypes.filter((trainingType: TrainingType) => {
        if (isTeacherProjectType(this.trainingType)) {
          return TEACHER_TRAINING_TYPE.includes(trainingType.data as any) &&
            trainingType.data !== TRAINING_TYPE.TEACHER_GENERAL;
        }
        else {
          return PUPIL_TRAINING_TYPE.includes(trainingType.data as any) &&
            trainingType.data !== TRAINING_TYPE.PUPIL_GENERAL;
        }
      });
    });
  }

  private _createFormGroup(): void {
    this.formGroup = this.fb.group({
      typeId: [this.trainingType, [Validators.required]],
      languageCode: [null, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]],
      // rubricId: [null, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]],
      methodId: [TrainingProjectComponent.METHOD_PHYSICAL, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]],
      caseManagerId: [null, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]],
      caseManagerBackupId: [null],
      counselorId: [null, [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]],
      maxParticipants: [
        10,
        [
          conditionallyRequiredValidator(() => this.showRequiredFieldErrors),
          Validators.min(0),
          Validators.max(999)
        ]
      ],
      educationLevelIds: [[], [conditionallyRequiredValidator(() => this.showRequiredFieldErrors)]],
      subjectId: [null, [conditionallyRequiredValidator(() => !this.isEventType(this.trainingType) && this.showRequiredFieldErrors)]],
      subsidizedId: [TrainingProjectComponent.NO_SUBSIDY],
      organizationId: [null]
    });
    this._addSubscriptions();
  }

  private _setReadOnlyValues(training: TrainingProject): void {
    if (training.caseManagerId) {
      this.referenceDataService.getCaseManager(training.caseManagerId)
        .subscribe(caseManager => {
          this.caseManager = caseManager;
          if (!caseManager.isActive) {
            this.caseManagers.push(caseManager);
            this.formGroup.get('caseManagerId').setValue(training.caseManagerId, { emitEvent: false });
          }
        });
    }

    if (training.caseManagerBackupId) {
      this.referenceDataService.getCaseManager(training.caseManagerBackupId)
        .subscribe(caseManager => {
          this.caseManagerBackup = caseManager;
          if (!caseManager.isActive) {
            this.caseManagerBackups.push(caseManager);
            this.formGroup.get('caseManagerBackupId').setValue(training.caseManagerBackupId, { emitEvent: false });
          }
        });
    }

    if (training.counselorId) {
      this.referenceDataService.getCounselor(training.counselorId)
        .subscribe(counselor => {
          this.counselor = counselor;
          if (!counselor.isActive) {
            this.counselors.push(counselor);
            this.formGroup.get('counselorId').setValue(training.counselorId, { emitEvent: false });
          }
        });
    }

    if (training.typeId) {
      this.referenceDataService.getTrainingType(training.typeId)
        .subscribe(type => {
          this.type = type;
        });
    }

    if (training.subjectId) {
      let request;

      if (isPupilProjectType(training.typeId)) {
        request = this.referenceDataService.getPupilSubjectType(training.subjectId);
      }
      else if (isTeacherProjectType(training.typeId)) {
        request = this.referenceDataService.getTeacherSubjectType(training.subjectId);
      }
      else if (isEventType(training.typeId)) {
        request = this.referenceDataService.getEducationEventSubjectType(training.subjectId);
      }
      request.pipe(first())
        .subscribe(type => {
          this.subjectType = type;
        });
    }

    if (training.subsidizedId) {
      this.referenceDataService.getTrainingSubsidyType(training.subsidizedId).pipe(first())
        .subscribe(type => {
          this.subsidyType = type;
        });
    }

    if (training.methodId) {
      this.referenceDataService.getTrainingMethod(training.methodId).pipe(first())
        .subscribe(method => {
          this.method = method;
        });
    }
  }

  private _addSubscriptions(): void {
    this.subscriptions.push(
      this.formGroup.get('counselorId')?.valueChanges.subscribe((newValue) => {
        this.referenceDataService.getCounselor(newValue)
          .subscribe(counselor => this.counselor = counselor);
      }),
      this.formGroup.get('caseManagerId')?.valueChanges.subscribe((newValue) => {
        this.referenceDataService.getCaseManager(newValue)
          .subscribe(caseManager => this.caseManager = caseManager);
      }),
      this.formGroup.get('caseManagerBackupId')?.valueChanges.subscribe((newValue) => {
        this.referenceDataService.getCaseManager(newValue)
          .subscribe(caseManager => this.caseManagerBackup = caseManager);
      }),
      this.formGroup.get('languageCode')?.valueChanges.subscribe(newValue => {
        if (isEventType(this.trainingType)) {
          return;
        }

        if (newValue === this.DUTCH_LANGUAGE_CODE) {
          if (!this.formGroup.get('typeId').value ||
            this.formGroup.get('typeId').value === TRAINING_TYPE.TEACHER_GENERAL ||
            this.formGroup.get('typeId').value === TRAINING_TYPE.PUPIL_GENERAL) {
            this.formGroup.get('typeId').setValue(undefined);
          }
        }
        else {
          this.formGroup.get('typeId').setValue(isTeacherProjectType(this.trainingType) ?
            TRAINING_TYPE.TEACHER_GENERAL : TRAINING_TYPE.PUPIL_GENERAL);
        }
      }),
      this.formGroup.get('typeId')?.valueChanges.subscribe(newValue => {
        if (newValue) {
          this.referenceDataService.getTrainingType(newValue)
            .subscribe(type => this.type = type);
        }
        else {
          this.type = undefined;
        }
      })
    );

    this.subscriptions.push(
      this.formGroup.valueChanges.pipe(delay(1)).subscribe((value) => {
        const data = { ...value };

        if (data.organizationId?.branchId) {
          data.organizationId = data.organizationId.branchId;
        }

        this.onChange(data);
        this.onTouched();
      })
    );
  }

  hasInactiveCaseManagersOrCounselors(): boolean {
    return (this.caseManager && !this.caseManager.isActive) ||
      (this.caseManagerBackup && !this.caseManagerBackup.isActive) ||
      (this.counselor && !this.counselor.isActive);
  }

  focusFirstInactiveCaseManager(): void {
    if (this.caseManager && !this.caseManager.isActive) {
      this.caseManagerField?.focus();
    }
    else if (this.caseManagerBackup && !this.caseManagerBackup.isActive) {
      this.caseManagerBackupField?.focus();
    }
    else if (this.counselor && !this.counselor.isActive) {
      this.counselorField?.focus();
    }
  }

  validate(): ValidationErrors | null {
    return (!this.hasErrors() && this.allRequiredFieldsFilled()(this.formGroup) === null) ? null : { basicInfoInvalid: true };
  }

  hasErrors(markAsTouched = false): boolean {
    if (this.formGroup.disabled) {
      return false;
    }

    if (markAsTouched) {
      validateAllFormFields(this.formGroup);
    }

    let isValid = true;
    Object.keys(this.formGroup.controls).forEach((field) => isValid = isValid && this.formGroup.get(field).valid);
    return !isValid;
  }

  private allRequiredFieldsFilled(): ValidatorFn {
    return (control: AbstractControl) => {
      const controlValue = control.value;

      const isValid =
        !!controlValue?.languageCode &&
        !!controlValue?.typeId &&
        // !!controlValue?.rubricId &&
        !!controlValue?.methodId &&
        !!controlValue?.subsidizedId &&
        (isEventType(this.trainingType) || !!controlValue?.subjectId) &&
        !!controlValue?.caseManagerId &&
        !!controlValue?.counselorId &&
        controlValue?.maxParticipants > 0 &&
        controlValue?.educationLevelIds?.length > 0;

      return isValid ? null : { required: true };
    };
  }
}
