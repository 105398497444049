import {
  Component,
  computed,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Signal,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { EnrollmentPopupComponent } from '../enrollments-popup/enrollment-popup.component';
import { first, Observable, switchMap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CancelEnrollmentDialogComponent } from '../cancel-enrollment-dialog/cancel-enrollment-dialog.component';
import { AuthorizationService, MyMessageService, Role } from '@alimento-ipv-frontend/ui-lib';
import { Training } from '../../../types/training.type';
import {
  CancelEnrollment,
  EnrollmentAction,
  EnrollmentActionEnum,
  EnrollmentBody,
  EnrollmentListItem,
  EnrollmentRequest,
  EnrollmentRequestAction,
  EnrollmentRequestParticipant,
  EnrollmentRequestUpdateEvent,
  EnrollmentUpdateEvent,
  ParticipantUpdateEvent,
  ValidationRequest
} from '../../../types/enrollment.type';
import { EnrollmentService } from '../../../services/enrollment.service';
import {
  EnrollmentRequestStatusKey,
  isOpenExternOrCustom,
  TRAINING_TYPE,
  TrainingStatusKey
} from '../../../types/reference-data.enum';
import { EnrollmentRequestPopupComponent } from '../enrollment-request-popup/enrollment-request-popup.component';
import { EnrollmentRequestActionEnum } from '../../../types/enrollment.enum';
import { ConfirmationService } from 'primeng/api';
import {
  EnrollmentRequestParticipantPopupComponent
} from '../enrollment-request-participant-popup/enrollment-request-participant-popup.component';
import { ReimbursementRequest } from '../../../types/reimbursement-request.type';
import { BranchService } from '../../../services/branch.service';

@Component({
  selector: 'alimento-ipv-frontend-enrollments',
  templateUrl: './enrollments.component.html',
  styleUrls: ['./enrollments.component.scss']
})
export class EnrollmentsComponent implements OnChanges {
  @Input()
  training: Signal<Training>;

  @Input()
  reimbursementRequest?: ReimbursementRequest;

  @Input()
  trainingStatus = '';

  @Input()
  trainingType: TRAINING_TYPE;

  @Input()
  groupId?: string;

  @Input()
  enterpriseId?: string;

  @Output()
  hasChange: EventEmitter<EnrollmentActionEnum> = new EventEmitter<EnrollmentActionEnum>();

  @ViewChild(CancelEnrollmentDialogComponent)
  cancelEnrollmentDialog: CancelEnrollmentDialogComponent;

  @ViewChild(EnrollmentRequestParticipantPopupComponent)
  participantPopup: EnrollmentRequestParticipantPopupComponent;

  readOnly = false;
  viewMode = false;
  trainingStatusIsDraft = true;
  isOpenExternOrCustom = false;
  currentParticipant: EnrollmentRequestParticipant;
  validationRequest: ValidationRequest = undefined;

  enrollments: Signal<EnrollmentListItem[]> = this.enrollmentService.enrollments;
  enrollmentRequests: Signal<EnrollmentRequest[]> = this.enrollmentService.enrollmentRequests;
  hasRequestInToVerify = computed(() =>
    this.enrollmentRequests().filter(request => request.stateId === EnrollmentRequestStatusKey.toVerify).length > 0);
  nrOfParticipants = this.enrollmentService.nrOfParticipants;

  protected readonly TrainingStatusKey = TrainingStatusKey;
  protected readonly navigator = navigator;

  constructor(
    private enrollmentService: EnrollmentService,
    private branchService: BranchService,
    private messageService: MyMessageService,
    private translateService: TranslateService,
    private authorizationService: AuthorizationService,
    private confirmationService: ConfirmationService
  ) {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['trainingStatus']) {
      this.trainingStatusIsDraft = this.trainingStatus === TrainingStatusKey.Draft;
    }
    if (changes['training']?.currentValue) {
      this.isOpenExternOrCustom = isOpenExternOrCustom(this.trainingType);
      this._loadValidationRequest();
    }
  }

  refreshEnrollments(includeRequests = false): void {
    this.enrollmentService.loadEnrollments(this.training().trainingId);
    if (includeRequests) {
      this.enrollmentService.loadEnrollmentRequests(this.training().trainingId);
      this._loadValidationRequest();
    }
  }

  addEnrollment(enrollmentPopupComponent: EnrollmentPopupComponent, asReserve: boolean): void {
    enrollmentPopupComponent.openPopup(undefined,
      asReserve ? EnrollmentActionEnum.addToReserve : EnrollmentActionEnum.create);
  }

  onEnrollmentPopupSubmit(
    enrollmentUpdateEvent: EnrollmentUpdateEvent,
    enrollmentPopupComponent: EnrollmentPopupComponent
  ) {
    const enrollment: EnrollmentBody = enrollmentUpdateEvent.enrollment;

    if (enrollmentUpdateEvent.action === EnrollmentActionEnum.view) {
      enrollmentUpdateEvent.setLoading(false);
      enrollmentPopupComponent.closePopup();
      return;
    }

    enrollmentUpdateEvent.setLoading(true);

    let sub;
    if (enrollmentUpdateEvent.action === EnrollmentActionEnum.create) {
      sub = this.enrollmentService.createEnrollment(this.training().trainingId, enrollment);
    }
    else if (enrollmentUpdateEvent.action === EnrollmentActionEnum.addToReserve) {
      sub = this.enrollmentService.addEnrollmentAsReserve(this.training().trainingId, enrollment);
    }
    else if (enrollmentUpdateEvent.action === EnrollmentActionEnum.edit) {
      sub = this.enrollmentService.updateEnrollment(this.training().trainingId, enrollmentUpdateEvent.enrollmentId, enrollment);
    }
    else if (enrollmentUpdateEvent.action === EnrollmentActionEnum.reEnroll) {
      sub = this.enrollmentService.reEnroll(this.training().trainingId, enrollmentUpdateEvent.enrollmentId, enrollment);
    }
    sub.subscribe({
      next: () => {
        enrollmentUpdateEvent.setLoading(false);
        enrollmentPopupComponent.closePopup();

        this.messageService.success(enrollmentUpdateEvent.action === EnrollmentActionEnum.create ?
          'enrollments.added' : 'enrollments.updated');

        this.refreshEnrollments();
        this.hasChange.emit(enrollmentUpdateEvent.action);
      },
      error: () => {
        enrollmentUpdateEvent.setLoading(false);
      }
    });
  }

  enrollmentAction(action: EnrollmentAction, enrollmentPopup: EnrollmentPopupComponent): void {
    this.viewMode = action.action === EnrollmentActionEnum.view;
    if (action.action === EnrollmentActionEnum.cancel) {
      this.cancelEnrollmentDialog.open(action.enrollment);
    }
    else if ([
      EnrollmentActionEnum.edit,
      EnrollmentActionEnum.reEnroll,
      EnrollmentActionEnum.view
    ].includes(action.action)) {
      this.enrollmentService
        .getEnrollment(action.enrollment.id)
        .pipe(first())
        .subscribe(enrollment => {
          enrollmentPopup.openPopup(enrollment, action.action);
        });
    }
    else if (action.action === EnrollmentActionEnum.navigateToPerson) {
      window.open(`/persons/${action.enrollment.personId}/detail`, '_blank');
    }
    else if (action.action === EnrollmentActionEnum.navigateToBranch) {
      window.open(`/branches/${action.enrollment.branch?.data}/detail`, '_blank');
    }
  }

  onEnrollmentRequestPopupSubmit(event: EnrollmentRequestUpdateEvent, enrollmentRequestPopupComponent: EnrollmentRequestPopupComponent): void {
    event.setLoading(true);

    let request = this.enrollmentService.updateEnrollmentRequest(event.enrollmentRequest);
    if (event.action === EnrollmentRequestActionEnum.validate) {
      request = request
        .pipe(switchMap(() => this.enrollmentService.validateEnrollmentRequest(event.enrollmentRequest.id)));
    }

    request.pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success();
          enrollmentRequestPopupComponent.closePopup();
          this.refreshEnrollments(this.isOpenExternOrCustom);
          event.setLoading(false);
        },
        error: () => {
          event.setLoading(false);
        }
      });
  }

  enrollmentRequestAction(action: EnrollmentRequestAction, enrollmentPopup: EnrollmentRequestPopupComponent): void {
    this.viewMode = action.action === EnrollmentRequestActionEnum.view;

    if ([
      EnrollmentRequestActionEnum.validate,
      EnrollmentRequestActionEnum.view
    ].includes(action.action)) {
      enrollmentPopup.openPopup(action.enrollmentRequest, action.action);
    }
    else if (action.action === EnrollmentRequestActionEnum.navigateToBranch) {
      window.open(`/branches/${action.enrollmentRequest.branchId}/detail`, '_blank');
    }
    else if (action.action === EnrollmentRequestActionEnum.editParticipant) {
      this.currentParticipant = action.participant;
      this.participantPopup.openPopup();
    }
    else if (action.action === EnrollmentRequestActionEnum.cancelParticipant) {
      this.executeEnrollmentRequestAction(action.action,
        this.enrollmentService.deleteEnrollmentRequestParticipant(action.participant));
    }
    else if (action.action === EnrollmentRequestActionEnum.cancel) {
      this.executeEnrollmentRequestAction(action.action,
        this.enrollmentService.cancelEnrollmentRequest(action.enrollmentRequest.id));
    }
    else if (action.action === EnrollmentRequestActionEnum.delete) {
      this.executeEnrollmentRequestAction(action.action,
        this.enrollmentService.deleteEnrollmentRequest(action.enrollmentRequest.id));
    }
    else if (action.action === EnrollmentRequestActionEnum.verify) {
      this.executeEnrollmentRequestAction(action.action,
        this.enrollmentService.verifyEnrollmentRequest(action.enrollmentRequest.id));
    }
  }

  cancelEnrollment(cancelEnrollment: CancelEnrollment): void {
    this.enrollmentService
      .cancelEnrollment(this.training().trainingId, cancelEnrollment)
      .pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success('enrollments.cancelled');
          this.hasChange.emit(EnrollmentActionEnum.cancel);
          this.refreshEnrollments();
        }
      });
  }

  addParticipant(participantPopup: EnrollmentRequestParticipantPopupComponent): void {
    this.currentParticipant = { } as EnrollmentRequestParticipant;
    this.currentParticipant.employerId = this.reimbursementRequest?.branchId;
    participantPopup.openPopup();
  }

  submitParticipant(event: ParticipantUpdateEvent, participantPopup: EnrollmentRequestParticipantPopupComponent): void {
    event.setLoading(true);

    (event.participant?.id ? this.enrollmentService.updateParticipant(event.participant) :
      this.enrollmentService.addParticipant(this.training().trainingId, event.participant))
      .pipe(first())
      .subscribe({
        next: () => {
          event.setLoading(false);
          participantPopup.closePopup();
          this.messageService.success();
          this.refreshEnrollments(this.isOpenExternOrCustom);
        },
        error: () => {
          event.setLoading(false);
        }
      });
  }

  requestToVerify(): void {
    this.enrollmentService.requestToVerify(this.training().trainingId).pipe(first())
      .subscribe(() => {
        this._loadValidationRequest();
        this.messageService.success();
      });
  }

  private _loadValidationRequest(): void {
    this.enrollmentService.getValidationRequest(this.training().trainingId)
      .pipe(first())
      .subscribe(validationRequest => this.validationRequest = validationRequest);
  }

  private executeEnrollmentRequestAction(action: EnrollmentRequestActionEnum, command: Observable<any>): void {
    this.confirmationService.confirm({
      header: this.translateService.instant(`enrollments.request.${action}Popup.title`),
      message: this.translateService.instant(`enrollments.request.${action}Popup.message`),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translateService.instant(`enrollments.request.${action}`),
      acceptIcon: 'hidden',
      rejectLabel: this.translateService.instant('cancel'),
      rejectButtonStyleClass: 'inverted-button',
      accept: () => {
        command.pipe(first())
          .subscribe(() => {
            this.messageService.success();
            this.refreshEnrollments(this.isOpenExternOrCustom);
          });
      }
    });
  }
}
