import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LeaveConfirm } from '../types/Leave-confirm.type';
import { LeaveConfirmDialogComponent } from '../components/leave-confirm-dialog/leave-confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class LeaveConfirmService {

  private requireConfirmationSource = new Subject<LeaveConfirm | null>();

  requireConfirmation$ = this.requireConfirmationSource.asObservable();

  confirm(confirmation: LeaveConfirm) {
    this.requireConfirmationSource.next(confirmation);
    return this;
  }

  leaveDialog(saveObservable: () => Observable<any>): Promise<boolean> {
    return new Promise((resolve) => {
      this.confirm({
        saveChanges: (dialog: LeaveConfirmDialogComponent) => {
          dialog.saving = true;
          saveObservable()
            .subscribe({
              next: () => {
                dialog.closeDialog();
                resolve(true);
              },
              error: () => {
                dialog.closeDialog();
                resolve(false);
              }
            });
        },
        cancelChanges: () => {
          resolve(true);
        },
        cancel: () => {
          resolve(false);
        }
      });
    });
  }
}
