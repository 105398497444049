import { Injectable } from '@angular/core';
import { Reimbursement, ReimbursementInfo } from '../../types/reimbursement-request.type';
import { REIMBURSEMENT_STATUS, REIMBURSEMENT_TYPE } from '../../types/reimbursement-request.enum';

@Injectable({
  providedIn: 'root'
})
export class ReimbursementMapper {

  calculateReimbursementData(reimbursements: Reimbursement[]): { [key: string]: ReimbursementInfo } {
    if (reimbursements?.length > 0) {
      const groupedData = Object.groupBy(reimbursements,
        (reimbursement) => {
          if (reimbursement.correctionReimbursementId) {
            return reimbursements
              .filter(item => item.reimbursementId === reimbursement.correctionReimbursementId)[0].reimbursementTypeId;
          }
          return reimbursement.reimbursementTypeId;
        });

      return Object.fromEntries(
        Object.values(REIMBURSEMENT_TYPE)
          .map(value => [value, (groupedData[value] || [])
            .reduce((result, current) => {
                result.reimbursements.push(current);
                if (current.statusId === REIMBURSEMENT_STATUS.PAYED) {
                  result.payed += current.amount;
                }
                result.sum += current.amount;
                return result;
              },
              { reimbursements: [], sum: 0, payed: 0 }
            )]
          )
      );
    }
    return undefined;
  }
}
