<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '40rem' }"
  [closable]="false"
  appendTo="body"
  header="{{ 'persons.employments.stopDialog.title' | translate }}"
>
  <div class="flex p-4 mb-4 bg-grey-0">
    <div class='basis-1/2'>
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        title="{{ 'persons.employments.branch' | translate }}"
        value="{{ employment?.branchName }}"
      ></alimento-ipv-frontend-metadata-item>
    </div>

    <div class='basis-1/2'>
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        title="{{ 'persons.employments.function' | translate }}"
        value="{{ employment?.function }}"
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>

  <div [formGroup]="stopEmploymentForm" class="w-[202px]">
    <alimento-ipv-frontend-form-field
      [formGroup]='stopEmploymentForm'
      [errorTemplate]='dateErrorTemplate'
      [required]='true'
      formField='stopEmploymentAt'
      fieldKey='trainings.sessions.date'
    >
      <p-calendar
        #focusElement
        [showIcon]="true"
        [showTime]="false"
        [showClear]="true"
        [showOnFocus]='false'
        [keepInvalid]='true'
        appendTo="body"
        dataType="date"
        dateFormat="dd/mm/yy"
        formControlName="stopEmploymentAt"
        id="stopEmploymentAt"
        placeholder="dd/mm/yyyy"
        styleClass="w-full"
      ></p-calendar>
      <ng-template #dateErrorTemplate>
        <small *ngIf='stopEmploymentForm.get("stopEmploymentAt")?.hasError("required")'>
          {{ 'trainings.sessions.dateRequired' | translate }}
        </small>
        <small *ngIf='stopEmploymentForm.get("stopEmploymentAt").hasError("invalidDate")'>
          {{ 'validation.invalidDate' | translate }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex">
      <p-button
        (onClick)="stopEmployment()"
        [loading]="loading"
        label="{{ 'persons.employments.stopDialog.buttons.stopEmployment' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'persons.employments.stopDialog.buttons.cancel' | translate }}"
        styleClass="inverted-button"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
