import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { FilterType, IFilter, SearchParam } from '@alimento-ipv-frontend/ui-lib';
import { map, Observable, of } from 'rxjs';
import { BranchListItem } from '../../types/searches.type';
import { Branch } from '../../types/branch.type';

@Injectable({
  providedIn: 'root'
})
export class BranchesMapper {

  constructor(private translateService: TranslateService) {
  }

  private noOfEmployeesOptions = [
    {
      label: '0-4',
      value: {
        minValue: 0,
        maxValue: 4
      }
    },
    {
      label: '5-9',
      value: {
        minValue: 5,
        maxValue: 9
      }
    },
    {
      label: '10-19',
      value: {
        minValue: 10,
        maxValue: 19
      }
    },
    {
      label: '20-49',
      value: {
        minValue: 20,
        maxValue: 49
      }
    },
    {
      label: '50-99',
      value: {
        minValue: 50,
        maxValue: 99
      }
    },
    {
      label: '100-199',
      value: {
        minValue: 100,
        maxValue: 199
      }
    },
    {
      label: '200-499',
      value: {
        minValue: 200,
        maxValue: 499
      }
    },
    {
      label: '500-999',
      value: {
        minValue: 500,
        maxValue: 999
      }
    },
    {
      label: '>=1000',
      value: {
        minValue: 1000,
        maxValue: 99999
      }
    }
  ];

  private _getBranchStates(): any[] {
    return [
      {
        label: this.translateService.instant('branches.active'),
        value: 'true'
      },
      {
        label: this.translateService.instant('branches.inActive'),
        value: 'false'
      }
    ];
  }

  private _getCategoryOptions(): any[] {
    return [
      {
        icon: 'restaurant',
        value: 'ff',
        key: 'isFood',
        filter: FilterType.filterIsFood,
        label: this.translateService.instant('branches.isFood')
      },
      {
        icon: 'storefront',
        value: 'fft',
        key: 'isFoodTrade',
        filter: FilterType.filterIsFoodTrade,
        label: this.translateService.instant('branches.isFoodTrade')
      },
      {
        icon: 'phishing',
        value: 'ffs',
        key: 'isFishing',
        filter: FilterType.filterIsFishing,
        label: this.translateService.instant('branches.isFishing')
      },
      {
        icon: 'school',
        value: 'ft',
        key: 'isTeacher',
        filter: FilterType.filterIsTeacher,
        label: this.translateService.instant('branches.isTeacher')
      },
      {
        icon: 'pin_drop',
        value: 'fl',
        key: 'isLocation',
        filter: FilterType.filterIsLocation,
        label: this.translateService.instant('branches.isLocation')
      }
    ];
  }

  getCategoryOptions(isEnterprise = false): {
    icon: string
    value: string
    key: string
    label: string,
    filter: FilterType
  }[] {
    const enterprisesFields = ['isFood', 'isFoodTrade', 'isFishing'];
    return this._getCategoryOptions().filter(field => !isEnterprise || enterprisesFields.includes(field.key));
  }

  getNumberOfEmployeesOptions(): Observable<IFilter[]> {
    return of(this.noOfEmployeesOptions)
      .pipe(map(data =>
        data.map(employeeItem =>
          ({
            type: FilterType.filterNumberEmployees,
            label: employeeItem.label,
            value: employeeItem.value.minValue + '-' + employeeItem.value.maxValue
          }) as IFilter)));
  }

  getStatesFilter(): Observable<IFilter[]> {
    return of(this._getBranchStates())
      .pipe(map(types => types.map(type =>
        ({
          type: FilterType.filterBranchState,
          label: type.label,
          value: type.value
        }) as IFilter)));
  }

  setCategoryFilter(params: SearchParam): void {
    if (params[FilterType.filterBranchCategory]) {
      params[FilterType.filterBranchCategory].map((filterValue: string) => this._getCategoryOptions()
        .filter(category => category.value === filterValue)[0].filter)
        .forEach((filter: FilterType) => params[filter] = true);
      delete params[FilterType.filterBranchCategory];
    }
  }

  setNumberOfEmployeesFilter(params: SearchParam): void {
    if (params[FilterType.filterNumberEmployees]) {
      params[FilterType.filterNumberEmployees] = params[FilterType.filterNumberEmployees]
        .map((filterValue: string) => ({
            minValue: filterValue.split('-')[0],
            maxValue: filterValue.split('-')[1]
          })
        );
    }
  }

  setStateFilter(params: SearchParam): void {
    if (params[FilterType.filterBranchState]?.length === 1) {
      params[FilterType.isActive] = params[FilterType.filterBranchState][0] === 'true';
      delete params[FilterType.filterBranchState];
    }
  }

  getBranchListItem(branch: Branch): BranchListItem {
    return {
      branchId: branch.branchId,
      name: branch.branchName,
      address: `${branch.street ? branch.street : ""} ${branch.houseNumber ? branch.houseNumber : ""}${branch.mailbox ? ' ' + branch.mailbox : ''},
      ${branch.postalCode ? branch.postalCode : ""} ${branch.city ? branch.city : ""}`,
      rszNumber: branch.rszNumber,
      vatNumber: branch.vatNumber,
      isActive: branch.active,
      isTeacher: branch.isTeacher,
      isFishing: branch.isFishing,
      isLocation: branch.isLocation,
      isFoodTrade: branch.isFoodTrade,
      isFood: branch.isFood,
      isMainBranch: false,
      numberOfEmployees: branch.numberOfClerksPC220 + branch.numberOfWorkersPC118,
      companyNumber: branch.companyNumber,
      alimentoId: branch.branchAlimentoId + "",
      enterpriseId: branch.enterpriseId
    };
  }
}
