import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Branch, ContactPerson, ContactPersonResult, TrainingPlan } from '../types/branch.type';
import {
  AccountNumberPipe, APP_CONFIG_TOKEN,
  AppConfig,
  HISTORY_TYPE,
  HistoryItem,
  PaginatedResponse
} from '@alimento-ipv-frontend/ui-lib';
import { BranchListItem } from '../types/searches.type';
import { DataLabelType } from '../types/reference-data.type';
import { TRAINING_PLAN_ACTION } from '../types/branch.enum';

@Injectable({
  providedIn: 'root'
})
export class BranchService {

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getBranch(branchId: string): Observable<Branch> {
    return this.http.get<Branch>(`${this.config.readApiUrl}/branch/${branchId}`);
  }

  getBranches(branchParams?: any): Observable<PaginatedResponse<BranchListItem>> {
    const params: HttpParams = this.mapToHttpParams(branchParams);
    return this.http.get<PaginatedResponse<BranchListItem>>(`${this.config.readApiUrl}/branch`, { params });
  }

  getContactPersons(branchId: string): Observable<ContactPerson[]> {
    return this.http
      .get<ContactPersonResult>(`${this.config.readApiUrl}/branch/${branchId}/contactpersons`)
      .pipe(map((result) => result.data));
  }

  getCurrentCountOfEnrollmentsForBranch(trainingId: string, branchId: string): Observable<number> {
    return this.http.get<number>(`${this.config.readApiUrl}/enrollments/count`, {
      params: {
        branchId: branchId,
        trainingId: trainingId
      }
    });
  }

  getTrainingPlans(branchId: string): Observable<TrainingPlan[]> {
    return this.http.get<TrainingPlan[]>(`${this.config.readApiUrl}/trainingplans`, {
      params: {
        branchId: branchId
      }
    });
  }

  createBranch(branch: Branch): Observable<{ id: string }> {
    return this.http.post<{id: string}>(`${this.config.writeApiUrl}/branches`, branch);
  }

  updateBranch(branch: Branch): Observable<{ id: string }> {
    return this.http.put<{id: string}>(`${this.config.writeApiUrl}/branches/${branch.branchId}`, branch);
  }

  createTrainingPlan(branchId: string, formData: any): Observable<{ id: string }> {
    formData.append("branchId", branchId);
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/trainingplans`, formData);
  }

  updateTrainingPlan(trainingPlanId: string, branchId: string, formData: any): Observable<{ id: string }> {
    formData.append("branchId", branchId);
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/trainingplans/${trainingPlanId}`, formData);
  }

  removeTrainingPlan(trainingPlanId: string): Observable<{ id: string }> {
    return this.http.delete<{ id: string }>(`${this.config.writeApiUrl}/trainingplans/${trainingPlanId}`);
  }

  approveTrainingPlan(trainingPlanId: string, data: any): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/trainingplans/${trainingPlanId}/approve`, data);
  }

  rejectTrainingPlan(trainingPlanId: string, data: any): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/trainingplans/${trainingPlanId}/reject`, data);
  }

  getTrainingPlanHistory(trainingPlanId: string): Observable<HistoryItem[]> {
    return this.http.get<{actions: HistoryItem[]}>(`${this.config.readApiUrl}/trainingplans/${trainingPlanId}/actions`)
      .pipe(map(results => {
        results.actions.forEach(historyItem => {
          historyItem.historyType = HISTORY_TYPE.TRAINING_PLAN;
          historyItem.actionName = TRAINING_PLAN_ACTION[historyItem.actionId];
        });
        return results.actions;
      }));
  }

  getAccountNumbers(branchId: string): Observable<DataLabelType[]> {
    return this.http.get<any[]>(`${this.config.readApiUrl}/branch/${branchId}/accountNumbers`)
      .pipe(map(accountNumbers => {
        return accountNumbers.map((accountNumber: any) =>
          ({ data: accountNumber.id, label: new AccountNumberPipe().transform(accountNumber.iban) }));
      }));
  }

  private mapToHttpParams(branchParams: any): HttpParams {
    let params: HttpParams = new HttpParams();

    if (!branchParams) {
      return params;
    }

    params = branchParams.first !== null ? params.append('off', branchParams.first) : params;
    params = branchParams.rows ? params.append('l', branchParams.rows) : params;

    params = branchParams.sortField ? params.append('s', branchParams.sortField) : params;
    params = branchParams.sortField ? params.append('o', branchParams.sortOrder === 1 ? 'asc' : 'desc') : params;

    const specialFilters = ['fne', 'categories'];
    Object.keys(branchParams.filters || {})
      .filter(filterKey => !specialFilters.includes(filterKey))
      .forEach((filterKey) => {
        params = branchParams.filters[filterKey]?.value
          ? params.append(filterKey, branchParams.filters[filterKey].value)
          : params;
      });

    branchParams?.filters?.fne?.value?.forEach((fne: any) => {
      params = params.append('fne', JSON.stringify(fne.value));
    });

    branchParams?.filters?.categories?.value?.forEach((category: string) => {
      params = params.append(category, true);
    });

    return params;
  }

  setAsMainBranch(branchId: string): Observable<void> {
    return this.http.put<void>(`${this.config.writeApiUrl}/branches/${branchId}/main`, {});
  }
}
