import {Injectable} from "@angular/core";
import {saveAs} from 'file-saver';
import {first, Observable, shareReplay} from "rxjs";
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: "root"
})
export class FileHandlerService {

  constructor(private http: HttpClient) {
  }

  getFileName(response: HttpResponse<Blob>): string {
    const contentDisposition = response.headers.get('content-disposition') || '';
    const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/"'/g, "");
    }
    else {
      return '';
    }
  }

  downloadFile(url: string, data: any, fileName?: string, extraHeaders?: { [key: string]: string }): Observable<any> {
    const post = this.getFile(url, data, extraHeaders).pipe(shareReplay(1), first());
    post.subscribe({
      next: (response: HttpResponse<Blob>) => saveAs(response.body, this.getFileName(response) || fileName || "file"),
      error: () => console.error("error in downloading or parsing file")
    });
    return post;
  }

  getFile(url: string, data: any, extraHeaders: { [key: string]: string } = {}): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders(extraHeaders);
    if (data) {
      return this.http.post(url, data, {
        headers: headers,
        observe: 'response',
        responseType: 'blob'
      });
    }
    else {
      return this.http.get(url, {
        headers: headers,
        observe: 'response',
        responseType: 'blob'
      });
    }
  }
}
