import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator
} from '@angular/forms';
import { debounceTime, distinctUntilChanged, noop } from 'rxjs';

@Component({
  selector: 'alimento-ipv-frontend-text-input',
  templateUrl: './text-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true,
    }
  ]
})
export class TextInputComponent implements OnInit, ControlValueAccessor, Validator {
  @Input()
  formControl: FormControl = new FormControl<string>(null);

  @Output()
  clear: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  changeValue: EventEmitter<string> = new EventEmitter<string>();

  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  ngOnInit(): void {
    this.formControl.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged()
    )
      .subscribe((value) => {
      this.onChangeCallback(value);
      this.changeValue.emit(value);
    });
  }

  clearInput() {
    this.formControl.setValue("");
    this.clear.emit();
  }

  writeValue(value: string) {
    if (value !== this.formControl.value) {
      this.formControl.setValue(value, {emitEvent: false});
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  validate() {
    return this.formControl.valid ? null : { text: { valid: false } };
  }

}
