<p-tabView *ngIf='enrollmentRequests.length > 0' [activeIndex]='activeIndex'>
  <p-tabPanel header='{{"enrollments.enrollmentRequests" | translate}} ({{enrollmentRequests.length}})'>
    <alimento-ipv-frontend-enrollment-card></alimento-ipv-frontend-enrollment-card>
    <p-accordion [multiple]="true">
      <p-accordionTab
        *ngFor="let groupedEnrollments of enrollmentRequestsMap | keyvalue: orderRequestsByStatus"
        header="{{ 'states.' + groupedEnrollments.key | translate | uppercase }} ({{ groupedEnrollments.value.length }})"
        [selected]="true"
      >
        <div *ngFor="let enrollmentRequest of groupedEnrollments.value">
          <alimento-ipv-frontend-enrollment-request-card
            (actionClicked)="enrollmentRequestAction.emit($event)"
            [enrollmentRequest]="$any(enrollmentRequest)"
            [validationRequest]='validationRequest'
            [training]='training'
            [readOnly]='readOnly'
          ></alimento-ipv-frontend-enrollment-request-card>
        </div>
      </p-accordionTab>
    </p-accordion>
  </p-tabPanel>
  <p-tabPanel header="{{ 'enrollments.enrollments' | translate }} ({{enrollments.length}})">
    <ng-container *ngTemplateOutlet='enrollmentsTemplate'></ng-container>
  </p-tabPanel>
</p-tabView>

<div *ngIf='enrollmentRequests.length === 0'>
  <ng-container *ngTemplateOutlet='enrollmentsTemplate'></ng-container>
</div>

<ng-template #enrollmentsTemplate>
  <alimento-ipv-frontend-enrollment-card></alimento-ipv-frontend-enrollment-card>
  <p-accordion [multiple]="true">
    <p-accordionTab
      *ngFor="let groupedEnrollments of enrollmentsMap | keyvalue: orderByStatus"
      header="{{ 'states.' + groupedEnrollments.key | translate | uppercase }} ({{ groupedEnrollments.value.length }})"
      [selected]="true"
    >
      <div *ngFor="let enrollment of groupedEnrollments.value">
        <alimento-ipv-frontend-enrollment-card
          (actionClicked)="enrollmentAction.emit($event)"
          [enrollment]="$any(enrollment)"
          [training]='training'
          [readOnly]='readOnly'
        ></alimento-ipv-frontend-enrollment-card>
      </div>
    </p-accordionTab>
  </p-accordion>
</ng-template>
