import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'alimento-ipv-frontend-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  message?: string;
  redirectUrl: string;
  showRedirect = true;

  constructor(private router: Router, private activeRoute: ActivatedRoute, private translateService: TranslateService) {
    const state = this.router.getCurrentNavigation()?.extras?.state;
    if (state) {
      this.message = state['message'];
      this.redirectUrl = state['redirectUrl'];
    }
    else if (activeRoute.snapshot.data['message']) {
      this.translateService.get(activeRoute.snapshot.data['message'])
        .subscribe(message => this.message = message);
      this.redirectUrl = activeRoute.snapshot.data['redirectUrl'] || '/';
      if (activeRoute.snapshot.data['showRedirect'] !== undefined) {
        this.showRedirect = activeRoute.snapshot.data['showRedirect'];
      }
      else {
        this.showRedirect = true;
      }
    }
    else {
      this.translateService
        .get('error.somethingWentWrong')
        .subscribe((somethingWentWrong) => (this.message = somethingWentWrong));
      this.redirectUrl = '/';
    }
  }
}
