<p-dialog
  (blur)="(dialogVisible)"
  *ngIf="dialogVisible"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [closable]='false'
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ 'enrollments.cancelDialog.title' | translate }}"
>
  <div class="p-4 mb-4 bg-grey-0 flex justify-between" *ngIf="enrollment">
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [title]="'enrollments.name' | translate"
        [value]="enrollment.firstName + ' ' + enrollment.lastName"
      ></alimento-ipv-frontend-metadata-item>
    </div>
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [title]="'enrollments.company' | translate"
        [value]="enrollment.branch?.label"
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>

  <div [formGroup]="cancelEnrollmentForm">
    <alimento-ipv-frontend-form-field
      [formGroup]="cancelEnrollmentForm"
      [formInputTemplate]="reasonTemplate"
      [required]="true"
      formField="reasonCancelledId"
      fieldKey="enrollments.cancelDialog.reasonCancelled"
    >
      <ng-template #reasonTemplate>
        <p-dropdown
          #focusElement
          [autofocus]='!popupHasOpened'
          class="w-full"
          id="reasonCancelledId"
          *ngIf="cancelEnrollmentReasons$ | async as cancelEnrollmentReasons"
          [options]="cancelEnrollmentReasons"
          formControlName="reasonCancelledId"
          optionValue="data"
          optionLabel="label"
          [filter]="true"
          placeholder="{{ 'trainings.cancelDialog.labels.chooseAnOption' | translate }}"
          appendTo='body'
        ></p-dropdown>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      [formGroup]="cancelEnrollmentForm"
      [formInputTemplate]="commentTemplate"
      formField="cancelledComment"
      fieldKey="enrollments.cancelDialog.comment"
    >
      <ng-template #commentTemplate>
        <textarea
          class="w-full"
          formControlName="cancelledComment"
          id="cancelledComment"
          pInputTextarea
          rows="2"
        ></textarea>
      </ng-template>
    </alimento-ipv-frontend-form-field>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex">
      <p-button
        (onClick)="cancelEnrollment()"
        label="{{ 'enrollments.cancelDialog.buttons.approve' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'enrollments.cancelDialog.buttons.cancel' | translate }}"
        styleClass="inverted-button"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
