import { Route } from '@angular/router';
import { TrainingListComponent } from './pages/training-list/training-list.component';
import { TrainingDetailComponent } from './pages/training-detail/training-detail.component';
import { CanDeactivateGuard, FEATURE, FeatureFlagGuard } from '@alimento-ipv-frontend/ui-lib';
import { CevoraTrainingDetailComponent } from './pages/cevora-training-detail/cevora-training-detail.component';
import { TRAINING_TYPE } from '../types/reference-data.enum';
import {
  ReimbursementRequestTrainingDetailComponent
} from './pages/reimbursement-request-training-detail/reimbursement-request-training-detail.component';
import { TRAINING_PROJECT_AUDIENCE } from '../types/training.enum';

export const trainingsRoutes: Route[] = [
  {
    path: '',
    component: TrainingListComponent,
    data: { title: 'titles.trainings.list' }
  },
  {
    path: 'open',
    children: [
      {
        path: 'create',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.trainings.create',
          trainingType: TRAINING_TYPE.OPEN_TRAINING
        }
      },
      {
        path: ':trainingId/detail',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.trainings.detail',
          trainingType: TRAINING_TYPE.OPEN_TRAINING
        }
      }
    ]
  },
  {
    path: 'cevora',
    children: [
      {
        path: 'create',
        component: CevoraTrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [FeatureFlagGuard],
        data: {
          title: 'titles.cevoraTrainings.create',
          trainingType: TRAINING_TYPE.CEVORA,
          featureFlag: FEATURE.CEVORA_TRAINING
        }
      },
      {
        path: ':trainingId/detail',
        component: CevoraTrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [FeatureFlagGuard],
        data: {
          title: 'titles.cevoraTrainings.detail',
          trainingType: TRAINING_TYPE.CEVORA,
          featureFlag: FEATURE.CEVORA_TRAINING
        }
      }
    ]
  },
  {
    path: 'openextern/:trainingId',
    component: ReimbursementRequestTrainingDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: {
      title: 'titles.reimbursementRequests.training',
      trainingType: TRAINING_TYPE.OPEN_EXTERN
    }
  },
  {
    path: 'custom/:trainingId',
    component: ReimbursementRequestTrainingDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: {
      title: 'titles.reimbursementRequests.training',
      trainingType: TRAINING_TYPE.CUSTOM
    }
  },
  {
    path: 'training-project-pupil',
    canActivate: [FeatureFlagGuard],
    data: {
      audience: TRAINING_PROJECT_AUDIENCE.PUPIL,
      trainingType: TRAINING_TYPE.PUPIL_GENERAL,
      featureFlag: FEATURE.EDUCATIONAL_TRAINING
    },
    children: [
      {
        path: 'create',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.trainingProjectPupil.create',
        }
      },
      {
        path: ':trainingId/detail',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],

        data: {
          title: 'titles.trainingProjectTeacher.detail',
        }
      }
    ]
  },
  {
    path: 'training-project-teacher',
    canActivate: [FeatureFlagGuard],
    data: {
      audience: TRAINING_PROJECT_AUDIENCE.TEACHER,
      trainingType: TRAINING_TYPE.TEACHER_GENERAL,
      featureFlag: FEATURE.EDUCATIONAL_TRAINING
    },
    children: [
      {
        path: 'create',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.trainingProjectPupil.create',
        }
      },
      {
        path: ':trainingId/detail',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.trainingProjectTeacher.detail',
        }
      }
    ]
  },
  {
    path: 'training-event',
    canActivate: [FeatureFlagGuard],
    data: {
      trainingType: TRAINING_TYPE.EDUCATION_TYPE_STUB,
      featureFlag: FEATURE.EDUCATIONAL_TRAINING
    },
    children: [
      {
        path: 'create',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.trainingEvent.create',
        }
      },
      {
        path: ':trainingId/detail',
        component: TrainingDetailComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          title: 'titles.trainingEvent.detail',
        }
      }
    ]
  }
];
