import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PurchaseEntryCreateEvent } from '../../../types/training.type';
import { Subscription } from 'rxjs';
import { SimpleBranch } from '../../../types/branch.type';

@Component({
  selector: 'alimento-ipv-frontend-purchase-entry-create-dialog',
  templateUrl: './purchase-entry-create-dialog.component.html'
})
export class PurchaseEntryCreateDialogComponent implements OnDestroy {

  @Input()
  branches: SimpleBranch[] = [];

  @Output()
  submitPopup = new EventEmitter<PurchaseEntryCreateEvent>();

  loading = false;
  dialogVisible = false;
  formGroup: FormGroup;
  nrOfBranches = 0;

  private _subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder) {
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe());
  }

  open(): void {
    this._createFormGroup();
    this.dialogVisible = true;
  }

  submit(): void {
    this.submitPopup.emit({
      branchIds: JSON.parse(JSON.stringify(this.formGroup.value)).branchIds,
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.formGroup.reset({ enrollmentIds: [] });
  }

  private _createFormGroup(): void {
    this.formGroup = this.fb.group({
      branchIds: [[]]
    });

    this._subscriptions.push(
      this.formGroup.get('branchIds').valueChanges.subscribe(newValue => {
        if (newValue) {
          this.nrOfBranches = newValue.length;
        }
      })
    );
  }

  getBranchesLabel = (branch: SimpleBranch) => {
    return `${branch.alimentoId} - ${branch.name} (${branch.address})`;
  };
}
