import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { Dropdown } from 'primeng/dropdown';
import { CancelSession, CancelSessionReason, SessionListItem } from '../../../types/session.type';
import { SessionService } from '../../../services/session.service';

@Component({
  selector: 'alimento-ipv-frontend-cancel-session-dialog',
  templateUrl: './cancel-session-dialog.component.html'
})
export class CancelSessionDialogComponent {
  @Input()
  session!: SessionListItem;

  @Output()
  cancelSessionConfirmed = new EventEmitter<CancelSession>();

  @ViewChild("focusElement")
  focusElement: Dropdown;

  dialogVisible = false;

  cancelSessionForm: FormGroup;

  cancelSessionReasons$: Observable<CancelSessionReason[]> = this.sessionService.getCancelSessionReasons();

  constructor(
    private fb: FormBuilder,
    private sessionService: SessionService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    this.cancelSessionForm = this.fb.group({
      reasonSessionCancelledId: [null, [Validators.required]],
      sessionCancelledComment: [null],
    });
  }

  open(session: SessionListItem) {
    this.session = session;
    this.dialogVisible = true;
    setTimeout(() => this.focusElement?.focus());
  }

  cancelSession() {
    if (!this.isDataValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('error.fillInRequiredFields'),
      });
      return;
    }

    this.cancelSessionConfirmed.emit(this.cancelSessionForm.value);
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.cancelSessionForm.reset();
  }

  private isDataValid(): boolean {
    validateAllFormFields(this.cancelSessionForm);
    return this.cancelSessionForm.valid;
  }
}
