<div *ngIf="trainingProgram"
     class="shadow-grey-2 shadow-md my-2 p-4 relative border border-input-border"
     [ngClass]='{
     "bg-primary-hover border-primary" : selected,
     "bg-white": !selected,
     "cursor-pointer hover:bg-grey-1": clickable
     }'
>
  <div class='grid grid-cols-[minmax(0,_1fr)_minmax(0,_1fr)_220px] items-center gap-8'>
    <span class='font-bold'
          [ngClass]='{"cursor-pointer hover:underline": !clickable}'
          (click)='onClick($event)'
    >
      {{trainingProgram.trainingProgramAlimentoId}} - {{trainingProgram.name}}
    </span>
    <span>{{rubric}}</span>
    <div class='flex items-center gap-2'>
      <i class='font-material text-lg'>calendar_month</i>
      <span>{{trainingProgram.startDate | date : 'dd/MM/yyyy'}} - {{trainingProgram.endDate | date : 'dd/MM/yyyy'}}</span>
    </div>
  </div>
</div>
