import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BranchDetailComponent } from './pages/branch-detail/branch-detail.component';
import { BranchListComponent } from './pages/branch-list/branch-list.component';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { RippleModule } from 'primeng/ripple';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InterimOfficeSelectComponent } from './components/interim-office-select/interim-office-select.component';
import { InterimOfficeCardComponent } from './components/interim-office-card/interim-office-card.component';
import { EmploymentSelectComponent } from './components/employment-select/employment-select.component';
import { UtilsModule } from '../utils/utils.module';
import { PersonsModule } from '../persons/persons.module';
import { TrainingPlanCardComponent } from './components/training-plan-card/training-plan-card.component';
import { TrainingPlanPopupComponent } from './components/training-plan-popup/training-plan-popup.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {
  TrainingPlanActionDialogComponent
} from './components/training-plan-action-dialog/training-plan-action-dialog.component';
import { BranchComponent } from './components/branch/branch.component';
import { SidebarModule } from 'primeng/sidebar';
import { BranchSearchCardComponent } from './components/branch-search-card/branch-search-card.component';
import { StatusChipComponent } from './components/status-chip/status-chip.component';
import { TrainingPlansComponent } from './components/training-plans/training-plans.component';
import { EditorModule } from 'primeng/editor';

@NgModule({
  imports: [
    CommonModule,
    UiLibModule,
    FormsModule,
    TableModule,
    RippleModule,
    ConfirmDialogModule,
    CheckboxModule,
    MultiSelectModule,
    AutoCompleteModule,
    InputTextModule,
    SkeletonModule,
    RadioButtonModule,
    UtilsModule,
    PersonsModule,
    InputTextareaModule,
    SidebarModule,
    EditorModule
  ],
  providers: [
    CanDeactivateGuard,
    ConfirmationService
  ],
  declarations: [
    BranchDetailComponent,
    BranchListComponent,
    InterimOfficeSelectComponent,
    InterimOfficeCardComponent,
    EmploymentSelectComponent,
    TrainingPlanCardComponent,
    TrainingPlanPopupComponent,
    TrainingPlanActionDialogComponent,
    BranchComponent,
    BranchSearchCardComponent,
    StatusChipComponent,
    TrainingPlansComponent
  ],
  exports: [
    InterimOfficeSelectComponent,
    InterimOfficeCardComponent,
    EmploymentSelectComponent,
    BranchSearchCardComponent,
    TrainingPlansComponent
  ]
})
export class BranchesModule {
}
