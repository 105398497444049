import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { IFilter, IFilterEvent } from '../../../types/search.type';
import { IconMapper } from '../../../utils/icon.mapper';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ml-multiselect-facet',
  templateUrl: './multiselect-facet.component.html',
  styleUrls: ['./multiselect-facet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MultiselectFacetComponent implements OnInit, OnChanges {
  @Input()
  filterName = "";
  @Input()
  data: IFilter[] = [];
  @Input()
  hasIcon = false;
  @Input()
  selectedItems: string[] = [];
  @Input()
  title = "";
  @Input()
  expanded = false;
  @Input()
  showHeader = true;
  @Input()
  iconMapper: (value: string) => string;
  @Input()
  iconClassMapper: (value: string) => string;
  @Input()
  inlineForm = false;

  @Output()
  searchValues: EventEmitter<IFilterEvent> = new EventEmitter<IFilterEvent>();
  selectGroup: FormGroup;
  uniqueId: string = Math.random().toFixed(5);

  constructor(private iconMapperService: IconMapper, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    if (!this.selectGroup) {
      this.selectGroup = this.formBuilder.group({
        items: new FormArray([])
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedItems']?.currentValue && this.data) {
      this.selectGroup = this.formBuilder.group({
        items: this.formBuilder.array(this.data.map(x => this.selectedItems.indexOf(x.value) > -1)),
      });
    }

    if (changes['data']?.currentValue) {
      this._removeNotExistingItems();
      this.selectGroup = this.formBuilder.group({
        items: this.formBuilder.array(this.data.map(x => this.selectedItems.indexOf(x.value) > -1)),
      });
    }
  }

  private _removeNotExistingItems(): void {
    setTimeout(() => {
      const existingItems = this.selectedItems.filter(x => this.data.map(x => x.value).indexOf(x) > -1);
      if (existingItems.length !== this.selectedItems.length) {
        this.selectedItems = existingItems;
        this.searchValues.emit({
          filter: this.filterName,
          values: this.selectedItems
        });
      }
    });
  }

  getIcon(icon: string): string {
    if (this.iconMapper) {
      return this.iconMapper(icon);
    }
    return this.iconMapperService.getIcon(icon) ||
      this.iconMapperService.getIcon(this.filterName + "." + icon);

  }

  getIconClass(icon: string): string {
    if (this.iconClassMapper) {
      return this.iconClassMapper(icon);
    }
    return "";
  }

  onCheckChange(value: string, event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    if (checked) {
      this.selectedItems = [...this.selectedItems, value];
    }
    else {
      this.selectedItems = this.selectedItems.filter(item => item !== value);
    }

    this.searchValues.emit({
      filter: this.filterName,
      values: this.selectedItems
    });
  }
}
