import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingTemplateListComponent } from './pages/training-template-list/training-template-list.component';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { FormsModule } from '@angular/forms';
import { TrainingTemplateDetailComponent } from './pages/training-template-detail/training-template-detail.component';
import { ConfirmationService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';
import { SessionsModule } from '../sessions/sessions.module';
import { UtilsModule } from '../utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    MultiSelectModule,
    UiLibModule,
    UtilsModule,
    InputTextModule,
    ConfirmDialogModule,
    InputSwitchModule,
    SessionsModule,
    MenuModule,
    RippleModule,
  ],
  providers: [ConfirmationService, CanDeactivateGuard],
  declarations: [TrainingTemplateListComponent, TrainingTemplateDetailComponent],
})
export class TrainingTemplatesModule {}
