<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ 'trainings.cancelDialog.title' | translate }}"
>
  <div class="flex bg-grey-0 p-4 mb-4" *ngIf="training">
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        title='{{"trainings.cancelDialog.labels.trainingName" | translate}}'
        [value]='training.customTitle'
      ></alimento-ipv-frontend-metadata-item>
    </div>
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        title='{{"trainings.cancelDialog.labels.firstSessionDate" | translate}}'
        [value]='firstSessionDate ? (firstSessionDate | date:"dd/MM/yyyy") : "/"'
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>

  <div [formGroup]="cancelTrainingForm">
    <div class="field">
      <label for="reasonCancelledId" class="block">{{
        'trainings.cancelDialog.labels.reasonTrainingCancelled' | translate
      }}</label>

      <p-dropdown
        #focusElement
        class="w-full"
        id="reasonCancelledId"
        *ngIf="cancelTrainingReasons$ | async as cancelTrainingReasons"
        [options]="cancelTrainingReasons"
        formControlName="reasonCancelledId"
        optionValue="data"
        optionLabel="label"
        [filter]="true"
        placeholder="{{ 'trainings.cancelDialog.labels.chooseAnOption' | translate }}"
        appendTo='body'
      ></p-dropdown>

      <small
        *ngIf="
          (cancelTrainingForm.get('reasonCancelledId')?.dirty ||
            cancelTrainingForm.get('reasonCancelledId')?.touched) &&
          cancelTrainingForm.get('reasonCancelledId')?.hasError('required')
        "
        class="p-error block"
      >
        {{ 'trainings.cancelDialog.errors.reasonRequired' | translate }}
      </small>
    </div>

    <div class="field">
      <label class="block" for="cancelledComment"
        >{{ 'trainings.cancelDialog.labels.remarks' | translate }}
        <small>({{ 'trainings.optional' | translate }})</small></label
      >
      <textarea
        class="w-full"
        formControlName="cancelledComment"
        id="cancelledComment"
        pInputTextarea
        rows="2"
      ></textarea>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex">
      <p-button
        (onClick)="cancelTraining()"
        [loading]='loading'
        icon="pi pi-times"
        iconPos="left"
        label="{{ 'trainings.cancelDialog.buttons.cancelTraining' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        icon="pi pi-arrow-left"
        iconPos="left"
        label="{{ 'trainings.cancelDialog.buttons.back' | translate }}"
        styleClass="inverted-button"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
