import { Component, HostListener, OnDestroy, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import {
  AuthorizationService,
  CanComponentDeactivate,
  ExtraMenuItem,
  FilterType,
  IFilter,
  InfiniteScrollDataAdapter,
  LeaveConfirmService,
  PaginatedResponse, Role,
  SearchFilter,
  SearchFilterType,
  SearchRequest,
  TabMenuItem,
  TitleService,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { first, map, Observable, Subscription, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MessageService } from 'primeng/api';
import { TrainingProgramComponent } from '../../components/training-program/training-program.component';
import { TrainingProgram } from '../../../types/training-program.type';
import { TrainingSearchItem } from '../../../types/training.type';
import { TrainingProgramService } from '../../../services/training-program.service';
import { TrainingService } from '../../../services/training.service';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { TRAINING_TYPE } from '../../../types/reference-data.enum';

@Component({
  selector: 'alimento-ipv-frontend-training-program-detail',
  templateUrl: './training-program-detail.component.html'
})
export class TrainingProgramDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  trainingProgramId: string;
  trainingProgram: TrainingProgram;

  trainingsCount: WritableSignal<number> = signal(undefined);
  tabMenuItems: TabMenuItem[];
  extraMenuItems: ExtraMenuItem[];
  activeTabIndex = 0;
  readOnly = false;
  saving = false;

  searchTrainingData$: InfiniteScrollDataAdapter<TrainingSearchItem>;
  searchTrainingFilters: SearchFilter[];
  hasChanges = false;

  @ViewChild(TrainingProgramComponent)
  basicInfoComponent: TrainingProgramComponent;

  private _subscriptions: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private trainingProgramService: TrainingProgramService,
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
    private titleService: TitleService,
    private location: Location,
    private messageService: MessageService,
    private trainingService: TrainingService,
    private referenceDataService: ReferenceDataService,
    private leaveConfirmationService: LeaveConfirmService,
    private authorizationService: AuthorizationService
  ) {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
    this._createMenuItems();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (!this.canDeactivate()) {
      $event.returnValue = 'Are you sure';
    }
  }

  ngOnInit() {
    this.route.params.pipe(first())
      .subscribe(params => {
        this.trainingProgramId = params['trainingProgramId'];
        if (this.trainingProgramId) {
          this._fetchReimbursementData();
        }
        else {
          this._navigateToErrorPage();
        }
      });

    this.route.queryParams.pipe(first())
      .subscribe(queryParams => this.activeTabIndex = Number(queryParams['activeTabIndex']) || 0);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  canDeactivate(): Promise<boolean> | boolean {
    if (!this.hasChanges) {
      return true;
    }

    return this.leaveConfirmationService.leaveDialog(() => this._saveTrainingProgram());
  }

  private _fetchReimbursementData(): void {
    this.trainingProgramService.getTrainingProgram(this.trainingProgramId)
      .pipe(first())
      .subscribe({
        next: trainingProgram => {
          this.trainingProgram = trainingProgram;
          this.titleService.setTitle([this.translateService.instant("trainingPrograms.trainingProgram"), trainingProgram.name]);
          this._createMenuItems();
          this._loadTrainingsCount();
          this._createTrainingSearch();
        },
        error: () => {
          this._navigateToErrorPage();
        }
      });
  }

  private _navigateToErrorPage(): void {
    this.router.navigate(['error'], {
      state: {
        message: this.translateService.instant('error.itemWithIdDoesNotExist', {
          item: this.translateService.instant('trainingPrograms.trainingProgram'),
          id: this.trainingProgramId
        }),
        redirectUrl: '/'
      }
    });
  }

  setActiveTabIndex(tabMenuItem: TabMenuItem) {
    this.activeTabIndex = tabMenuItem.index;
    this._setDetailUrl();
  }

  saveReimbursementRequest(): void {
    this._saveTrainingProgram().subscribe();
  }

  private _setDetailUrl(): void {
    if (this.trainingProgramId) {
      this.location.replaceState(
        this.router
          .createUrlTree(['/training-programs', this.trainingProgramId], {
            queryParams: { activeTabIndex: this.activeTabIndex }
          })
          .toString()
      );
    }
  }

  private _createMenuItems(): void {
    this._subscriptions.push(
      this.translationService.languageChange$.subscribe(() => {
        this.tabMenuItems = [
          { name: 'basic', index: 0, title: this.translateService.instant('reimbursementRequests.basicDataTitle') },
          {
            name: 'training',
            index: 1,
            disabled: !this.trainingProgram?.trainingProgramAlimentoId,
            title: this.translateService.instant('reimbursementRequests.trainingTitle'),
            count: this.trainingsCount
          }
        ];

        this.extraMenuItems = [
          // {
          //   name: 'history',
          //   title: this.translateService.instant('reimbursementRequests.historyTitle'),
          //   disabled: true,
          //   command: () => {
          //     //TODO
          //   }
          // }
        ];
      })
    );
  }

  private _loadTrainingsCount(): void {
    if (this.trainingProgram) {
      const searchRequest: SearchRequest = { filters: [] };
      searchRequest.filters.push({
        type: FilterType.filterTrainingProgramId,
        values: [this.trainingProgramId]
      });
      this.trainingService.getTrainingsCount(searchRequest).pipe(first())
        .subscribe(count => {
          this.trainingsCount.set(count);
        });
    }
  }

  private _createTrainingSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: []
    };
    searchRequest.filters.push({
      type: FilterType.filterTrainingProgramId,
      values: [this.trainingProgramId]
    });

    this.searchTrainingData$ = new InfiniteScrollDataAdapter<TrainingSearchItem>((searchRequest: SearchRequest):
    Observable<PaginatedResponse<TrainingSearchItem>> => {
      return this.trainingService.searchTrainings(searchRequest);
    }, searchRequest, true);

    this.searchTrainingFilters = [
      {
        type: SearchFilterType.searchBar,
        key: FilterType.search
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.rubric',
        key: FilterType.filterRubric,
        data: this.referenceDataService.getTrainingRubrics()
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterRubric,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.type',
        key: FilterType.filterType,
        data: this.referenceDataService.getTrainingTypes()
          .pipe(map(types => types
            .filter(type => type.data !== TRAINING_TYPE.OPEN_TRAINING)
            .map(type =>
              ({
                type: FilterType.filterType,
                label: type.label,
                value: type.data
              }) as IFilter))
          )
      },
      {
        type: SearchFilterType.date,
        label: 'trainings.sessions.date',
        key: 'date'
      },
      {
        type: SearchFilterType.multiselect,
        label: 'trainings.caseManager',
        key: FilterType.filterCaseManager,
        data: this.referenceDataService.getCaseManagers(true)
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterCaseManager,
              label: type.label,
              value: type.data
            }) as IFilter)))
      }
    ];
  }

  private _saveTrainingProgram(): Observable<any> {
    if (!this.basicInfoComponent.isValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('trainings.sessions.hasErrors')
      });

      return new Observable<{ id: string }>((observable) => observable.error());
    }

    this.saving = true;
    const data = this.basicInfoComponent.getData() as TrainingProgram;

    return this.trainingProgramService.updateTrainingProgram(this.trainingProgramId, data)
      .pipe(first(), tap({
        next: () => {
          this.saving = false;
          this.hasChanges = false;
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('saveSuccess')
          });
        },
        error: () => {
          this.saving = false;
        }
      }));
  }
}
