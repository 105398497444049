import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'accountNumber'
})
export class AccountNumberPipe implements PipeTransform {

  transform(value: string | undefined): string | undefined {
    if (value && value.length === 16) {
      return value.replace(/^BE([0-9][0-9])([0-9][0-9][0-9][0-9])([0-9][0-9][0-9][0-9])([0-9][0-9][0-9][0-9])$/, "BE$1 $2 $3 $4");
    }
    return value;
  }

}
