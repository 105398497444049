import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[autoWidth]'
})
export class AutoWidthDirective {

  constructor(private el: ElementRef) {}

  @HostListener('ngModelChange') onChange() {
    this.resize();
  }

  @HostListener('keyup') onKeyUp() {
    this.resize();
  }

  @HostListener('focus') onFocus() {
    this.resize();
  }

  private resize() {
    this.el.nativeElement.setAttribute('size', this.el.nativeElement.value.length);
  }
}
