<alimento-ipv-frontend-employment-popup
  #employmentPopupComponent
  [employmentAction]='currentEmploymentAction'
  (formSubmit)="onEmploymentPopupSubmit($event, employmentPopupComponent)"
></alimento-ipv-frontend-employment-popup>

<alimento-ipv-frontend-stop-employment-dialog
  (stopEmploymentConfirmed)="stopEmployment($event)"
></alimento-ipv-frontend-stop-employment-dialog>

<alimento-ipv-frontend-header title="{{ 'persons.employmentsTitle' | translate }}">
  <p-button
    *ngIf='!readOnly'
    (onClick)="addEmployment(employmentPopupComponent)"
    label="{{ 'persons.employments.actions.add' | translate | uppercase }}"
    icon="pi pi-map-marker"
  ></p-button>
</alimento-ipv-frontend-header>

<div class="employments-list" *ngIf="employments() as employments">
  <p-tabView styleClass="employments-tabview">
    <p-tabPanel header="{{ 'persons.employments.current' | translate }} ({{ employments.current.length }})">
      <div class="mt-2">
        <alimento-ipv-frontend-employment-list-item
          *ngFor="let employment of employments.current; let i = index"
          [readOnly]="readOnly"
          [employment]="employment"
          (actionClicked)="employmentAction($event, employmentPopupComponent)"
        ></alimento-ipv-frontend-employment-list-item>

        <div class="employments-list--empty" *ngIf="employments.current.length === 0">
          <span>{{ 'persons.employments.noCurrentEmployments' | translate }}</span>
        </div>
      </div>
    </p-tabPanel>

    <p-tabPanel header="{{ 'persons.employments.previous' | translate }}">
      <div class="mt-2">
        <alimento-ipv-frontend-employment-list-item
          *ngFor="let employment of employments.previous; let i = index"
          [readOnly]="readOnly"
          [employment]="employment"
          (actionClicked)="employmentAction($event, employmentPopupComponent)"
        ></alimento-ipv-frontend-employment-list-item>

        <div class="employments-list--empty" *ngIf="employments.previous.length === 0">
          <span>{{ 'persons.employments.noPreviousEmployments' | translate }}</span>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>
</div>
