<div class="flex flex-col font-light">
  <span class="text-[10px] text-grey-4 uppercase overflow-hidden block text-ellipsis">
    {{ (title || "") | translate }}
  </span>
  <ng-content></ng-content>
  <span class="text-text text-[16px] whitespace-pre-line flex flex-wrap gap-2 {{cardLayout? 'leading-4' : 'leading-6'}}">
    <ng-container *ngFor='let value of (values || [])'>
      <a *ngIf='isLink'
         class='text-primary underline cursor-pointer'
         [routerLink]='value.link || []'>
        {{value.label}}
      </a>
      <span *ngIf='!isLink'>{{value.label}}</span>
    </ng-container>
  </span>
</div>


