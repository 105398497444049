import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, retry, tap, timer } from 'rxjs';

@Injectable()
export class ETagInterceptor implements HttpInterceptor {
  private eTag = '';

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const modifiedReq = request.clone({
      headers: request.headers.set('If-Match', this.eTag),
    });
    return next.handle(modifiedReq).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          this.eTag = event.headers.get('ETag') || this.eTag;
        }
      }),
      retry({ count: 10, delay: this.shouldRetry })
    );
  }

  shouldRetry(error: HttpErrorResponse): Observable<any> {
    if (error.status === 412) {
      return timer(500);
    }
    throw error;
  }
}
