import { Route } from '@angular/router';
import { CanDeactivateGuard, FEATURE, FeatureFlagGuard } from '@alimento-ipv-frontend/ui-lib';
import { EnterpriseListComponent } from './pages/enterprise-list/enterprise-list.component';
import { EnterpriseDetailComponent } from './pages/enterprise-detail/enterprise-detail.component';

export const EnterprisesRoutes: Route[] = [
  {
    path: '',
    component: EnterpriseListComponent,
    data: { title: 'titles.enterprises.list' }
  },
  {
    path: ':enterpriseId/detail',
    component: EnterpriseDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.enterprises.detail' }
  },
  {
    path: 'create',
    component: EnterpriseDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    canActivate: [FeatureFlagGuard],
    data: { title: 'titles.enterprises.create', featureFlag: FEATURE.BRANCH_AND_ENTERPRISE_MANAGEMENT }
  },
];
