<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ 'trainings.purchaseEntries.create' | translate }}"
>
<div *ngIf="dialogVisible" [formGroup]="formGroup">
  <div class='mb-4'>
    <span class='text-lg'>{{'trainings.purchaseEntries.selectBranches' | translate}}</span>
  </div>

  <alimento-ipv-frontend-select-list
    formControlName='branchIds'
    [items]='branches'
    [label]='getBranchesLabel'
    selectAllKey='trainings.purchaseEntries.selectAll'
  >
  </alimento-ipv-frontend-select-list>
</div>

  <ng-template pTemplate="footer">
    <div class="flex">
      <p-button
        (onClick)="submit()"
        [loading]='loading'
        [disabled]='nrOfBranches < 1'
        label="{{ 'trainings.purchaseEntries.createPurchaseEntries' | translate }} ({{ nrOfBranches }})"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'trainings.purchaseEntries.back' | translate }}"
        styleClass="inverted-button"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
