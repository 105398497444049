<div *ngIf="branchListItem"
     class="shadow-grey-2 shadow-md my-2 p-4 relative border border-input-border
{{selected ? 'bg-primary bg-opacity-20' : 'bg-white'}}"
     [ngClass]='{"hover:bg-grey-1 cursor-pointer": selectable}'
>
  <div class='grid grid-cols-[minmax(0,_1fr)_minmax(0,_1fr)_100px] items-start w-full'>
    <div class='flex items-center gap-1 flex-wrap'>
      <span class='font-bold text-lg'>{{branchListItem.alimentoId}} - {{branchListItem.name}}</span>
      <span *ngIf='branchListItem.companyNumber'>({{branchListItem.companyNumber}})</span>
    </div>

    <span>{{branchListItem.address}}</span>

    <div class='flex justify-end'>
      <alimento-ipv-frontend-branch-status-chip [isActive]='branchListItem.isActive'></alimento-ipv-frontend-branch-status-chip>
    </div>
  </div>

  <div class='flex items-start justify-between'>
    <div class='flex flex-col gap-1'>
      <span *ngIf='branchListItem.rszNumber'>{{'branches.rszNumber' | translate}}: {{branchListItem.rszNumber}}</span>
      <span *ngIf='branchListItem.vatNumber'>{{'branches.vatNumber' | translate}}: {{branchListItem.vatNumber}}</span>
    </div>

    <div class='flex flex-col items-center justify-between'>
      <div class='flex items-center gap-2 px-1'>
        <span *ngFor='let category of branchesMapper.getCategoryOptions(false)'
              class='font-material w-4'
              pTooltip='{{category.label}}'
              tooltipPosition='bottom'>
          {{$any(branchListItem)[category.key] ? category.icon : ''}}
        </span>
      </div>
      <span>{{branchListItem.numberOfEmployees}} {{'enterprises.employees' | translate}}</span>
    </div>
  </div>
</div>
