import { Injectable } from '@angular/core';
import { FilterType, SearchParam } from '@alimento-ipv-frontend/ui-lib';
import {
  PUPIL_TRAINING_TYPE,
  PupilTrainingTypeKey,
  TEACHER_TRAINING_TYPE,
  TeacherTrainingTypeKey
} from '../../types/reference-data.enum';

@Injectable({
  providedIn: 'root'
})
export class TrainingMapper {

  changeTrainingTypeFilter(params: SearchParam): void {
    if (params[FilterType.filterType]?.length > 0) {
      if (params[FilterType.filterType].includes(PupilTrainingTypeKey)) {
        params[FilterType.filterType] = [
          ...Object.values(PUPIL_TRAINING_TYPE),
          ...params[FilterType.filterType].filter((value: string) => value !== PupilTrainingTypeKey)
        ];
      }
      if (params[FilterType.filterType].includes(TeacherTrainingTypeKey)) {
        params[FilterType.filterType] = [
          ...Object.values(TEACHER_TRAINING_TYPE),
          ...params[FilterType.filterType].filter((value: string) => value !== TeacherTrainingTypeKey)
        ];
      }
    }
  }
}
