import {Injectable} from '@angular/core';
import {MessageService} from "primeng/api";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class MyMessageService {
  constructor(private messageService: MessageService,
              private translateService: TranslateService) {
  }

  notAllFieldsValid(): void {
    this.messageService.add({
      severity: 'error',
      detail: this.translateService.instant("error.notAllFieldsValid"),
      life: 3000
    });
  }

  success(text?: string): void {
    this.messageService.add({
      severity: 'success',
      contentStyleClass: text ? '' : 'no-detail',
      summary: this.translateService.instant("saveSuccess"),
      detail: text ? this.translateService.instant(text) : undefined,
      life: 3000
    });
  }

  remove(text: string = "removed"): void {
    this.messageService.add({
      severity: 'info',
      summary: this.translateService.instant("removed"),
      detail: this.translateService.instant(text),
      life: 3000
    });
  }

  error(text: string): void {
    this.messageService.add({
      severity: 'error',
      detail: this.translateService.instant(text),
      life: 3000
    });
  }
}
