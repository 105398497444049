import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExtraMenuItem, TabMenuItem } from '../../types/tab-menu-item.type';

@Component({
  selector: 'alimento-ipv-frontend-tab-menu',
  templateUrl: './tab-menu.component.html'
})
export class TabMenuComponent {
  @Input()
  data: TabMenuItem[] = [];

  @Input()
  extra: ExtraMenuItem[] = [];

  @Input()
  activeTabIndex = 0;

  @Output()
  activeTabChanged: EventEmitter<TabMenuItem> = new EventEmitter<TabMenuItem>();

  @Output()
  extraTabClicked: EventEmitter<ExtraMenuItem> = new EventEmitter<ExtraMenuItem>();

  activateTab(item: TabMenuItem): void {
    if (!item.disabled && (!item.count || item.count() !== -1)) {
      this.activeTabIndex = item.index;
      this.activeTabChanged.emit(item);
    }
  }

  clickTab(item: ExtraMenuItem): void {
    if (!item.disabled && (!item.count || item.count() !== -1)) {
      if (item.command) {
        item.command();
      }
      this.extraTabClicked.emit(item);
    }
  }
}
