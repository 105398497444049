<div class='mt-4 mx-8'>
  <div class="flex items-center justify-between mb-4">
    <h1 class="uppercase">{{ 'reimbursementRequests.menuTitle' | translate }}</h1>
    <p-button
      *hideIfAllUnauthorized='[Role.ADMIN, Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE]'
      label="{{ 'reimbursementRequests.actions.add' | translate }}"
      styleClass="uppercase"
      [routerLink]="['create']"
    ></p-button>
  </div>

  <lib-search [data$]='searchData$'
              [filters]='searchFilters'
              [sortItems]='sortFilters'
              [cardTemplate]='cardTemplate'
              name='reimbursement-requests'
              searchPlaceholderKey='reimbursementRequests.searchPlaceholder'
              availableResultsTranslateKey='reimbursementRequests.menuTitle'
  >
    <ng-template #cardTemplate let-item="item">
      <alimento-ipv-frontend-reimbursement-request-card
        [reimbursementRequest]='item'
        [routerLink]='["/reimbursement-requests", item.trainingAllowanceApplicationId, "detail"]'
        tabindex="0"
      ></alimento-ipv-frontend-reimbursement-request-card>
    </ng-template>
  </lib-search>
</div>
