<div *ngIf='enterprise' [formGroup]='formGroup' class='person-form max-w-[650px]'>

  <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                    [required]='true'
                                    fieldKey='enterprises.enterpriseName'
                                    formField='enterpriseName'>
    <span *ngIf='readOnly' class='read-only-field'>{{ enterprise?.enterpriseName }}</span>
    <input *ngIf='!readOnly'
           class='w-full'
           formControlName='enterpriseName'
           id='enterpriseName'
           pInputText
           type='text' />
  </alimento-ipv-frontend-form-field>

  <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                    [required]='true'
                                    [errorTemplate]='companyNumberErrorTemplate'
                                    fieldKey='branches.companyNumber'
                                    formField='companyNumber'>
    <span *ngIf='readOnly || enterprise.enterpriseId' class='read-only-field'>{{ enterprise.companyNumber }}</span>
    <input *ngIf='!(readOnly || enterprise.enterpriseId)'
           class='w-full'
           formControlName='companyNumber'
           id='companyNumber'
           pInputText
           type='text' />

    <ng-template #companyNumberErrorTemplate>
      <small *ngIf="formGroup.get('companyNumber').errors?.['companyNumberInvalid']">
        {{"validation.companyNumberInvalid" | translate }}
      </small>
      <small *ngIf="formGroup.get('companyNumber').errors?.['required']">
        {{"validation.isRequired" | translate:{field: 'branches.companyNumber' | translate} }}
      </small>
    </ng-template>
  </alimento-ipv-frontend-form-field>

  <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                    [errorTemplate]='VATNumberErrorTemplate'
                                    fieldKey='branches.vatNumber'
                                    formField='vatNumber'>
    <span *ngIf='readOnly' class='read-only-field'>{{ enterprise.vatNumber }}</span>
    <input *ngIf='!readOnly' class='w-full' formControlName='vatNumber' id='vatNumber' pInputText type='text' />

    <ng-template #VATNumberErrorTemplate>
      <small *ngIf="formGroup.get('vatNumber').errors?.['VATNumberInvalid']">
        {{"validation.vatNumberInvalid" | translate }}
      </small>
      <small *ngIf="formGroup.get('vatNumber').errors?.['required']">
        {{"validation.isRequired" | translate:{field: 'branches.vatNumber' | translate} }}
      </small>
    </ng-template>
  </alimento-ipv-frontend-form-field>

  <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                    [errorTemplate]='RSZNumberErrorTemplate'
                                    fieldKey='branches.rszNumber'
                                    formField='rszNumber'>
    <span *ngIf='readOnly' class='read-only-field'>{{ enterprise.rszNumber }}</span>
    <input *ngIf='!readOnly' class='w-full' formControlName='rszNumber' id='rszNumber' pInputText type='text' />

    <ng-template #RSZNumberErrorTemplate>
      <small *ngIf="formGroup.get('rszNumber').errors?.['RSZNumberInvalid']">
        {{"validation.rszNumberInvalid" | translate }}
      </small>
      <small *ngIf="formGroup.get('rszNumber').errors?.['required']">
        {{"validation.isRequired" | translate:{field: 'branches.rszNumber' | translate} }}
      </small>
    </ng-template>
  </alimento-ipv-frontend-form-field>

  <div class='grid grid-cols-3 gap-4'>
    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      fieldKey='branches.numberOfEmployees'
                                      formField='numberOfEmployees'>
      <span *ngIf='readOnly' class='read-only-field'>{{ enterprise.numberOfEmployees }}</span>
      <p-inputNumber *ngIf='!readOnly'
                     inputStyleClass='w-full'
                     formControlName='numberOfEmployees'
                     [min]='0'
                     [maxFractionDigits]='0'
                     id='numberOfEmployees'>
      </p-inputNumber>

    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      fieldKey='branches.numberOfClerks'
                                      formField='numberOfClerksPC220'>
      <span *ngIf='readOnly' class='read-only-field'>{{ enterprise.numberOfClerksPC220 }}</span>
      <p-inputNumber *ngIf='!readOnly'
                     inputStyleClass='w-full'
                     formControlName='numberOfClerksPC220'
                     [min]='0'
                     [maxFractionDigits]='0'
                     id='numberOfClerksPC220'>
      </p-inputNumber>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      fieldKey='branches.numberOfWorkers'
                                      formField='numberOfWorkersPC118'>
      <span *ngIf='readOnly' class='read-only-field'>{{ enterprise.numberOfWorkersPC118 }}</span>
      <p-inputNumber *ngIf='!readOnly'
                     inputStyleClass='w-full'
                     formControlName='numberOfWorkersPC118'
                     [min]='0'
                     [maxFractionDigits]='0'
                     id='numberOfWorkersPC118'>
      </p-inputNumber>
    </alimento-ipv-frontend-form-field>
  </div>

  <alimento-ipv-frontend-form-field
    [formGroup]='formGroup'
    fieldKey='branches.rszCodes'
  >
    <div *ngIf='!readOnly'>
      <div *ngFor='let rszCodesGroup of rszCodes.controls; let i = index'>
        <ng-container formArrayName='rszCodes'>
          <div class='flex items-center justify-between'>
            <div class='flex-grow'>
              <input *ngIf='!readOnly' [formControlName]='i' class='w-full' pInputText type='text' />
            </div>
            <div class='basis-12'>
              <button (click)='deleteRszCode(i)' *ngIf='rszCodes.controls.length > 1' class='p-2 group'>
                <i class='pi pi-times p-2 text-grey-3 group-hover:bg-primary-hover'></i>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
      <div class='flex items-center w-fit mt-2 p-2 text-primary hover:bg-primary-hover'>
        <button (click)='addRszCode()'>
          <i class='pi pi-plus mr-2'></i> {{ 'enterprises.actions.addRszCode' | translate }}
        </button>
      </div>
    </div>
    <span *ngIf='readOnly' class='read-only-field'>{{enterprise.rszCodes.join(", ")}}</span>
  </alimento-ipv-frontend-form-field>

  <alimento-ipv-frontend-form-field
    [formGroup]='formGroup'
    formField='naceCodesIds'
    fieldKey='enterprises.naceCodesIds'
  >
    <span *ngIf='readOnly' class='read-only-field'>{{naceCodesReadOnly}}</span>
    <div *ngIf='!readOnly'>
      <p-multiSelect
        [filter]='true'
        [options]='naceCodes'
        [showClear]='true'
        appendTo='body'
        formControlName='naceCodesIds'
        id='naceCodesIds'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'persons.employments.workStatusPlaceholder' | translate }}"
        styleClass='w-full'
      ></p-multiSelect>
    </div>
  </alimento-ipv-frontend-form-field>

  <div class='pb-4'>
    <p-checkbox
      [binary]='true'
      formControlName='isEducationalInstitution'
      label="{{ 'enterprises.isEducationalInstitution' | translate }}"
    ></p-checkbox>
  </div>

  <alimento-ipv-frontend-form-field
    [formGroup]='formGroup'
    [errorTemplate]='dateErrorTemplate'
    formField='rszCancellationDate'
    fieldKey='branches.rszCancellationDate'
  >
    <span *ngIf='readOnly' class='read-only-field'>{{enterprise.rszCancellationDate | date:"dd/MM/yyyy"}}</span>
    <div class='max-w-[204px]'>
      <p-calendar
        *ngIf='!readOnly'
        [showIcon]="true"
        [showTime]="false"
        [showOnFocus]='false'
        [keepInvalid]='true'
        appendTo="body"
        dataType="date"
        dateFormat="dd/mm/yy"
        formControlName="rszCancellationDate"
        id="rszCancellationDate"
        placeholder="dd/mm/yyyy"
        styleClass="w-full"
      ></p-calendar>
    </div>

    <ng-template #dateErrorTemplate>
      <!--      <small *ngIf='formGroup.get("rszCancellationDate")?.hasError("required")'>-->
      <!--        {{ 'trainings.sessions.dateRequired' | translate }}-->
      <!--      </small>-->
      <small *ngIf='formGroup.get("rszCancellationDate").hasError("invalidDate")'>
        {{ 'validation.invalidDate' | translate }}
      </small>
    </ng-template>
  </alimento-ipv-frontend-form-field>

  <div>
    <h3>{{ 'branches.sectors' | translate }}</h3>

    <div class='flex flex-col gap-2'>
      <p-checkbox
        [binary]='true'
        formControlName='isFood'
        label="{{ 'branches.isFood' | translate }}"
      ></p-checkbox>

      <p-checkbox
        [binary]='true'
        formControlName='isFishing'
        label="{{ 'branches.isFishing' | translate }}"
      ></p-checkbox>

      <p-checkbox
        [binary]='true'
        formControlName='isFoodTrade'
        label="{{ 'branches.isFoodTrade' | translate }}"
      ></p-checkbox>
    </div>
  </div>
</div>
