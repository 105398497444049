import { Route } from '@angular/router';
import { CanDeactivateGuard } from '@alimento-ipv-frontend/ui-lib';
import { PersonListComponent } from './pages/person-list/person-list.component';
import { PersonDetailComponent } from './pages/person-detail/person-detail.component';

export const PersonsRoutes: Route[] = [
  {
    path: '',
    component: PersonListComponent,
    data: { title: 'titles.persons.list' }
  },
  {
    path: ':personId/detail',
    component: PersonDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.persons.detail' }
  },
  {
    path: 'create',
    component: PersonDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.persons.create' }
  }
];
