import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { TrainingProgramCardComponent } from './components/training-program-card/training-program-card.component';
import { RouterLink } from '@angular/router';
import { EditorModule } from 'primeng/editor';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BasicInfoComponent } from './components/basic-info/basic-info.component';
import { SummaryDescriptionComponent } from './components/summary-description/summary-description.component';
import { AddressComponent } from './components/address/address.component';
import { SubSectorsComponent } from './components/subsectors-dialog/sub-sectors.component';
import { LanguageCodeComponent } from './components/language-code/language-code.component';
import { BranchCardComponent } from './components/branch-card/branch-card.component';
import { BranchSelectComponent } from './components/branch-select/branch-select.component';
import { TrainingCardComponent } from './components/training-card/training-card.component';
import { EducationLevelDialogComponent } from './components/education-level-dialog/education-level-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    UiLibModule,
    RouterLink,
    EditorModule,
    CheckboxModule,
    RadioButtonModule,
    DropdownModule,
    MultiSelectModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
  ],
  providers: [
    CanDeactivateGuard
  ],
  declarations: [
    BasicInfoComponent,
    SummaryDescriptionComponent,
    AddressComponent,
    SubSectorsComponent,
    LanguageCodeComponent,
    TrainingProgramCardComponent,
    BranchCardComponent,
    BranchSelectComponent,
    TrainingCardComponent,
    EducationLevelDialogComponent
  ],
  exports: [
    TrainingProgramCardComponent,
    BasicInfoComponent,
    SummaryDescriptionComponent,
    AddressComponent,
    LanguageCodeComponent,
    BranchCardComponent,
    BranchSelectComponent,
    TrainingCardComponent,
    EducationLevelDialogComponent
  ]
})
export class UtilsModule {
}
