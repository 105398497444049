import { Component, Input } from '@angular/core';
import { SearchEnterpriseItem } from '../../../types/enterprise.type';
import { BranchesMapper } from '../../../utils/mapper/branches.mapper';

@Component({
  selector: 'alimento-ipv-frontend-enterprise-card',
  templateUrl: './enterprise-card.component.html'
})
export class EnterpriseCardComponent {

  @Input()
  enterprise: SearchEnterpriseItem;

  constructor(public branchesMapper: BranchesMapper) {
  }

}
