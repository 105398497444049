import { Component, OnInit } from '@angular/core';
import {
  FilterType,
  IFilter,
  InfiniteScrollDataAdapter,
  PaginatedResponse,
  SearchFilter,
  SearchFilterType,
  SearchRequest,
  SearchComponent,
  AuthenticationService, Role, AuthorizationService
} from '@alimento-ipv-frontend/ui-lib';
import { first, map, Observable } from 'rxjs';
import { TabViewChangeEvent } from 'primeng/tabview';
import { CompleteTaskDialogComponent } from '../../components/complete-task-dialog/complete-task-dialog.component';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { CommunicationsMapper } from '../../utils/communications.mapper';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { CompleteTaskEvent, SearchCommunicationsItem } from '../../../types/communications.type';
import { CommunicationsService } from '../../../services/communications.service';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { COMMUNICATION_TO_DO, MailLogState } from '../../../types/communications.enum';

@Component({
  selector: 'alimento-ipv-frontend-communication-list',
  templateUrl: './communication-list.component.html'
})
export class CommunicationListComponent implements OnInit {

  readOnly = true;
  toDoSearchData$: InfiniteScrollDataAdapter<SearchCommunicationsItem>;
  toDOSearchFilters: SearchFilter[];
  searchData$: InfiniteScrollDataAdapter<SearchCommunicationsItem>;
  searchFilters: SearchFilter[];
  activeIndex = 0;
  toDoCount: number;

  private NUMBER_OF_LOADED_ITEMS = 10;

  constructor(private router: Router,
              private location: Location,
              private activatedRoute: ActivatedRoute,
              private communicationsService: CommunicationsService,
              private referenceDataService: ReferenceDataService,
              private messageService: MessageService,
              private mapper: CommunicationsMapper,
              private translateService: TranslateService,
              private authorizationService: AuthorizationService,
              private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
    this.activatedRoute.queryParams
      .pipe(first())
      .subscribe(params => {
        this.activeIndex = params["tabIndex"] || 0;
      });

    this._loadToDoCount();
    this._createToDoSearch();
    this._createSearch();
  }

  private _createToDoSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: this.NUMBER_OF_LOADED_ITEMS,
      filters: []
    };
    searchRequest.filters.push({type: FilterType.toDo, values: [COMMUNICATION_TO_DO]});

    this.toDoSearchData$ = new InfiniteScrollDataAdapter<SearchCommunicationsItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<SearchCommunicationsItem>> => {
      return this.communicationsService.searchCommunications(searchRequest);
    }, searchRequest, true);
    this.toDOSearchFilters = [
      {
        type: SearchFilterType.date,
        label: 'communications.sendDate',
        key: 'date'
      },
      {
        type: SearchFilterType.multiselect,
        label: 'communications.mailTemplate',
        key: FilterType.mailTemplate,
        data: this.referenceDataService.getMailTypes()
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.mailTemplate,
              label: this.translateService.instant("mailTypes." + type.data),
              value: type.data
            }) as IFilter)))
      }
    ];
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: this.NUMBER_OF_LOADED_ITEMS,
      filters: []
    };

    this.searchData$ = new InfiniteScrollDataAdapter<SearchCommunicationsItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<SearchCommunicationsItem>> => {
      return this.communicationsService.searchCommunications(searchRequest);
    }, searchRequest, true);
    this.searchFilters = [
      {
        type: SearchFilterType.date,
        label: 'communications.sendDate',
        key: 'date'
      },
      {
        type: SearchFilterType.multiselect,
        label: 'communications.mailStatus',
        key: FilterType.mailStatus,
        hasIcon: true,
        iconMapper: (item: string) => this.mapper.getMailLogStateIcon(item as MailLogState),
        iconClassMapper: (item: string) => ("text-xl text-" + this.mapper.getMailLogColor(item as MailLogState)),
        data: this.referenceDataService.getMailStatus()
          .pipe(map(types => types.map(type =>
            ({ type: FilterType.mailStatus, label: type.label, value: type.data }) as IFilter)))
      },
      {
        type: SearchFilterType.multiselect,
        label: 'communications.mailTemplate',
        key: FilterType.mailTemplate,
        data: this.referenceDataService.getMailTypes()
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.mailTemplate,
              label: this.translateService.instant("mailTypes." + type.data),
              value: type.data
            }) as IFilter)))
      }
    ];
  }

  changeTab(event: TabViewChangeEvent): void {
    this.location.replaceState(
      this.router.createUrlTree(
        ["communications"],
        {queryParams: {tabIndex: event.index}}
      ).toString()
    );

    this.activeIndex = event.index;
  }

  completeTasks(event: CompleteTaskEvent, completeTaskDialog: CompleteTaskDialogComponent, searchComponent: SearchComponent): void {
    event.setLoading(true);
    this.communicationsService.completeTasks(event.data).pipe(first())
      .subscribe({
        next: () => {
          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('communications.tasksCompleted')
          });
          searchComponent.refresh();
          completeTaskDialog.closeDialog();
          event.setLoading(false);
        },
        error: () => event.setLoading(false)
      });
  }

  beforeSearchRequest = (searchComponent: SearchComponent, searchRequest: SearchRequest) => {
    if (searchComponent.isToggled) {
      searchRequest.filters.push({type: FilterType.filterSenderId, values: [
        this.authenticationService.getCurrentUserId()
        ]});
    }

    this.mapper.changeStatusFilter(searchRequest);
  }

  private _loadToDoCount() {
    const communicationRequest = {filters: []} as SearchRequest;
    communicationRequest.filters.push({type: FilterType.toDo, values: [COMMUNICATION_TO_DO]});
    communicationRequest.filters.push({type: FilterType.filterSenderId, values: [this.authenticationService.getCurrentUserId()]});

    this.communicationsService.getCommunicationsCount(communicationRequest).pipe(first())
      .subscribe(count => this.toDoCount = count);
  }
}
