import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  APP_CONFIG_TOKEN,
  AppConfig,
  PaginatedResponse,
  PersonMatchesRequest,
  SearchMapper,
  SearchRequest
} from '@alimento-ipv-frontend/ui-lib';
import { map, Observable } from 'rxjs';
import { BranchListItem, EmploymentSearchItem, TrainingProgramSearchItem } from '../types/searches.type';
import { EmploymentMapper } from '../utils/mapper/employment.mapper';
import { SearchPersonItem } from '../types/person.type';
import { BranchesMapper } from '../utils/mapper/branches.mapper';
import { TrainingSearchItem } from '../types/training.type';
import { TrainingMapper } from '../utils/mapper/training.mapper';

@Injectable({
  providedIn: 'root'
})
export class SearchesService {
  constructor(private http: HttpClient,
              private employmentMapper: EmploymentMapper,
              private branchesMapper: BranchesMapper,
              private trainingMapper: TrainingMapper,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  searchTrainingPrograms(searchParams: SearchRequest): Observable<PaginatedResponse<TrainingProgramSearchItem>> {
    const params = SearchMapper.getParams(searchParams);
    return this.http.post<PaginatedResponse<TrainingProgramSearchItem>>
    (`${this.config.readApiUrl}/trainingprogramsearches`, params);
  }

  searchEmployments(searchParams: SearchRequest): Observable<PaginatedResponse<EmploymentSearchItem>> {
    const params = SearchMapper.getParams(searchParams);
    this.employmentMapper.setEmploymentRoleFilter(params);

    return this.http.post<PaginatedResponse<EmploymentSearchItem>>
    (`${this.config.readApiUrl}/employmentsearches `, params);
  }

  searchBranches(branchParams?: SearchRequest): Observable<PaginatedResponse<BranchListItem>> {
    const params = SearchMapper.getParams(branchParams);
    this.branchesMapper.setCategoryFilter(params);
    this.branchesMapper.setStateFilter(params);
    this.branchesMapper.setNumberOfEmployeesFilter(params);

    return this.http.post<PaginatedResponse<BranchListItem>>(`${this.config.readApiUrl}/branchsearches`, params);
  }

  searchPerson(personParams?: SearchRequest): Observable<PaginatedResponse<SearchPersonItem>> {
    const params = SearchMapper.getParams(personParams);
    this.employmentMapper.setEmploymentRoleFilter(params);
    return this.http.post<PaginatedResponse<SearchPersonItem>>(`${this.config.readApiUrl}/personsearches`, params)
      .pipe(map(result => {
        result.data = result.data.map(person => {
          if (person.employments) {
            person.employments = person.employments.map(employment => {
              this.employmentMapper.setBranchName(employment);
              return employment;
            });
          }
          return person;
        });
        return result;
      }));
  }

  searchPersonMatches(request: PersonMatchesRequest): Observable<SearchPersonItem[]> {
    return this.http.post<any>(`${this.config.readApiUrl}/personmatches `, request).pipe(map(result => {
      if (result.exactPersonMatch) {
        return [result.exactPersonMatch, ...result.personMatchesToValidate];
      }
      else {
        return result.personMatchesToValidate;
      }
    }));
  }

  searchTrainings(requestParams: SearchRequest): Observable<PaginatedResponse<TrainingSearchItem>> {
    const params = SearchMapper.getParams(requestParams);
    this.trainingMapper.changeTrainingTypeFilter(params);
    return this.http.post<PaginatedResponse<TrainingSearchItem>>(`${this.config.readApiUrl}/trainingsearches`, params);
  }
}
