<div class="pb-4 items-center relative">
  <div class="flex flex-wrap items-center" *ngIf="filterList.length > 0" >
    <ng-container *ngFor="let filter of filterList; let i = index">
      <span
        *ngIf="i < 10 || showAllFilters"
        class="p-2 bg-grey-3 bg-opacity-10 rounded mb-1 mr-1 flex items-center hover:bg-opacity-5"
      >
        <span [innerHTML]="filter.label"></span>
        <span (click)="removeFilterEvent(filter)" class="font-material ml-3 cursor-pointer">
          Close
        </span>
      </span>
    </ng-container>
    <ng-container *ngIf="filterList.length > 10">
      <span *ngIf="!showAllFilters"
            [pTooltip]="'showAllFilters' | translate"
            class="p-2 mb-1 mr-1 bg-grey-3 text-white rounded hover:bg-opacity-70 cursor-pointer"
            (click)="showAllFilters = !showAllFilters">
        +{{filterList.length - 10}}
      </span>
    </ng-container>
    <span (click)="removeAllFiltersEvent()"
      class="text-grey-3 ml-2 hover:text-grey-2 cursor-pointer uppercase font-bold"
    >{{"search.clearFilters" | translate}}</span>
  </div>
  <div *ngIf="filterList.length === 0" class="py-2 mb-1">
    <span class="uppercase text-black text-opacity-40 font-bold">{{"search.noActiveFilters" | translate}}</span>
  </div>
</div>

