export enum ENROLLMENT_VIA {
  NO_COMPANY = "877081bd-aee8-4a23-a5c0-6ef28078a195",
  COMPANY = "174ff334-db38-4c47-b3b2-8d1f8fa64a77",
  CEVORA = "0fb79e27-d9fd-457a-9c29-bb8ed105f553"
}

export enum EnrollmentRequestActionEnum {
  view = 'view',
  save = 'save',
  validate = 'validate',
  verify = 'verify',
  delete = "delete",
  cancel = 'cancel',
  navigateToBranch = 'navigateToBranch',
  editParticipant = 'editParticipant',
  cancelParticipant = 'cancelParticipant',
  deleteParticipant = 'deleteParticipant',
}

export enum ENROLLMENT_RESULT_ID {
  CANCEL = "d79a0ef7-1114-47da-b40d-c09e63697a52",
  SUBSCRIBE = "1d9bc947-f523-43d1-a7d3-4e16a4e7add1",
  RESERVE = "0053395f-f949-4d4c-92a2-5287afd6b1fc"
}

export type EnrollmentResultKey = (typeof ENROLLMENT_RESULT_ID)[keyof typeof ENROLLMENT_RESULT_ID];

export const getEnrollmentResultKey = (status: EnrollmentResultKey): string => {
  return Object.keys(ENROLLMENT_RESULT_ID).filter(
    (key) => (ENROLLMENT_RESULT_ID as { [key: string]: string })[key] === status
  )[0];
};
