export {};
declare global {
  interface String {
    format(): string;

    toLowerFirstLetter(): string
  }
}

if (!String.prototype.format) {
  String.prototype.format = function (...args) {
    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
  };
}

if (!String.prototype.toLowerFirstLetter) {
  String.prototype.toLowerFirstLetter = function () {
    return this.replace(/[A-Z]/, (match: string) => match.toLowerCase());
  }
}
