import {Injectable} from "@angular/core";
import {formatDate} from "@angular/common";
import { TranslationService } from '../services/translation.service';

@Injectable({
  providedIn: "root"
})
export class DateMapper {

  constructor(private localeService: TranslationService) {
  }

  formatDates(fromDate: Date, toDate?: Date, format = "d MMM \''\'YY", timeZone = 'nl'): string {
    const fromDateString = formatDate(fromDate, format, this.localeService.getCurrentLanguage() + "-BE", timeZone)
      .replace("\.", "");
    if (toDate) {
      const toDateString = formatDate(toDate, format, this.localeService.getCurrentLanguage() + "-BE", timeZone)
        .replace("\.", "");
      return fromDateString !== toDateString ? fromDateString + "\n - \n" + toDateString : fromDateString;
    }
    return fromDateString;
  }

  static getDateFromDateTimeAsString(value: Date | string): string | undefined {
    if (!value) {
      return undefined;
    }

    return formatDate(value, "yyyy-MM-dd", "nl", "nl");
  }

  static getTimeFromDate(date: Date): string {
    if (!date) {
      return "";
    }
    return date.getHours() + ":" + String(date.getMinutes()).padStart(2, '0');
  }

  static getDateFromTime(time: string): Date {
    if (!time) {
      return undefined;
    }
    const date = new Date();
    const timeParts = time.split(":");

    if (timeParts.length != 2) {
      return undefined;
    }
    date.setHours(parseInt(timeParts[0]));
    date.setMinutes(parseInt(timeParts[1]));

    return date;
  }

  static getTimeNumber(time: string): number {
    return Number(time.replace(":", ""));
  }
}
