import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnterpriseDetailComponent } from './pages/enterprise-detail/enterprise-detail.component';
import { EnterpriseListComponent } from './pages/enterprise-list/enterprise-list.component';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { FormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
import { RippleModule } from 'primeng/ripple';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { UtilsModule } from '../utils/utils.module';
import { PersonsModule } from '../persons/persons.module';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { EnterpriseCardComponent } from './components/enterprise-card/enterprise-card.component';
import { StatusChipComponent } from './components/status-chip/status-chip.component';
import { EnterpriseBranchCardComponent } from './components/enterprise-branch-card/enterprise-branch-card.component';
import { AccountNumberCardComponent } from './components/account-number-card/account-number-card.component';
import {
  AccountNumberActionDialogComponent
} from './components/account-number-action-dialog/account-number-action-dialog.component';
import { AccountNumberPopupComponent } from './components/account-number-popup/account-number-popup.component';
import { InputMaskModule } from 'primeng/inputmask';
import { EnterpriseComponent } from './components/enterprise/enterprise.component';
import { SmeBonusComponent } from './components/sme-bonus/sme-bonus.component';

@NgModule({
  imports: [
    CommonModule,
    UiLibModule,
    FormsModule,
    TableModule,
    RippleModule,
    ConfirmDialogModule,
    CheckboxModule,
    MultiSelectModule,
    AutoCompleteModule,
    InputTextModule,
    SkeletonModule,
    RadioButtonModule,
    UtilsModule,
    PersonsModule,
    InputTextareaModule,
    InputMaskModule
  ],
  providers: [
    CanDeactivateGuard,
    ConfirmationService
  ],
  declarations: [
    EnterpriseDetailComponent,
    EnterpriseListComponent,
    EnterpriseCardComponent,
    StatusChipComponent,
    EnterpriseBranchCardComponent,
    AccountNumberCardComponent,
    AccountNumberActionDialogComponent,
    AccountNumberPopupComponent,
    EnterpriseComponent,
    SmeBonusComponent
  ],
  exports: [
  ]
})
export class EnterprisesModule {
}
