import { Route } from '@angular/router';
import { TrainingProgramDetailComponent } from './pages/training-program-detail/training-program-detail.component';
import { CanDeactivateGuard } from '@alimento-ipv-frontend/ui-lib';

export const trainingProgramRoutes: Route[] = [
  {
    path: ':trainingProgramId',
    component: TrainingProgramDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.trainingPrograms.detail' }
  }
];
