import { inject, Injectable } from '@angular/core';
import { first, Observable, shareReplay, tap } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Role } from '../types/Roles.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  private _roles: Role[];
  private _initObservable: Observable<void>;
  private _initialized = false;

  constructor(private authenticationService: AuthenticationService,
              private router: Router
  ) {
  }

  hasRole(checkRole: Role): boolean {
    return !!(this._roles && this._roles.find(role => {
      return role === checkRole;
    }));
  }

  hasAnyRole(checkRoles: Role[]): boolean {
    return checkRoles.some(role => this.hasRole(role));
  }

  hasAllRoles(checkRoles: Role[]): boolean {
    return checkRoles.every(role => this.hasRole(role));
  }

  get initialized(): boolean {
    return this._initialized;
  }

  initialize(): Observable<void> {
    if (!this._initObservable) {
      this._initObservable = new Observable<void>((observable) => {
        this.authenticationService.getRoles().pipe(first())
          .subscribe({
            next: roles => {
              this._roles = roles;
              this._initialized = true;
              observable.next();
              observable.complete();
            },
            error: (e) => observable.error(e)
          });
      }).pipe(shareReplay(1));
    }
    return this._initObservable;
  }

  hasAnyRequiredRole(roles: Role[]): Observable<boolean> | boolean {
    if (this.initialized) {
      return this.hasAnyRole(roles ? roles : []);
    }
    else {
      return new Observable<boolean>(observable =>
        this.authenticationService.currentUser.subscribe(user => {
          if (user) {
            this.initialize().subscribe({
              next: () => {
                observable.next(this.hasAnyRole(roles ? roles : null));
                observable.complete();
              },
              error: () => observable.error(false)
            });
          }
        })
      ).pipe(
        tap((canActivate: boolean) => {
          if (!canActivate) {
            this.router.navigate(['unAuthorized']);
          }
        })
      );
    }
  }
}

export const hasRole: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const authorizationService = inject(AuthorizationService);
  return authorizationService.hasAnyRequiredRole(route.data['auth']);
};
