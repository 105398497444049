import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PurchaseEntry } from '../types/training.type';
import { SimpleBranch } from '../types/branch.type';
import { APP_CONFIG_TOKEN, AppConfig } from '@alimento-ipv-frontend/ui-lib';

@Injectable({
  providedIn: 'root'
})
export class PurchaseEntryService {
  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getPurchaseEntries(trainingId: string): Observable<PurchaseEntry[]> {
    return this.http.get<PurchaseEntry[]>(`${this.config.readApiUrl}/purchaseentries`, {
      params: {
        trainingId: trainingId
      }
    });
  }

  getPurchaseEntryBranches(trainingId: string): Observable<SimpleBranch[]> {
    return this.http.get<SimpleBranch[]>(`${this.config.readApiUrl}/trainings/${trainingId}/branches`, {
      params: {
        noPurchaseEntries: true
      }
    });
  }

  createPurchaseEntries(trainingId: string, branchIds: string[]): Observable<void> {
     return this.http.post<void>(`${this.config.writeApiUrl}/purchaseentries`, {
       trainingId: trainingId,
       branchIds: branchIds
     });
  }
}
