<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ 'trainings.reactivateDialog.title' | translate }}"
>
  <div class="flex bg-grey-0 p-4 mb-4" *ngIf="training()">
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        title='{{"trainings.reactivateDialog.labels.trainingName" | translate}}'
        [value]='training().customTitle'
      ></alimento-ipv-frontend-metadata-item>
    </div>
    <div class="basis-1/2">
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        title='{{"trainings.reactivateDialog.labels.firstSessionDate" | translate}}'
        [value]='firstSessionDate ? (firstSessionDate | date:"dd/MM/yyyy") : "/"'
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>

  <div [formGroup]="reactivateTrainingForm">
    <div class="field">
      <label class="block" for="comments"
        >{{ 'trainings.reactivateDialog.labels.remarks' | translate }}
        <small>({{ 'trainings.optional' | translate }})</small>
      </label>
      <textarea
        class="w-full"
        formControlName="comments"
        id="comments"
        pInputTextarea
        rows="2"
      ></textarea>
    </div>
  </div>

  <div class='my-2'>
    {{'trainings.reactivateDialog.text' | translate}}
  </div>

  <ng-template pTemplate="footer">
    <div class="flex">
      <p-button
        (onClick)="reactivateTraining()"
        icon="pi pi-times"
        iconPos="left"
        label="{{ 'trainings.reactivateDialog.buttons.submit' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        icon="pi pi-arrow-left"
        iconPos="left"
        label="{{ 'trainings.reactivateDialog.buttons.cancel' | translate }}"
        styleClass="inverted-button"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
