import { Component, forwardRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator
} from "@angular/forms";
import {Subscription} from "rxjs";
import {AutoComplete} from "primeng/autocomplete";
import {DomHandler} from "primeng/dom";

@Component({
  selector: 'alimento-ipv-frontend-time-picker',
  templateUrl: './time-picker.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true
    }
  ]
})
export class TimePickerComponent implements ControlValueAccessor, Validator, OnDestroy {

  ALL_VALUES: string[] = [];
  values: string[] = [];
  SKIP_TIME_ON_ARROW_UP_DOWN: number = 2;

  formControl: FormControl<string> = new FormControl<string>("");

  private open: boolean = false;
  private _subscriptions: Subscription[] = [];

  @Input()
  required = false;

  @ViewChild(AutoComplete)
  autoComplete: AutoComplete;

  get value(): string {
    return this.formControl.value;
  }

  set value(value: string) {
    this.formControl.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  constructor() {
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j = j + 15) {
        this.ALL_VALUES.push((i < 10 ? '0' : '') + i + ":" + (j < 10 ? '0' : '') + j);
      }
    }
    this.values = this.ALL_VALUES;

    this._subscriptions.push(this.formControl.valueChanges.subscribe(() => {
      this.onTouched();
    }));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  search(event: any): void {
    if (event.originalEvent?.key !== 'ArrowDown' && event.originalEvent?.key !== 'ArrowUp') {
      this.values = [...this._getSuggestions(event.query)];
      // this.values = [...this._getSuggestions("")];
      // setTimeout(() => {
      //   this.autoComplete.highlightOption = this._getClosestTimeTo(this._getValueAsTime(event.query));
      //   this.autoComplete.highlightOptionChanged = true;
      //   this._scrollHighlightIntoView();
      // })
    }
    else {
      this.autoComplete.loading = false;
    }
  }

  private _getSuggestions(query: string): string[] {
    return this.ALL_VALUES
      .filter(value => value.indexOf(query) >= 0)
      .sort((a, b) => {
        if (a.startsWith(query) && b.startsWith(query)) {
          return this.ALL_VALUES.indexOf(a) - this.ALL_VALUES.indexOf(b);
        }
        else {
          return a.startsWith(query) ? -1 : 1;
        }
      });
  }

  setValue(): void {
    let value = this.formControl.value;
    this.formControl.setValue(this._getValueAsTime(value));
    this.onChange(this.formControl.value);
  }

  private _getValueAsTime(value: string): string {
    if (value && !this.isValidTime(value)) {
      if (value.length > 2 && value.indexOf(":") < 0) {
        const hours = value.substring(0, value.length - 2);
        value = (hours.length === 1 ? '0' : '') + hours + ":" + value.substring(value.length - 2);
        if (!this.isValidTime(value)) {
          return "00:00";
        }
        else {
          return value;
        }
      }
      else {
        return this._getSuggestions(value)[0] || "00:00";
      }
    }
    return value;
  }

  private isValidTime(value: string): boolean {
    if (!value) {
      return !this.required;
    }

    const timeSplit = value.split(':');
    return (
      timeSplit.length === 2 &&
      Number(timeSplit[0]) >= 0 &&
      Number(timeSplit[0]) < 24 &&
      Number(timeSplit[1]) >= 0 &&
      Number(timeSplit[1]) < 60
    );
  }

  onChange: any = () => {
  };
  onTouched: any = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(value: string): void {
    if (value) {
      if (value.split(":")[0]?.length === 1) {
        value = "0" + value;
      }
      this.formControl.setValue(value);
    }
    else {
      this.formControl.reset();
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.isValidTime(this.formControl.value) ? null : {time: {valid: false}};
  }

  selectAll(autoComplete: AutoComplete): void {
    const nativeElement = autoComplete.inputEL.nativeElement;
    nativeElement.setSelectionRange(0, nativeElement.value.length);
  }

  private _getClosestTimeTo(time: string): string {
    const number = Number(time.split(":")[1]);
    if (number % 15 === 0) {
      return time;
    }
    else {
      return time.split(":")[0] + ":" +
        (number - (number % 15)).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
    }
  }

  completeValue(autoComplete: AutoComplete, event: any): void {
    if (event.key === 'Backspace') {
      return;
    }

    const nativeElement = autoComplete.inputEL.nativeElement;
    const length = nativeElement.value.length;
    const suggestions = this._getSuggestions(nativeElement.value);
    let newValue;
    if (event.key === "Enter" && !this.open) {
      this.values = [...this._getSuggestions("")];
      this.autoComplete.show();
      this.autoComplete.highlightOption = this._getClosestTimeTo(nativeElement.value);
      this.autoComplete.highlightOptionChanged = true;
      this._scrollHighlightIntoView();
    }
    else if (event.key === 'ArrowUp' && suggestions.length === 1 && !this.autoComplete.overlayVisible) {
      newValue = this.ALL_VALUES[(this.ALL_VALUES.indexOf(suggestions[0]) + this.SKIP_TIME_ON_ARROW_UP_DOWN) % this.ALL_VALUES.length];
    }
    else if (event.key === 'ArrowDown' && suggestions.length === 1 && !this.autoComplete.overlayVisible) {
      newValue = this.ALL_VALUES[(this.ALL_VALUES.indexOf(suggestions[0]) - this.SKIP_TIME_ON_ARROW_UP_DOWN + this.ALL_VALUES.length) % this.ALL_VALUES.length];
    }
    else if (length > 0 && length < 4 && suggestions.length > 0 && suggestions[0].startsWith(nativeElement.value)) {
      newValue = suggestions[0];
      // this.values = [...this._getSuggestions(newValue)];
    }

    if (newValue) {
      this.formControl.setValue(newValue, {emitEvent: false});
      nativeElement.value = newValue;
      nativeElement.setSelectionRange(length, 5);
    }
  }

  onShow(): void {
    this.open = true;
  }

  onHide(): void {
    setTimeout(() => {
      this.open = false;
    }, 500)
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable({emitEvent: false});
    }
    else {
      this.formControl.enable({emitEvent: false});
    }
  }

  private _scrollHighlightIntoView(): void {
    setTimeout(() => {
      if (this.autoComplete.overlayViewChild?.overlayViewChild?.nativeElement) {
        let listItem = DomHandler.findSingle(this.autoComplete.overlayViewChild.overlayViewChild.nativeElement, 'li.p-highlight');
        if (listItem) {
          listItem.scrollIntoView({block: "end"})
        }
      }
    });
  }
}
