import { Route } from '@angular/router';
import { CanDeactivateGuard } from '@alimento-ipv-frontend/ui-lib';
import {
  ReimbursementRequestListComponent
} from './pages/reimbursement-request-list/reimbursement-request-list.component';
import {
  ReimbursementRequestDetailComponent
} from './pages/reimbursement-request-detail/reimbursement-request-detail.component';
import {
  ReimbursementRequestTrainingDetailComponent
} from '../trainings/pages/reimbursement-request-training-detail/reimbursement-request-training-detail.component';
import { TRAINING_TYPE } from '../types/reference-data.enum';

export const reimbursementRequestsRoutes: Route[] = [
  {
    path: '',
    component: ReimbursementRequestListComponent,
    data: { title: 'titles.reimbursementRequests.list' }
  },
  {
    path: 'create',
    component: ReimbursementRequestDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.reimbursementRequests.create' }
  },
  {
    path: ':reimbursementRequestId/detail',
    component: ReimbursementRequestDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { title: 'titles.reimbursementRequests.detail' }
  },
  {
    path: ':reimbursementRequestId/training/create',
    component: ReimbursementRequestTrainingDetailComponent,
    canDeactivate: [CanDeactivateGuard],
    data: { 
      title: 'titles.reimbursementRequests.createTraining',
      trainingType: TRAINING_TYPE.OPEN_EXTERN
    }
  }

];
