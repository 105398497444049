<div>
  <p-autoComplete
    #autoComplete
    [suggestions]="values"
    (completeMethod)="search($event)"
    (onBlur)="setValue()"
    [formControl]="formControl"
    (onFocus)="selectAll(autoComplete)"
    (onKeyUp)="completeValue(autoComplete, $event)"
    (onHide)="onHide()"
    (onShow)="onShow()"
    [showEmptyMessage]='false'
    inputStyleClass="w-28"
    styleClass="after:content-['Schedule'] after:font-material after:absolute after:right-3 after:top-[25%] {{formControl.disabled ? 'after:opacity-60': ''}}"
    appendTo="body"
    placeholder="00:00"
    ngDefaultControl
  ></p-autoComplete>
</div>
