import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureFlagService} from "../services/feature-flag.service";
import { FEATURE } from '../types/ui-lib.enum';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[removeIfFeatureOn]'
})
export class RemoveIfFeatureOnDirective {

  @Input() set removeIfFeatureOn(feature: FEATURE) {
    this.featureFlagService.initialize().subscribe(_ => {
      if (!this.featureFlagService.featureOn(feature)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
      else {
        this.viewContainer.clear();
      }
    });
  }

  constructor(private featureFlagService: FeatureFlagService,
              public templateRef: TemplateRef<any>,
              public viewContainer: ViewContainerRef) {
  }
}
