import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { distinctUntilChanged, Subscription } from 'rxjs';

@Component({
  selector: 'alimento-ipv-frontend-detail-page-layout',
  templateUrl: './detail-page-layout.component.html'
})
export class DetailPageLayoutComponent implements OnDestroy {
  @Input()
  title?: string;

  @Input()
  navigationUrl: string[] = ['/'];

  @Input()
  navigationQueryParams: any;

  @Input()
  relatedForm?: FormGroup;

  @Input()
  navigateText = 'back';

  smallSize = false;
  private _subscription: Subscription;

  constructor(private router: Router, private breakpointObserver: BreakpointObserver) {
    this._subscription = this.breakpointObserver
      .observe(['(min-width: 1344px)', '(min-width: 1000px)'])
      .pipe(distinctUntilChanged())
      .subscribe(() => this.smallSize = !this.breakpointObserver.isMatched('(min-width: 1340px)'));
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  navigateBack() {
    this.router.navigate(this.navigationUrl, {queryParams: this.navigationQueryParams});
  }
}
