import { Component, ElementRef, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { Menu } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { DomHandler } from 'primeng/dom';

@Component({
  selector: 'alimento-ipv-frontend-more-actions',
  templateUrl: './more-actions.component.html',
  styleUrls: ['./more-actions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MoreActionsComponent {
  @Input()
  width = '250px';

  @Input()
  actions: MenuItem[] = [];

  private actionMenu?: Menu;

  @Input()
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateSelectedItem: (item: any) => void = () => {
  };

  @HostListener('document:click', ['$event'])
  clickOut(event: any) {
    // click event is needed because stopImmediatePropagation kills the focus event
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.actionMenu?.hide();
      delete this.actionMenu;
    }
  }

  constructor(private eRef: ElementRef) {
  }

  showActions(actionsMenu: Menu, event: Event) {
    event.stopImmediatePropagation();
    actionsMenu.model.forEach((item: any) => {
      this.updateSelectedItem(item);
      return item;
    });
    actionsMenu.toggle(event);

    this.actionMenu = actionsMenu;

    setTimeout(() => {
      this.absolutePosition(actionsMenu.container, event.target);
    });
  }

  absolutePosition(element: any, target: any): void {
    const elementDimensions = element.offsetParent
      ? { width: element.offsetWidth, height: element.offsetHeight }
      : DomHandler.getHiddenElementDimensions(element);
    const elementOuterHeight = elementDimensions.height;
    const elementOuterWidth = elementDimensions.width;
    const targetOuterHeight = target.offsetHeight;
    const targetOuterWidth = target.offsetWidth;
    const targetOffset = target.getBoundingClientRect();
    const windowScrollTop = DomHandler.getWindowScrollTop();
    const windowScrollLeft = DomHandler.getWindowScrollLeft();
    const viewport = DomHandler.getViewport();
    let top: number;

    if (targetOffset.top + targetOuterHeight + elementOuterHeight > viewport.height) {
      top = targetOffset.top + windowScrollTop - elementOuterHeight;
      element.style.transformOrigin = 'bottom';

      if (top < 0) {
        top = windowScrollTop;
      }
    }
    else {
      top = targetOuterHeight + targetOffset.top + windowScrollTop;
      element.style.transformOrigin = 'top';
    }

    const left = Math.max(0, targetOffset.left + windowScrollLeft + targetOuterWidth - elementOuterWidth);

    element.style.top = top + 'px';
    element.style.left = left + 'px';
  }
}
