import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { TitleService, TranslationService } from '@alimento-ipv-frontend/ui-lib';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '@alimento-ipv-frontend/environments';

@Component({
  selector: 'alimento-ipv-frontend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'dali';

  ready = false;
  routeSubscription!: Subscription;

  constructor(
    private router: Router,
    private translate: TranslationService,
    private messageService: MessageService,
    private titleService: TitleService
  ) {
    this.translate.setInitLanguage();
    this.titleService.init();
    this.translate.languageChange$.subscribe(lang => {
      if (lang) {
        this.ready = true;
      }
    });

    if (environment.appInsightsConnectionString) {
      const appInsights = new ApplicationInsights({
        config: {
          connectionString: environment.appInsightsConnectionString
        }
      });
      appInsights.loadAppInsights();
      appInsights.trackPageView();
    }
  }

  ngOnInit() {
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.messageService.clear();
      }
    });
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }
}
