import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Signal,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { SessionPopupComponent } from '../session-popup/session-popup.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { first } from 'rxjs';
import { CancelSessionDialogComponent } from '../cancel-session-dialog/cancel-session-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { RegisterAbsenceDialogComponent } from '../register-absence-dialog/register-absence-dialog.component';
import { AuthorizationService, Role } from '@alimento-ipv-frontend/ui-lib';
import { TRAINING_TYPE, TrainingStatusKey } from '../../../types/reference-data.enum';
import { SessionActionEnum } from '../../../types/session.enum';
import {
  CancelSession, RegisterAbsenceEvent,
  Session,
  SessionAction,
  SessionListItem,
  SessionUpdateEvent
} from '../../../types/session.type';
import { SessionService } from '../../../services/session.service';

@Component({
  selector: 'alimento-ipv-frontend-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.scss']
})
export class SessionsComponent implements OnDestroy, OnChanges {
  @ViewChild(CancelSessionDialogComponent)
  cancelSessionDialogComponent!: CancelSessionDialogComponent;

  @ViewChild(RegisterAbsenceDialogComponent)
  registerAbsenceDialog: RegisterAbsenceDialogComponent;

  @Input()
  trainingType: TRAINING_TYPE;
  @Input()
  trainingId = '';
  @Input()
  trainingMethodId = '';
  @Input()
  trainingStatus: TrainingStatusKey = TrainingStatusKey.Draft;
  @Input()
  yearOfRequest?: number;

  @Output()
  hasChanges: EventEmitter<SessionActionEnum> = new EventEmitter<SessionActionEnum>();
  @Output()
  sessionUpdated = new EventEmitter<void>();

  sessions: Signal<SessionListItem[]> = this.sessionService.sessions;
  currentSession: Signal<Session> = this.sessionService.currentTrainingSession;
  addSessionDisabled = true;
  readOnly = true;

  protected readonly TRAINING_TYPE = TRAINING_TYPE;

  constructor(
    private sessionService: SessionService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private authorizationService: AuthorizationService
  ) {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['trainingType']?.currentValue) {
      this.sessionService.setTrainingType(this.trainingType);
    }

    if (changes['trainingStatus'] || changes['trainingId']) {
      this.addSessionDisabled = !this.trainingId || this.trainingStatus === TrainingStatusKey.Cancelled;
    }
  }

  ngOnDestroy() {
    this.sessionService.clearSessions();
  }

  onSessionPopupSubmit(sessionUpdateEvent: SessionUpdateEvent, sessionPopupComponent: SessionPopupComponent) {
    const session: Session = sessionUpdateEvent.session;

    sessionUpdateEvent.setLoading(true);

    const createOrUpdateSession$ = session.id
      ? this.sessionService.updateSession(this.trainingId, session.id, session)
      : this.sessionService.createSession(this.trainingId, session);

    return createOrUpdateSession$.subscribe({
      next: (response: { id: string }) => {
        const sessionId: string = response?.id ?? session.id;
        this.sessionService.getSession(this.trainingId, sessionId).subscribe(() => {
          sessionUpdateEvent.setLoading(false);

          sessionPopupComponent.closePopup();

          this.messageService.add({
            severity: 'success',
            detail: this.translateService.instant('trainings.sessions.saved') + '!'
          });

          this.sessionService.loadSessions(this.trainingId);
          this.sessionUpdated.emit();
        });
      },
      error: () => {
        sessionUpdateEvent.setLoading(false);
      }
    });
  }

  cancelSession(cancelSession: CancelSession) {
    if (this.cancelSessionDialogComponent.session?.id) {
      this.sessionService
        .cancelSession(this.trainingId, this.cancelSessionDialogComponent.session.id, cancelSession)
        .subscribe({
          next: () => {
            this.sessionService.loadSessions(this.trainingId);
            this.messageService.add({
              severity: 'success',
              detail: this.translateService.instant('trainings.sessions.cancelled') + '!'
            });
            this.hasChanges.emit(SessionActionEnum.cancel);
          }
        });
    }
  }

  addSession(sessionPopupComponent: SessionPopupComponent): void {
    this.sessionService.addNewSession(this.trainingMethodId).subscribe(() => {
      sessionPopupComponent.openPopup();
    });
  }

  sessionAction(sessionAction: SessionAction, sessionPopupComponent: SessionPopupComponent) {
    if (sessionAction.action === SessionActionEnum.edit) {
      this.sessionService.editSession(this.trainingId, <string>sessionAction.session.id).subscribe(() => {
        sessionPopupComponent.openPopup();
      });
    }
    else if (sessionAction.action === SessionActionEnum.copy) {
      this.sessionService.copySession(this.trainingId, <string>sessionAction.session.id).subscribe(() => {
        sessionPopupComponent.openPopup();
      });
    }
    else if (sessionAction.action === SessionActionEnum.cancel) {
      this.cancelSessionDialogComponent.open(sessionAction.session);
    }
    else if (sessionAction.action === SessionActionEnum.registerAbsence) {
      this.sessionService
        .getParticipantsAndAbsences(this.trainingId, sessionAction.session.id)
        .pipe(first())
        .subscribe((sessionParticipants) => {
          this.registerAbsenceDialog.open(
            sessionAction.session,
            sessionParticipants,
            sessionAction.session.absenceRegistrationComplete
          );
        });
    }
    else if (sessionAction.action === SessionActionEnum.delete) {
      this.confirmationService.confirm({
        header: this.translateService.instant('trainings.sessions.deleteDialog.title'),
        message: this.translateService.instant('trainings.sessions.deleteDialog.message'),
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: 'hidden',
        acceptLabel: this.translateService.instant('trainings.sessions.deleteDialog.delete'),
        rejectLabel: this.translateService.instant('trainings.sessions.deleteDialog.cancel'),
        rejectButtonStyleClass: 'inverted-button',
        accept: () => {
          this.sessionService.deleteSession(this.trainingId, <string>sessionAction.session.id).subscribe(() => {
            this.messageService.add({
              severity: 'success',
              detail: this.translateService.instant('trainings.sessions.deleted')
            });
            this.sessionService.loadSessions(this.trainingId);
          });
        }
      });
    }
  }

  registerAbsence(event: RegisterAbsenceEvent, dialog: RegisterAbsenceDialogComponent) {
    event.setLoading(true);
    this.sessionService.registerAbsence(this.trainingId, event.sessionId, event.registerAbsenceBody).subscribe({
      next: () => {
        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant('trainings.sessions.registeredAbsence') + '!'
        });
        event.setLoading(false);
        dialog.closeDialog();
        this.sessionService.loadSessions(this.trainingId);
      },
      error: () => event.setLoading(false)
    });
  }
}
