<p-dialog
  *ngIf='popupVisible'
  (blur)='popupVisible = false'
  (onHide)='closePopup()'
  [(visible)]='popupVisible'
  [closable]='false'
  [draggable]='false'
  [modal]='true'
  [resizable]='false'
  [style]="{ width: '40rem', height: '90vh' }"
  appendTo='body'
>
  <ng-template pTemplate="header">
    <div class="dialog-header-content">
      <span class="p-dialog-title">{{titleObject.title | translate}}</span>
    </div>
  </ng-template>

  <div *ngIf='employment' [formGroup]='employmentForm'>
    <div [hidden]="formIndex !== 0" [formGroup]='personForm' *ngIf='personForm'>
      <ng-template #personFormTemplate>
        <alimento-ipv-frontend-person
          [dataOptional]="false"
          [person]="person"
          [readOnly]='personFormIndex === 0'
          formControlName="personForm"
          name="personForm"
        ></alimento-ipv-frontend-person>
      </ng-template>
      <div>
        <p-tabView (onChange)="clearPersonForm($event)" [(activeIndex)]="personFormIndex">
          <p-tabPanel header="{{ 'enrollments.searchPerson' | translate }}" [cache]="false">
            <div class='mt-2'>
              <alimento-ipv-frontend-person-select
                [formControl]="searchPersonFormControl"
              ></alimento-ipv-frontend-person-select>

              <div [hidden]="!searchPersonFormControl.value" class="mt-3 bg-grey-0 p-4">
                <ng-container *ngTemplateOutlet="personFormTemplate"></ng-container>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="{{ 'enrollments.newPerson' | translate }}" [cache]="false">
            <div class='mt-2'>
              <ng-container *ngTemplateOutlet="personFormTemplate"></ng-container>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>

    <div [hidden]="formIndex !== 1">
      <div *ngIf='showPersonInfo && employment?.personId'>
        <div *ngIf='currentPerson' class='conditional-fields flex items-start gap-4 !pb-2'>
          <div class='w-1/2'>
            <alimento-ipv-frontend-metadata-item
              title="{{ 'persons.name' | translate }}"
              value="{{ currentPerson?.lastName }} {{ currentPerson?.firstName }}"
            ></alimento-ipv-frontend-metadata-item>
          </div>
          <div class='w-1/2'>
            <alimento-ipv-frontend-metadata-item
              [value]='currentPerson.nationalIdentificationNumber ?
            (currentPerson.nationalIdentificationNumber | insz) :
            currentPerson.dateOfBirth | date:"dd/MM/yyyy"'
              title="{{ 'persons.idNoOrDateOfBirth' | translate }}"
            >
            </alimento-ipv-frontend-metadata-item>
          </div>
        </div>
      </div>

      <div *ngIf='showBranchInfo'>
        <alimento-ipv-frontend-form-field
          *ngIf='!isNoBranch'
          [formGroup]='employmentForm'
          [required]='true'
          fieldKey='persons.employments.branch'
          formField='branch'
        >
        <span *ngIf='employmentForm.disabled || employment?.employmentId ' class='read-only-field'>
          {{currentBranch?.branchName}}
        </span>
          <div *ngIf='employmentForm.enabled && !employment?.employmentId'>
            <alimento-ipv-frontend-branch-select formControlName='branch' id='branch'
            ></alimento-ipv-frontend-branch-select>
          </div>
        </alimento-ipv-frontend-form-field>

        <div *ngIf='currentBranch && !isNoBranch' class='conditional-fields flex items-start gap-4 !pb-2'>
          <div class='w-1/3'>
            <alimento-ipv-frontend-metadata-item
              title="{{ 'persons.employments.address' | translate }}"
              value="{{ currentBranch.street }} {{ currentBranch.houseNumber }} {{
              currentBranch.mailbox ? 'bus ' + currentBranch.mailbox : ''
            }}
          {{ currentBranch.postalCode }} {{ currentBranch.city }}"
            ></alimento-ipv-frontend-metadata-item>
          </div>
          <div class='w-1/3'>
            <alimento-ipv-frontend-metadata-item
              [value]='currentBranch.companyNumber'
              title="{{ 'branches.companyNumber' | translate }}"
            >
            </alimento-ipv-frontend-metadata-item>
          </div>
          <div class='w-1/3'>
            <alimento-ipv-frontend-metadata-item
              [value]='currentBranch.rszNumber'
              title="{{ 'branches.rszNumber' | translate }}"
            >
            </alimento-ipv-frontend-metadata-item>
          </div>
        </div>
      </div>

      <h2 class='text-xl text-text font-bold my-4'>{{ 'persons.employments.professionalData' | translate }}</h2>

      <alimento-ipv-frontend-form-field
        [formGroup]='employmentForm'
        fieldKey='persons.employments.function'
        formField='function'
      >
        <span *ngIf='employmentForm.disabled' class='read-only-field'>{{employmentForm.get('function')?.value}}</span>
        <input *ngIf='employmentForm.enabled' class='w-full'
               formControlName='function' id='function' pInputText type='text' />
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field
        [formGroup]='employmentForm'
        [errorTemplate]='dateErrorTemplate'
        formField='startDate'
        fieldKey='persons.employments.startDate'
      >
        <span *ngIf='employmentForm.disabled' class='read-only-field'>{{employmentForm.get('startDate')?.value | date:"dd/MM/yyyy"}}</span>
        <p-calendar
          *ngIf='employmentForm.enabled'
          [showIcon]="true"
          [showTime]="false"
          [showOnFocus]='false'
          [keepInvalid]='true'
          appendTo="body"
          dataType="date"
          dateFormat="dd/mm/yy"
          formControlName="startDate"
          id="startDate"
          placeholder="dd/mm/yyyy"
          styleClass="w-full"
        ></p-calendar>

        <ng-template #dateErrorTemplate>
          <small *ngIf='employmentForm.get("startDate")?.hasError("required")'>
            {{ 'trainings.sessions.dateRequired' | translate }}
          </small>
          <small *ngIf='employmentForm.get("startDate").hasError("invalidDate")'>
            {{ 'validation.invalidDate' | translate }}
          </small>
        </ng-template>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field
        [formGroup]='employmentForm'
        fieldKey='persons.employments.workStatus'
        formField='workStatusId'
      >
        <span *ngIf='employmentForm.disabled' class='read-only-field'>{{employment?.workStatus?.label}}</span>
        <div *ngIf='employmentForm.enabled'>
          <p-dropdown
            *ngIf='workStatuses$ | async as workStatuses'
            [filter]='true'
            [options]='workStatuses'
            [showClear]='true'
            appendTo='body'
            formControlName='workStatusId'
            id='workStatus'
            optionLabel='label'
            optionValue='data'
            placeholder="{{ 'persons.employments.workStatusPlaceholder' | translate }}"
            styleClass='w-full'
          ></p-dropdown>
        </div>
      </alimento-ipv-frontend-form-field>


      <alimento-ipv-frontend-form-field
        [formGroup]='employmentForm'
        [errorTemplate]='emailErrorTemplate'
        fieldKey='persons.employments.professionalEmail'
        formField='professionalEmail'
      >
        <span *ngIf='employmentForm.disabled'
              class='read-only-field'>{{employmentForm.get('professionalEmail')?.value}}</span>
        <input *ngIf='employmentForm.enabled' class='w-full'
               formControlName='professionalEmail' id='professionalEmail' pInputText type='text' />
        <ng-template #emailErrorTemplate>
          <small *ngIf="employmentForm.get('professionalEmail')?.errors?.['required']" class="p-error block">
            {{ 'validation.isRequired' | translate : { field: 'persons.employments.professionalEmail' | translate } }}
          </small>
          <small *ngIf="employmentForm.get('professionalEmail')?.errors?.['email']" class="p-error block">
            {{ 'validation.invalidEmail' | translate }}
          </small>
        </ng-template>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field
        [formGroup]='employmentForm'
        fieldKey='persons.employments.phoneNumber'
        formField='phoneNumber'
      >
        <span *ngIf='employmentForm.disabled' class='read-only-field'>{{employmentForm.get('phoneNumber')?.value}}</span>
        <input *ngIf='employmentForm.enabled' class='w-full'
               formControlName='phoneNumber' id='phoneNumber' pInputText type='text' />
      </alimento-ipv-frontend-form-field>

      <ng-container *ngIf='!isNoBranch'>
        <h2 class='text-xl text-text font-bold my-4'>{{ 'persons.employments.currentResponsibilities' | translate }}</h2>

        <div class='field'>
          <p-checkbox
            [binary]='true'
            [formControl]="$any(employmentForm.controls['isTeacher'])"
            inputId='is-teacher'
            label="{{ 'persons.employments.isTeacher' | translate }}"
          ></p-checkbox>
        </div>

        <div class='field'>
          <p-checkbox
            [binary]='true'
            [formControl]="$any(employmentForm.controls['isContactPerson'])"
            inputId='is-contact-person'
            label="{{ 'persons.employments.isContactPerson' | translate }}"
          ></p-checkbox>
        </div>

        <div *ngIf="employmentForm.controls['isContactPerson']?.value === true" class='conditional-fields'>
          <alimento-ipv-frontend-form-field
            [formGroup]='employmentForm'
            fieldKey='persons.employments.responsibilities'
            formField='responsibilityIds'
          >
            <span *ngIf='employmentForm.disabled' class='read-only-field'>{{responsibilitiesReadOnly}}</span>
            <div *ngIf='employmentForm.enabled'>
              <p-multiSelect
                *ngIf='responsibilities$ | async as responsibilities'
                [filter]='true'
                [options]='responsibilities'
                [showClear]='true'
                appendTo='body'
                formControlName='responsibilityIds'
                id='responsibilities'
                optionLabel='label'
                optionValue='data'
                placeholder="{{ 'persons.employments.workStatusPlaceholder' | translate }}"
                styleClass='w-full'
              ></p-multiSelect>
            </div>
          </alimento-ipv-frontend-form-field>
        </div>
      </ng-container>
    </div>
  </div>

  <ng-template pTemplate='footer'>
    <div class='flex items-center' [ngClass]='{"justify-between": showSelectPersonForm}'>
      <p-button
        (onClick)='formIndex = 0'
        *ngIf='showSelectPersonForm && formIndex === 1'
        label="{{ 'enrollments.previous' | translate }}"
        styleClass='inverted-button'
      ></p-button>

      <p-button
        (onClick)='addOrUpdateEmployment()'
        *ngIf='employment && titleObject.complete && formIndex === 1'
        [loading]='loading'
        icon='pi pi-save'
        iconPos='left'
        label="{{ titleObject.complete | translate }}"
      ></p-button>

      <p-button
        *ngIf='!showSelectPersonForm || formIndex === 0'
        (onClick)='closePopup()'
        icon='pi pi-times'
        iconPos='left'
        label="{{ 'persons.employments.actions.cancel' | translate }}"
        styleClass='inverted-button'
      ></p-button>

      <p-button
        (onClick)='goToNextStep()'
        *ngIf='showSelectPersonForm && formIndex === 0'
        label="{{ 'enrollments.next' | translate }}"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
