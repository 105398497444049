<p-confirmDialog [baseZIndex]="10000"></p-confirmDialog>
<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>
<ng-container *ngIf='person()'>
  <alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                       [linkedEntity]='person().personId'
                                       [type]='PersonNote'
                                       [readonly]='readOnly'>
  </alimento-ipv-frontend-notes-sidebar>
</ng-container>

<form *ngIf="isNewPerson || person()">
  <alimento-ipv-frontend-detail-page-layout
    title="{{ 'persons.person' | translate }}"
    [navigationUrl]="['/persons']"
    [relatedForm]="personFormGroup"
  >
    <ng-container top-action>
      <div class="title" *ngIf="person(); else newPerson">
        <span class='text-text'>{{ person().firstName }} {{ person().lastName }}</span>
        <span *ngIf="person().personAlimentoId" class="alimento-id mx-2 whitespace-nowrap text-text">- {{ person().personAlimentoId }}</span>
      </div>
      <ng-template #newPerson>
        <span class="title">{{ 'persons.new' | translate }}</span>
      </ng-template>

      <div class="buttons" *ngIf='!readOnly'>
        <button
          pButton
          pRipple
          type="button"
          label="{{ 'persons.actions.save' | translate }}"
          class="secondary-button uppercase"
          icon="pi pi-save"
          loadingIcon="pi pi-spin pi-spinner"
          (click)="savePerson()"
          [loading]="savingPerson"
          [disabled]="!personComponent?.formGroup?.dirty"
          data-test="save-person-btn"
        ></button>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        (activeTabChanged)="setActiveTabIndex($event)"
        [activeTabIndex]="activeTabIndex"
        [data]="tabMenuItems"
        [extra]='extraMenuItems'
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <div [hidden]="activeTabIndex !== 0" >
        <alimento-ipv-frontend-header title="{{ 'persons.personDataTitle' | translate }}">
        </alimento-ipv-frontend-header>

        <alimento-ipv-frontend-person
          [person]="person()"
          [showPersonalContactData]="true"
          [dataOptional]="true"
          [readOnly]='readOnly'
          (personFormChanges)="onPersonFormChanges($event)"
        ></alimento-ipv-frontend-person>
      </div>

      <div [hidden]="activeTabIndex !== 1">
        <alimento-ipv-frontend-employments [person]="person()" [readOnly]='readOnly'></alimento-ipv-frontend-employments>
      </div>

      <div [hidden]='activeTabIndex !== 2' *removeIfFeatureOff='FEATURE.TRANSITION'>
        <alimento-ipv-frontend-transitions [person]='person()' [readOnly]='readOnly'></alimento-ipv-frontend-transitions>
      </div>

      <div [hidden]="activeTabIndex !== 3">
        <alimento-ipv-frontend-header title="{{ 'persons.trainingsTitle' | translate }}">
        </alimento-ipv-frontend-header>
      </div>

      <div [hidden]="activeTabIndex !== 4">
        <alimento-ipv-frontend-header title="{{ 'persons.historyTitle' | translate }}"> </alimento-ipv-frontend-header>
      </div>
    </ng-container>

    <ng-container metadata *ngIf="person">
      <ng-container *ngIf="person()?.createdOn">
        <alimento-ipv-frontend-metadata-item
          title="{{ 'persons.createdOn' | translate }}"
          value="{{ person().createdOn | date : 'dd/MM/yyyy' }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf="person()?.editedOn">
        <alimento-ipv-frontend-metadata-item
          title="{{ 'persons.lastEdited' | translate }}"
          value="{{ person().editedBy }}
          {{ 'persons.editedOn' | translate : { on: person().editedOn | date : 'dd/MM/yyyy' } }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</form>
