import { Inject, Injectable } from '@angular/core';
import { concatMap, from, groupBy, map, mergeMap, Observable, reduce, shareReplay, toArray } from 'rxjs';
import {
  NameAndBirthdateUniqueResponse,
  NationalIdValidationResponse,
  Person,
  Transition,
  TransitionItem
} from '../types/person.type';
import { HttpClient, HttpParams } from '@angular/common/http';
import { GlobalMapper } from '../utils/mapper/global.mapper';
import { APP_CONFIG_TOKEN, AppConfig, DateMapper } from '@alimento-ipv-frontend/ui-lib';

@Injectable({
  providedIn: 'root'
})
export class PersonService {

  private _nameBirthdayUniqueCache: { [key: string]: Observable<NameAndBirthdateUniqueResponse> } = {};
  private _inszCache: { [key: string]: Observable<NationalIdValidationResponse> } = {};

  constructor(private http: HttpClient,
              private globalMapper: GlobalMapper,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getPerson(personId: any): Observable<Person> {
    return this.http.get<Person>(`${this.config.readApiUrl}/person/${personId}`);
  }

  createPerson(person: Person): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/person`, this.parsePerson(person));
  }

  updatePerson(personId: string, person: Person): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/person/${personId}`, this.parsePerson(person));
  }

  validateNationalIdentificationNumber(nationalIdentificationNumber: string): Observable<NationalIdValidationResponse> {
    if (!this._inszCache[nationalIdentificationNumber]) {
      const params: HttpParams = new HttpParams().set('nationalIdentificationNumber', nationalIdentificationNumber);

      this._inszCache[nationalIdentificationNumber] = this.http.get<NationalIdValidationResponse>(
        `${this.config.readApiUrl}/person/isnationalidentificationnumbervalidandunique`,
        { params }
      ).pipe(shareReplay(1));
    }
    return this._inszCache[nationalIdentificationNumber];
  }

  isNameAndBirthdateUnique(
    firstName: string,
    lastName: string,
    dateOfBirth: Date
  ): Observable<NameAndBirthdateUniqueResponse> {
    const key = firstName + lastName + DateMapper.getDateFromDateTimeAsString(dateOfBirth);
    if (!this._nameBirthdayUniqueCache[key]) {
      const params: HttpParams = new HttpParams()
        .set('firstname', firstName)
        .set('lastName', lastName)
        .set('dateOfBirth', DateMapper.getDateFromDateTimeAsString(dateOfBirth));

      this._nameBirthdayUniqueCache[key] = this.http.get<NameAndBirthdateUniqueResponse>(
        `${this.config.readApiUrl}/person/isnameandbirthdateunique`,
        { params }).pipe(shareReplay(1));
    }
    return this._nameBirthdayUniqueCache[key];
  }

  getGroupedTransitions(personId: string): Observable<any> {
    return this.http.get<TransitionItem[]>(`${this.config.readApiUrl}/transitions`, {
      params: {
        personId: personId
      }
    }).pipe(
      map(result => {
        result = result.map(transition => this.globalMapper.setEditedBy(transition));
        return result;
      }),
      concatMap(items => from(items)),
      groupBy(transition => transition.transitionStatusId),
      mergeMap(group => group
        .pipe(
          groupBy(transition => transition.branchName),
          mergeMap(subGroup => subGroup.pipe(
            reduce((acc, cur) => {
                acc.transitions.push(cur);
                return acc;
              },
              { branchName: subGroup.key, transitions: [] }
            ))
          ),
          map(result => {
            result.transitions = result.transitions.sort((a, b) => {
              return new Date(a.validFrom) < new Date(b.validFrom) ? 1 : -1;
            });
            return result;
          }),
          reduce((acc, cur) => {
              acc.values.push(cur);
              return acc;
            },
            { state: group.key, values: [] }
          )
        )
      ),
      toArray()
    );
  }

  getTransition(transitionId: string): Observable<TransitionItem> {
    return this.http.get<TransitionItem>(`${this.config.readApiUrl}/transitions/${transitionId}`);
  }

  createTransition(transition: Transition): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/transitions`, transition);
  }

  updateTransition(transition: Transition): Observable<{ id: string }> {
    return this.http.put<{
      id: string
    }>(`${this.config.writeApiUrl}/transitions/${transition.id}`, transition);
  }

  removeTransition(transitionId: string): Observable<void> {
    return this.http.delete<void>(`${this.config.writeApiUrl}/transitions/${transitionId}`);
  }

  executeTransitionAction(transitionId: string, action: string, data = {}): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(`${this.config.writeApiUrl}/transitions/${transitionId}/${action}`, data);
  }

  private parsePerson(person: Person) {
    return {
      ...person,
      dateOfBirth: DateMapper.getDateFromDateTimeAsString(person.dateOfBirth),
      nationalIdentificationNumber: person.nationalIdentificationNumber
        ? person.nationalIdentificationNumber.replace(/[.-]/g, '')
        : null
    };
  }
}
