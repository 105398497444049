import { Observable } from 'rxjs';

export interface IFilter {
  label: string,
  value: string,
  type: string
}

export interface IFilterEvent {
  filter: string,
  values: string[]
}

export interface DateFilter {
  [key: string]: string | undefined;

  fromDate: string | undefined,
  toDate: string | undefined
}

export class PaginatedResponse<T> {
  data: T[];
  totalCount: number;

  constructor(items: T[], total: number) {
    this.data = items;
    this.totalCount = total;
  }
}

export interface FilterValue {
  type: FilterType,
  value: string,
  label?: string;
}

export interface ITypeFilter {
  type: string;
  values: string[];
}

export type SearchRequest = {
  [keu: string]: any;

  first?: number;
  rows?: number;
  filters?: any;
  sortField?: string;
  sortOrder?: number;
}

export type SearchFilterValue = {
  type: string,
  values: any[]
};

export enum SearchFilterType {
  date = "date",
  searchBar = "searchBar",
  multiselect = "multiselect",
  hr = "hr",
  lazyLoadItem = "lazyLoadItem",
  sort = "sort"
}

export enum FilterType {
  search = 'searchTerm',
  searchShort = 'st',
  searchName = 'sn',
  fromDate = 'filterStart',
  toDate = 'filterEnd',
  mailStatus = 'filterMailStatus',
  mailItemStatus = 'filterMailItemStatus',
  mailTemplate = 'filterMailType',
  filterSenderId = 'filterSenderId',
  toDo = 'filterTaskStatus',
  filterContextType = 'filterContextCategory',
  filterContextValue = 'filterContextValue',
  isBlocked = 'fb',
  filterStatuses = 'filterStatuses',
  filterYear = 'filterYear',
  filterType = 'filterTypes',
  filterTrainingAllowanceApplicationId = 'filterTrainingAllowanceApplicationId',
  filterTrainingProgramId = 'filterTrainingProgramId',
  dateFilterType_fromDate = 'fromDate',
  dateFilterType_toDate = 'toDate',
  isActiveEmployment = 'filterActiveEmployments',
  filterRubric = 'filterRubrics',
  filterCaseManager = 'filterCaseManagers',
  filterBranch = 'filterBranch',
  filterNumberEmployees = 'filterNumberEmployees',
  filterBranchCategory = "filterBranchCategory",
  filterBranchState = "filterBranchState",
  filterIsFoodTrade = 'filterIsFoodTrade',
  filterIsFood = 'filterIsFood',
  isActive = 'filterIsActive',
  filterIsFishing = 'filterIsFishing',
  filterIsTeacher = 'filterIsTeacher',
  isContactPerson = 'isContactPerson',
  filterWorkStatus = 'filterWorkStatus',
  filterIsLocation = 'filterIsLocation',
  filterEnterpriseId = 'filterEnterpriseId',
  employmentRole = 'employmentRole',
  filterIsContactPerson = 'filterIsContactPerson',
  embed = "embed",
  filterBranches = "filterBranches",
  onlyActiveEmployment = 'onlyActiveEmployment',
  filterIsPublished = "filterPublished",
  filterEnterpriseGroupId = 'FilterEnterpriseGroupId',
  filterEnrollmentrequestsToValidate = "filterEnrollmentrequestsToValidate",
  filterAccountNumbersToValidate = "filterAccountNumbersToValidate"
}

export const singleValueFilters: FilterType[] = [
  FilterType.filterSenderId,
  FilterType.filterTrainingAllowanceApplicationId,
  FilterType.filterContextValue,
  FilterType.filterContextType,
  FilterType.toDo,
  FilterType.search,
  FilterType.filterTrainingProgramId,
  FilterType.filterBranch,
  FilterType.filterEnterpriseId,
  FilterType.embed,
  FilterType.onlyActiveEmployment,
  FilterType.filterEnterpriseGroupId
];

export const booleanFilters: FilterType[] = [
  FilterType.filterIsLocation,
  FilterType.filterIsTeacher,
  FilterType.isActive,
  FilterType.isContactPerson,
  FilterType.isActiveEmployment,
  FilterType.filterIsPublished,
  FilterType.filterEnrollmentrequestsToValidate,
  FilterType.filterAccountNumbersToValidate
];

export interface SearchFilter {
  label?: string,
  key: string,
  type: SearchFilterType,
  hasIcon?: boolean,
  data?: Observable<any[]>,
  expanded?: boolean;
  showHeader?: boolean;
  dataSource?: (request: SearchRequest) => Observable<PaginatedResponse<IFilter>>;
  getItem?: (value: string) => Observable<IFilter>;
  addEmptyValue?: boolean;
  emptyValue?: any;
  emptyLabelKey?: string;
  iconMapper?: (value: string) => string;
  iconClassMapper?: (value: string) => string;
}

export type SearchParam = {
  [key: string]: any,
  offset?: number,
  limit?: number,
  sort?: string,
  order?: string
}

export type PersonMatchesRequest = {
  nationalIdentificationNumber: string,
  firstName: string,
  lastName: string,
  birthDate: Date,
  employmentBranchAlimentoId: number,
  employerId: string,
  professionalPhoneNumber: string,
  professionalEmail: string,
  maxResults: number
}
