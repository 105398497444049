import { Component, effect, signal, WritableSignal } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { first } from 'rxjs';
import { TreeNodeSelectEvent, TreeNodeUnSelectEvent } from 'primeng/tree';
import { TreeNode } from 'primeng/api';
import { SubSectorAndFederationIDs, TrainingSubSector } from '../../../types/reference-data.type';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-sub-sectors',
  templateUrl: './sub-sectors.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SubSectorsComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SubSectorsComponent
    }
  ]
})
export class SubSectorsComponent implements ControlValueAccessor, Validator {
  dialogVisible = false;
  subSectorSelection: TreeNode | TreeNode[] = [];
  subSectors: WritableSignal<TreeNode[]> = signal(undefined);
  editSubSectors: TrainingSubSector[] = [];
  currentSubSectors: TrainingSubSector[] = [];
  disabled = false;
  formValue: WritableSignal<SubSectorAndFederationIDs> = signal(undefined);

  constructor(private referenceDataService: ReferenceDataService) {
    effect(() => {
      if (this.subSectors() && this.formValue()) {
        this.currentSubSectors = this._mapArraysToTrainingSubSector(this.formValue());
      }
    });
    this.referenceDataService.getSubSectors().pipe(first())
      .subscribe(subSectors =>
        this.subSectors.set(subSectors.map(subSector => this._toTreeNode(subSector))));
  }

  writeValue(value?: SubSectorAndFederationIDs): void {
    this.formValue.set(value);
  }

  closeDialog(): void {
    this.dialogVisible = false;
  }

  onSubmit(): void {
    this.currentSubSectors = this._mapTreeNodesToTrainingSubSector(this._getSubSectorSelection());
    this.onChange(this._mapTreeToArray(this.currentSubSectors));
    this.onTouched();
    this.dialogVisible = false;
  }

  updateFormControl = () => {
    this.onChange(this._mapTreeToArray(this.currentSubSectors));
    this.onTouched();
  };

  updateEditSubSector = () => {
    this.subSectorSelection = this._mapTrainingSUbSectorToTreeNodes(this.editSubSectors);
  };

  openPopup(): void {
    this.dialogVisible = true;
    this.editSubSectors = JSON.parse(JSON.stringify(this.currentSubSectors));
    this.subSectorSelection = this._mapTrainingSUbSectorToTreeNodes(this.currentSubSectors);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return control.valid ? null : { subSectorsInvalid: true };
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onNodeSelect(event: TreeNodeSelectEvent): void {
    if (event.node.parent &&
      !this._getSubSectorSelection().map(sector => sector.data).includes(event.node.parent.data)) {
      this._getSubSectorSelection().push(event.node.parent);
      this.selectionChange();
    }
  }

  onNodeUnselect(event: TreeNodeUnSelectEvent): void {
    if (!event.node.parent) {
      this.subSectorSelection = this._getSubSectorSelection().filter(node => node.parent?.data !== event.node.data);
      this.selectionChange();
    }
  }

  selectionChange(): void {
    this.editSubSectors = this._mapTreeNodesToTrainingSubSector(this._getSubSectorSelection());
  }

  expandAll(): void {
    this.subSectors().forEach(subSector => subSector.expanded = true);
  }

  collapseAll(): void {
    this.subSectors().forEach(subSector => subSector.expanded = false);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: (value: any) => void = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private _getSubSectorSelection(): TreeNode[] {
    return this.subSectorSelection as TreeNode[];
  }

  private _toTreeNode(subSector: TrainingSubSector): TreeNode {
    return {
      data: subSector.data,
      label: subSector.label,
      children: subSector.federations?.map(federation => ({
        data: federation.data,
        label: federation.label
      } as TreeNode))
    } as TreeNode;
  }

  private _mapTrainingSUbSectorToTreeNodes(subSectors: TrainingSubSector[]): TreeNode[] {
    const treeNodes: TreeNode[] = [];
    subSectors.forEach(subSector => {
      const matchingTreeNode = this.subSectors()
        .filter(subSectorTreeNode => subSectorTreeNode.data === subSector.data)[0];
      treeNodes.push(matchingTreeNode);

      subSector.federations?.forEach(federation => {
        treeNodes.push(matchingTreeNode.children
          .filter(federationTreeNode => federationTreeNode.data === federation.data)[0]);
      });
    });
    return treeNodes;
  }

  private _mapTreeNodesToTrainingSubSector(treeNodes: TreeNode[]): TrainingSubSector[] {
    const subSectors: TrainingSubSector[] = [];

    treeNodes.filter(subSector => !subSector.parent)
      .forEach(subSector => subSectors.push(
        { data: subSector.data, label: subSector.label, federations: [] } as TrainingSubSector)
      );

    treeNodes.filter(subSector => subSector.parent)
      .forEach(federation => {
        const subSector = subSectors.filter(subSector => subSector.data === federation.parent.data)[0];
        if (!subSector) {
          return;
        }

        if (!subSector.federations) {
          subSector.federations = [];
        }
        subSector.federations.push({ data: federation.data, label: federation.label });
      });

    return subSectors;
  }

  private _mapArraysToTrainingSubSector(subSectorAndFederations: SubSectorAndFederationIDs): TrainingSubSector[] {
    return this.subSectors()
      .filter(subSector => (subSectorAndFederations.subSectorIds || []).includes(subSector.data))
      .map(subSector => ({
        data: subSector.data,
        label: subSector.label,
        federations: (subSector.children || [])
          .filter(federation => (subSectorAndFederations.federationIds || []).includes(federation.data))
          .map(federation => ({data: federation.data, label: federation.label}))
      }));
  }

  private _mapTreeToArray(subSectors: TrainingSubSector[]): SubSectorAndFederationIDs {
    return {
      subSectorIds: subSectors
        .map(subSector => subSector.data),
      federationIds: subSectors
        .map(subSector => subSector.federations).flat(1)
        .map(federation => federation.data)
    };
  }
}
