import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { TrainingProgramDetailComponent } from './pages/training-program-detail/training-program-detail.component';
import { TrainingProgramComponent } from './components/training-program/training-program.component';
import { InputTextModule } from 'primeng/inputtext';
import { TrainingProgramCardComponent } from './components/training-program-card/training-program-card.component';
import { SelectTrainingProgramComponent } from './components/select-training-program/select-training-program.component';
import { UtilsModule } from '../utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    UiLibModule,
    InputTextModule,
    UtilsModule
  ],
  providers: [
    CanDeactivateGuard
  ],
  declarations: [
    TrainingProgramDetailComponent,
    TrainingProgramComponent,
    TrainingProgramCardComponent,
    SelectTrainingProgramComponent
  ],
  exports: [
    SelectTrainingProgramComponent,
    TrainingProgramCardComponent
  ]
})
export class TrainingProgramModule {}
