<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '40rem' }"
  appendTo="body"
  header="{{ 'trainings.sessions.cancelDialog.title' | translate }}"
>
  <div class="flex mb-4 p-4 bg-grey-0" *ngIf="session">
    <div class='basis-1/2'>
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        title="{{ 'trainings.sessions.sessionName' | translate }}"
        [value]="session.title || ('trainings.sessions.sessionOf' | translate : { date: session.date | date : 'dd/MM/yyyy' })"
      ></alimento-ipv-frontend-metadata-item>
    </div>
    <div class='basis-1/2'>
      <alimento-ipv-frontend-metadata-item
        [cardLayout]='true'
        title="{{ 'trainings.sessions.date' | translate }}"
        [value]="session.date | date : 'dd/MM/yyyy'"
      ></alimento-ipv-frontend-metadata-item>
    </div>
  </div>

  <div [formGroup]="cancelSessionForm">
    <div class="field">
      <label for="reasonSessionCancelledId" class="block">{{
        'trainings.cancelDialog.labels.reasonTrainingCancelled' | translate
      }}</label>

      <p-dropdown
        #focusElement
        class="w-full"
        id="reasonSessionCancelledId"
        *ngIf="cancelSessionReasons$ | async as cancelSessionReasons"
        [options]="cancelSessionReasons"
        formControlName="reasonSessionCancelledId"
        optionValue="data"
        optionLabel="label"
        [filter]="true"
        appendTo='body'
        placeholder="{{ 'trainings.cancelDialog.labels.chooseAnOption' | translate }}"
      ></p-dropdown>

      <small
        *ngIf="
          (cancelSessionForm.get('reasonSessionCancelledId')?.dirty ||
            cancelSessionForm.get('reasonSessionCancelledId')?.touched) &&
          cancelSessionForm.get('reasonSessionCancelledId')?.hasError('required')
        "
        class="p-error block"
      >
        {{ 'trainings.cancelDialog.errors.reasonRequired' | translate }}
      </small>
    </div>

    <div class="field">
      <label class="block" for="sessionCancelledComment"
        >{{ 'trainings.cancelDialog.labels.remarks' | translate }}
        <small>({{ 'trainings.optional' | translate }})</small></label
      >
      <textarea
        class="w-full"
        formControlName="sessionCancelledComment"
        id="sessionCancelledComment"
        pInputTextarea
        rows="2"
      ></textarea>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex">
      <p-button
        (onClick)="cancelSession()"
        label="{{ 'trainings.sessions.cancelDialog.buttons.cancelSession' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'trainings.sessions.cancelDialog.buttons.stopCancelSession' | translate }}"
        styleClass="inverted-button"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
