<div class='mt-4 mx-8'>
  <div class="flex items-center justify-between mb-4">
    <h1 class="uppercase">{{ 'branches.menuTitle' | translate }}</h1>
  </div>

  <lib-search [data$]='searchData$'
              [filters]='searchFilters'
              [cardTemplate]='cardTemplate'
              name='branches'
              searchPlaceholderKey='branches.searchListPlaceholder'
              availableResultsTranslateKey='branches.menuTitle'
  >
    <ng-template #cardTemplate let-item="item">
      <alimento-ipv-frontend-branch-search-card
        [branchListItem]='item'
        [routerLink]='["/branches", item.branchId, "detail"]'
        tabindex="0"
      ></alimento-ipv-frontend-branch-search-card>
    </ng-template>
  </lib-search>
</div>
