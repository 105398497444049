import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { CanDeactivateGuard } from '@alimento-ipv-frontend/ui-lib';

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' }) ],
  providers: [CanDeactivateGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
