import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TrainingProgram } from '../types/training-program.type';
import { APP_CONFIG_TOKEN, AppConfig, DateMapper } from '@alimento-ipv-frontend/ui-lib';

@Injectable({
  providedIn: 'root'
})
export class TrainingProgramService {
  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  getTrainingProgram(trainingProgramId: string): Observable<TrainingProgram> {
    return this.http.get<TrainingProgram>(`${this.config.readApiUrl}/trainingprograms/${trainingProgramId}`);
  }

  createTrainingProgram(trainingProgram: TrainingProgram): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/trainingprograms`, this._parseTrainingProgram(trainingProgram));
  }

  updateTrainingProgram(trainingProgramId: string, trainingProgram: TrainingProgram): Observable<{ id: string }> {
    return this.http.put<{
      id: string
    }>(`${this.config.writeApiUrl}/trainingprograms/${trainingProgramId}`, this._parseTrainingProgram(trainingProgram));
  }

  deleteTrainingProgram(trainingProgramId: string): Observable<any> {
    return this.http.delete(`${this.config.writeApiUrl}/trainingprograms/${trainingProgramId}`);
  }

  private _parseTrainingProgram(trainingProgram: TrainingProgram): any {
    return {
      ...trainingProgram,
      startDate: DateMapper.getDateFromDateTimeAsString(trainingProgram.startDate),
      endDate: DateMapper.getDateFromDateTimeAsString(trainingProgram.endDate),
    }
  }
}
