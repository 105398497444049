import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { DateMapper, dateValid, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { AccountNumber, AccountNumberUpdateEvent } from '../../../types/enterprise.type';
import { ValidationService } from '../../../services/validation.service';

@Component({
  selector: 'alimento-ipv-frontend-account-number-popup',
  templateUrl: './account-number-popup.component.html'
})
export class AccountNumberPopupComponent implements OnChanges, OnDestroy {
  @Input()
  accountNumber?: AccountNumber;

  @Output()
  formSubmit = new EventEmitter<AccountNumberUpdateEvent>();

  popupVisible = false;
  formGroup!: FormGroup;
  loading = false;
  canChangeStartDate = false;

  private _subscriptions: (Subscription | undefined)[] = [];

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private validationService: ValidationService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['accountNumber']?.currentValue) {
      this.createForm();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  openPopup(): void {
    this.popupVisible = true;
    this.createForm();
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.formGroup;
    this._subscriptions.forEach(sub => sub?.unsubscribe());
    this._subscriptions = [];
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    return this.formGroup.valid;
  }

  getFormData(): any {
    const data = JSON.parse(JSON.stringify(this.formGroup.value));

    data.startDate = DateMapper.getDateFromDateTimeAsString(data.startDate);
    data.endDate = DateMapper.getDateFromDateTimeAsString(data.endDate);

    data.iban = data.iban.replace(/ /g, "");

    return data;
  }

  addOrUpdate(approve: boolean): void {
    if (!this.isDataValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('trainings.sessions.hasErrors')
      });
      return;
    }

    this.formSubmit.emit({
      accountNumber: this.getFormData(),
      approve: approve,
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  showApproveButton(): boolean {
    return !this.accountNumber?.approved;
  }

  private createForm() {
    const startDate = this.fb.control(
      this.accountNumber?.startDate ? new Date(this.accountNumber.startDate) : undefined,
      [Validators.required, dateValid()]);

    this.formGroup = this.fb.group({
      accountNumberId: [this.accountNumber?.accountNumberId],
      startDate: startDate,
      endDate: [this.accountNumber?.endDate ? new Date(this.accountNumber.endDate) : undefined,
        [this.validateMinValue(startDate), dateValid()]],
      iban: [this.accountNumber?.iban, {
        updateOn: 'blur',
        validators: [Validators.required],
        asyncValidators: [this.validationService.validateIBAN()]
      }],
      description: [this.accountNumber?.description]
    });

    this.canChangeStartDate = !this.accountNumber?.accountNumberId || new Date(this.accountNumber?.startDate) >= new Date();
    this._addSubscriptions();
  }

  private _addSubscriptions(): void {
    this._subscriptions.push(
      this.formGroup.get('startDate')?.valueChanges.subscribe(() => {
        this.formGroup.get('endDate')?.updateValueAndValidity();
      })
    );
  }

  private validateMinValue(otherControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control.value && otherControl.value && new Date(control.value) <= new Date(otherControl.value)) {
        return { minValueToLow: true };
      }
      return null;
    };
  }
}
