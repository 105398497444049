<div *ngIf="accountNumber" class="group flex flex-col border-b border-b-grey-2 py-2">
  <div
    (click)="toggleShowExtraInfo()"
    class="flex gap-4 cursor-pointer group-hover:bg-primary group-hover:bg-opacity-[7%]"
  >
    <div>
      <div
        (keyup.enter)="toggleShowExtraInfo()"
        class="flex items-center p-2 m-2 text-primary outline-primary"
        tabindex="0"
      >
        <i class="pi pi-angle-{{ showExtraInfo ? 'up' : 'down' }}"></i>
      </div>
    </div>

    <div class="flex-grow mb-4">
      <div class="flex justify-between items-center mt-2 mr-2">
        <div class='flex items-center gap-4'>
          <span class="text-title">
            {{accountNumber.iban | accountNumber}}
          </span>
          <i class='pi pi-star' style='font-size: 16px' *ngIf='accountNumber.default'></i>
          <span class='font-bold' [ngClass]='getStateColor()'>{{('enterprises.accountNumbers.' + accountNumberState) | translate}}</span>
        </div>

        <div class="flex items-center gap-4">
          <span *ngIf='accountNumber.endDate'>{{'enterprises.accountNumbers.fromTo' | translate:
            {from: accountNumber.startDate | date: 'dd/MM/yyyy', to: accountNumber.endDate | date: 'dd/MM/yyyy' } }}</span>
          <span *ngIf='!accountNumber.endDate'> {{'enterprises.accountNumbers.from' | translate}} {{accountNumber.startDate | date: 'dd/MM/yyyy'}}</span>

          <div
            (click)="toggleAction(primaryAction.action, $event)"
            (keyup.enter)="toggleAction(primaryAction.action, $event)"
            *ngIf="primaryAction"
            class="p-button inverted-button h-8 w-8 bg-white justify-center hover:bg-primary focus:bg-primary hover:bg-opacity-[7%] focus:bg-opacity-[7%]"
            tabindex="0"
            [pTooltip]="primaryActionTooltip | translate"
          >
            <span class="material-symbols-outlined text-base">
              {{ primaryAction.icon }}
            </span>
          </div>

          <alimento-ipv-frontend-more-actions [actions]="actions"></alimento-ipv-frontend-more-actions>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showExtraInfo" class="bg-grey-0 mt-4 p-4 pl-12">
    <div class='flex flex-col items-end gap-1'>
      <div class='flex items-center gap-1'>
        <span class='font-bold'>{{lastAction | translate}}</span>
        <span *ngIf='accountNumber.approved'>{{'enterprises.accountNumbers.byOn' | translate:
          {by: accountNumber.approvedBy || ('enterprises.accountNumbers.noName' | translate),
            on: accountNumber.approvedOn ? (accountNumber.approvedOn | date : 'dd/MM/yyyy') : ('enterprises.accountNumbers.noName' | translate)} }}</span>
<!--        <span *ngIf='!accountNumber.approved'>{{'branches.trainingPlans.byOn' | translate:-->
<!--          {by: accountNumber. || ('branches.trainingPlans.noName' | translate),-->
<!--            on: accountNumber.approvedOn ? (accountNumber.approvedOn | date : 'dd/MM/yyyy') : ('branches.trainingPlans.noName' | translate)} }}</span>-->
      </div>
    </div>
  </div>
</div>

