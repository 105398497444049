import { NgModule } from '@angular/core';
import { ReimbursementRequestsModule } from './reimbursement-requests/reimbursement-requests.module';
import { CommonModule } from '@angular/common';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';

@NgModule({
  imports: [
    CommonModule,
    UiLibModule,
    ReimbursementRequestsModule,
  ],
  providers: [
    CanDeactivateGuard
  ],
  exports: [
  ]
})
export class ApplicationLibModule {}
