import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { HtmlUtils } from './HtmlUtils';

export function validateAllFormFields(formGroup: FormGroup): void {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormArray) {
      for (const formArrayGroup in control.controls) {
        if (control.get(formArrayGroup) instanceof FormGroup) {
          validateAllFormFields(control.get(formArrayGroup) as FormGroup);
        }
        else {
          validateFormControl(control.get(formArrayGroup) as FormControl);
        }
      }
    }
    else if (control instanceof FormControl) {
      validateFormControl(control);
    }
    else if (control instanceof FormGroup) {
      if (!control.disabled) {
        validateAllFormFields(control);
      }
    }
  });
}

export function validateFormControl(control: FormControl): void {
  if (!control.disabled) {
    control.markAsTouched({ onlySelf: false });
    control.markAsDirty({ onlySelf: true });
  }
}

export function isFieldInvalid(formGroup: FormGroup, field: string): boolean {
  return !formGroup.get(field)?.valid;
}

export function flattenObject(obj: { [key: string]: any }): { [key: string]: any } {
  const flattened: { [key: string]: any } = {};

  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (typeof value === 'object' && !(value instanceof Date) && value !== null && !Array.isArray(value)) {
      Object.assign(flattened, flattenObject(value));
    }
    else {
      flattened[key] = value;
    }
  });

  return flattened;
}

export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export function emailPattern(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    const regex = new RegExp(EMAIL_REGEX);
    if (control.value && !regex.test(control.value)) {
      return {email: true};
    }
    return null;
  }
}

export function dateValid(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value && !(control.value instanceof Date)) {
      return { "invalidDate": true };
    }
    return null;
  }
}

export function maxLengthRichText(maxLength: number, htmlUtils: HtmlUtils): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value && htmlUtils.convertQuillListsToHtml(control.value).length > maxLength) {
      return {maxLength: true};
    }
    return null;
  }
}

export function minLengthArray(minLength: number): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control.value || control.value.length < minLength) {
      return {minLength: true};
    }
    return null;
  }
}

export function validateMinValue(otherControl: AbstractControl): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value && otherControl.value && new Date(control.value) <= new Date(otherControl.value)) {
      return { minValueToLow: true };
    }
    return null;
  };
}
