import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'alimento-ipv-frontend-metadata-item',
  templateUrl: './metadata-item.component.html'
})
export class MetadataItemComponent implements OnChanges {
  @Input()
  title?: string;

  @Input()
  value?: string;

  @Input()
  values?: {label: string, link: any[] | string | null | undefined}[];

  @Input()
  isLink = false;

  @Input()
  link: any[] | string | undefined;

  @Input()
  cardLayout = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']?.currentValue) {
      this.values = [{label: this.value, link: this.link}];
    }
  }
}
