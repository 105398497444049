<div class="flex flex-col items-start">
  <h1 *ngIf="showLabel" class="text-lg font-bold mb-2">{{"search" | translate}}</h1>
  <span class="w-full p-input-icon-left p-input-icon-right">
    <i class="pi pi-search !right-auto"></i>
    <input
      [formControl]="formControl"
      #searchField
      class="w-full px-10"
      pInputText
      placeholder='{{(placeholderKey || "search.searchbarPlaceholder") | translate}}'
      type="text"
    />
    <i (click)="clearValue()" *ngIf="formControl.value" class="pi pi-times !left-auto cursor-pointer"></i>
  </span>
</div>
