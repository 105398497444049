import { Component, ElementRef, HostListener } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { AccountInfo } from '@azure/msal-browser';
import { TranslationService } from '../../services/translation.service';
import { DataLabelType } from '../../types/ui-lib.type';

@Component({
  selector: 'alimento-ipv-frontend-user-menu',
  templateUrl: './user-menu.component.html'
})
export class UserMenuComponent {
  userMenuOpen = false;
  currentUser: AccountInfo;
  languages: DataLabelType[];
  currentLang: string;

  @HostListener('document:click', ['$event'])
  clickOutsideOfComponent(event: MouseEvent) {
    if (this.userMenuOpen && !this.elementRef.nativeElement.contains(event.target)) {
      this.userMenuOpen = false;
    }
  }

  constructor(private elementRef: ElementRef,
              private translationService: TranslationService,
              private authorizationService: AuthenticationService) {
    this._initLanguage();
    this.authorizationService.currentUser.subscribe(currentUser => {
      this.currentUser = currentUser;
    });
  }

  private _initLanguage(): void {
    this.languages = this.translationService
      .getLanguages()
      .map(language => ({ data: language, label: language.toUpperCase() }));

    this.translationService.languageChange$.subscribe(lang => {
      this.currentLang = lang;
    });
  }

  toggleUserMenu() {
    this.userMenuOpen = !this.userMenuOpen;
  }

  changeLanguage(language: string): void {
    this.translationService.changeLanguage(language);
  }

  logout(): void {
    this.authorizationService.logout();
    this.userMenuOpen = false;
  }
}
