<p-confirmDialog [baseZIndex]="10000"></p-confirmDialog>
<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>

<form [formGroup]="templateForm">
  <alimento-ipv-frontend-detail-page-layout
    title="{{ 'templates.templateOpenTraining' | translate }}"
    [relatedForm]="templateForm"
    [navigationUrl]="['/training-templates']"
  >
    <ng-container top-action>
      <div class="flex items-center">
        <alimento-ipv-frontend-form-field
          [formGroup]='templateForm'
          [errorTemplate]='customTitleError'
          formGroupClass='!pb-0'
          formField='customTitle'
          labelClass='hidden'
        >
          <span class='text-xl' *ngIf='readOnly'>{{templateForm.get("customTitle")?.value}}</span>
          <input
            *ngIf='!readOnly'
            id="customTitle"
            formControlName="customTitle"
            type="text"
            class="min-w-[500px] max-w-[1000px]"
            pInputText
            placeholder="{{ 'templates.titlePlaceholder' | translate }}"
            data-test="custom-title-input"
            autoWidth
          />
          <ng-template #customTitleError>
            <small *ngIf="templateForm.get('customTitle')?.hasError('required')">
              {{ 'validation.isRequired' | translate : { field: 'templates.title' | translate | lowercase } }}
            </small>
            <small *ngIf="templateForm.get('customTitle')?.hasError('maxlength')">
              {{ 'validation.maxLength' | translate : { number: 200 } }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>

        <span *ngIf="template?.trainingTemplateAlimentoId" class="text-xl mx-2 whitespace-nowrap text-text">
          - {{ template.trainingTemplateAlimentoId }}</span>
      </div>

      <div class="flex gap-4" *ngIf='!readOnly'>
        <span
          [pTooltip]="!templateForm.get('customTitle')?.value ? ('templates.saveNotPossibleTooltip' | translate) : ''"
        >
          <button
            pButton
            pRipple
            type="button"
            label="{{ 'templates.actions.save' | translate }}"
            class="secondary-button uppercase"
            icon="pi pi-save"
            loadingIcon="pi pi-spin pi-spinner"
            [loading]="savingTemplate"
            (click)="saveTemplate()"
            [disabled]="isActivateDisabled"
          ></button>
        </span>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        [data]="tabMenuItems"
        [activeTabIndex]="activeTabIndex"
        (activeTabChanged)="setActiveTabIndex($event)"
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <alimento-ipv-frontend-basic-info
        [hidden]="activeTabIndex !== 0"
        formControlName="basicDataForm"
        [type]='TRAINING_TYPE.TEMPLATE'
        [showRequiredFieldErrors]="showRequiredFieldErrors"
        #basicInfoComponent
      >
      </alimento-ipv-frontend-basic-info>

      <alimento-ipv-frontend-summary-description
        [hidden]="activeTabIndex !== 1"
        formControlName="descriptionForm"
        [showRequiredFieldErrors]="showRequiredFieldErrors"
        #summaryDescriptionComponent
      >
      </alimento-ipv-frontend-summary-description>

      <alimento-ipv-frontend-sessions
        [hidden]="activeTabIndex !== 2"
        [trainingId]="templateId"
        [trainingMethodId]="basicInfoComponent.formGroup.get('methodId')?.value"
        [trainingType]='TRAINING_TYPE.TEMPLATE'
      >
      </alimento-ipv-frontend-sessions>
    </ng-container>

    <ng-container metadata>
      <alimento-ipv-frontend-training-status-chip
        [status]="getTemplateStatusKey(templateStatus)"
      ></alimento-ipv-frontend-training-status-chip>

      <button
        pButton
        pRipple
        type="button"
        label="{{ 'templates.actions.saveAndActivate' | translate }}"
        class="p-button-primary uppercase"
        loadingIcon="pi pi-spin pi-spinner"
        [loading]="activatingTemplate"
        (click)="activateTemplate()"
        *ngIf="!readOnly && templateStatus === templateStatusKeys.Draft"
        [disabled]="!templateReadyToActivate || savingTemplate"
      ></button>

      <button
        pButton
        pRipple
        type="button"
        label="{{ 'templates.actions.createTrainingFromTemplate' | translate }}"
        class="p-button-primary p-button-outlined uppercase"
        (click)="createTrainingFromTemplate(templateId)"
        *ngIf="!readOnly && templateStatus === templateStatusKeys.Active"
      ></button>

      <div
        *ngIf="!readOnly && templateStatus === templateStatusKeys.Draft && !templateReadyToActivate"
        class="activation-not-possible"
      >
        <span [innerHTML]="'templates.activateNotPossible' | translate"></span>
      </div>

      <div
        class="link-button link-button-with-icon"
        *ngIf="!readOnly && templateStatus === templateStatusKeys.Draft && !templateReadyToActivate"
      >
        <p-inputSwitch #showRequiredToggle [formControl]="requiredFieldToggle"></p-inputSwitch>
        <span (click)="showRequiredToggle.writeValue(!showRequiredToggle.checked())">{{ 'templates.showErrors' | translate }}</span>
      </div>

      <span
        class="h-[1px] bg-grey-2"
        *ngIf="!readOnly && templateStatus === templateStatusKeys.Draft && !templateReadyToActivate"
      ></span>

      <div
        class="link-button link-button-with-icon link-button--danger"
        *ngIf="!readOnly && templateStatus === templateStatusKeys.Draft && templateId"
        (click)="deleteTemplate()"
      >
        <i class="pi pi-trash"></i>
        <span>{{ 'templates.actions.delete' | translate }}</span>
      </div>

      <span class="h-[1px] bg-grey-2" *ngIf="templateId"></span>

      <ng-container *ngIf="templateCreatedBy">
        <alimento-ipv-frontend-metadata-item
          title="{{ 'templates.createdBy' | translate }}"
          value="{{ templateCreatedBy }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf="templateCreatedOn">
        <alimento-ipv-frontend-metadata-item
          title="{{ 'templates.createdOn' | translate }}"
          value="{{ templateCreatedOn | date : 'dd/MM/yyyy' }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</form>
