import {
  Component,
  computed,
  effect,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Signal,
  signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AuthorizationService,
  CanComponentDeactivate,
  ExtraMenuItem,
  FEATURE,
  FeatureFlagService,
  flattenObject,
  LeaveConfirmService,
  MyMessageService,
  NOTES_TYPE,
  NotesSidebarComponent,
  Role,
  TabMenuItem,
  TitleService,
  TranslationService,
  validateAllFormFields
} from '@alimento-ipv-frontend/ui-lib';
import { first, forkJoin, Observable, Subscription, switchMap, tap } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { BasicTrainingComponent } from '../../components/basic-training/basic-training.component';
import {
  BaseTraining,
  FinancialDocument,
  Reimbursement,
  ReimbursementRequest,
  TrainingData
} from '../../../types/reimbursement-request.type';
import { ReimbursementRequestService } from '../../../services/reimbursement-request.service';
import {
  ParticipantsListDialogComponent
} from '../../../trainings/components/participants-list-dialog/participants-list-dialog.component';
import {
  CertificatesDialogComponent
} from '../../../trainings/components/certificates-dialog/certificates-dialog.component';
import {
  CommunicateChangesDialogComponent
} from '../../../trainings/components/communicate-changes-dialog/communicate-changes-dialog.component';
import {
  ApproveEnrollmentsDialogComponent
} from '../../../trainings/components/approve-enrollments-dialog/approve-enrollments-dialog.component';
import {
  DOCUMENT_SET_TYPE,
  getTrainingStatusKey,
  TRAINING_TYPE,
  TrainingStatusKey
} from '../../../types/reference-data.enum';
import { Branch } from '../../../types/branch.type';
import { SessionService } from '../../../services/session.service';
import { EnrollmentService } from '../../../services/enrollment.service';
import { BranchService } from '../../../services/branch.service';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { Enterprise } from '../../../types/enterprise.type';
import { EnterprisesService } from '../../../services/enterprises.service';
import { PurchaseEntry } from '../../../types/training.type';
import { PurchaseEntryService } from '../../../services/purchase-entries.service';
import { DocumentService } from '../../../services/document.service';

@Component({
  selector: 'alimento-ipv-frontend-reimbursement-request-training-detail',
  templateUrl: './reimbursement-request-training-detail.component.html'
})
export class ReimbursementRequestTrainingDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  @ViewChild('basicTrainingComponent')
  basicTrainingComponent!: BasicTrainingComponent;
  @ViewChild(ParticipantsListDialogComponent)
  participantsPopup: ParticipantsListDialogComponent;
  @ViewChild(CertificatesDialogComponent)
  certificatesPopup: CertificatesDialogComponent;
  @ViewChild(CommunicateChangesDialogComponent)
  communicateChangesPopup: CommunicateChangesDialogComponent;
  @ViewChild(ApproveEnrollmentsDialogComponent)
  approveEnrollmentsPopup: ApproveEnrollmentsDialogComponent;
  @ViewChild(NotesSidebarComponent)
  notesSidebarComponent: NotesSidebarComponent;

  @Input()
  readOnly = true;

  sessionsMenuItem: TabMenuItem;
  participantsMenuItem: TabMenuItem;
  tabMenuItems: TabMenuItem[];
  extraMenuItems: ExtraMenuItem[];
  activeTabIndex = 0;
  trainingForm: FormGroup;
  trainingId!: string;
  type: TRAINING_TYPE;
  reimbursementRequestId!: string;
  title: string;

  trainingStatus: TrainingStatusKey = TrainingStatusKey.Draft;
  getTrainingStatusKey = getTrainingStatusKey;
  training: WritableSignal<BaseTraining> = signal(undefined);
  isOngoing: Signal<boolean> = this.sessionService.isOngoing;
  savingTraining = false;
  activatingTraining = false;
  loading = false;
  trainingStatusKeys = TrainingStatusKey;
  firstSessionDate?: Date;
  lastSessionDate?: Date;
  showNotes = false;
  TrainingNote = NOTES_TYPE.TrainingNote;
  notesHasChanges = false;
  documentCount: WritableSignal<number> = signal(undefined);
  reimbursementRequest: WritableSignal<ReimbursementRequest> = signal(undefined);
  branch: Branch;
  enterprise: WritableSignal<Enterprise> = signal(undefined);
  financialDocumentsFeatureOn: boolean;
  reimbursementFeatureOn: boolean;
  financialDocuments: WritableSignal<FinancialDocument[]> = signal([]);
  reimbursements: WritableSignal<Reimbursement[]> = signal([]);
  trainingData: Signal<TrainingData> = computed(() => (
    {
      totalAmountToPayout: this.training()?.totalPreparationCost + this.training()?.totalCostWithoutPreparation -
        this.reimbursements()?.reduce((previousValue, currentValue) => currentValue.amount + previousValue, 0),
      sumTotalAmount: this.training()?.totalCostWithoutPreparation,
      sumPreparationAmount: this.training()?.totalPreparationCost,
      nrOfParticipants: this.enrollmentService.nrOfParticipants(),
      smeBalance: this.enterprise()?.smeBalance,
      smeBonus: this.enterprise()?.smeBonus,
      smeBonusValidFrom: this.enterprise()?.smeBonusValidFrom,
      smeBonusValidUntil: this.enterprise()?.smeBonusValidUntil,
      totalDuration: ''
    })
  );
  purchaseEntries: WritableSignal<PurchaseEntry[]> = signal([]);

  private _subscriptions: Subscription[] = [];

  protected readonly TRAINING_TYPE = TRAINING_TYPE;

  get isActivateDisabled(): boolean {
    return this.activatingTraining ||
      this.trainingStatus === this.trainingStatusKeys.Cancelled ||
      !this.trainingForm.get('customTitle')?.value;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (!this.canDeactivate()) {
      $event.returnValue = 'Are you sure';
    }
  }

  constructor(
    private fb: FormBuilder,
    private reimbursementRequestService: ReimbursementRequestService,
    private messageService: MyMessageService,
    private confirmationService: ConfirmationService,
    private leaveConfirmationService: LeaveConfirmService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private translateService: TranslateService,
    private translationService: TranslationService,
    public sessionService: SessionService,
    private enrollmentService: EnrollmentService,
    private authorizationService: AuthorizationService,
    private titleService: TitleService,
    private branchService: BranchService,
    private referenceDataService: ReferenceDataService,
    private featureService: FeatureFlagService,
    private enterpriseService: EnterprisesService,
    private purchaseEntryService: PurchaseEntryService,
    private documentService: DocumentService
  ) {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
    this._createMenuItems();
    this._createTrainingForm();

    effect(() => {
      const sessionsWithDates = this.sessionService.sessions().filter(session => session.date);
      this.firstSessionDate = sessionsWithDates.length > 0 ? new Date(sessionsWithDates[0].date) : undefined;
      this.lastSessionDate = sessionsWithDates.length > 0 ? new Date(sessionsWithDates[sessionsWithDates.length - 1].date) : undefined;
    });

    effect(() => {
      if (this.training()) {
        this.trainingStatus = (this.training().statusId as TrainingStatusKey) ?? TrainingStatusKey.Draft;
        if (this.reimbursementRequest()) {
          this._fillTrainingForm(this.training());
          this._setTitle();
        }
      }
      this._createMenuItems();
    }, { allowSignalWrites: true });

    effect(() => {
      if (this.reimbursementRequest()) {
        if (this.training()) {
          this._fillTrainingForm(this.training());
          this._setTitle();
          this.getPurchaseEntries();
        }
      }
      this._createMenuItems();
    }, { allowSignalWrites: true });
  }

  ngOnInit(): void {
    this.route.params.pipe(first()).subscribe(params => {
      this.reimbursementRequestId = params['reimbursementRequestId'];
      this.trainingId = params['trainingId'];
      this.route.data.pipe(first())
        .subscribe(data => {
          this.type = data['trainingType'];

          if (this.reimbursementRequestId) {
            this._fetchReimbursementRequestData();
          }
          else if (this.trainingId) {
            this._fetchTrainingData();
          }
          else {
            this.router.navigate(['error'], {
              state: {
                message: this.translateService.instant('error.itemWithIdDoesNotExist', {
                  item: this.translateService.instant('trainings.openTraining'),
                  id: this.trainingId
                }),
                redirectUrl: '/reimbursement-requests'
              }
            });
          }
        });
    });

    this._subscriptions.push(
      this.route.queryParams.subscribe(
        queryParams => this.activeTabIndex = Number(queryParams['activeTabIndex']) || 0
      )
    );
  }

  private _fetchReimbursementRequestData(): void {
    this.reimbursementRequestService.getRequest(this.reimbursementRequestId)
      .pipe(first())
      .subscribe({
        next: reimbursementRequest => {
          this.reimbursementRequest.set(reimbursementRequest);
          this._fetchBranchData();

          if (!this.training()) {
            if (this.trainingId) {
              this._fetchTrainingData();
            }
            else {
              this._fillTrainingForm({} as BaseTraining);
            }
          }

          this._createMenuItems();
          this._loadDocuments();
        },
        error: () => {
          this.router.navigate(['error'], {
            state: {
              message: this.translateService.instant('error.itemWithIdDoesNotExist', {
                item: this.translateService.instant('reimbursementRequests.request'),
                id: this.reimbursementRequestId
              }),
              redirectUrl: '/reimbursement-requests'
            }
          });
        }
      });
  }

  private _fetchTrainingData(): void {
    this.reimbursementRequestService.getTraining(this.trainingId, this.type)
      .pipe(first())
      .subscribe(training => {
        this.training.set(training);
        if (!this.reimbursementRequest()) {
          this.reimbursementRequestId = this.training().trainingAllowanceApplicationId;
          this._fetchReimbursementRequestData();
        }
        this.sessionService.loadSessions(this.trainingId, training.typeId);
        this.enrollmentService.loadEnrollments(this.trainingId);
        this.enrollmentService.loadEnrollmentRequests(this.trainingId);
        if (!window.history.state?.newTraining) {
          this._loadDocuments();
        }
        this.featureService.initialize().pipe(first()).subscribe(() => {
          this.financialDocumentsFeatureOn = this.featureService.featureOn(FEATURE.FINANCIAL_DOCUMENTS);
          this.reimbursementFeatureOn = this.featureService.featureOn(FEATURE.REIMBURSEMENT);
          this.getFinancialDocuments();
          this.getReimbursements();
        });
        this._setDetailUrl();
      });
  }

  private _fetchBranchData(): void {
    this.branchService.getBranch(this.reimbursementRequest().branchId).pipe(first())
      .subscribe(branch => {
        this.branch = branch;
        this._fetchEnterpriseData();
        this._setTitle();
      });
  }

  private _fetchEnterpriseData(): void {
    if (this.branch) {
      this.enterpriseService.getEnterprise(this.branch.enterpriseId).pipe(first())
        .subscribe(enterprise => this.enterprise.set(enterprise));
    }
  }

  canDeactivate(): Promise<boolean> | boolean {
    if (!this.trainingForm.dirty && !this.notesHasChanges) {
      return true;
    }

    const saveActions: Observable<any>[] = [];
    if (this.trainingForm.dirty) {
      saveActions.push(this._saveTrainingData());
    }

    if (this.notesHasChanges) {
      saveActions.push(this.notesSidebarComponent.saveChanges());
    }

    return this.leaveConfirmationService.leaveDialog(() => forkJoin(saveActions));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe());
    this.enrollmentService.clearEnrollments();
  }

  setActiveTabIndex(tabMenuItem: TabMenuItem): void {
    this.activeTabIndex = tabMenuItem.index;
    this._setDetailUrl();
  }

  saveTraining(): void {
    const executeSave = () => {
      this.savingTraining = true;
      this._saveTrainingData()
        .subscribe({
          next: () => this.savingTraining = false,
          error: () => this.savingTraining = false
        });
    };

    if (this.basicTrainingComponent.hasInactiveCaseManagersOrCounselors()) {
      this.confirmationService.confirm({
        header: this.translateService.instant('trainings.inactiveCaseManagerDialog.title'),
        message: this.translateService.instant('trainings.inactiveCaseManagerDialog.message'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.accept'),
        acceptIcon: 'hidden',
        rejectLabel: this.translateService.instant('trainings.inactiveCaseManagerDialog.reject'),
        rejectButtonStyleClass: 'inverted-button',
        accept: () => executeSave(),
        reject: () => {
          this.activeTabIndex = 0;
          setTimeout(() => this.basicTrainingComponent.focusFirstInactiveCaseManager());
        }
      });
    }
    else {
      executeSave();
    }
  }

  loadTrainingData(): void {
    this.reimbursementRequestService.getTraining(this.trainingId, this.training()?.typeId || this.type)
      .pipe(first())
      .subscribe((training: BaseTraining) => {
        this.training.set(training);
      });
  }

  getFinancialDocuments(): void {
    if (this.trainingId && this.financialDocumentsFeatureOn) {
      this.reimbursementRequestService.getFinancialDocuments(this.trainingId).pipe(first())
        .subscribe(financialDocuments => this.financialDocuments.set(financialDocuments.data));
    }
  }

  financialDocumentsChanged(): void {
    this.getFinancialDocuments();
    this.loadTrainingData();
  }

  getReimbursements(): void {
    if (this.trainingId && this.reimbursementFeatureOn) {
      this.reimbursementRequestService.getReimbursements(this.trainingId).pipe(first())
        .subscribe(reimbursements => this.reimbursements.set(reimbursements));
    }
  }

  reimbursementsChanged(): void {
    this.loadTrainingData();
    this.getReimbursements();
  }

  private _createTrainingForm(): void {
    this.trainingForm = this.fb.group({
      trainingAlimentoId: [null],
      trainingAllowanceApplicationId: [],
      customTitle: ['', [Validators.required, Validators.maxLength(200)]],
      basicDataForm: []
    });
  }

  private _fillTrainingForm(training: BaseTraining) {
    this.trainingStatus = (training.statusId as TrainingStatusKey) ?? TrainingStatusKey.Draft;

    this.trainingForm.patchValue({
      trainingAlimentoId: training.trainingAlimentoId,
      customTitle: training.customTitle,
      trainingAllowanceApplicationId: this.reimbursementRequestId,
      basicDataForm: BasicTrainingComponent.createFormData(training, this.reimbursementRequest())
    }, { emitEvent: false });

    this.trainingForm.markAsPristine();

    if (this.readOnly) {
      this.trainingForm.disable({ emitEvent: false });
    }
  }

  private _saveTrainingData(): Observable<{ id: string }> {
    const training: BaseTraining = { ...(flattenObject(this.trainingForm.value) as BaseTraining) };

    this.basicTrainingComponent.validateFormFields();
    validateAllFormFields(this.trainingForm);
    if (!this.trainingForm.valid) {
      this.messageService.notAllFieldsValid();
      return new Observable<{ id: string }>((observable) => observable.error());
    }

    let changeType$: Observable<void>;
    if (this.training()?.trainingId && training.typeId !== this.training().typeId) {
      changeType$ = this.reimbursementRequestService.changeTrainingType(this.training()?.trainingId, this.training().typeId, training.typeId)
        .pipe(first());
    }
    else {
      changeType$ = new Observable<void>(observable => observable.next());
    }

    return changeType$.pipe(
      switchMap(() =>
        this.trainingId
          ? this.reimbursementRequestService.updateTraining(this.trainingId, training)
          : this.reimbursementRequestService.createTraining(training)),
      tap({
        next: (response: { id: string }) => {
          this.trainingId = response.id;
          this.type = training.typeId;
          this.trainingForm.markAsPristine();
          this.messageService.success('trainings.saved');
          this._fetchTrainingData();
        }
      }),
      first()
    );
  }

  private _setDetailUrl(): void {
    if (this.trainingId) {
      const basePath = this.type === TRAINING_TYPE.CUSTOM ? 'custom' : 'openextern';
      this.location.replaceState(
        this.router
          .createUrlTree(['/trainings', basePath, this.trainingId], {
            queryParams: { activeTabIndex: this.activeTabIndex }
          })
          .toString()
      );
    }
  }

  private _createMenuItems(): void {
    this._subscriptions.push(
      this.translationService.languageChange$.subscribe(() => {
        this.sessionsMenuItem = {
          index: 1,
          name: 'sessions',
          title: this.translateService.instant('trainings.sessions.sessions'),
          count: this.sessionService.nrOfSessions,
          disabled: !this.training()?.trainingId
        };
        this.participantsMenuItem = {
          index: 2,
          name: 'participants',
          title: this.translateService.instant('trainings.participantsHeader'),
          count: this.enrollmentService.nrOfParticipants,
          // maxCount: this.training()?.maxParticipants,
          disabled: !this.training()?.trainingId
        };
        this.tabMenuItems = [
          { index: 0, name: 'basicData', title: this.translateService.instant('trainings.basicData.formTitle') },
          this.sessionsMenuItem,
          this.participantsMenuItem,
          {
            index: 3, name: 'financialDocuments',
            disabled: !this.financialDocumentsFeatureOn,
            title: this.translateService.instant('financialDocuments.menuTitle')
          },
          {
            index: 4, name: 'reimbursements',
            disabled: !this.reimbursementFeatureOn,
            title: this.translateService.instant('reimbursements.menuTitle')
          },
          {
            index: 5,
            name: 'purchaseEntries',
            title: this.translateService.instant('trainings.purchaseEntries.menuTitle'),
            disabled: !this.training()?.trainingId,
            count: computed(() => this.purchaseEntries().length)
          }
        ];

        this.extraMenuItems = [
          {
            name: 'notes',
            title: this.translateService.instant('trainings.notesData.formTitle'),
            disabled: !this.training()?.trainingId,
            command: () => {
              if (this.showNotes) {
                this.notesSidebarComponent.close();
              }
              else {
                this.showNotes = !this.showNotes;
              }
            }
          },
          {
            name: 'documents',
            title: this.translateService.instant('documents'),
            disabled: !this.training()?.trainingId || !this.training()?.documentSetUrl,
            count: this.documentCount,
            command: () => {
              window.open(this.training()?.documentSetUrl, '_blank');
            }
          }
        ];
      })
    );
  }

  private _setTitle(): void {
    if (this.branch && this.training()) {
      this.referenceDataService.getTrainingType(this.training().typeId).pipe(first())
        .subscribe(type => {
          this.title = `${this.translateService.instant('reimbursementRequests.request')} ${this.branch.branchName} - ${type.label}`;
          this.titleService.setTitle([this.title]);
        });
    }
  }

  private _loadDocuments(): void {
    if (this.training()?.documentSetUrl) {
        this.documentService.getDocumentCount(this.trainingId, DOCUMENT_SET_TYPE.TRAINING).pipe(first())
        .subscribe(count => {
          this.documentCount.set(count.count);
          if (count.count === -1) {
            this.documentService.createDocumentSet(this.trainingId, DOCUMENT_SET_TYPE.TRAINING).pipe(first())
              .subscribe(documentSet => {
                this.training.update(training => {
                  training.documentSetUrl = documentSet.url;
                  return training;
                });
                this.documentCount.set(0);
              });
          }
        });
    }
  }

  getPurchaseEntries(): void {
    if (this.trainingId) {
      this.purchaseEntryService.getPurchaseEntries(this.trainingId).pipe(first())
        .subscribe(purchaseEntries => this.purchaseEntries.set(purchaseEntries));
    }
  }
}
