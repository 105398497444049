import { Route } from '@angular/router';
import { CommunicationListComponent } from './pages/communication-list/communication-list.component';

export const communicationsRoutes: Route[] = [
  {
    path: '',
    component: CommunicationListComponent,
    data: { title: 'titles.communications.list' }
  }
];
