import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FilterMetadata, MenuItem, MenuItemCommandEvent } from 'primeng/api';
import { BehaviorSubject, debounceTime, distinctUntilChanged, first, map, Observable, Subscription, tap } from 'rxjs';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthorizationService, PaginatedResponse,
  Role,
  TEMPLATE_FILTER_KEY, TEMPLATE_PAGE,
  TEMPLATE_SORT_KEY,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { TrainingTemplate, TrainingTemplateListItem } from '../../../types/training-template.type';
import {
  CaseManager, LanguageCode,
  TrainingTemplateRubric,
  TrainingTemplateStatus,
  TrainingTemplateType
} from '../../../types/reference-data.type';
import { getTemplateStatusKey, TemplateStatusKey } from '../../../types/reference-data.enum';
import { TrainingTemplateService } from '../../../services/training-template.service';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-training-template-list',
  templateUrl: './training-template-list.component.html',
  styleUrls: ['./training-template-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TrainingTemplateListComponent implements OnInit, OnDestroy {
  templates: TrainingTemplateListItem[] = [];
  totalRecords = 0;
  loading = false;
  actions: MenuItem[] = [];

  trainingStatuses$: Observable<TrainingTemplateStatus[]> = this.referenceDataService
    .getTemplateStatuses()
    .pipe(first());
  caseManagers$: Observable<CaseManager[]> = this.referenceDataService.getCaseManagers().pipe(first());
  trainingTypes$: Observable<TrainingTemplateType[]> = this.referenceDataService.getTrainingTypes().pipe(first());
  trainingRubrics$: Observable<TrainingTemplateRubric[]> = this.referenceDataService.getTrainingRubrics().pipe(first());

  languageCodes$: Observable<LanguageCode[]> = this.referenceDataService.getLanguageCodes().pipe(
    first(),
    map((codes) =>
      codes.map((code) => {
        code.label = code.label.toUpperCase();
        return code;
      })
    )
  );

  getTemplateStatusKey = getTemplateStatusKey;
  filters: {
    [s: string]: FilterMetadata | FilterMetadata[] | undefined
  } = {};
  sorting: {
    field: string;
    order: number
  };
  page = 0;
  private idFieldChangesSub: Subscription;
  private idFieldChanges: BehaviorSubject<any>;
  private readOnly = true;

  protected readonly Role = Role;

  @ViewChild(Table)
  private table: Table;

  constructor(
    private trainingTemplateService: TrainingTemplateService,
    private referenceDataService: ReferenceDataService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private authorizationService: AuthorizationService,
    private translationService: TranslationService
  ) {
    this.readOnly = !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
    this.actions = this.getActions();

    this.translationService.languageChange$.subscribe(() => {
      if (this.table) {
        this.loadTrainings(this.table.createLazyLoadMetadata());
      }
    });
  }

  ngOnInit(): void {
    this.loading = true;
    const filters = localStorage.getItem(TEMPLATE_FILTER_KEY);
    const sort = localStorage.getItem(TEMPLATE_SORT_KEY);
    const page = sessionStorage.getItem(TEMPLATE_PAGE);

    if (filters) {
      this.filters = JSON.parse(filters);
    }

    if (sort) {
      this.sorting = JSON.parse(sort);
    }

    if (page) {
      this.page = Number(page);
    }
  }

  ngOnDestroy(): void {
    this.idFieldChangesSub?.unsubscribe();
  }

  loadTrainings(event: TableLazyLoadEvent) {
    this.loading = true;

    localStorage.setItem(TEMPLATE_FILTER_KEY, JSON.stringify(event.filters));
    localStorage.setItem(TEMPLATE_SORT_KEY, JSON.stringify({ field: event.sortField, order: event.sortOrder }));
    sessionStorage.setItem(TEMPLATE_PAGE, event.first + "");

    this.trainingTemplateService
      .getTemplates(event)
      .pipe(first())
      .subscribe({
        next: (trainingTemplateListResult: PaginatedResponse<TrainingTemplateListItem>) => {
          this.templates = trainingTemplateListResult.data;
          this.totalRecords = trainingTemplateListResult.totalCount;
          this.loading = false;
        },
        error: () => {
          this.templates = [];
          this.totalRecords = 0;
          this.loading = false;
        }
      });
  }

  clearFilters(templateTable: Table) {
    localStorage.removeItem(TEMPLATE_FILTER_KEY);
    localStorage.removeItem(TEMPLATE_SORT_KEY);
    sessionStorage.removeItem(TEMPLATE_PAGE);
    templateTable.clear();
  }

  updateActionItem(trainingTemplate: TrainingTemplate): (item: any) => void {
    return (item: any) => {
      item.trainingTemplate = trainingTemplate;

      if (item.id === 'createTrainingFromTemplate') {
        item.visible = trainingTemplate.status?.data === TemplateStatusKey.Active;
      }
    };
  }

  private getActions(): MenuItem[] {
    const actions: MenuItem[] = [
      {
        id: 'showDetail',
        label: this.translate.instant('templates.actions.showDetail'),
        icon: 'pi pi-eye',
        iconStyle: {
          position: 'absolute',
          right: '2px'
        },
        command: (event: MenuItemCommandEvent) => {
          const item = event.item as any;
          this.router.navigate(['/training-templates', item.trainingTemplate.id, 'detail']);
        }
      }
    ];

    if (!this.readOnly) {
      actions.push({
        id: 'createTrainingFromTemplate',
        label: this.translate.instant('templates.actions.createTrainingFromTemplate'),
        command: (event) => {
          const item = event.item as any;
          this.createTrainingFromTemplate(item.trainingTemplate.id);
        }
      });
    }

    return actions;
  }

  private createTrainingFromTemplate(trainingTemplateId: string) {
    this.trainingTemplateService
      .createTrainingFromTemplate(trainingTemplateId)
      .pipe(first())
      .subscribe(trainingId =>
          this.router.navigate(['/trainings', "open", trainingId, 'detail'], {
            relativeTo: this.route.parent,
            state: {
              newTraining: true
            }
          })
      );
  }

  onIdInput(event: any, filterCallback: any) {
    if (!this.idFieldChangesSub) {
      this.idFieldChanges = new BehaviorSubject<any>(event);
      this.idFieldChangesSub = this.idFieldChanges
        .pipe(
          debounceTime(400),
          distinctUntilChanged(),
          tap(value => {
            filterCallback(value.value);
          })
        )
        .subscribe();
    }
    else {
      this.idFieldChanges.next(event);
    }
  }
}
