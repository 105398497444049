<div class='relative'>
  <div #inputContainer class='relative flex items-center'>
    <i *ngIf='showSearchIcon' class='pi pi-search absolute top-4 left-4'></i>
    <div class='w-full relative'>
      <input #inputField
             (blur)='onInputBlur($event)'
             (keydown.arrowDown)='onKeyDown($event)'
             (keydown.arrowUp)='onKeyUp($event)'
             (keydown.enter)='onKeyEnter($event)'
             (keydown.escape)='onEscape($event)'
             [formControl]='inputControl'
             [placeholder]='placeholder || ""'
             class="bg-white border border-[#d4d4d8] w-full rounded-[2px] rounded-r-none h-12 p-3 pr-10
             focus:outline-none focus:border-yellow focus:shadow-primeng disabled:opacity-60 outline-none"
             [ngClass]='{"pl-10": showSearchIcon}'
             type='text'
      />
      <i (click)='clear(); inputField.focus();'
         (keyup.enter)='clear(); inputField.focus();'
         *ngIf='showClear && inputControl.value'
         class='pi pi-times absolute p-2 right-4 top-2 mt-[1px] hover:bg-primary-hover cursor-pointer'></i>
    </div>

    <button #dropdownButton
            (blur)='onButtonBlur($event)'
            (click)='dropdownButtonClicked($event)'
            [disabled]='disabled'
            class='w-12 h-12 !p-4 !rounded-l-none'
            pButton
            type='button'>
      <i class='{{dropdownIcon}}'></i>
    </button>
  </div>
  <app-body-content *ngIf='resultListVisible' [removeIfScrollOutside]='true'>
    <div #resultListContainer class='absolute z-[9999] t-0 l-0' [ngStyle]='resultListStyle'>
      <div *ngIf='headerTemplate'
           class='r-0 p-3 pr-8 bg-grey-0 border border-[#d4d4d8] mt-1' isEllipsis pTooltip
           [ngStyle]='{height: minHeight + "px", width: width ? width + "px" : "100%"}'
      >
        <ng-container *ngTemplateOutlet='headerTemplate'></ng-container>
      </div>
      <div #resultList
           [ngStyle]='resultListStyle'
           [ngClass]="{'mt-1': !headerTemplate}"
           class="r-0 bg-white h-[200px] border border-[#d4d4d8] overflow-y-auto">
        <lib-card-view
          #cardViewComponent
          (onSelectChange)='onSelect($event)'
          [canChangeView]='false'
          [cardTemplate]='itemTemplate'
          [data$]='data$'
          [loadOnScroll]='true'
          [scrollWindow]='resultList'
          [selectable]='true'
          [showFilterInfo]='false'
          [showSearching]='false'
          [skeletonTemplate]='skeletonTemplate'
          viewMode='list'
        >
          <ng-template #defaultTemplate let-item='item'>
            {{item[field]}}
          </ng-template>
          <ng-template #itemTemplate let-item='item' let-selected='selected'>
            <div *ngIf='item'
                 [ngClass]="{'bg-primary-hover': selected}"
                 class='p-3 hover:bg-primary-hover cursor-pointer' isEllipsis pTooltip>
              <ng-container *ngTemplateOutlet='template ? template : defaultTemplate; context: {item: item}'></ng-container>
            </div>
          </ng-template>
          <ng-template #skeletonTemplate>
            <div class='p-3'>
              <div class='flex flex-col w-full'>
                <p-skeleton></p-skeleton>
              </div>
            </div>
          </ng-template>
        </lib-card-view>
      </div>
    </div>
  </app-body-content>

</div>
