import { Inject, Injectable } from '@angular/core';
import { first, Observable, shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { FEATURE } from '../types/ui-lib.enum';
import { APP_CONFIG_TOKEN, AppConfig } from '../types/ui-lib.type';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  private _featureFlags: FEATURE[] = [];
  private _initialized = false;
  private _initObservable: Observable<void>;

  NO_CACHE_HEADERS: { [key: string]: string } = {
    'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
    'Pragma': 'no-cache',
    'Expires': '0'
  };

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  featureOff(feature: FEATURE): boolean {
    return !this.featureOn(feature);
  }

  featureOn(feature: FEATURE): boolean {
    if (!feature) {
      return true;
    }
    return this._featureFlags && !!this._featureFlags.find(featureFlag => {
      return featureFlag === feature;
    });
  }

  anyFeatureOn(features: FEATURE[]): boolean {
    return features.some(feature => this.featureOn(feature));
  }

  get initialized(): boolean {
    return this._initialized;
  }

  initialize(): Observable<void> {
    if (!this._initObservable) {
      this._initObservable = new Observable<void>(observable => {
        this.http.get<any[]>(`${this.config.readApiUrl}/features/features`, {headers: this.NO_CACHE_HEADERS})
          .pipe(first(), map(features =>
            features.filter(feature => feature.isEnabled).map(feature => feature.feature as FEATURE)))
          .subscribe({
            next: featureFlags => {
              this._featureFlags = featureFlags;
              this._initialized = true;
              observable.next();
              observable.complete();
            },
            error: (e) => observable.error(e)
          });
      }).pipe(shareReplay(1));
    }
    return this._initObservable;
  }
}
