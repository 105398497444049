import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { defaultIfEmpty, forkJoin, of, take } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { FilterType, FilterValue, IFilter, SearchFilter } from '../../../types/search.type';
import { DateMapper } from '../../../utils/date.mapper';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-active-filter',
  templateUrl: './active-filter.component.html'
})
export class ActiveFilterComponent implements OnChanges {
  @Input()
  filterData: SearchFilter[];

  @Input()
  set toDateFilter(value: string) {
    if (value) {
      this._updateFilterList(FilterType.dateFilterType_toDate, [{
        label: this.translateService.instant("search.to") + " " + this.dateMapper.formatDates(new Date(value)),
        value: value,
        type: FilterType.dateFilterType_toDate
      }])
    }
    else {
      this._removeTypeFromFilterList(FilterType.dateFilterType_toDate);
    }
  }

  @Input()
  set fromDateFilter(value: string) {
    if (value) {
      this._updateFilterList(FilterType.dateFilterType_fromDate, [{
        label: this.translateService.instant("search.from") + " " + this.dateMapper.formatDates(new Date(value)),
        value: value,
        type: FilterType.dateFilterType_fromDate
      }])
    }
    else {
      this._removeTypeFromFilterList(FilterType.dateFilterType_fromDate);
    }
  }

  @Input()
  set searchFilter(value: string) {
    if (value) {
      this._updateFilterList(FilterType.search, [{
        label: value,
        value: value,
        type: FilterType.search
      }]);
    }
    else {
      this._removeTypeFromFilterList(FilterType.search);
    }
  }

  @Input()
  set multiSelectFilter(value: { [key: string]: string[] }) {
    Object.values(FilterType).forEach(filterKey => {
      if (!this._filterData[filterKey] && !this.lazyFilters.includes(filterKey)) {
        this.initialValues[filterKey] = value[filterKey];
        return;
      }

      if (this.lazyFilters.includes(filterKey)) {
        this._updateLazyLoadFilters(filterKey, value[filterKey]);
      }
      else {
        this._updateMultiSelectFilters(filterKey, value[filterKey]);
      }
    });
  }

  @Output()
  removeFilter: EventEmitter<FilterValue> = new EventEmitter<FilterValue>();

  @Output()
  removeAllFilters: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  filtersHaveChanged = false;

  initialValues: { [key: string]: string[] } = {};
  filterList: FilterValue[] = [];
  showAllFilters = false;
  private lazyFilters: string[] = [];
  private _filterData: { [key: string]: any[] } = {};

  constructor(private translateService: TranslateService, private dateMapper: DateMapper) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['filterData']?.currentValue) {
      changes['filterData'].currentValue.forEach((filter: SearchFilter) => {
        if (filter.data) {
          filter.data?.pipe(take(1)).subscribe(data => {
            if (filter.key) {
              this._filterData[filter.key] = data;
            }
            else {
              this._filterData[filter.label] = data;
            }

            if (this.initialValues[filter.key]) {
              this._updateMultiSelectFilters(filter.key, this.initialValues[filter.key])
            }
          });
        }
        else if (filter.getItem) {
          this.lazyFilters.push(filter.key);
          if (this.initialValues[filter.key]) {
            this._updateLazyLoadFilters(filter.key, this.initialValues[filter.key]);
          }
        }
      });
    }
  }

  private _updateLazyLoadFilters(filterKey: string, values: string[]): void {
    if (!this._filterData[filterKey]) {
      this._filterData[filterKey] = [];
    }

    const searchFilter = this.filterData.filter(item => item.key === filterKey)[0];

    forkJoin((values || [])
      .filter(value => !this._filterData[filterKey].map(data => data.value).includes(value))
      .map(value => {
        if (searchFilter.addEmptyValue && value === searchFilter.emptyValue) {
          return of({
            label: this.translateService.instant(searchFilter.emptyLabelKey),
            value: searchFilter.emptyValue
          } as IFilter);
        }
        else {
          return searchFilter.getItem(value);
        }
      })
      .map(observable =>
        observable.pipe(tap(result => this._filterData[filterKey].push(result))))
    )
      .pipe(defaultIfEmpty(null))
      .subscribe(() => this._updateMultiSelectFilters(filterKey, values));
  }

  private _updateMultiSelectFilters(filterKey: string, values: string[]) {
    const newFilters = (values || []).map(filter => ({
      label: this._getTranslatedFilter(filter, this._filterData[filterKey]),
      value: filter,
      type: filterKey as FilterType
    }));
    this._updateFilterList(filterKey, newFilters);
    delete this.initialValues[filterKey];
  }

  private _getTranslatedFilter(value: string, data: IFilter[]): string {
    return data.find(filter => filter.value === value)?.label || value;
  }

  removeFilterEvent(filter: FilterValue): void {
    this.removeFilter.emit(filter);
  }

  removeAllFiltersEvent(): void {
    this.showAllFilters = false;
    this.removeAllFilters.emit(true);
  }

  private _updateFilterList(type: string, newFilters: FilterValue[]): void {
    this._removeTypeFromFilterList(type);
    newFilters.forEach(newFilter => this.filterList.push(newFilter));
  }

  private _removeTypeFromFilterList(type: string): void {
    this.filterList = this.filterList.filter(filter => filter.type !== type);
  }
}
