import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainingListComponent } from './pages/training-list/training-list.component';
import { TrainingDetailComponent } from './pages/training-detail/training-detail.component';
import { InputTextModule } from 'primeng/inputtext';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { FormsModule } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CheckboxModule } from 'primeng/checkbox';
import { RippleModule } from 'primeng/ripple';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { MenuModule } from 'primeng/menu';
import { CancelTrainingDialogComponent } from './components/cancel-training-dialog/cancel-training-dialog.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AccordionModule } from 'primeng/accordion';
import { CloseTrainingDialogComponent } from './components/close-training-dialog/close-training-dialog.component';
import {
  CommunicateChangesDialogComponent
} from './components/communicate-changes-dialog/communicate-changes-dialog.component';
import {
  ParticipantsListDialogComponent
} from './components/participants-list-dialog/participants-list-dialog.component';
import { CertificatesDialogComponent } from './components/certificates-dialog/certificates-dialog.component';
import {
  ApproveEnrollmentsDialogComponent
} from './components/approve-enrollments-dialog/approve-enrollments-dialog.component';
import {
  TrainingCommunicationsComponent
} from './components/training-communications/training-communications.component';
import { UtilsModule } from '../utils/utils.module';
import { SessionsModule } from '../sessions/sessions.module';
import { EnrollmentsModule } from '../enrollments/enrollments.module';
import { CommunicationsModule } from '../communications/communications.module';
import {
  ReactivateTrainingDialogComponent
} from './components/reactivate-training-dialog/reactivate-training-dialog.component';
import { CevoraTrainingDetailComponent } from './pages/cevora-training-detail/cevora-training-detail.component';
import { BasicTrainingComponent } from './components/basic-training/basic-training.component';
import {
  FinancialDocumentPopupComponent
} from './components/financial-document-popup/financial-document-popup.component';
import { FinancialDocumentCardComponent } from './components/financial-document-card/financial-document-card.component';
import {
  ReimbursementRequestTrainingDetailComponent
} from './pages/reimbursement-request-training-detail/reimbursement-request-training-detail.component';
import { TrainingProgramModule } from '../training-programs/training-program.module';
import { TrainingProjectComponent } from './components/training-project/training-project.component';
import {
  FinancialDocumentsOverviewComponent
} from './components/financial-documents-overview/financial-documents-overview.component';
import {
  ReimbursementsOverviewComponent
} from './components/reimbursements-overview/reimbursements-overview.component';
import { ReimbursementPopupComponent } from './components/reimbursement-popup/reimbursement-popup.component';
import { ReimbursementCardComponent } from './components/reimbursement-card/reimbursement-card.component';
import {
  ReimbursementActionDialogComponent
} from './components/reimbursement-action-dialog/reimbursement-action-dialog.component';
import {
  PurchaseEntriesOverviewComponent
} from './components/purchase-entries-overview/purchase-entries-overview.component';
import { PurchaseEntryCardComponent } from './components/purchase-entry-card/purchase-entry-card.component';
import {
  PurchaseEntryCreateDialogComponent
} from './components/purchase-entry-create-dialog/purchase-entry-create-dialog.component';
import {
  ReimbursementCorrectionPopupComponent
} from './components/reimbursement-correction-popup/reimbursement-correction-popup.component';
import { RadioButtonModule } from 'primeng/radiobutton';

@NgModule({
  imports: [
    CommonModule,
    UiLibModule,
    FormsModule,
    UtilsModule,
    InputTextModule,
    InputNumberModule,
    InputTextareaModule,
    CheckboxModule,
    RippleModule,
    MultiSelectModule,
    ConfirmDialogModule,
    TableModule,
    MenuModule,
    InputSwitchModule,
    UtilsModule,
    AccordionModule,
    SessionsModule,
    EnrollmentsModule,
    CommunicationsModule,
    TrainingProgramModule,
    RadioButtonModule
  ],
  exports: [
    UtilsModule
  ],
  providers: [
    ConfirmationService,
    CanDeactivateGuard
  ],
  declarations: [
    TrainingListComponent,
    TrainingDetailComponent,
    CancelTrainingDialogComponent,
    CloseTrainingDialogComponent,
    CommunicateChangesDialogComponent,
    ParticipantsListDialogComponent,
    CertificatesDialogComponent,
    ApproveEnrollmentsDialogComponent,
    TrainingCommunicationsComponent,
    ReactivateTrainingDialogComponent,
    CevoraTrainingDetailComponent,
    BasicTrainingComponent,
    FinancialDocumentPopupComponent,
    FinancialDocumentCardComponent,
    ReimbursementRequestTrainingDetailComponent,
    TrainingProjectComponent,
    FinancialDocumentsOverviewComponent,
    ReimbursementsOverviewComponent,
    ReimbursementPopupComponent,
    ReimbursementCardComponent,
    ReimbursementActionDialogComponent,
    PurchaseEntriesOverviewComponent,
    PurchaseEntryCardComponent,
    PurchaseEntryCreateDialogComponent,
    ReimbursementCorrectionPopupComponent
  ]
})
export class TrainingsModule {}
