import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyMessageService, validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import {
  Reimbursement, ReimbursementCorrectionInfo,
  ReimbursementUpdateEvent,
  TrainingData
} from '../../../types/reimbursement-request.type';
import { InputNumber } from 'primeng/inputnumber';
import { EnrollmentService } from '../../../services/enrollment.service';
import { REIMBURSEMENT_CORRECTION, REIMBURSEMENT_TYPE } from '../../../types/reimbursement-request.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'alimento-ipv-frontend-reimbursement-correction-popup',
  templateUrl: './reimbursement-correction-popup.component.html'
})
export class ReimbursementCorrectionPopupComponent implements OnChanges, OnDestroy {
  @Input()
  parentReimbursement: Reimbursement;

  @Input()
  correction?: Reimbursement;

  @Input()
  trainingData: TrainingData;

  @Input()
  extraInfo!: ReimbursementCorrectionInfo;

  @Output()
  formSubmit = new EventEmitter<ReimbursementUpdateEvent>();

  popupVisible = false;
  formGroup!: FormGroup;
  loading = false;

  protected readonly REIMBURSEMENT_CORRECTION = REIMBURSEMENT_CORRECTION;
  protected readonly REIMBURSEMENT_TYPE = REIMBURSEMENT_TYPE;

  private _subscription: Subscription;

  constructor(private fb: FormBuilder,
              private messageService: MyMessageService,
              public enrollmentService: EnrollmentService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['parentReimbursement']?.currentValue || changes['correction']?.currentValue) {
      this.createForm();
    }

    if (changes['extraInfo']?.currentValue && this.formGroup) {
      this._setAmountValidations();
    }
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  openPopup(): void {
    this.popupVisible = true;
    this.createForm();
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.formGroup;
    this._subscription?.unsubscribe();
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    return this.formGroup.valid;
  }

  getFormData(): any {
    const data = JSON.parse(JSON.stringify(this.formGroup.value));
    if (data.adjustmentType === REIMBURSEMENT_CORRECTION.ADD_ADJUSTMENT) {
      data.amount = -data.amount;
    }
    delete data.adjustmentType;
    return data;
  }

  addOrUpdateReimbursement(): void {
    if (!this.isDataValid()) {
      this.messageService.notAllFieldsValid();
      return;
    }

    this.formSubmit.emit({
      id: this.correction?.reimbursementId,
      reimbursement: this.getFormData(),
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  private createForm(): void {
    this.formGroup = this.fb.group({
      reimbursementTypeId: [REIMBURSEMENT_TYPE.CORRECTION],
      correctionReimbursementId: [this.parentReimbursement.reimbursementId],
      adjustmentType: [this.correction && this.correction.amount < 0 ? REIMBURSEMENT_CORRECTION.ADD_ADJUSTMENT : REIMBURSEMENT_CORRECTION.ADD_EXTRA],
      amount: [Math.abs(this.correction?.amount || 0), [Validators.required]],
      remarks: [this.correction?.remarks]
    });

    this._subscription = this.formGroup.get("adjustmentType").valueChanges.subscribe(() => {
      this._setAmountValidations();
    });
    this._setAmountValidations();
  }

  onKeyDown($event: KeyboardEvent, numberControl: InputNumber): void {
    if ($event.key === '-' && numberControl.value && ('' + numberControl.value).indexOf('-') < 0) {
      numberControl.writeValue('-' + numberControl.value);
    }
  }

  private _setAmountValidations(): void {
    const amountControl = this.formGroup.get("amount");
    if (this.formGroup.get("adjustmentType").value === REIMBURSEMENT_CORRECTION.ADD_EXTRA) {
      amountControl.setValidators([Validators.required, Validators.max(this.extraInfo?.maxCorrection), Validators.min(0.01)]);
    }
    else {
      amountControl.setValidators([Validators.required, Validators.max(this.extraInfo?.minCorrection), Validators.min(0.01)]);
    }
    amountControl.updateValueAndValidity();
  }
}
