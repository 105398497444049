<p-dialog
  (blur)="popupVisible = false"
  (onHide)="closePopup()"
  [(visible)]="popupVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [closable]="true"
  [styleClass]="'full-screen-dialog'"
  appendTo="body"
  *ngIf="popupVisible"
>
  <ng-template pTemplate="header">
    <div class="dialog-header-content">
      <span class="p-dialog-title">{{titleObject.title | translate}}</span>
    </div>
  </ng-template>

  <div class="dialog-body-content" [formGroup]="formGroup" *ngIf="formGroup">
    <div>
      <alimento-ipv-frontend-header title='enrollments.popup.branchesTitle'></alimento-ipv-frontend-header>

      <alimento-ipv-frontend-enrollment-request-branch-popup [request]='enrollmentRequest'
                                                             (formSubmit)='updateBranch($event)'
      ></alimento-ipv-frontend-enrollment-request-branch-popup>

      <div *ngIf="formGroup.get('branchId')?.value">
        <div *ngIf="formGroup.get('branchId').value !== NO_BRANCH_ID">
          <alimento-ipv-frontend-branch-search-card [branch]='branch' [selectable]='false'></alimento-ipv-frontend-branch-search-card>
        </div>
        <div *ngIf="formGroup.get('branchId').value === NO_BRANCH_ID">
          <span>{{'enrollments.branch.noBranchSelected' | translate}}</span>
        </div>
        <div class='flex flex-col items-end' *ngIf='!isOpenExternOrCustom'>
          <p-button styleClass='inverted-button'
                    *ngIf='!readOnly'
                    (onClick)='validateBranch()'
                    label='{{"enrollments.branch.selectOther" | translate}}'></p-button>
        </div>
      </div>

      <div *ngIf="!formGroup.get('branchId')?.value">
        <div class="shadow-grey-2 shadow-md my-2 p-4 bg-white relative border border-input-border cursor-pointer flex w-full gap-4">
          <div class='flex justify-between gap-2 w-full'>
            <div class='flex flex-col gap-1'>
              <span class='font-bold text-lg'>{{enrollmentRequest?.branchName}}</span>
              <span>{{"branches.vatNumber" | translate}}: {{enrollmentRequest?.vatNumber | accountNumber}}</span>
            </div>
            <div class='flex gap-1'>
              <span>{{ enrollmentRequest?.branchStreet }}</span>
              <span>{{ enrollmentRequest?.branchNumber }},</span>
              <span *ngIf='enrollmentRequest?.branchMailbox'>bus {{enrollmentRequest?.branchMailbox }}</span>
              <span>{{ enrollmentRequest?.branchPostalCode }}</span>
              <span>{{ enrollmentRequest?.branchCity }}</span>
            </div>
          </div>
          <div>
            <p-button *ngIf="!readOnly"
                      [label]='"enrollments.actions.validate" | translate | uppercase'
                      (onClick)='validateBranch()'
            ></p-button>
          </div>
        </div>
      </div>

      <div *ngIf='isOpenExternOrCustom && reimbursementRequest && formGroup.get("branchId").value !== reimbursementRequest?.branchId'
           class='my-4 p-4 bg-grey-0 border-l-4 border-l-danger flex flex-col items-start'
      >
        <span>{{'enrollments.verify.branchNotMatching' | translate}}</span>
      </div>

      <div class='p-2 conditional-fields' *ngIf='!isOpenExternOrCustom &&
      formGroup.get("branchId")?.value && formGroup.get("branchId").value !== NO_BRANCH_ID'>
        <h3>{{ 'branches.invoicing' | translate }}</h3>

        <div class='pl-2'>
          <div class='pb-4'>
            <p-checkbox
              formControlName="invoicingSame"
              [binary]="true"
              label="{{ 'branches.invoicingSame' | translate }}"
            ></p-checkbox>
          </div>

          <div class="p-4 pb-1 mb-2 bg-grey-1" [hidden]="formGroup.get('invoicingSame')?.value">
            <alimento-ipv-frontend-form-field
              [formGroup]='formGroup'
              formField='invoiceName'
              fieldKey='branches.invoiceName'
              [required]="!formGroup.get('invoicingSame')?.value"
            >
              <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('invoiceName')?.value}}</span>
              <input *ngIf='formGroup.enabled' class="w-full"
                     formControlName="invoiceName" id="invoiceName" pInputText type="text" />
            </alimento-ipv-frontend-form-field>

            <alimento-ipv-frontend-address
              [formControl]="$any(formGroup.get('invoiceAddress'))"
              [optional]="this.formGroup.get('invoicingSame')?.value"
              [readOnly]='formGroup.disabled'
            ></alimento-ipv-frontend-address>
          </div>

          <alimento-ipv-frontend-form-field
            [formGroup]='formGroup'
            [errorTemplate]='emailErrorTemplate'
            formField='invoiceEmail'
            fieldKey='branches.email'
          >
            <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('invoiceEmail')?.value}}</span>
            <input *ngIf='formGroup.enabled' class="w-full"
                   formControlName="invoiceEmail" id="invoiceEmail" pInputText type="text" />
            <ng-template #emailErrorTemplate>
              <small *ngIf="formGroup.get('invoiceEmail')?.hasError('email')">
                {{ 'branches.formErrors.invoiceEmailNotCorrect' | translate }}
              </small>
            </ng-template>
          </alimento-ipv-frontend-form-field>

          <alimento-ipv-frontend-form-field
            [formGroup]='formGroup'
            formField='invoiceReference'
            fieldKey='branches.reference'
          >
            <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('invoiceReference')?.value}}</span>
            <input *ngIf='formGroup.enabled' class="w-full"
                   formControlName="invoiceReference" id="invoiceReference" pInputText type="text" />
          </alimento-ipv-frontend-form-field>
        </div>

        <h3>{{ 'branches.extra' | translate }}</h3>

        <div class="pl-2">
          <label class="block mb-2">{{ 'branches.trainingPlan' | translate }}</label>

          <div class="field-radiobutton mb-1">
            <p-radioButton
              label="{{ 'yes' | translate }}"
              name="trainingPlan"
              [value]="true"
              formControlName="trainingPlan"
              id="training-plan-yes"
            ></p-radioButton>
          </div>

          <div class="field-radiobutton">
            <p-radioButton
              label="{{ 'no' | translate }}"
              name="trainingPlan"
              [value]="false"
              formControlName="trainingPlan"
              id="training-plan-false"
            ></p-radioButton>
          </div>

          <small
            *ngIf="
            (formGroup.get('trainingPlan')?.dirty || formGroup.get('trainingPlan')?.touched) &&
            formGroup.get('trainingPlan')?.hasError('required')
          "
            class="p-error block"
          >
            {{ 'validation.noValueSelected' | translate }}
          </small>

          <span *ngIf='branch?.isFood && trainingPlan' class='italic text-text text-sm'>
          {{'branches.latestTrainingPlan' | translate}} {{trainingPlan.validFrom | date:"d MMMM yyyy"}} - {{trainingPlan.validTo | date:"d MMMM yyyy"}}
        </span>

          <div *ngIf="formGroup.get('trainingPlan')?.value === true" class="mt-4">
            <alimento-ipv-frontend-form-field
              [formGroup]='formGroup'
              [errorTemplate]='dateErrorTemplate'
              formField='trainingPlanDate'
              fieldKey='branches.trainingPlanDate'
            >
              <span *ngIf='formGroup.disabled'>{{formGroup.get('trainingPlanDate')?.value | date:'dd/MM/yyyy' }}</span>
              <div *ngIf='formGroup.enabled' class='max-w-[204px]'>
                <p-calendar
                  [showIcon]="true"
                  [showTime]="false"
                  [showOnFocus]='false'
                  [keepInvalid]='true'
                  appendTo="body"
                  dataType="date"
                  dateFormat="dd/mm/yy"
                  formControlName="trainingPlanDate"
                  id="trainingPlanDate"
                  placeholder="dd/mm/yyyy"
                  styleClass="w-full"
                ></p-calendar>
              </div>
              <ng-template #dateErrorTemplate>
                <small *ngIf='formGroup.get("trainingPlanDate")?.hasError("required")'>
                  {{ 'trainings.sessions.dateRequired' | translate }}
                </small>
                <small *ngIf='formGroup.get("trainingPlanDate").hasError("invalidDate")'>
                  {{ 'validation.invalidDate' | translate }}
                </small>
              </ng-template>
            </alimento-ipv-frontend-form-field>
          </div>
        </div>
      </div>

      <div *ngIf='isOpenExternOrCustom'>
        <div class='grid grid-cols-2'>
          <alimento-ipv-frontend-form-field
            [required]='true'
            fieldKey='enrollments.verify.bankAccountNumber'
          >
            <span class='read-only-field'>{{enrollmentRequest.bankAccountNumber | accountNumber}}</span>
          </alimento-ipv-frontend-form-field>

          <alimento-ipv-frontend-form-field
            [formGroup]='formGroup'
            formField='invoiceReference'
            fieldKey='branches.reference'
          >
            <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('invoiceReference')?.value}}</span>
            <input *ngIf='formGroup.enabled' class="w-full"
                   formControlName="invoiceReference" pInputText type="text" />
          </alimento-ipv-frontend-form-field>
        </div>

        <div *ngIf='isOpenExternOrCustom && reimbursementRequest && showBankAccountNotMatching'
             class='my-4 p-4 bg-grey-0 border-l-4 border-l-danger flex flex-col items-start'
        >
          <span>{{'enrollments.verify.accountNumberNotMatching' | translate}}</span>
          <span>{{'enrollments.verify.bankAccountNumberOnBranch' | translate}}: {{bankAccountNumber | accountNumber}}</span>
        </div>
      </div>
    </div>

    <div *ngIf='formGroup.get("branchId").value'>
      <alimento-ipv-frontend-enrollment-request-participant-validation-popup [participant]='selectedParticipant'
                                                                             [isOpenExternOrCustom]='isOpenExternOrCustom'
                                                                             [groupId]='groupId'
                                                                             [enterpriseId]='enterpriseId'
                                                                  [enrollmentRequest]='enrollmentRequest'
                                                                  (formSubmit)='saveParticipant($event)'
      ></alimento-ipv-frontend-enrollment-request-participant-validation-popup>

      <alimento-ipv-frontend-header title='enrollments.participants.title'></alimento-ipv-frontend-header>

      <div class='flex items-center gap-2' *ngIf='selectedParticipants.length > 0 && !readOnly'>
        <p-button [label]='"enrollments.participants.toSubscribe" | translate'
                  (onClick)='changeParticipantStatus(ENROLLMENT_RESULT_ID.SUBSCRIBE)'
        ></p-button>
        <p-button [label]='"enrollments.participants.toReserve" | translate'
                  (onClick)='changeParticipantStatus(ENROLLMENT_RESULT_ID.RESERVE)'
        ></p-button>
        <p-button [label]='"enrollments.participants.toCancel" | translate'
                  (onClick)='changeParticipantStatus(ENROLLMENT_RESULT_ID.CANCEL)'
        ></p-button>
      </div>

      <ng-container *ngTemplateOutlet='participantsTemplate; context: {type: ENROLLMENT_RESULT_ID.SUBSCRIBE, participants: subscribeParticipants()}'
      ></ng-container>
      <ng-container *ngTemplateOutlet='participantsTemplate; context: {type: ENROLLMENT_RESULT_ID.RESERVE, participants: reserveParticipants()}'
      ></ng-container>
      <ng-container *ngTemplateOutlet='participantsTemplate; context: {type: ENROLLMENT_RESULT_ID.CANCEL, participants: cancelParticipants()}'
      ></ng-container>

      <ng-template #participantsTemplate let-type="type" let-participants="participants">
        <div *ngIf="participants.length > 0">
          <div (click)="collapsed[type] = !collapsed[type]"
               class="flex items-center gap-4 cursor-pointer mt-4">
            <i class="pi pi-angle-{{ !collapsed[type] ? 'up' : 'down' }}"></i>
            <span class="text-lg">{{ 'enrollments.participants.validationResult.' + (getEnrollmentResultKey(type) | lowercase) | translate }} ({{participants.length}})</span>
          </div>
          <div [hidden]="collapsed[type]" class="mt-4">
            <lib-card-list (onSelectionChange)="updateSelectedParticipants($event)"
                           [cardTemplate]="attendeeTemplate"
                           [data]="participants"
                           [multiSelect]="true"
                           [selectable]="!readOnly"
                           [styling]="3"
                           [triggerSelectOnClick]="false"
                           checkboxClass="mt-2"
                           elementClass="hover:bg-primary-hover"
                           noDataText=" "
            >
              <ng-template #attendeeTemplate let-attendee="item">
                <alimento-ipv-frontend-enrollment-request-participant-card [participant]='attendee'
                                                                           [readOnly]='readOnly'
                                                                           [valid]='_participantIsValid(attendee)'
                                                                           (editClicked)='editParticipant(attendee)'
                ></alimento-ipv-frontend-enrollment-request-participant-card>
              </ng-template>
            </lib-card-list>
          </div>
        </div>
      </ng-template>
    </div>

    <div *ngIf="formGroup.get('branchId').value && formGroup.get('branchId').value !== NO_BRANCH_ID">
      <alimento-ipv-frontend-header title='enrollments.contactData'></alimento-ipv-frontend-header>

      <div class='mb-4'>
        <h3>{{'enrollments.contactDataOfEnrollment' | translate}}</h3>
        <div class='flex flex-col gap-1 ml-1'>
          <span>
            {{'enrollments.name' | translate}}: {{enrollmentRequest?.contact1LastName}} {{enrollmentRequest.contact1FirstName}}
          </span>
          <span>
            {{'enrollments.email' | translate}}: {{enrollmentRequest?.contact1Email}}
          </span>
          <span>
            {{'enrollments.phoneNumber' | translate}}: {{enrollmentRequest?.contact1PhoneNumber}}
          </span>
        </div>
      </div>

      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [required]='true'
        formField='contact1Id'
        fieldKey='enrollments.contactPerson'
      >
      <span *ngIf='formGroup.disabled' class='read-only-field'>
        <div *ngIf='contactPerson1ReadOnly'>
         <span>{{ contactPerson1ReadOnly.lastName }} {{ contactPerson1ReadOnly.firstName }}
           <span *ngIf='contactPerson1ReadOnly.isInactive' class='text-danger italic ml-2'>
              {{"trainings.basicData.inactive" | translate}}
            </span>
          </span>
        </div>
      </span>
        <div *ngIf='formGroup.enabled'>
          <p-dropdown
            [filter]="true"
            [options]="contactPersons"
            appendTo="body"
            formControlName="contact1Id"
            id="contact1Id"
            optionLabel="lastName"
            optionValue="personId"
            styleClass="w-full"

            placeholder="{{ 'enrollments.chooseOption' | translate }}"
            [showClear]="true"
          >
            <ng-template let-contactPerson pTemplate="selectedItem">
              <div *ngIf='contactPerson'>
             <span>{{ contactPerson.lastName }} {{ contactPerson.firstName }}
               <span *ngIf='contactPerson.isInactive' class='text-danger italic ml-2'>
                  {{"trainings.basicData.inactive" | translate}}
                </span>
              </span>
              </div>
            </ng-template>
            <ng-template let-contactPerson pTemplate="item">
              <div *ngIf='contactPerson'>
             <span>{{ contactPerson.lastName }} {{ contactPerson.firstName }}
               <span *ngIf='contactPerson.isInactive' class='text-danger italic ml-2'>
                  {{"trainings.basicData.inactive" | translate}}
                </span>
              </span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </alimento-ipv-frontend-form-field>

      <div class="conditional-fields" *ngIf="formGroup.get('contact1Id')?.value">
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          fieldKey='enrollments.email'
        >
          <span class='inputHeight'>{{contactPerson1ReadOnly.email}}</span>
        </alimento-ipv-frontend-form-field>

        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          fieldKey='enrollments.phoneNumber'
        >
          <span class='inputHeight'>{{contactPerson1ReadOnly.phoneNumber}}</span>
        </alimento-ipv-frontend-form-field>

        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [required]="contactNotEditable"
          fieldKey='enrollments.contactResponsibilities'
        >
          <span class='inputHeight'>{{contact1ResponsibilitiesReadOnly}}</span>
        </alimento-ipv-frontend-form-field>
        <div class='mb-2' *ngIf='!contactNotEditable'>{{'enrollments.contactDataNotEditable' | translate}}</div>
      </div>

      <div *ngIf='isOpenExternOrCustom'>
        <div class='mb-4'>
          <h3>{{'enrollments.contactDataOfEnrollment' | translate}} {{'enrollments.verify.contactPerson' | translate}} 2</h3>
          <div class='flex flex-col gap-1 ml-1'>
          <span>
            {{'enrollments.name' | translate}}: {{enrollmentRequest?.contact2LastName}} {{enrollmentRequest.contact2FirstName}}
          </span>
            <span>
            {{'enrollments.email' | translate}}: {{enrollmentRequest?.contact2Email}}
          </span>
            <span>
            {{'enrollments.phoneNumber' | translate}}: {{enrollmentRequest?.contact2PhoneNumber}}
          </span>
          </div>
        </div>

        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          formField='contact2Id'
          fieldKey='enrollments.contactPerson'
        >
      <span *ngIf='formGroup.disabled' class='read-only-field'>
        <div *ngIf='contactPerson2ReadOnly'>
         <span>{{ contactPerson2ReadOnly.lastName }} {{ contactPerson2ReadOnly.firstName }}
           <span *ngIf='contactPerson2ReadOnly.isInactive' class='text-danger italic ml-2'>
              {{"trainings.basicData.inactive" | translate}}
            </span>
          </span>
        </div>
      </span>
          <div *ngIf='formGroup.enabled'>
            <p-dropdown
              [filter]="true"
              [options]="contactPersons"
              appendTo="body"
              formControlName="contact2Id"
              id="contact2Id"
              optionLabel="lastName"
              optionValue="personId"
              styleClass="w-full"

              placeholder="{{ 'enrollments.chooseOption' | translate }}"
              [showClear]="true"
            >
              <ng-template let-contactPerson pTemplate="selectedItem">
                <div *ngIf='contactPerson'>
             <span>{{ contactPerson.lastName }} {{ contactPerson.firstName }}
               <span *ngIf='contactPerson.isInactive' class='text-danger italic ml-2'>
                  {{"trainings.basicData.inactive" | translate}}
                </span>
              </span>
                </div>
              </ng-template>
              <ng-template let-contactPerson pTemplate="item">
                <div *ngIf='contactPerson'>
             <span>{{ contactPerson.lastName }} {{ contactPerson.firstName }}
               <span *ngIf='contactPerson.isInactive' class='text-danger italic ml-2'>
                  {{"trainings.basicData.inactive" | translate}}
                </span>
              </span>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
        </alimento-ipv-frontend-form-field>

        <div class="conditional-fields" *ngIf="formGroup.get('contact2Id')?.value">
          <alimento-ipv-frontend-form-field
            [formGroup]='formGroup'
            fieldKey='enrollments.email'
          >
            <span class='inputHeight'>{{contactPerson2ReadOnly.email}}</span>
          </alimento-ipv-frontend-form-field>

          <alimento-ipv-frontend-form-field
            [formGroup]='formGroup'
            fieldKey='enrollments.phoneNumber'
          >
            <span class='inputHeight'>{{contactPerson2ReadOnly.phoneNumber}}</span>
          </alimento-ipv-frontend-form-field>

          <alimento-ipv-frontend-form-field
            [formGroup]='formGroup'
            [required]="contactNotEditable"
            fieldKey='enrollments.contactResponsibilities'
          >
            <span class='inputHeight'>{{contact2ResponsibilitiesReadOnly}}</span>
          </alimento-ipv-frontend-form-field>
          <div class='mb-2' *ngIf='!contactNotEditable'>{{'enrollments.contactDataNotEditable' | translate}}</div>
        </div>
      </div>
    </div>

    <div>
      <alimento-ipv-frontend-header title='enrollments.comments'></alimento-ipv-frontend-header>

      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        fieldKey='enrollments.comments'
        formField='remarks'
      >
        <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('remarks')?.value}}</span>
        <div *ngIf='formGroup.enabled'>
          <textarea class='w-full'
                    cols='30'
                    formControlName='remarks'
                    id='comments'
                    pInputTextarea
                    rows='5'
          ></textarea>
        </div>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field
        *ngIf='!isOpenExternOrCustom'
        [formGroup]='formGroup'
        [required]='true'
        fieldKey='enrollments.enrollmentCost'
      >
        <span class='read-only-field'>{{totalCost() | currency:"EUR"}}</span>
      </alimento-ipv-frontend-form-field>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="dialog-footer-content">
      <p-button
        (onClick)="closePopup()"
        icon="pi pi-times"
        iconPos="left"
        label="{{ 'trainings.sessions.cancel' | translate }}"
        styleClass="inverted-button"
      ></p-button>

      <div *ngIf='!readOnly'>
        <p-button
          (onClick)="save()"
          [loading]="loading"
          label="{{ 'enrollments.request.save' | translate}}"
        ></p-button>
        <p-button
          (onClick)="submit()"
          [loading]="loading"
          label="{{ titleObject.complete | translate}}"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>
