<div [ngClass]='{"shadow-menu": appMenuOpen}' class='app-menu'>
  <div (click)='toggleAppMenu()'
       class='h-16 w-16 relative bg-white text-primary flex justify-center items-center cursor-pointer group'>
    <span class='material-symbols-outlined group-hover:bg-primary-hover group-hover:text-primary-button-hover p-2'>
      apps
    </span>
    <span *ngIf='totalCount > 0' class='absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white
    bg-alimento-red border-2 border-white rounded-full top-2 right-0'>{{totalCount}}</span>
  </div>

  <div *ngIf='appMenuOpen'
       class='app-menu-content absolute min-w-[200px] right-[112px] z-50 p-4 flex flex-col bg-white shadow-menu '>
    <div
      (click)='triggerOnClickAction(menuItem, $event.ctrlKey)'
      *ngFor='let menuItem of menuItems'
      class='text-primary flex justify-between items-center cursor-pointer p-2 font-bold hover:bg-primary-hover'
    >
      <div class='flex gap-2 items-center'>
        <span class='material-symbols-outlined'>{{ menuItem.icon }}</span>
        <span>{{ menuItem.label }}</span>
      </div>
      <span *ngIf='menuItem.badge > 0' class='inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white
    bg-alimento-red border-2 border-white rounded-full top-2 right-0'>{{menuItem.badge}}</span>
    </div>
  </div>
</div>
