<alimento-ipv-frontend-transition-popup
  #transitionPopupComponent
  [transitionAction]='currentTransitionAction'
  (formSubmit)="onTransitionPopupSubmit($event, transitionPopupComponent)"
  [person]='person'
></alimento-ipv-frontend-transition-popup>

<alimento-ipv-frontend-transition-action-dialog [transition]='currentTransition'
                                                [action]='currentTransitionAction'
                                                (submitDialog)='executeTransitionAction($event)'
></alimento-ipv-frontend-transition-action-dialog>

<alimento-ipv-frontend-header title="{{ 'persons.transitionsTitle' | translate }}">
  <p-button
    *ngIf='!readOnly'
    (onClick)="addTransition(transitionPopupComponent)"
    label="{{ 'persons.transitions.actions.add' | translate | uppercase }}"
  ></p-button>
</alimento-ipv-frontend-header>

<div class="employments-list" *ngIf="groupedTransitions">
  <p-tabView styleClass="employments-tabview">
    <p-tabPanel *ngIf='getTransitions(TRANSITION_STATE.DRAFT) as draftTransitions'
                header="{{ 'persons.transitions.draftTransitions' | translate }} ({{ getTransitionsCount(draftTransitions) }})">
      <ng-container *ngTemplateOutlet='transitionsTemplate; context: {transitions: draftTransitions}'></ng-container>
    </p-tabPanel>

    <p-tabPanel *ngIf='getTransitions(TRANSITION_STATE.ACTIVE) as activeTransitions'
                header="{{ 'persons.transitions.activeTransitions' | translate }} ({{ getTransitionsCount(activeTransitions) }})">
      <ng-container *ngTemplateOutlet='transitionsTemplate; context: {transitions: activeTransitions}'></ng-container>
    </p-tabPanel>

    <p-tabPanel *ngIf='getTransitions(TRANSITION_STATE.REJECTED) as rejectedTransitions'
                header="{{ 'persons.transitions.rejectedTransitions' | translate }} ({{ getTransitionsCount(rejectedTransitions) }})">
      <ng-container *ngTemplateOutlet='transitionsTemplate; context: {transitions: rejectedTransitions}'></ng-container>
    </p-tabPanel>

    <p-tabPanel *ngIf='getTransitions(TRANSITION_STATE.CLOSED) as closedTransitions'
                header="{{ 'persons.transitions.closedTransitions' | translate }} ({{ getTransitionsCount(closedTransitions) }})">
      <ng-container *ngTemplateOutlet='transitionsTemplate; context: {transitions: closedTransitions}'></ng-container>
    </p-tabPanel>
  </p-tabView>

  <ng-template #transitionsTemplate let-transitions="transitions">
    <div class="mt-4 flex flex-col gap-4">
      <div *ngFor='let branchTransition of transitions'>
        <div (click)='toggleCollapse(branchTransition.branchName)' class='flex items-center gap-2 cursor-pointer'>
          <i class="pi pi-angle-{{ !collapsed[branchTransition.branchName] ? 'up' : 'down' }}"></i>
          <span class='font-bold text-lg'>{{branchTransition.branchName}}</span>
        </div>
        <div *ngIf='!collapsed[branchTransition.branchName]'>
          <alimento-ipv-frontend-transition-card *ngFor='let transition of branchTransition.transitions'
                                                 (actionClicked)='transitionAction($event, transitionPopupComponent)'
                                                 [readOnly]='readOnly'
                                                 [transition]='transition'>
          </alimento-ipv-frontend-transition-card>
        </div>
      </div>

      <div class="" *ngIf="transitions.length === 0">
        <span>{{ 'persons.transitions.noTransitions' | translate }}</span>
      </div>
    </div>
  </ng-template>
</div>
