import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first, Subscription } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class TitleService implements OnDestroy {
  private _routerEventSub: Subscription;
  private readonly titleSuffix = "Dali";

  constructor(private router: Router,
              private title: Title,
              private translateService: TranslateService) {
  }

  init(): void {
    this._routerEventSub = this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd && event.snapshot.component) {
        if (event.snapshot.data['title']) {
          this.translateService.get(event.snapshot.data['title']).pipe(first())
            .subscribe(title => this.setTitle([title]));
        }
        else {
          this.setTitle([]);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this._routerEventSub?.unsubscribe();
  }

  setTitle(title: string[]): void {
    title.push(this.titleSuffix);
    this.title.setTitle(title.join(" | "));
  }
}
