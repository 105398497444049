import { Component, Input, TemplateRef } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'alimento-ipv-frontend-form-field',
  templateUrl: './form-field.component.html',
})
export class FormFieldComponent {
  @Input()
  formInputTemplate: TemplateRef<any>;

  @Input()
  fieldKey: string;

  @Input()
  formField: string;

  @Input()
  formGroup: FormGroup;

  @Input()
  required = false;

  @Input()
  labelTemplate: TemplateRef<any>;

  @Input()
  errorTemplate: TemplateRef<any>;

  @Input()
  formInputClass: string = '';

  @Input()
  formGroupClass: string = '';

  @Input()
  labelClass: string = '';

  @Input()
  hideError = false;

  @Input()
  alwaysShowError = false;

  @Input()
  includeTouchedASInvalid = true;

  isFieldInvalid(): boolean {
    if (this.formField && this.formGroup) {
      return (!this.formGroup.get(this.formField).disabled && !this.formGroup.get(this.formField).valid) &&
        (!this.includeTouchedASInvalid || this.formGroup.get(this.formField).touched);
    }
    return false;
  }
}
