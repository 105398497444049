<div class="items-center gap-2 grid grid-cols-[minmax(0,_1fr)_minmax(0,_1fr)]">
  <div isEllipsis pTooltip>
    <span *ngIf="office">{{ office.name }}</span>
    <span *ngIf="!office" class="font-bold">{{ 'interim-offices.officeName' | translate }}</span>
  </div>

  <div isEllipsis pTooltip>
    <span *ngIf="office">{{ office.street }} {{office.number}}, {{office.city}}</span>
    <span *ngIf="!office" class="font-bold">{{ 'interim-offices.address' | translate }}</span>
  </div>
</div>
