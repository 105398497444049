import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import {
  DateMapper,
  dateValid,
  IFile,
  minLengthArray,
  validateAllFormFields,
  validateMinValue
} from '@alimento-ipv-frontend/ui-lib';
import { TrainingPlan, TrainingPlanUpdateEvent } from '../../../types/branch.type';

@Component({
  selector: 'alimento-ipv-frontend-training-plan-popup',
  templateUrl: './training-plan-popup.component.html'
})
export class TrainingPlanPopupComponent implements OnChanges, OnDestroy {
  @Input()
  trainingPlan?: TrainingPlan;

  @Output()
  formSubmit = new EventEmitter<TrainingPlanUpdateEvent>();

  popupVisible = false;
  formGroup!: FormGroup;
  loading = false;
  files: IFile[] = [];

  private _subscriptions: (Subscription | undefined)[] = [];

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['trainingPlan']?.currentValue) {
      this.createForm();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription?.unsubscribe());
  }

  openPopup(): void {
    this.popupVisible = true;
    this.createForm();
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.formGroup;
    this._subscriptions.forEach(sub => sub?.unsubscribe());
    this._subscriptions = [];
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    return this.formGroup.valid && this.files.length >= 1;
  }

  getFormData(): any {
    const data = JSON.parse(JSON.stringify(this.formGroup.value));

    data.validFrom = DateMapper.getDateFromDateTimeAsString(data.validFrom);
    data.validTo = DateMapper.getDateFromDateTimeAsString(data.validTo);
    data.due = DateMapper.getDateFromDateTimeAsString(data.due);


    delete data.files;

    const formData: any = new FormData();
    Object.keys(data)
      .filter(key => (data as any)[key])
      .forEach(key => formData.append(key, (data as any)[key]));

    this.files.filter(file => file.data)
      .forEach(file => formData.append('files', file.data));

    this.files.filter(file => !file.data)
      .forEach(file => formData.append('filesToKeep', file.id));

    return formData;
  }

  addOrUpdateTrainingPlan(): void {
    if (!this.isDataValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('trainings.sessions.hasErrors')
      });
      return;
    }

    this.formSubmit.emit({
      id: this.trainingPlan.id,
      trainingPlan: this.getFormData(),
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  private createForm() {
    const validFrom = this.fb.control(
      this.trainingPlan?.validFrom ? new Date(this.trainingPlan.validFrom) : undefined,
      [Validators.required, dateValid()]);

    this.formGroup = this.fb.group({
      id: [this.trainingPlan?.id],
      validFrom: validFrom,
      validTo: [this.trainingPlan?.validTo ? new Date(this.trainingPlan.validTo) : undefined,
        [Validators.required, validateMinValue(validFrom), dateValid()]],
      due: [this.trainingPlan?.due ? new Date(this.trainingPlan.due) : undefined, [dateValid()]],
      moreThan20Employees: [this.trainingPlan?.moreThan20Employees || false],
      validReceptionPolicy: [this.trainingPlan?.validReceptionPolicy || false],
      validConsultativeBody: [this.trainingPlan?.validConsultativeBody || false],
      consultedSyndic: [this.trainingPlan?.consultedSyndic || false],
      comments: [this.trainingPlan?.comments],
      files: [this.trainingPlan?.files || [], minLengthArray(1)]
    });

    this.files = this.trainingPlan?.files || [];

    this._addSubscriptions();
    this._updateBooleanFields(this.trainingPlan?.validReceptionPolicy);
  }

  filesChange(files: IFile[]) {
    this.formGroup.get('files').setValue(files);
  }

  private _addSubscriptions(): void {
    this._subscriptions.push(
      this.formGroup.get('validFrom')?.valueChanges.subscribe(() => {
        this.formGroup.get('validTo')?.updateValueAndValidity();
      })
    );

    this._subscriptions.push(
      this.formGroup.get('validConsultativeBody')?.valueChanges.subscribe((newValue) => {
        this._updateBooleanFields(newValue);
      })
    );
  }

  private _updateBooleanFields(validReceptionPolicy: boolean): void {
    if (validReceptionPolicy) {
      this.formGroup.get('validReceptionPolicy').enable();
      this.formGroup.get('consultedSyndic').enable();
    }
    else {
      this.formGroup.get('validReceptionPolicy').disable();
      this.formGroup.get('validReceptionPolicy').setValue(false);
      this.formGroup.get('consultedSyndic').disable();
      this.formGroup.get('consultedSyndic').setValue(false);
    }
  }
}
