import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { filter, first, Subscription, switchMap } from 'rxjs';
import { EnrollmentExtraFormComponent } from '../enrollment-extra-form/enrollment-extra-form.component';
import { EnrollmentBranchFormComponent } from '../enrollment-branch-form/enrollment-branch-form.component';
import { DateMapper, FormComponent } from '@alimento-ipv-frontend/ui-lib';
import { EnrollmentCommentsFormComponent } from '../enrollment-comments-form/enrollment-comments-form.component';
import { Person, SearchPersonItem } from '../../../types/person.type';
import { ENROLLMENT_VIA } from '../../../types/enrollment.enum';
import {
  EnrollmentActionEnum,
  EnrollmentBody,
  EnrollmentDetail,
  EnrollmentUpdateEvent,
  ExtraMailResponse
} from '../../../types/enrollment.type';
import { Training } from '../../../types/training.type';
import { PersonSelectComponent } from '../../../persons/components/person-select/person-select.component';
import { PersonService } from '../../../services/person.service';
import { EnrollmentService } from '../../../services/enrollment.service';

@Component({
  selector: 'alimento-ipv-frontend-enrollment-popup',
  templateUrl: './enrollment-popup.component.html',
  styleUrls: ['./enrollment-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnrollmentPopupComponent implements OnDestroy {
  popupVisible = false;
  activeIndex = 0;
  stepsItems: MenuItem[] = [];
  formGroup!: FormGroup;
  loading = false;

  persons: SearchPersonItem[] = [];
  person?: Person;
  searchPersonFormControl = new FormControl<any>(undefined);
  branchId!: string;
  enrollmentVia: ENROLLMENT_VIA;
  currentPerson!: Person;

  enrollment?: EnrollmentDetail;
  enrollmentActionEnum?: EnrollmentActionEnum;

  personFormIndex = 0;
  extraMailResponse: ExtraMailResponse;

  @Input()
  training: Training;

  @Input()
  readOnly = false;

  @Output()
  formSubmit = new EventEmitter<EnrollmentUpdateEvent>();

  @ViewChildren(FormComponent)
  subForms!: FormComponent[];

  @ViewChild(PersonSelectComponent)
  personSelectComponent: PersonSelectComponent;

  protected readonly ENROLLMENT_VIA = ENROLLMENT_VIA;

  private _subscriptions: Subscription[] = [];
  private readonly NO_STYLE = 'no-style';
  private readonly EDIT_STYLE = 'edit-style';
  private readonly COMPLETE_STYLE = 'complete-style';
  private _titleMap: Map<any, any>;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private translateService: TranslateService,
    private personService: PersonService,
    private enrollmentService: EnrollmentService
  ) {
    this._createTitleMap();
    this.createFormGroup();
    this._subscriptions.push(
      this.searchPersonFormControl.valueChanges
        .pipe(
          filter((person) => person),
          switchMap((person: SearchPersonItem) => this.personService.getPerson(person.personId))
        )
        .subscribe((person: Person) => {
          this.person = person;
        })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  private createStepsItems(): MenuItem[] {
    return [
      { id: 'branchForm', label: '&nbsp', escape: false, styleClass: this.EDIT_STYLE, disabled: true },
      { id: 'personForm', label: '&nbsp', escape: false, styleClass: this.NO_STYLE, disabled: true },
      { id: 'extraForm', label: '&nbsp', escape: false, styleClass: this.NO_STYLE, disabled: true },
      { id: 'commentsForm', label: '&nbsp', escape: false, styleClass: this.NO_STYLE, disabled: true }
    ];
  }

  private createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      personForm: [],
      branchForm: [],
      extraForm: [],
      commentsForm: []
    });
  }

  private _createTitleMap(): void {
    this._titleMap = new Map();
    this._titleMap.set(EnrollmentActionEnum.view, {
      title: 'enrollments.viewEnrollment',
      complete: 'enrollments.close'
    });
    this._titleMap.set(EnrollmentActionEnum.create, {
      title: 'enrollments.addEnrollment',
      complete: 'enrollments.completeAddEnrollment'
    });
    this._titleMap.set(EnrollmentActionEnum.edit, {
      title: 'enrollments.editEnrollment',
      complete: 'enrollments.completeEditEnrollment'
    });
    this._titleMap.set(EnrollmentActionEnum.reEnroll, {
      title: 'enrollments.reEnrollParticipant',
      complete: 'enrollments.reEnrollParticipant'
    });
    this._titleMap.set(EnrollmentActionEnum.addToReserve, {
      title: 'enrollments.addParticipantAsBackup',
      complete: 'enrollments.addParticipantAsBackup'
    });
  }

  get titleObject(): { title: string, complete: string } {
    return this._titleMap.get(this.enrollmentActionEnum);
  }

  openPopup(enrollment: EnrollmentDetail | undefined, enrollmentActionEnum: EnrollmentActionEnum): void {
    this.activeIndex = 0;
    this.personFormIndex = 0;
    this.enrollment = enrollment;
    this.enrollmentActionEnum = enrollmentActionEnum;
    this.stepsItems = this.createStepsItems();
    this.formGroup.reset();
    this.searchPersonFormControl.reset();

    if (enrollment) {
      this.searchPersonFormControl.setValue(enrollment.person, { emitEvent: false });
      this.person = enrollment.person as unknown as Person;

      this.formGroup.patchValue({
        branchForm: EnrollmentBranchFormComponent.createFormData(enrollment),
        personForm: { ...enrollment.person },
        extraForm: EnrollmentExtraFormComponent.createFormData(enrollment),
        commentsForm: EnrollmentCommentsFormComponent.createFormData(enrollment)
      });

      if (this.readOnly) {
        this.formGroup.disable();
      }
      else {
        this.formGroup.enable();
      }
    }
    this.popupVisible = true;

    setTimeout(() => {
      const { form } = this._getActiveTabAndFormGroup();
      form?.setFocus();
    });
  }

  closePopup(): void {
    this.currentPerson = undefined;
    this.branchId = undefined;
    this.person = undefined;
    this.popupVisible = false;
    this.personSelectComponent?.clear();
  }

  goToPreviousStep(): void {
    if (this.activeIndex > 0) {
      this.stepsItems[this.activeIndex].styleClass = this.NO_STYLE;
      this.activeIndex--;
      this.stepsItems[this.activeIndex].styleClass = this.EDIT_STYLE;
    }
    else {
      this.closePopup();
    }
  }

  private _getActiveTabAndFormGroup(): { activeTab: string, form: FormComponent } {
    const activeTab: string = this.stepsItems[this.activeIndex].id || '';
    const subForm: FormComponent = this.subForms.filter((form) => form.name === activeTab)[
      activeTab === 'personForm' ? this.personFormIndex : 0
      ];
    return { activeTab: activeTab, form: subForm };
  }

  goToNextStep(): void {
    this.stepsItems[this.activeIndex].styleClass = this.COMPLETE_STYLE;

    const { activeTab, form } = this._getActiveTabAndFormGroup();

    this.enrollmentVia = this.formGroup.get('branchForm')?.value?.enrollmentViaId;
    this.branchId = this.formGroup.get('branchForm')?.value?.branch?.branchId;
    if (activeTab === 'personForm') {
      this.currentPerson = this.formGroup.get('personForm')?.value as Person;
    }

    if (!form || !form.isValid()) {
      this.showInvalidFormErrorToast();
      return;
    }

    if (this.activeIndex < this.stepsItems.length - 1) {
      this.activeIndex++;
      this.stepsItems[this.activeIndex].styleClass = this.EDIT_STYLE;

      const { activeTab, form } = this._getActiveTabAndFormGroup();
      if (activeTab === 'personForm') {
        this.personSelectComponent?.focus();
      }
      else {
        form?.setFocus();
      }
      if (activeTab === 'commentsForm') {
        this._calculateExtraMail();
      }
    }
    else {
      this.addOrUpdateEnrollment();
    }
  }

  private showInvalidFormErrorToast() {
    this.messageService.add({
      severity: 'error',
      detail: this.translateService.instant('enrollments.hasErrors')
    });
  }

  private addOrUpdateEnrollment() {
    this.formSubmit.emit({
      enrollmentId: this.enrollment?.enrollment?.enrollmentId,
      enrollment: this._getEnrollmentBody(),
      action: this.enrollmentActionEnum,
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  private _getEnrollmentBody(): EnrollmentBody {
    const data = this.formGroup.value;
    return {
      person: {
        ...data.personForm,
        dateOfBirth: DateMapper.getDateFromDateTimeAsString(data.personForm.dateOfBirth)
      },
      enrollment: {
        ...data.branchForm,
        branchId: data.branchForm.branch?.branchId,
        trainingPlanDate: DateMapper.getDateFromDateTimeAsString(data.branchForm.trainingPlanDate),
        ...data.extraForm.enrollment,
        ...data.commentsForm
      },
      employment: data.extraForm?.employment
    };
  }

  private _calculateExtraMail(): void {
    const enrollmentBody = this._getEnrollmentBody();
    this.enrollmentService.willTriggerExtraMail(
      this.training.trainingId,
      enrollmentBody.enrollment?.workStatus,
      enrollmentBody.person?.dateOfBirth,
      enrollmentBody.employment?.employmentId,
      enrollmentBody.enrollment?.branchId,
      enrollmentBody.person?.personId
    ).pipe(first())
      .subscribe(extraMailResponse => this.extraMailResponse = extraMailResponse);
  }

  public clearForm(event: any) {
    if (event.index === 1) {
      this.person = {} as Person;
    }
    else if (event.index === 0) {
      this.searchPersonFormControl.reset();
    }
  }
}
