<ng-template #itemTemplate let-item="item">
  <i *ngIf="item.completed" class="pi pi-check mr-2"></i>
  <i *ngIf="item.errors" class="pi pi-exclamation-triangle mr-2"></i>
  <span class='text-text'>
       {{ item.title }}
    </span>
  <ng-container *ngIf="item.count && item.maxCount === undefined">
    <span class='text-text' *ngIf="item.count() > 0">({{ item.count() }})</span>
  </ng-container>
  <ng-container *ngIf="item.count && item.maxCount !== undefined">
    <span class='text-text'>({{ item.count() }}/{{ item.maxCount }})</span>
  </ng-container>
</ng-template>

<div *ngIf='data?.length > 0' class='mb-4'>
  <span class='text-grey-4 text-sm uppercase'>{{'data' | translate}}</span>
  <ul>
    <li *ngFor='let item of data'
        (click)="activateTab(item)"
        (keyup.enter)="activateTab(item)"
        tabindex="0"
        class="cursor-pointer px-4 py-2 focus:font-bold focus:pl-6 hover:bg-primary-hover"
        [ngClass]="[
          item.index === activeTabIndex ? 'font-bold border-l-2 border-l-primary' : '',
          item.disabled ? 'cursor-default opacity-25 hover:bg-none focus:font-normal focus:pl-4' : ''
        ]"
    >
      <ng-container *ngTemplateOutlet="itemTemplate; context: {item: item}"></ng-container>
    </li>
  </ul>
</div>

<div *ngIf='extra?.length > 0'>
  <span class='text-grey-4 text-sm uppercase'>{{'extra' | translate}}</span>
  <ul>
    <li *ngFor='let item of extra'
        (click)="clickTab(item)"
        (keyup.enter)="clickTab(item)"
        tabindex="0"
        class="cursor-pointer px-4 py-2 focus:font-bold focus:pl-6 hover:bg-primary-hover"
        [ngClass]="[
          (item.disabled || (item.count && item.count() === -1)) ? 'cursor-default opacity-25 hover:bg-none focus:font-normal focus:pl-4' : ''
        ]"
    >
      <ng-container *ngTemplateOutlet="itemTemplate; context: {item: item}"></ng-container>
    </li>
  </ul>
</div>


