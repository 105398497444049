import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslationService } from '@alimento-ipv-frontend/ui-lib';
import { ACCEPT_LANGUAGE } from '@alimento-ipv-frontend/application-lib';

@Injectable()
export class AcceptLanguageInterceptor implements HttpInterceptor {
  constructor(private readonly injector: Injector) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    try {
      if (!request.headers.keys().includes(ACCEPT_LANGUAGE)) {
        const modifiedReq = request.clone({
          headers: request.headers.set(ACCEPT_LANGUAGE, this.injector.get(TranslationService).getCurrentLanguage())
        });
        return next.handle(modifiedReq);
      }
      else {
        return next.handle(request);
      }
    }
    catch {
      return next.handle(request);
    }
  }
}
