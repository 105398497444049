import { Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { first, Observable, Subscription } from 'rxjs';
import {
  FilterType,
  InfiniteScrollDataAdapter,
  LazyDropdownComponent,
  PaginatedResponse,
  SearchRequest
} from '@alimento-ipv-frontend/ui-lib';
import { SearchesService } from '../../../services/searches.service';
import { EmploymentSearchItem } from '../../../types/searches.type';
import { EmploymentService } from '../../../services/employment.service';
import { Employment } from '../../../types/person.type';

@Component({
  selector: 'alimento-ipv-frontend-employment-select',
  templateUrl: './employment-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmploymentSelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmploymentSelectComponent),
      multi: true
    }
  ]
})
export class EmploymentSelectComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input()
  formControl: FormControl<Employment> = new FormControl<Employment>(undefined);

  @Input({ required: true })
  branchId: string;

  private _subscriptions: Subscription[] = [];
  private NUMBER_OF_LOADED_ITEMS = 30;
  protected readonly FilterType = FilterType;

  data$: InfiniteScrollDataAdapter<EmploymentSearchItem>;

  @ViewChild(LazyDropdownComponent)
  private dropdownComponent: LazyDropdownComponent;

  get value(): Employment {
    return this.formControl.value;
  }

  set value(value: Employment) {
    this.formControl.setValue(value);
    this.onChange(value);
    this.onTouched();
  }

  constructor(private employmentService: EmploymentService,
              private searchesService: SearchesService) {
    this._subscriptions.push(
      this.formControl.valueChanges.subscribe((value) => {
        this.onChange(value?.employmentId);
        this.onTouched();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['branchId']?.currentValue) {
      this._createDataSource();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  focus(): void {
    setTimeout(() => this.dropdownComponent.setFocus());
  }

  getSelectedString = (item: EmploymentSearchItem) =>
    (`${item.lastName} ${item.firstName}` + (item.function ? ` (${item.function})` : ''));

  _createDataSource(): void {
    const searchRequest: any = {
      first: 0,
      rows: this.NUMBER_OF_LOADED_ITEMS,
      filters: []
    };

    searchRequest.filters.push({ type: FilterType.isActiveEmployment, values: ['true'] });
    searchRequest.filters.push({ type: FilterType.filterBranch, values: [this.branchId] });

    this.data$ = new InfiniteScrollDataAdapter<EmploymentSearchItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<EmploymentSearchItem>> => {
      return this.searchesService.searchEmployments(searchRequest);
    }, searchRequest, true);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: any = () => {
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: any = () => {
  };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  writeValue(value: any) {
    if (value && (typeof value === 'string')) {
      this.employmentService
        .getEmployment(value)
        .pipe(first())
        .subscribe((employment) => this.formControl.setValue(employment, { emitEvent: false }));
    }
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  validate() {
    return this.formControl.valid ? null : { employment: { valid: false } };
  }

  clear(): void {
    this.formControl.setValue(null);
  }
}
