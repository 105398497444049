import { Component, EventEmitter, OnDestroy, Output, Signal } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommunicateChangesEvent } from '../../../types/training.type';
import { EnrollmentService } from '../../../services/enrollment.service';
import { EnrollmentListItem } from '../../../types/enrollment.type';
import { Subscription } from 'rxjs';

@Component({
  selector: 'alimento-ipv-frontend-communicate-changes-dialog',
  templateUrl: './communicate-changes-dialog.component.html'
})
export class CommunicateChangesDialogComponent implements OnDestroy {

  @Output()
  submitPopup = new EventEmitter<CommunicateChangesEvent>();

  loading = false;
  dialogVisible = false;
  formGroup: FormGroup;
  enrollments: Signal<EnrollmentListItem[]> = this.enrollmentService.activeEnrollments;
  nrOfEnrollments = 0;

  private _subscriptions: Subscription[] = [];

  constructor(private fb: FormBuilder, private enrollmentService: EnrollmentService) {
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(sub => sub.unsubscribe());
  }

  open() {
    this._createFormGroup();
    this.dialogVisible = true;
  }

  submit() {
    this.submitPopup.emit({
      data: JSON.parse(JSON.stringify(this.formGroup.value)),
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.formGroup.reset({ enrollmentIds: [] });
  }

  private _createFormGroup(): void {
    this.formGroup = this.fb.group({
      enrollmentIds: [[]],
      comments: [null],
    });

    this._subscriptions.push(
      this.formGroup.get('enrollmentIds').valueChanges.subscribe(newValue => {
        if (newValue) {
          this.nrOfEnrollments = newValue.length;
        }
      })
    );
  }

  getEnrollmentsLabel = (participant: EnrollmentListItem) => {
    return participant.lastName + ' ' + participant.firstName;
  };
}
