import { Component, EventEmitter, Input, Output, Signal } from '@angular/core';
import {
  FinancialDocument,
  FinancialDocumentAction,
  FinancialDocumentUpdateEvent, TrainingData
} from '../../../types/reimbursement-request.type';
import { FinancialDocumentPopupComponent } from '../financial-document-popup/financial-document-popup.component';
import { FINANCIAL_DOCUMENT_ACTION_ENUM } from '../../../types/reimbursement-request.enum';
import { first } from 'rxjs';
import { MyMessageService } from '@alimento-ipv-frontend/ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ReimbursementRequestService } from '../../../services/reimbursement-request.service';

@Component({
  selector: 'alimento-ipv-frontend-financial-documents-overview',
  templateUrl: './financial-documents-overview.component.html'
})
export class FinancialDocumentsOverviewComponent {

  @Input()
  trainingId: string;

  @Input()
  readOnly: boolean;

  @Input()
  financialDocuments: Signal<FinancialDocument[]>;

  @Output()
  changes: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  trainingData: Signal<TrainingData>;

  currentFinancialDocument: FinancialDocument;
  currentFinancialDocumentAction: FINANCIAL_DOCUMENT_ACTION_ENUM;

  constructor(private messageService: MyMessageService,
              private translateService: TranslateService,
              private confirmationService: ConfirmationService,
              private reimbursementRequestService: ReimbursementRequestService) {

  }

  addFinancialDocument(financialDocumentPopupComponent: FinancialDocumentPopupComponent): void {
    this.currentFinancialDocument = {} as FinancialDocument;
    financialDocumentPopupComponent.openPopup();
  }

  financialDocumentActionClicked(event: FinancialDocumentAction, financialDocumentPopupComponent: FinancialDocumentPopupComponent): void {
    this.currentFinancialDocument = JSON.parse(JSON.stringify(event.financialDocument));
    this.currentFinancialDocumentAction = event.action;
    if (event.action === FINANCIAL_DOCUMENT_ACTION_ENUM.edit) {
      financialDocumentPopupComponent.openPopup();
    }
    else if (event.action === FINANCIAL_DOCUMENT_ACTION_ENUM.delete) {
      this._deleteFinancialDocument(event.financialDocument);
    }
  }

  financialDocumentSubmit(event: FinancialDocumentUpdateEvent, financialDocumentPopupComponent: FinancialDocumentPopupComponent): void {
    event.setLoading(true);

    const createOrUpdateFinancialDocument$ = event.id
      ? this.reimbursementRequestService.updateFinancialDocument(event.id, event.financialDocument)
      : this.reimbursementRequestService.createFinancialDocument(this.trainingId, event.financialDocument);

    createOrUpdateFinancialDocument$.pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success('financialDocuments.saved');
          this.changes.emit();
          event.setLoading(false);
          financialDocumentPopupComponent.closePopup();
        },
        error: () => {
          event.setLoading(false);
        }
      });
  }

  private _deleteFinancialDocument(financialDocument: FinancialDocument): void {
    this.confirmationService.confirm({
      header: this.translateService.instant('financialDocuments.deleteTitle'),
      message: this.translateService.instant('financialDocuments.deleteMessage'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translateService.instant('financialDocuments.deleteTitle'),
      acceptIcon: 'hidden',
      rejectLabel: this.translateService.instant('financialDocuments.cancel'),
      rejectButtonStyleClass: 'inverted-button',
      accept: () => {
        this.reimbursementRequestService.deleteFinancialDocument(financialDocument.id).pipe(first())
          .subscribe(() => {
            this.messageService.success('financialDocuments.deleted');
            this.changes.emit();
          });
      }
    });
  }
}
