<p-dialog
  (blur)="popupVisible = false"
  (onHide)="closePopup()"
  [(visible)]="popupVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [closable]="true"
  [styleClass]="'full-screen-dialog'"
  appendTo="body"
  *ngIf="popupVisible"
>
  <ng-template pTemplate="header">
    <div class="dialog-header-content">
      <span class="p-dialog-title">{{titleObject.title | translate}}</span>
    </div>
  </ng-template>

  <div class="dialog-steps-container">
    <p-steps
      [model]="stepsItems"
      styleClass="enrollment-steps"
      [readonly]="true"
      [(activeIndex)]="activeIndex"
    ></p-steps>
  </div>

  <form class="dialog-body-content" [formGroup]="formGroup" *ngIf="formGroup">
    <div [hidden]="activeIndex !== 0">
      <alimento-ipv-frontend-enrollment-branch-form
        [training]="training"
        [enrollmentId]='enrollment?.enrollment?.enrollmentId'
        formControlName="branchForm"
        name="branchForm"
      >
      </alimento-ipv-frontend-enrollment-branch-form>
    </div>
    <div [hidden]="activeIndex !== 1">
      <ng-template #personFormTemplate>
        <alimento-ipv-frontend-person
          [dataOptional]="false"
          [showPersonalContactData]="enrollmentVia === ENROLLMENT_VIA.NO_COMPANY"
          [person]="person"
          [readOnly]='!!enrollment?.enrollment?.enrollmentId'
          formControlName="personForm"
          name="personForm"
        ></alimento-ipv-frontend-person>
      </ng-template>
      <div *ngIf='!enrollment?.enrollment?.enrollmentId else personFormTemplate'>
        <p-tabView (onChange)="clearForm($event)" [(activeIndex)]="personFormIndex">
          <p-tabPanel header="{{ 'enrollments.searchPerson' | translate }}" [cache]="false">
            <div>
              <alimento-ipv-frontend-person-select
                [formControl]="searchPersonFormControl"
              ></alimento-ipv-frontend-person-select>

              <div [hidden]="!searchPersonFormControl.value" class="selected-person-container">
                <ng-container *ngTemplateOutlet="personFormTemplate"></ng-container>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="{{ 'enrollments.newPerson' | translate }}" [cache]="false">
            <ng-container *ngTemplateOutlet="personFormTemplate"></ng-container>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
    <div [hidden]="activeIndex !== 2">
      <alimento-ipv-frontend-extra-form
        formControlName="extraForm"
        name="extraForm"
        [training]='training'
        [branchId]="branchId"
        [person]="currentPerson"
        [enrollment]='enrollment'
      >
      </alimento-ipv-frontend-extra-form>
    </div>
    <div [hidden]="activeIndex !== 3">
      <alimento-ipv-frontend-comments-form
        [enrollmentVia]='enrollmentVia'
        [extraMail]='extraMailResponse'
        formControlName="commentsForm"
        name="commentsForm"
      ></alimento-ipv-frontend-comments-form>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <div class="dialog-footer-content">
      <p-button
        (onClick)="goToPreviousStep()"
        icon="{{ activeIndex === 0 ? 'pi pi-times' : 'pi pi-angle-left' }}"
        iconPos="left"
        label="{{ (activeIndex === 0 ? 'trainings.sessions.cancel' : 'enrollments.previous') | translate }}"
        styleClass="inverted-button"
      ></p-button>

      <p-button
        (onClick)="goToNextStep()"
        [loading]="loading"
        icon="pi pi-angle-right"
        iconPos="right"
        label="{{
          (activeIndex === stepsItems.length - 1
            ? titleObject.complete
            : 'enrollments.next'
          ) | translate
        }}"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
