<div *ngIf='formGroup' [formGroup]='formGroup'>
  <div class='max-w-[650px]'>
    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      formField='name'
                                      fieldKey='trainingPrograms.name'
                                      [required]='true'
    >
      <span class='read-only-field' *ngIf='formGroup.disabled'>{{formGroup.get('name').value}}</span>
      <input *ngIf='formGroup.enabled'
             class="w-full"
             formControlName="name"
             id="name"
             pInputText
             type="text"
      />
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                      [required]='true'
                                      formField='rubricId'
                                      fieldKey='trainingPrograms.rubric'
    >
      <span class='read-only-field' *ngIf='trainingProgram?.trainingProgramId || formGroup.disabled'>
        {{rubricReadOnly}}
      </span>
      <p-dropdown
        *ngIf='formGroup.enabled && !trainingProgram?.trainingProgramId'
        [filter]='true'
        [options]='rubrics'
        [showClear]='true'
        formControlName='rubricId'
        id='rubricId'
        optionLabel='label'
        optionValue='data'
        placeholder="{{ 'trainings.basicData.chooseOption' | translate }}"
        styleClass='w-full'
        appendTo='body'
      ></p-dropdown>
    </alimento-ipv-frontend-form-field>

    <div class='flex gap-4'>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [required]='true'
        [errorTemplate]='startDateErrorTemplate'
        formField='startDate'
        fieldKey='trainingPrograms.startDate'
      >
        <span *ngIf='formGroup.disabled'>{{formGroup.get('startDate')?.value | date:'dd/MM/yyyy' }}</span>
        <div *ngIf='formGroup.enabled' class='max-w-[204px]'>
          <p-calendar
            [showIcon]="true"
            [showTime]="false"
            [showOnFocus]='false'
            [keepInvalid]='true'
            appendTo="body"
            dataType="date"
            dateFormat="dd/mm/yy"
            formControlName="startDate"
            id="startDate"
            placeholder="dd/mm/yyyy"
            styleClass="w-full"
          ></p-calendar>
        </div>
        <ng-template #startDateErrorTemplate>
          <small *ngIf='formGroup.get("startDate")?.hasError("required")'>
            {{ 'trainings.sessions.dateRequired' | translate }}
          </small>
          <small *ngIf='formGroup.get("startDate").hasError("invalidDate")'>
            {{ 'validation.invalidDate' | translate }}
          </small>
        </ng-template>
      </alimento-ipv-frontend-form-field>

      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [errorTemplate]='endDateErrorTemplate'
        formField='endDate'
        fieldKey='trainingPrograms.endDate'
      >
        <span *ngIf='formGroup.disabled'>{{formGroup.get('endDate')?.value | date:'dd/MM/yyyy' }}</span>
        <div *ngIf='formGroup.enabled' class='max-w-[204px]'>
          <p-calendar
            [showIcon]="true"
            [showTime]="false"
            [showOnFocus]='false'
            [keepInvalid]='true'
            appendTo="body"
            dataType="date"
            dateFormat="dd/mm/yy"
            formControlName="endDate"
            id="endDate"
            placeholder="dd/mm/yyyy"
            styleClass="w-full"
          ></p-calendar>
        </div>
        <ng-template #endDateErrorTemplate>
          <small *ngIf='formGroup.get("endDate")?.hasError("required")'>
            {{ 'trainings.sessions.dateRequired' | translate }}
          </small>
          <small *ngIf='formGroup.get("endDate").hasError("invalidDate")'>
            {{ 'validation.invalidDate' | translate }}
          </small>
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>
  </div>
</div>
