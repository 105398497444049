<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '30rem' }"
  appendTo="body"
  header="{{ 'trainings.certificatesPopup.header' | translate }}"
>
  <div *ngIf="dialogVisible" [formGroup]="formGroup" class="register-absence-dialog">
    <ng-container *ngIf='isEligibleForTraining && participants?.length > 0; else notEligible'>
      <div class='mb-4'>
        <span class='text-lg'>{{'trainings.certificatesPopup.whichEnrollments' | translate}}</span>
      </div>

      <alimento-ipv-frontend-select-list
        formControlName='participants'
        [items]='participants'
        idField='participantId'
        [label]='getEnrollmentsLabel'
        selectAllKey='trainings.certificatesPopup.allEnrollments'
      >
      </alimento-ipv-frontend-select-list>

      <div>
        <label class='font-bold text-lg'>{{"trainings.certificatesPopup.options" | translate}}</label>
        <div>
          <p-checkbox formControlName='individualFiles'
                      [binary]='true'
                      (click)='$event.stopImmediatePropagation()'
                      label='{{"trainings.certificatesPopup.individualFiles" | translate}}'
          ></p-checkbox>
        </div>
      </div>
    </ng-container>
    <ng-template #notEligible>
      <span *ngIf='!isEligibleForTraining'>{{ 'trainings.certificatesPopup.notEligible' | translate}}</span>
      <span *ngIf='isEligibleForTraining && participants?.length === 0'>
        {{ 'trainings.certificatesPopup.noParticipants' | translate}}
      </span>
    </ng-template>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex">
      <p-button
        (onClick)="onSubmit()"
        [disabled]='nrOfEnrollments < 1'
        [loading]="loading"
        label="{{ 'trainings.certificatesPopup.generate' | translate }} ({{ nrOfEnrollments }})"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'trainings.sessions.registerAbsenceDialog.cancel' | translate }}"
        styleClass="inverted-button"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
