<div>
  <alimento-ipv-frontend-form-field
    [required]='true'
    formGroupClass='pb-0'
    formField='languageCode'
    fieldKey='trainings.basicData.language'
  >
    <div class='field-radiobutton'>
      <p-radioButton
        [formControl]='formControl'
        id='nl'
        label="{{ 'trainings.basicData.dutch' | translate }}"
        name='languageCode'
        value='nl'
      ></p-radioButton>
    </div>

    <div class='field-radiobutton'>
      <p-radioButton
        [formControl]='formControl'
        id='fr'
        label="{{ 'trainings.basicData.french' | translate }}"
        name='languageCode'
        value='fr'
      ></p-radioButton>
    </div>
    <div class='field-radiobutton' *ngIf='showExtraLanguages'>
      <p-radioButton
        [formControl]='formControl'
        id='de'
        label="{{ 'trainings.basicData.german' | translate }}"
        name='languageCode'
        value='de'
      ></p-radioButton>
    </div>

    <div class='field-radiobutton' *ngIf='showExtraLanguages'>
      <p-radioButton
        [formControl]='formControl'
        id='en'
        label="{{ 'trainings.basicData.english' | translate }}"
        name='languageCode'
        value='en'
      ></p-radioButton>
    </div>
  </alimento-ipv-frontend-form-field>
</div>
