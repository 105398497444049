import { Component, computed, effect, EventEmitter, Input, Output, signal, Signal, ViewChild } from '@angular/core';
import {
  Reimbursement,
  ReimbursementAction,
  ReimbursementActionEvent,
  ReimbursementCorrectionInfo,
  ReimbursementExtraInfo,
  ReimbursementInfo,
  ReimbursementUpdateEvent,
  TrainingData
} from '../../../types/reimbursement-request.type';
import { REIMBURSEMENT_ACTION_ENUM, REIMBURSEMENT_TYPE } from '../../../types/reimbursement-request.enum';
import { first } from 'rxjs';
import { MyMessageService } from '@alimento-ipv-frontend/ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ReimbursementRequestService } from '../../../services/reimbursement-request.service';
import { ReimbursementPopupComponent } from '../reimbursement-popup/reimbursement-popup.component';
import {
  ReimbursementActionDialogComponent
} from '../reimbursement-action-dialog/reimbursement-action-dialog.component';
import {
  ReimbursementCorrectionPopupComponent
} from '../reimbursement-correction-popup/reimbursement-correction-popup.component';
import { ReimbursementMapper } from '../../../utils/mapper/reimbursement.mapper';

@Component({
  selector: 'alimento-ipv-frontend-reimbursements-overview',
  templateUrl: './reimbursements-overview.component.html'
})
export class ReimbursementsOverviewComponent {
  @Input()
  trainingId: string;

  @Input()
  readOnly: boolean;

  @Input()
  reimbursements: Signal<Reimbursement[]> = signal([]);

  @Input()
  trainingData!: Signal<TrainingData>;

  @Output()
  changes: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(ReimbursementActionDialogComponent)
  reimbursementActionDialogComponent: ReimbursementActionDialogComponent;

  @ViewChild(ReimbursementPopupComponent)
  reimbursementPopupComponent: ReimbursementPopupComponent;

  @ViewChild(ReimbursementCorrectionPopupComponent)
  reimbursementCorrectionPopupComponent: ReimbursementCorrectionPopupComponent;

  transitionReimbursements: Signal<Reimbursement[]> = computed(() => this.reimbursements()
    .filter(reimbursement => reimbursement.reimbursementTypeId === REIMBURSEMENT_TYPE.TRANSITION));

  reimbursementData: Signal<{ [key: string]: ReimbursementInfo }> =
    computed(() => this.reimbursementMapper.calculateReimbursementData(this.reimbursements()));

  currentReimbursement: Reimbursement;
  currentReimbursementAction: REIMBURSEMENT_ACTION_ENUM;
  reimbursementExtraInfo: ReimbursementExtraInfo;
  editReimbursementExtraInfo: ReimbursementExtraInfo;
  reimbursementCorrectionInfo: ReimbursementCorrectionInfo;
  currentCorrection: Reimbursement;
  private _extraInfoTimeout: any;

  protected readonly REIMBURSEMENT_ACTION_ENUM = REIMBURSEMENT_ACTION_ENUM;
  protected readonly REIMBURSEMENT_TYPE = REIMBURSEMENT_TYPE;

  constructor(private messageService: MyMessageService,
              private translateService: TranslateService,
              private confirmationService: ConfirmationService,
              private reimbursementRequestService: ReimbursementRequestService,
              private reimbursementMapper: ReimbursementMapper) {
    effect(() => {
      this.trainingData();
      this.reimbursements();

      if (this._extraInfoTimeout) {
        clearTimeout(this._extraInfoTimeout);
      }
      this._extraInfoTimeout = setTimeout(() => {
        if (this.trainingId) {
          this._getReimbursementExtraInfo();
        }
      }, 500);
    });
  }

  addReimbursement(reimbursementPopupComponent: ReimbursementPopupComponent): void {
    this.currentReimbursement = {} as Reimbursement;
    reimbursementPopupComponent.openPopup();
  }

  reimbursementActionClicked(event: ReimbursementAction): void {
    this.currentReimbursement = JSON.parse(JSON.stringify(event.reimbursement));
    this.currentReimbursementAction = event.action;
    if (event.action === REIMBURSEMENT_ACTION_ENUM.edit) {
      if (event.reimbursement.reimbursementTypeId === REIMBURSEMENT_TYPE.CORRECTION) {
        this.reimbursementRequestService.getReimbursementCorrectionInfo(event.reimbursement.correctionReimbursementId).pipe(first())
          .subscribe((reimbursementCorrectionInfo: ReimbursementCorrectionInfo) => {
            this.reimbursementCorrectionInfo = reimbursementCorrectionInfo;
            this.currentCorrection = this.currentReimbursement;
            this.reimbursementCorrectionPopupComponent.openPopup();
          });
      }
      else {
        this.reimbursementRequestService.getReimbursementExtraInfo(this.trainingId, event.reimbursement.reimbursementId).pipe(first())
          .subscribe((reimbursementExtraInfo: ReimbursementExtraInfo) => {
            this.editReimbursementExtraInfo = reimbursementExtraInfo;
            this.reimbursementPopupComponent.openPopup();
          });
      }
    }
    else if (event.action === REIMBURSEMENT_ACTION_ENUM.confirmForPayment) {
      this.reimbursementActionDialogComponent.open();
    }
    else if (event.action === REIMBURSEMENT_ACTION_ENUM.addCorrection) {
      this.reimbursementRequestService.getReimbursementCorrectionInfo(event.reimbursement.reimbursementId).pipe(first())
        .subscribe((reimbursementCorrectionInfo: ReimbursementCorrectionInfo) => {
          this.reimbursementCorrectionInfo = reimbursementCorrectionInfo;
          this.currentCorrection = undefined;
          this.reimbursementCorrectionPopupComponent.openPopup();
        });
    }
    else if (event.action === REIMBURSEMENT_ACTION_ENUM.delete) {
      this._deleteReimbursement(event.reimbursement);
    }
  }

  reimbursementSubmit(event: ReimbursementUpdateEvent, reimbursementPopupComponent: ReimbursementPopupComponent): void {
    event.setLoading(true);

    event.reimbursement.trainingId = this.trainingId;
    const createOrUpdateReimbursement$ = event.id
      ? this.reimbursementRequestService.updateReimbursement(event.id, event.reimbursement)
      : this.reimbursementRequestService.createReimbursement(event.reimbursement);

    createOrUpdateReimbursement$.pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success();
          this.changes.emit();
          event.setLoading(false);
          reimbursementPopupComponent.closePopup();
        },
        error: () => event.setLoading(false)
      });
  }

  reimbursementCorrectionSubmit(event: ReimbursementUpdateEvent, popup: ReimbursementCorrectionPopupComponent): void {
    event.setLoading(true);

    event.reimbursement.trainingId = this.trainingId;
    const createOrUpdateReimbursement$ = event.id
      ? this.reimbursementRequestService.updateReimbursement(event.id, event.reimbursement)
      : this.reimbursementRequestService.createReimbursementCorrection(event.reimbursement.correctionReimbursementId, event.reimbursement);

    createOrUpdateReimbursement$.pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success();
          this.changes.emit();
          event.setLoading(false);
          popup.closePopup();
        },
        error: () => event.setLoading(false)
      });
  }

  private _getReimbursementExtraInfo(): void {
    this.reimbursementRequestService.getReimbursementExtraInfo(this.trainingId, undefined).pipe(first())
      .subscribe((reimbursementExtraInfo: ReimbursementExtraInfo) =>
        this.reimbursementExtraInfo = reimbursementExtraInfo);
  }

  private _deleteReimbursement(reimbursement: Reimbursement): void {
    this.confirmationService.confirm({
      header: this.translateService.instant('reimbursements.deleteTitle'),
      message: this.translateService.instant('reimbursements.deleteMessage'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translateService.instant('reimbursements.deleteTitle'),
      acceptIcon: 'hidden',
      rejectLabel: this.translateService.instant('reimbursements.cancel'),
      rejectButtonStyleClass: 'inverted-button',
      accept: () => {
        this.reimbursementRequestService.deleteReimbursement(reimbursement.reimbursementId).pipe(first())
          .subscribe(() => {
            this.messageService.success('reimbursements.deleted');
            this.changes.emit();
          });
      }
    });
  }

  executeReimbursementAction(event: ReimbursementActionEvent) {
    event.setLoading(true);
    const action = this.currentReimbursementAction;
    this.reimbursementRequestService.executeReimbursementAction(event.id, action, event.data).pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success('reimbursements.' + action + 'Success');
          event.setLoading(false);
          this.reimbursementActionDialogComponent.closeDialog();
          this.changes.emit();
        },
        error: () => {
          event.setLoading(false);
        }
      });
  }

  getCorrections(reimbursementId: string): Reimbursement[] {
    return this.reimbursements()
      .filter(reimbursement => reimbursement.reimbursementTypeId === REIMBURSEMENT_TYPE.CORRECTION)
      .filter(reimbursement => reimbursement.correctionReimbursementId === reimbursementId);
  }

  getReimbursements(type: REIMBURSEMENT_TYPE): Reimbursement[] {
    return this.reimbursementData()[type].reimbursements
      .filter(reimbursement => reimbursement.reimbursementTypeId === type);
  }
}
