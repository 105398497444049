<p-dialog
  *ngIf='popupVisible'
  (blur)='popupVisible = false'
  (onHide)='closePopup()'
  [(visible)]='popupVisible'
  [closable]='false'
  [draggable]='false'
  [modal]='true'
  [resizable]='false'
  [style]="{ width: '80rem' }"
  appendTo='body'
  header="{{'enrollments.participants.validateParticipant' | translate}}"
>
  <div *ngIf='participant' [formGroup]='formGroup'>
    <div class='mb-4'>
      <h2 class='text-[21px] font-bold mb-4'>{{'enrollments.participants.enrolledParticipant' | translate}}</h2>
      <div class='flex flex-col gap-1 ml-1'>
        <span>{{'enrollments.participants.name' | translate}}: {{participant?.lastName}} {{participant.firstName}}</span>
        <span *ngIf='participant?.nationalIdentificationNumber'>
          {{'enrollments.participants.insz' | translate}}: {{participant?.nationalIdentificationNumber | insz}}
        </span>
        <span *ngIf='participant?.email'>
          {{'enrollments.participants.email' | translate}}: {{participant?.email}}
        </span>
        <span *ngIf='participant?.employerId'>
          {{'reimbursementRequests.branch' | translate}}: {{participant?.employerName}}
        </span>
        <span *ngIf='participant?.workStatusId'>
          {{'enrollments.participants.statute' | translate}}: {{statute}}
        </span>
        <span *ngIf='participant?.function'>
          {{'enrollments.participants.function' | translate}}: {{participant?.function}}
        </span>
      </div>
    </div>

    <div class='flex flex-col gap-2'>
      <div class='flex flex-col gap-2'>
        <p-radioButton
          [formControl]='personFormControl'
          id='suggested'
          label="{{ 'enrollments.participants.suggested' | translate }}"
          name='personMatch'
          value='suggested'
        ></p-radioButton>

        <div *ngIf='personFormControl.value === PERSON_FORM_TYPE_SUGGESTED'>
          <lib-card-list #suggestionList
                         [data]="suggestedPersonMatches"
                         [selectable]='true'
                         [autoSelect]='false'
                         [noDataText]='"enrollments.participants.noSuggestedMatches"'
                         [styling]='3'
                         [cardTemplate]='suggestionTemplate'
                         (onSelectionChange)='onSelectSuggestedChange($event)'
          >
            <ng-template #suggestionTemplate let-item="item" let-selected="selected">
              <alimento-ipv-frontend-person-card [person]='item'
                                                 [selected]='selected'
                                                 [showExternalLink]='true'
                                                 [showEmployments]='true'
              ></alimento-ipv-frontend-person-card>
            </ng-template>
          </lib-card-list>
        </div>
      </div>

      <div class='flex flex-col gap-2'>
        <p-radioButton
          [formControl]='personFormControl'
          id='existing'
          label="{{ 'enrollments.participants.existingPerson' | translate }}"
          name='personMatch'
          value='existing'
        ></p-radioButton>
        <div *ngIf='personFormControl.value === PERSON_FORM_TYPE_EXISTING'>
          <alimento-ipv-frontend-person-select
            [formControl]="searchPersonFormControl"
            [includeEmployments]='true'
          ></alimento-ipv-frontend-person-select>

          <div *ngIf='selectedPerson' class='mt-4'>
            <alimento-ipv-frontend-person-card [person]='searchPersonFormControl.value'
                                               [selected]='true'
                                               [showExternalLink]='true'
                                               [showEmployments]='true'
            ></alimento-ipv-frontend-person-card>
          </div>
        </div>
      </div>

      <div class='flex flex-col gap-2'>
        <p-radioButton
          [formControl]='personFormControl'
          id='new'
          label="{{ 'enrollments.participants.newPerson' | translate }}"
          name='personMatch'
          value='new'
        ></p-radioButton>
      </div>

      <div class='mt-4'>
        <h2 class='text-[21px] font-bold mb-4'>{{ 'persons.addData' | translate }}</h2>
        <alimento-ipv-frontend-person [dataOptional]='false'
                                      [person]='personDetail'
                                      [showPersonalContactData]='enrollmentRequest.branchId === NO_BRANCH_ID'
                                      [disableUniqueValidation]='personFormControl.value !== PERSON_FORM_TYPE_NEW'
                                      name="person"
        ></alimento-ipv-frontend-person>
      </div>
    </div>

    <div class='mt-4 max-w-[650px]' *ngIf='enrollmentRequest.branchId !== NO_BRANCH_ID'>
      <alimento-ipv-frontend-extra-form formControlName="extraForm"
                                        name="extraForm"
                                        [loadActualData]='false'
                                        [branchId]="participant.employerId || enrollmentRequest.branchId"
                                        [groupId]='groupId'
                                        [enterpriseId]='enterpriseId'
                                        [person]="$any(selectedPerson)"
                                        [showBranchContactPersonInfo]='false'
                                        [showContactInfo]='!isOpenExternOrCustom'
                                        [enrollment]='undefined'>
      </alimento-ipv-frontend-extra-form>
    </div>

    <div *ngIf='!isOpenExternOrCustom'>
      <h2 class='text-[21px] font-bold mb-4'>{{'enrollments.participants.extraInfo' | translate}}</h2>

      <alimento-ipv-frontend-form-field
        [errorTemplate]='enrollmentCostError'
        [formGroup]='formGroup'
        [required]='true'
        fieldKey='enrollments.enrollmentCost'
        formField='cost'
      >
        <span *ngIf='formGroup.disabled' class='read-only-field'>€ {{formGroup.get('cost')?.value}}</span>
        <div *ngIf='formGroup.enabled'>
          <p-inputNumber
            [max]='9999'
            [min]='0'
            [required]='true'
            currency='EUR'
            formControlName='cost'
            id='cost'
            inputStyleClass='w-36'
            locale='nl-BE'
            mode='currency'
          ></p-inputNumber>
        </div>
        <ng-template #enrollmentCostError>
          <small *ngIf="formGroup.get('cost').hasError('required')">
            {{ 'validation.isRequired' | translate : { field: 'enrollments.enrollmentCost' | translate } }}
          </small>
          <small *ngIf="formGroup.get('cost').hasError('min')">
            {{ 'validation.minValue' | translate : { number: 0 } }}
          </small>
          <small *ngIf="formGroup.get('cost').hasError('max')">
            {{ 'validation.maxValue' | translate : { number: 10000 } }}
          </small>
        </ng-template>
      </alimento-ipv-frontend-form-field>
    </div>

  </div>

  <ng-template pTemplate='footer'>
    <div class='flex'>
      <p-button
        *ngIf='participant'
        [loading]='loading'
        (onClick)='addOrUpdateForm()'
        icon='pi pi-save'
        iconPos='left'
        label="{{ 'enrollments.participants.validateParticipant' | translate }}"
      ></p-button>
      <p-button
        (onClick)='closePopup()'
        icon='pi pi-times'
        iconPos='left'
        label="{{ 'persons.transitions.actions.cancel' | translate }}"
        styleClass='inverted-button'
      ></p-button>
    </div>
  </ng-template>
</p-dialog>
