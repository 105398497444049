<div *ngIf='transition'
     class="flex items-start shadow-grey-2 shadow-md my-2 p-4 bg-white hover:bg-grey-1 relative border border-input-border cursor-pointer">
  <div class='w-full grid grid-cols-2 gap-4 items-start'>
    <div class='flex flex-col gap-2'>
      <div class='flex items-center gap-4'>
        <span class='font-bold text-lg capitalize'>{{type?.label}}</span>
        <span class='text-primary' *ngIf='transition.transitionStatusId !== TRANSITION_STATE.ACTIVE'>{{status}}</span>
      </div>
      <span *ngIf='rubrics'>{{rubrics}}</span>
      <span *ngIf='transition.function && transition.transitionTypeId === TRANSITION_TYPE.FUNCTION_CHANGE'>
        {{transition.function}}
      </span>
    </div>
    <div class='flex flex-col items-end gap-2'>
      <span *ngIf='transition.validUntil && transition.validFrom'>
        {{'persons.transitions.validFromUntil' | translate:{
        from: transition.validFrom | date: "dd/MM/yyyy", until: transition.validUntil | date: "dd/MM/yyyy"} }}
      </span>
      <span *ngIf='transition.validFrom && !transition.validUntil'>
        {{"persons.transitions.validFrom" | translate}} {{transition.validFrom | date: "dd/MM/yyyy"}}
      </span>
      <span *ngIf='transition.validUntil && !transition.validFrom'>
        {{"persons.transitions.validTo" | translate}} {{transition.validUntil | date: "dd/MM/yyyy"}}
      </span>

      <div class='flex items-center gap-2'>
        <p-button *ngIf='transition.transitionStatusId === TRANSITION_STATE.DRAFT && !readOnly'
                  (onClick)="onActionClicked(TransitionActionEnum.activate)"
                  label="{{'persons.transitions.actions.activate' | translate}}"
        >
        </p-button>
        <p-button *ngIf='transition.transitionStatusId === TRANSITION_STATE.DRAFT && !readOnly'
                  (onClick)="onActionClicked(TransitionActionEnum.reject)"
                  styleClass='inverted-button with-border'
                  label="{{'persons.transitions.actions.reject' | translate}}"
        >
        </p-button>
      </div>
      <div class='flex items-center gap-4' *ngIf='transition.transitionStatusId === TRANSITION_STATE.ACTIVE || transition.transitionStatusId === TRANSITION_STATE.CLOSED'>
        <span>{{'persons.transitions.totalBudget' | translate}}: {{transition.totalBudget | currency:"EUR"}}</span>
        <span>{{'persons.transitions.remainingBudget' | translate}}: {{transition.remainingBudget | currency:"EUR"}}</span>
        <span>{{'persons.transitions.hoursFollowed' | translate: {hours: transition.numberOfHoursFollowed} }}</span>
      </div>
    </div>
  </div>
  <div class='w-16 flex justify-end'>
    <alimento-ipv-frontend-more-actions [actions]='actions'>
    </alimento-ipv-frontend-more-actions>
  </div>
</div>
