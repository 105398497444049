import { computed, Inject, Injectable, signal, WritableSignal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { Employment, MappedEmployments } from '../types/person.type';
import { APP_CONFIG_TOKEN, AppConfig, PaginatedResponse } from '@alimento-ipv-frontend/ui-lib';

@Injectable({
  providedIn: 'root'
})
export class EmploymentService {
  private emptyMappedEmployments: MappedEmployments = {
    current: [] as Employment[],
    previous: [] as Employment[]
  };

  employments: WritableSignal<MappedEmployments> = signal(this.emptyMappedEmployments);
  nrOfActiveEmployments = computed(() => this.employments().current.length);

  private _personId: string;

  constructor(private http: HttpClient,
              @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  loadEmployments(personId: string) {
    this._personId = personId;
    this.http
      .get<PaginatedResponse<Employment>>(`${this.config.readApiUrl}/person/${personId}/employments`)
      .pipe(
        map((response) => {
          return {
            current: response.data
              .filter((employment) => !employment.endDate || new Date(employment.endDate) >= new Date())
              .sort((a, b) => {
                if (a.isMainEmployment) return -1;
                if (b.isMainEmployment) return 1;
                return 0;
              }),
            previous: response.data
              .filter(employment => employment.endDate && new Date(employment.endDate) < new Date())
              .sort((a, b) => {
                return new Date(a.endDate) < new Date(b.endDate) ? 1 : -1;
              })
          };
        })
      )
      .subscribe((mappedEmployments: MappedEmployments) => {
        this.employments.set(mappedEmployments);
      });
  }

  clearEmployments() {
    this.employments.set(this.emptyMappedEmployments);
  }

  getEmployment(employmentId: string): Observable<Employment> {
    return this.http.get<Employment>(`${this.config.readApiUrl}/employments/${employmentId}`);
  }

  createEmployment(personId: string, employment: Employment): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(`${this.config.writeApiUrl}/person/${personId}/employments`, employment);
  }

  updateEmployment(personId: string, employmentId: string, employment: Employment): Observable<{ id: string }> {
    return this.http.put<{ id: string }>(
      `${this.config.writeApiUrl}/person/${personId}/employments/${employmentId}`,
      employment
    );
  }

  setAsMainEmployment(personId: string, employmentId: string): Observable<void> {
    return this.http.put<void>(`${this.config.writeApiUrl}/person/${personId}/employments/${employmentId}/main`, {});
  }

  stopEmployment(personId: string, employmentId: string, stopEmploymentAt: string): Observable<void> {
    return this.http.put<void>(`${this.config.writeApiUrl}/person/${personId}/employments/${employmentId}/stop`, {
      stopEmploymentAt
    });
  }

  getEmploymentsOfPersonByBranch(branchId: string, personId: string): Observable<Employment | null> {
    if (!personId) {
      return of(null);
    }
    return this.http.get<PaginatedResponse<Employment>>(`${this.config.readApiUrl}/person/${personId}/employments`).pipe(
      map((result) => result.data),
      map((employments) => employments.filter((employment) => employment.branchId === branchId)[0])
    );
  }

  reloadEmployments(): void {
    if (this._personId) {
      this.loadEmployments(this._personId);
    }
  }
}
