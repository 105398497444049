import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionsComponent } from './components/sessions/sessions.component';
import { SessionPopupComponent } from './components/session-popup/session-popup.component';
import { SessionListItemComponent } from './components/session-list-item/session-list-item.component';
import { CancelSessionDialogComponent } from './components/cancel-session-dialog/cancel-session-dialog.component';
import { RegisterAbsenceDialogComponent } from './components/register-absence-dialog/register-absence-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { BranchesModule } from '../branches/branches.module';
import { UtilsModule } from '../utils/utils.module';

@NgModule({
  imports: [
    CommonModule,
    UiLibModule,
    TranslateModule,
    CheckboxModule,
    MultiSelectModule,
    InputTextModule,
    InputTextareaModule,
    MenuModule,
    BranchesModule,
    UtilsModule
  ],
  declarations: [
    SessionsComponent,
    SessionPopupComponent,
    SessionListItemComponent,
    CancelSessionDialogComponent,
    RegisterAbsenceDialogComponent
  ],
  exports: [SessionsComponent]
})
export class SessionsModule {}
