import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { Dropdown } from 'primeng/dropdown';
import { CancelEnrollment, EnrollmentListItem } from '../../../types/enrollment.type';
import { DataLabelType } from '../../../types/reference-data.type';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-cancel-enrollment-dialog',
  templateUrl: './cancel-enrollment-dialog.component.html',
  styleUrls: ['./cancel-enrollment-dialog.component.scss'],
})
export class CancelEnrollmentDialogComponent {
  @Input()
  enrollment!: EnrollmentListItem;

  @Output()
  cancelEnrollmentConfirm = new EventEmitter<CancelEnrollment>();

  @ViewChild("focusElement")
  focusElement: Dropdown;

  dialogVisible = false;
  cancelEnrollmentForm: FormGroup;
  cancelEnrollmentReasons$: Observable<DataLabelType[]> = this.referenceDataService.getCancelEnrollmentReasons();
  popupHasOpened = false;

  constructor(
    private fb: FormBuilder,
    private referenceDataService: ReferenceDataService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  private _createForm(): void {
    this.cancelEnrollmentForm = this.fb.group({
      enrollmentId: [this.enrollment.id],
      reasonCancelledId: [null, [Validators.required]],
      cancelledComment: [null],
    });
  }

  open(enrollment: EnrollmentListItem) {
    this.enrollment = enrollment;
    this._createForm();
    this.dialogVisible = true;
    setTimeout(() => {
      if (this.focusElement) {
        this.focusElement.focus();
      }
    });
  }

  cancelEnrollment() {
    if (!this.isDataValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('error.fillInRequiredFields'),
      });
      return;
    }

    this.cancelEnrollmentConfirm.emit(this.cancelEnrollmentForm.value);
    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.popupHasOpened = true;
    delete this.cancelEnrollmentForm;
  }

  private isDataValid(): boolean {
    validateAllFormFields(this.cancelEnrollmentForm);
    return this.cancelEnrollmentForm.valid;
  }
}
