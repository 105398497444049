import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { first } from 'rxjs';
import { Router } from '@angular/router';
import { TrainingSearchItem } from '../../../types/training.type';
import {
  getTrainingStatusKey,
  isEventType,
  isPupilProjectType,
  isTeacherProjectType,
  TRAINING_TYPE
} from '../../../types/reference-data.enum';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-training-card',
  templateUrl: './training-card.component.html'
})
export class TrainingCardComponent implements OnChanges {

  @Input()
  training: TrainingSearchItem;

  trainingType: string;
  rubric: string;

  protected readonly getTrainingStatusKey = getTrainingStatusKey;

  constructor(private referenceDataService: ReferenceDataService, private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['training']?.currentValue) {
      this.referenceDataService.getTrainingType(this.training.typeId).pipe(first())
        .subscribe(type => this.trainingType = type.label);

      if (this.training.rubricId) {
        this.referenceDataService.getTrainingRubric(this.training.rubricId).pipe(first())
          .subscribe(rubric => this.rubric = rubric.label);
      }
    }
  }

  navigateToTraining(event: MouseEvent): void {
    let path: string[];

    if (this.training.typeId === TRAINING_TYPE.OPEN_TRAINING) {
      path = ['/trainings', 'open', this.training.trainingId, 'detail'];
    }
    else if (this.training.typeId === TRAINING_TYPE.OPEN_EXTERN) {
      path = ['/trainings', 'openextern', this.training.trainingId];
    }
    else if (this.training.typeId === TRAINING_TYPE.CUSTOM) {
      path = ['/trainings', 'custom', this.training.trainingId];
    }
    else if (this.training.typeId === TRAINING_TYPE.CEVORA) {
      path = ['/trainings', 'cevora', this.training.trainingId, 'detail'];
    }
    else if (isPupilProjectType(this.training.typeId)) {
      path = ['/trainings', 'training-project-pupil', this.training.trainingId, 'detail'];
    }
    else if (isTeacherProjectType(this.training.typeId)) {
      path = ['/trainings', 'training-project-teacher', this.training.trainingId, 'detail'];
    }
    else if (isEventType(this.training.typeId)) {
      path = ['/trainings', 'training-event', this.training.trainingId, 'detail'];
    }

    if (event.ctrlKey) {
      window.open(path.join('/'), '_blank');
    }
    else {
      this.router.navigate(path);
    }
  }
}
