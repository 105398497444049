<p-dialog
  (blur)="dialogVisible"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '21rem' }"
  [closable]='false'
  appendTo="body"
  header="{{ 'validation.saveChangesTitle' | translate }}"
>
  <div>
    <span>{{'validation.saveChangesLong' | translate}}</span>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex flex-col items-center justify-center gap-2">
      <div class='w-full'>
        <button
          #saveButton
          pButton
          label="{{ 'validation.saveChanges' | translate }}"
          loadingIcon="pi pi-spin pi-spinner"
          (click)="saveChanges()"
          [loading]="saving"
          [style]="{width: '100%'}"
        ></button>
      </div>
      <div class='w-full'>
        <p-button
          (onClick)="cancelChanges()"
          [style]="{width: '100%'}"
          label="{{ 'validation.dontSaveChanges' | translate }}"
          styleClass="inverted-button"
        ></p-button>
      </div>
      <div class='w-full'>
        <p-button
          (onClick)="cancel()"
          [style]="{width: '100%'}"
          label="{{ 'validation.stayOnPage' | translate }}"
          styleClass="inverted-button"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>
