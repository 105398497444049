import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';

import { first, Observable } from 'rxjs';
import { IFile } from '../../types/ui-lib.type';
import { MyMessageService } from '../../services/my-message.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.sass']
})
export class DocumentsComponent {
  @Input()
  files: IFile[] = [];

  @Output()
  filesChange = new EventEmitter<IFile[]>();

  @Input()
  onAddFile: (file: File) => Observable<IFile>;

  @Input()
  onDeleteFile: (file: IFile) => Observable<void>;

  @Input()
  getFile: (file: IFile) => Observable<any>;

  @Input()
  canDelete = false;

  @Input()
  canAdd = true;

  @Input()
  showFilesAsList = false;

  constructor(private messageService: MyMessageService,
              private translateService: TranslateService,
              private confirmService: ConfirmationService) {
  }

  onFileDropped(files: File[]): void {
    for (const file of files) {
      this.addFile(file);
    }
  }

  fileBrowseHandler(event: any, fileDropRef: HTMLInputElement): void {
    for (const file of event.target.files) {
      this.addFile(file);
    }
    fileDropRef.value = '';
  }

  addFile(file: File): void {
    if (!this.canAdd) {
      return;
    }

    const filesize = (file.size / 1024) / 1024;
    if (filesize < 25) {

      const tempFile = {
        id: Math.random() + '',
        loading: false,
        fileName: file.name,
        data: file
      } as IFile;
      this.files.push(tempFile);

      if (this.onAddFile) {
        this.onAddFile(file).pipe(first()).subscribe({
          next: newFile => {
            this._removeFile(tempFile.id);
            this.files.push(newFile);
            this.filesChange.emit(this.files);
            this.messageService.success('fileUploaded');
          },
          error: () => {
            this._removeFile(tempFile.id);
            this.messageService.error('errors.somethingWentWrong');
          }
        });
      }
      else {
        this.filesChange.emit(this.files);
      }

    }
    else {
      this.messageService.error('fileToBig');
    }
  }

  deleteFile(deleteFile: IFile, event: MouseEvent): void {
    if (!this.canDelete || deleteFile.loading) {
      return;
    }

    event.stopImmediatePropagation();
    this.confirmService.confirm({
      header: this.translateService.instant('removeDocument'),
      acceptLabel: this.translateService.instant('remove'),
      rejectLabel: this.translateService.instant('cancel'),
      message: this.translateService.instant('removeDocumentText'),
      accept: () => {
        deleteFile.loading = true;
        if (this.onDeleteFile) {
          this.onDeleteFile(deleteFile).pipe(first())
            .subscribe(() => {
              this.messageService.success('fileDeleted');
              this._removeFile(deleteFile.id);
            });
        }
        else {
          this._removeFile(deleteFile.id);
        }
      },
      reject: () => {
        deleteFile.loading = false;
      }
    });
  }

  getImgClass(file: IFile): string {
    if (/.*\.pdf/.test(file.fileName)) {
      return 'pi pi-file-pdf';
    }
    else if (/.*\.docx?/.test(file.fileName)) {
      return 'pi pi-file-word';
    }
    else if (/.*\.(jpg|jpeg|png)/.test(file.fileName)) {
      return 'pi pi-image';
    }
    else if (/.*\.xlsx??/.test(file.fileName)) {
      return 'pi pi-file-excel';
    }
    return 'pi pi-file';
  }

  openFile(file: IFile, event: MouseEvent) {
    event.stopImmediatePropagation();
    if (file.loading) {
      return;
    }

    if (file.data) {
      const blob = new Blob([file.data], { type: file.data.type });
      const url  = URL.createObjectURL(blob);
      window.open(url);
    }
    else if (file.url) {
      window.open(file.url);
    }
    else if (this.getFile) {
      file.loading = true;
      this.getFile(file).pipe(first())
        .subscribe({
          next: () => {
            file.loading = false;
          },
          error: () => {
            file.loading = false;
          }
        });
    }
  }

  private _removeFile(fileId: string): void {
    this.files = this.files.filter(file => file.id != fileId);
    this.filesChange.emit(this.files);
  }
}
