import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { Tooltip } from 'primeng/tooltip';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[isEllipsis]',
})
export class EllipsisDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef, private tooltip: Tooltip) {}

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.classList.add('whitespace-nowrap');
    this.elementRef.nativeElement.classList.add('text-ellipsis');
    this.elementRef.nativeElement.classList.add('overflow-hidden');

    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      if (element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth) {
        this.tooltip.setOption({ tooltipLabel: element.innerText });
      }
    }, 1000);
  }
}
