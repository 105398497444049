import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonListComponent } from './pages/person-list/person-list.component';
import { PersonDetailComponent } from './pages/person-detail/person-detail.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { TableModule } from 'primeng/table';
import { TranslateModule } from '@ngx-translate/core';
import { CanDeactivateGuard, UiLibModule } from '@alimento-ipv-frontend/ui-lib';
import { FormsModule } from '@angular/forms';
import { PersonCardComponent } from './components/person-card/person-card.component';
import { InputMaskModule } from 'primeng/inputmask';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { InszPipe } from './utils/insz.pipe';
import { CheckboxModule } from 'primeng/checkbox';
import { PersonComponent } from './components/person/person.component';
import { RippleModule } from 'primeng/ripple';
import { EmploymentsComponent } from './components/employments/employments.component';
import { EmploymentListItemComponent } from './components/employment-list-item/employment-list-item.component';
import { EmploymentPopupComponent } from './components/employment-popup/employment-popup.component';
import { StopEmploymentDialogComponent } from './components/stop-employment-dialog/stop-employment-dialog.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PersonSelectComponent } from './components/person-select/person-select.component';
import { SkeletonModule } from 'primeng/skeleton';
import { UtilsModule } from '../utils/utils.module';
import { TransitionsComponent } from './components/transitions/transitions.component';
import { TransitionPopupComponent } from './components/transition-popup/transition-popup.component';
import { TransitionCardComponent } from './components/transition-card/transition-card.component';
import {
  TransitionActionDialogComponent
} from './components/transition-action-dialog/transition-action-dialog.component';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
  imports: [
    CommonModule,
    MultiSelectModule,
    SharedModule,
    TableModule,
    TranslateModule,
    UiLibModule,
    FormsModule,
    InputMaskModule,
    AutoCompleteModule,
    InputTextModule,
    CheckboxModule,
    RippleModule,
    UtilsModule,
    ConfirmDialogModule,
    SkeletonModule,
    InputTextareaModule
  ],
  providers: [CanDeactivateGuard, ConfirmationService],
  declarations: [
    PersonListComponent,
    PersonDetailComponent,
    PersonCardComponent,
    InszPipe,
    PersonComponent,
    EmploymentsComponent,
    EmploymentListItemComponent,
    EmploymentPopupComponent,
    StopEmploymentDialogComponent,
    PersonSelectComponent,
    TransitionsComponent,
    TransitionPopupComponent,
    TransitionCardComponent,
    TransitionActionDialogComponent
  ],
  exports: [
    PersonCardComponent,
    PersonComponent,
    PersonSelectComponent,
    EmploymentPopupComponent,
    EmploymentListItemComponent,
    StopEmploymentDialogComponent,
    InszPipe
  ]
})
export class PersonsModule {}
