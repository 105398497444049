<p-dialog
  (blur)="(dialogVisible)"
  (onHide)="closeDialog()"
  [(visible)]="dialogVisible"
  [draggable]="false"
  [closable]='false'
  [modal]="true"
  [resizable]="false"
  [style]="{ width: '54rem'}"
  appendTo="body"
  header="{{ 'trainings.basicData.educationLevelPopup.title' | translate }}"
>
  <div *ngIf="dialogVisible" class='flex h-full gap-4'>

    <div class='w-1/2'>
      <div class='w-full relative'>
        <i class='pi pi-search absolute top-4 left-4'></i>
        <input
          #inputField
          autofocus
          class="w-full mb-2 !px-10"
          id="searchSector"
          pInputText
          placeholder="{{ 'trainings.basicData.educationLevelPopup.searchPlaceholder' | translate }}"
          type="text"
          (input)='tree._filter($any($event.target).value)'
        />
        <i *ngIf='!!inputField.value'
          (click)='inputField.value = ""; tree._filter(""); inputField.focus();'
           (keyup.enter)='inputField.value = ""; tree._filter(""); inputField.focus();'
           class='pi pi-times absolute p-2 right-4 top-2 mt-[1px] hover:bg-primary-hover cursor-pointer'></i>
      </div>

      <div class='flex items-center justify-between'>
        <p-button
          (onClick)="expandAll()"
          label="{{ 'trainings.basicData.subSectorPopup.expandAll' | translate }}"
          styleClass="inverted-button"
        ></p-button>
        <p-button
          (onClick)="collapseAll()"
          label="{{ 'trainings.basicData.subSectorPopup.collapseAll' | translate }}"
          styleClass="inverted-button"
        ></p-button>
      </div>
      <div class='max-h-80 overflow-y-auto'>
        <p-tree
          #tree
          [(selection)]='educationLevelSelection'
          [value]='educationLevels()'
          selectionMode='checkbox'
          [metaKeySelection]='false'
          [propagateSelectionDown]='true'
          [propagateSelectionUp]='true'
          (onNodeSelect)='selectionChange()'
          (onNodeUnselect)='selectionChange()'
          (selectionChange)='selectionChange()'
        >
        </p-tree>
      </div>
    </div>
    <div class='w-1/2 bg-grey-0'>
      <h2 class='mx-4 py-2 border-b border-b-grey-2 text-xl font-bold text-text uppercase'>
        {{'trainings.basicData.subSectorPopup.yourSelection' | translate}}
      </h2>
      <ng-container *ngTemplateOutlet='selectedItemsTemplate;
      context: {data: editEducationLevel, updateData: updateEditEducationLevel}'></ng-container>
    </div>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex">
      <p-button
        (onClick)="onSubmit()"
        label="{{ 'trainings.basicData.subSectorPopup.save' | translate }}"
      ></p-button>
      <p-button
        (onClick)="closeDialog()"
        label="{{ 'trainings.basicData.subSectorPopup.cancel' | translate }}"
        styleClass="inverted-button"
      ></p-button>
    </div>
  </ng-template>
</p-dialog>

<ng-container *ngTemplateOutlet='selectedItemsTemplate;
context: {data: currentEducationLevels, updateData: updateFormControl}'></ng-container>

<ng-template #selectedItemsTemplate let-data="data" let-updateData='updateData'>
  <div class='bg-grey-0 p-4' *ngIf='data.length > 0'>
    <ng-container *ngFor='let item of data; let i = index'>
      <div class='flex p-2 justify-between items-center border-b border-b-grey-2'>
        <span>{{item.label}}</span>
        <i *ngIf='!disabled' (click)='data.splice(i, 1); updateData();'
           class='font-material text-danger cursor-pointer not-italic font-bold'>Close</i>
      </div>
      <ng-container *ngFor='let subType of (item.levels || []); let j = index'>
        <div class='ml-4 p-2 flex justify-between items-center border-b border-b-grey-2'>
          <span>{{subType.label}}</span>
          <i *ngIf='!disabled' (click)='item.levels?.splice(j, 1); updateData()'
             class='font-material text-danger cursor-pointer not-italic font-bold'>Close</i>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<div
  class="link-button link-button-with-icon"
  (click)="openPopup()"
  *ngIf='!disabled'
>
  <i class="font-material">Add</i>
  <span>{{ 'trainings.basicData.educationLevelPopup.select' | translate }}</span>
</div>
