import { Component, Input } from '@angular/core';
import { SearchPersonItem } from '../../../types/person.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'alimento-ipv-frontend-person-card',
  templateUrl: './person-card.component.html'
})
export class PersonCardComponent {
  @Input()
  person?: SearchPersonItem;

  @Input()
  showEmployments = false;

  @Input()
  selected = false;

  @Input()
  showExternalLink = false;

  @Input()
  showExtraInfo = true;

  constructor(private referenceDataService: ReferenceDataService) {
  }

  getWorkStatus(workStatusId: string): Observable<string> {
    return this.referenceDataService.getWorkStatus(workStatusId).pipe(map(workStatus => workStatus.label));
  }
}
