import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AuthorizationService,
  CanComponentDeactivate,
  ExtraMenuItem,
  FEATURE,
  FeatureFlagService,
  FilterType,
  InfiniteScrollDataAdapter,
  LeaveConfirmService,
  MyMessageService,
  NOTES_TYPE,
  NotesSidebarComponent,
  PaginatedResponse,
  Role,
  SearchComponent,
  SearchFilter,
  SearchFilterType,
  SearchRequest,
  TabMenuItem,
  TitleService,
  TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { first, forkJoin, Observable, of, Subscription, switchMap, tap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { BranchListItem } from '../../../types/searches.type';
import { SearchesService } from '../../../services/searches.service';
import {
  AccountNumber,
  AccountNumberAction,
  AccountNumberActionEvent,
  AccountNumberUpdateEvent,
  Enterprise,
  SearchAccountNumber
} from '../../../types/enterprise.type';
import { EnterprisesService } from '../../../services/enterprises.service';
import { AccountNumberPopupComponent } from '../../components/account-number-popup/account-number-popup.component';
import { AccountNumberActionEnum } from '../../../types/enterprises.enum';
import { BranchActionEnum } from '../../../types/branch.enum';
import { BranchAction } from '../../../types/branch.type';
import {
  AccountNumberActionDialogComponent
} from '../../components/account-number-action-dialog/account-number-action-dialog.component';
import { BranchService } from '../../../services/branch.service';
import { EnterpriseComponent } from '../../components/enterprise/enterprise.component';

@Component({
  selector: 'alimento-ipv-frontend-enterprise-detail',
  templateUrl: './enterprise-detail.component.html'
})
export class EnterpriseDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  enterprise: Enterprise;
  enterpriseId?: string;
  tabMenuItems: TabMenuItem[];
  extraMenuItems: ExtraMenuItem[];
  activeTabIndex = 0;
  savingEnterprise = false;
  smeBonusActive: boolean;

  showNotes = false;
  readOnly = true;
  accountNumberReadOnly = true;
  hasChanges = false;
  notesHasChanges: boolean;

  searchBranches$: InfiniteScrollDataAdapter<BranchListItem>;
  searchPreviousBranches$: InfiniteScrollDataAdapter<BranchListItem>;
  searchBranchesFilters: SearchFilter[];
  searchPreviousBranchesFilters: SearchFilter[];
  accountNumbers: Observable<SearchAccountNumber[]>;
  currentAccountNumber: AccountNumber;
  currentAccountNumberAction: AccountNumberActionEnum;
  EnterpriseNote = NOTES_TYPE.EnterpriseNote;

  @ViewChild(NotesSidebarComponent)
  notesSidebarComponent: NotesSidebarComponent;

  @ViewChild('searchBranches')
  searchBranchesComponent: SearchComponent;

  @ViewChild('searchPreviousBranches')
  searchPreviousBranchesComponent: SearchComponent;

  @ViewChild(AccountNumberActionDialogComponent)
  accountNumberActionDialogComponent: AccountNumberActionDialogComponent;

  @ViewChild(EnterpriseComponent)
  enterpriseComponent: EnterpriseComponent;

  protected readonly FEATURE = FEATURE;
  private _subscriptions: Subscription[] = [];

  constructor(
    private enterpriseService: EnterprisesService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
    private titleService: TitleService,
    private location: Location,
    private searchesService: SearchesService,
    private featureService: FeatureFlagService,
    private messageService: MyMessageService,
    private branchesService: BranchService,
    private leaveConfirmationService: LeaveConfirmService,
    private authorizationService: AuthorizationService
  ) {
    this.featureService.initialize().pipe(first())
      .subscribe(() => {
        this.readOnly = this.featureService.featureOff(FEATURE.BRANCH_AND_ENTERPRISE_MANAGEMENT) ||
          !this.authorizationService.hasAnyRole([Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE, Role.ADMIN]);
        this.accountNumberReadOnly = this.featureService.featureOff(FEATURE.ACCOUNT_NUMBER);
      });
  }

  ngOnInit() {
    this.route.params.pipe(first())
      .subscribe(params => {
        this.enterpriseId = params['enterpriseId'];
        if (this.enterpriseId) {
          this._fetchEnterpriseData();
        }
        else {
          this.enterprise = {} as Enterprise;
        }
        this._createMenuItems();
      });

    this.route.queryParams.pipe(first())
      .subscribe(queryParams => this.activeTabIndex = Number(queryParams['activeTabIndex'] || 0));
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  canDeactivate(): Promise<boolean> | boolean {
    if (!this.hasChanges && !this.notesHasChanges) {
      return true;
    }

    const saveActions: Observable<any>[] = [];
    if (this.hasChanges) {
      saveActions.push(this._saveEnterprise());
    }

    if (this.notesHasChanges) {
      saveActions.push(this.notesSidebarComponent.saveChanges());
    }

    return this.leaveConfirmationService.leaveDialog(() => forkJoin(saveActions));
  }

  setActiveTabIndex(tabMenuItem: TabMenuItem) {
    this.activeTabIndex = tabMenuItem.index;
    this._setDetailUrl();
  }

  saveEnterprise(): void {
    this.savingEnterprise = true;
    this._saveEnterprise().subscribe({
      next: () => this.savingEnterprise = false,
      error: () => this.savingEnterprise = false
    });
  }

  private _fetchEnterpriseData(): void {
    this.enterpriseService.getEnterprise(this.enterpriseId)
      .pipe(first())
      .subscribe({
        next: enterprise => {
          this.enterprise = enterprise;
          this.titleService.setTitle([this.translateService.instant('enterprises.enterprise'), enterprise.enterpriseName]);
          this._createBranchesSearch();
          this._createPreviousBranchesSearch();
          this._getAccountNumbers();
          this._createMenuItems();
          this._calculateSmeBonusIsActive();
        },
        error: () => {
          this._navigateToErrorPage();
        }
      });
  }

  private _updateEnterpriseInfo(enterpriseId: string): void {
    if (enterpriseId) {
      this.enterpriseService.getEnterprise(enterpriseId).subscribe((enterprise: Enterprise) => {
        this.enterprise = enterprise;
        if (!this.enterpriseComponent?.formGroup?.value?.enterpriseAlimentoId) {
          this.enterpriseComponent?.formGroup.patchValue({
            enterpriseAlimentoId: enterprise.enterpriseAlimentoId
          }, { emitEvent: false });
        }
        this._calculateSmeBonusIsActive();
      });
    }
  }

  private _saveEnterprise(): Observable<{ id: string }> {
    if (!this.enterpriseComponent.isValid()) {
      this.messageService.error('enterprises.hasErrors');

      return new Observable<{ id: string }>((observable) => observable.error());
    }

    this.savingEnterprise = true;

    const data = this.enterpriseComponent.getData() as Enterprise;

    const createOrUpdateEnterprise$ = this.enterpriseId
      ? this.enterpriseService.updateEnterprise(this.enterpriseId, data)
      : this.enterpriseService.createEnterprise(data);

    return of(null)
      .pipe(
        tap(() => this.savingEnterprise = true),
        switchMap(() => createOrUpdateEnterprise$),
        first(),
        tap((response: { id: string }) => {
          this.enterpriseId = response.id;
          this.hasChanges = false;
          this.messageService.success();
          this._setDetailUrl();

          this._updateEnterpriseInfo(this.enterpriseId);
          this._createMenuItems();
        })
      );
  }

  private _setDetailUrl(): void {
    if (this.enterpriseId) {
      this.location.replaceState(
        this.router
          .createUrlTree(['/enterprises', this.enterpriseId, 'detail'], {
            queryParams: { activeTabIndex: this.activeTabIndex }
          })
          .toString()
      );
    }
  }

  private _navigateToErrorPage(): void {
    this.router.navigate(['error'], {
      state: {
        message: this.translateService.instant('error.itemWithIdDoesNotExist', {
          item: this.translateService.instant('enterprises.enterprise'),
          id: this.enterpriseId
        }),
        redirectUrl: '/'
      }
    });
  }

  private _createMenuItems(): void {
    this._subscriptions.push(
      this.translationService.languageChange$.subscribe(() => {
        this.tabMenuItems = [
          { name: 'basic', index: 0, title: this.translateService.instant('branches.basicDataTitle') },
          {
            name: 'financialData',
            index: 1,
            title: this.translateService.instant('enterprises.financialDataTitle'),
            disabled: !this.enterpriseId
          },
          {
            name: 'branches',
            index: 2,
            title: this.translateService.instant('enterprises.branchesTitle'),
            disabled: !this.enterpriseId
          }
        ];
        if (this.featureService.featureOn(FEATURE.ENTERPRISE_SME_BONUS)) {
          this.tabMenuItems.push({
            name: 'smeBonus',
            index: 3,
            title: this.translateService.instant('enterprises.smeBonus'),
            disabled: !this.enterpriseId || !this.enterprise?.smeBonus
          });
        }

        this.extraMenuItems = [
          {
            name: 'notes',
            title: this.translateService.instant('branches.notesTitle'),
            disabled: !this.enterpriseId,
            command: () => {
              if (this.showNotes) {
                this.notesSidebarComponent.close();
              }
              else {
                this.showNotes = !this.showNotes;
              }
            }
          }
          // { name: 'history', title: this.translateService.instant('branches.historyTitle') },
        ];
      })
    );
  }

  private _createBranchesSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: []
    };
    searchRequest.filters.push({
      type: FilterType.filterEnterpriseId,
      values: [this.enterpriseId]
    });
    searchRequest.filters.push({
      type: FilterType.isActive,
      values: [true]
    });

    this.searchBranches$ = new InfiniteScrollDataAdapter<BranchListItem>((searchRequest: SearchRequest):
    Observable<PaginatedResponse<BranchListItem>> => {
      return this.searchesService.searchBranches(searchRequest);
    }, searchRequest, true);

    this.searchBranchesFilters = [
      {
        type: SearchFilterType.searchBar,
        key: FilterType.search
      }
    ];
  }

  private _createPreviousBranchesSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 9,
      filters: []
    };
    searchRequest.filters.push({
      type: FilterType.filterEnterpriseId,
      values: [this.enterpriseId]
    });
    searchRequest.filters.push({
      type: FilterType.isActive,
      values: [false]
    });

    this.searchPreviousBranches$ = new InfiniteScrollDataAdapter<BranchListItem>((searchRequest: SearchRequest):
    Observable<PaginatedResponse<BranchListItem>> => {
      return this.searchesService.searchBranches(searchRequest);
    }, searchRequest, true);

    this.searchPreviousBranchesFilters = [
      {
        type: SearchFilterType.searchBar,
        key: FilterType.search
      }
    ];
  }

  private _getAccountNumbers(): void {
    this.accountNumbers = this.enterpriseService.getAccountNumbers(this.enterpriseId);
  }

  accountNumberActionClicked(event: AccountNumberAction, accountNumberPopupComponent: AccountNumberPopupComponent): void {
    if (event.action === AccountNumberActionEnum.setAsDefault) {
      this.enterpriseService.setAccountNumberAsDefault(event.accountNumber.accountNumberId).pipe(first())
        .subscribe({
          next: () => {
            this.messageService.success();
            this._getAccountNumbers();
          }
        });
    }
    else {
      this.enterpriseService.getAccountNumber(event.accountNumber.accountNumberId).pipe(first())
        .subscribe(accountNumber => {
          this.currentAccountNumber = accountNumber;
          this.currentAccountNumberAction = event.action;
          if (event.action === AccountNumberActionEnum.edit) {
            accountNumberPopupComponent.openPopup();
          }
          else if (event.action === AccountNumberActionEnum.approve) {
            this.accountNumberActionDialogComponent.open();
          }
          else if (event.action === AccountNumberActionEnum.reject) {
            this.accountNumberActionDialogComponent.open();
          }
        });
    }
  }

  createNewAccountNumber(accountNumberPopupComponent: AccountNumberPopupComponent): void {
    this.currentAccountNumber = {} as AccountNumber;
    accountNumberPopupComponent.openPopup();
  }

  accountNumberSubmit(event: AccountNumberUpdateEvent, accountNumberPopupComponent: AccountNumberPopupComponent): void {
    event.setLoading(true);

    const createOrUpdateTrainingPlan$ = event.accountNumber.accountNumberId
      ? this.enterpriseService.updateAccountNumber(this.enterpriseId, event.accountNumber)
      : this.enterpriseService.createAccountNumber(this.enterpriseId, event.accountNumber);

    createOrUpdateTrainingPlan$.pipe(first())
      .subscribe({
        next: (result: { id: string }) => {
          this.messageService.success('branches.trainingPlans.saved');

          if (event.approve) {
            this.currentAccountNumberAction = AccountNumberActionEnum.approve;
            event.accountNumber.accountNumberId = result.id;
            this.currentAccountNumber = event.accountNumber;
            this.accountNumberActionDialogComponent.open();
          }
          else {
            this._getAccountNumbers();
          }

          event.setLoading(false);
          accountNumberPopupComponent.closePopup();
        },
        error: () => {
          event.setLoading(false);
        }
      });
  }

  executeAccountNumberAction(event: AccountNumberActionEvent): void {
    event.setLoading(true);
    let action$: Observable<any>;
    let successText: string;
    if (this.currentAccountNumberAction === AccountNumberActionEnum.approve) {
      action$ = this.enterpriseService.approveAccountNumber(this.currentAccountNumber.accountNumberId, event.data);
      successText = 'approvedSuccess';
    }
    else if (this.currentAccountNumberAction === AccountNumberActionEnum.reject) {
      action$ = this.enterpriseService.rejectAccountNumber(this.currentAccountNumber.accountNumberId, event.data);
      successText = 'rejectSuccess';
    }

    action$.pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success('branches.trainingPlans.' + successText);
          event.setLoading(false);
          this.accountNumberActionDialogComponent.closeDialog();
          this._getAccountNumbers();
        },
        error: () => {
          event.setLoading(false);
        }
      });
  }

  branchCardClicked(event: BranchAction): void {
    if (event.action === BranchActionEnum.setAsMainBranch) {
      this.branchesService
        .setAsMainBranch(event.branch.branchId)
        .pipe(first())
        .subscribe({
          next: () => {
            this.messageService.success('enterprises.branches.mainBranchChanged');
            this.searchBranchesComponent.refresh();
          }
        });
    }
  }

  private _calculateSmeBonusIsActive(): void {
    this.smeBonusActive = new Date(this.enterprise.smeBonusValidFrom) <= new Date() &&
      new Date(this.enterprise.smeBonusValidUntil) >= new Date() &&
      this.enterprise.smeBalance > 0;
  }

  navigateToBranch(branch: BranchListItem, event: MouseEvent): void {
    if (event.ctrlKey) {
      window.open(`/branches/${branch.branchId}/detail`, '_blank')
    }
    else {
      this.router.navigate(['/branches', branch.branchId, 'detail']);
    }
  }
}
