<div class="title">{{ 'enrollments.professionalPersonData' | translate }}</div>

<div [formGroup]="formGroup">
  <div>
    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      formField='employment.function'
      fieldKey='enrollments.function'
    >
      <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('employment.function')?.value}}</span>
      <div *ngIf='formGroup.enabled' formGroupName="employment">
        <input #focusElement class="w-full" formControlName="function" id="employment.function" pInputText type="text" />
      </div>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [required]='showBranchField'
      formField='employment.employer'
      fieldKey='branches.branch'
      [hidden]='!showBranchField'
    >
    <div *ngIf='formGroup.enabled' formGroupName="employment">
      <alimento-ipv-frontend-branch-select formControlName='employer'
            [extraFilters]='branchSearchFilter'
      ></alimento-ipv-frontend-branch-select>
    </div>

    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [required]='true'
      formField='enrollment.workStatus'
      fieldKey='enrollments.workStatus'
    >
      <span *ngIf='formGroup.disabled' class='read-only-field'>{{workStatusReadOnly}}</span>
      <div *ngIf='formGroup.enabled' formGroupName='enrollment'>
        <p-dropdown
          *ngIf="workStatus$ | async as workStatus"
          [filter]="true"
          [options]="workStatus"
          appendTo="body"
          formControlName="workStatus"
          id="enrollment.workStatus"
          optionLabel="label"
          optionValue="data"
          placeholder="{{ 'enrollments.chooseOption' | translate }}"
          styleClass="w-full"
          [showClear]="true"
        ></p-dropdown>
      </div>
    </alimento-ipv-frontend-form-field>

    <div class='conditional-fields' [hidden]='!showInterimOfficeFields'>
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [required]='showInterimOfficeFields'
        formField='enrollment.interimOfficeId'
        fieldKey='enrollments.interimOffice'
      >
        <span *ngIf='formGroup.disabled' class='read-only-field'>{{interimOfficeReadOnly}}</span>
        <div *ngIf='formGroup.enabled' formGroupName='enrollment'>
          <alimento-ipv-frontend-interim-office-select
            formControlName='interimOfficeId'
          ></alimento-ipv-frontend-interim-office-select>
        </div>
      </alimento-ipv-frontend-form-field>

      <div *ngIf='interimOffice'>
        <div class='max-w-[425px]'>
          <alimento-ipv-frontend-address
            [formControl]='interimAddress'
            [readOnly]='true'
          ></alimento-ipv-frontend-address>
        </div>

        <h2 class="text-xl text-text font-bold my-4">{{ 'enrollments.interimContact' | translate }}</h2>
        <div class='flex gap-4'>
          <div class='basis-1/2'>
            <alimento-ipv-frontend-form-field
              [formGroup]='formGroup'
              formField='enrollment.interimOfficeContactFirstName'
              fieldKey='enrollments.interimContactFirstName'
            >
              <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('enrollment.interimOfficeContactFirstName')?.value}}</span>
              <div *ngIf='formGroup.enabled' formGroupName='enrollment'>
                <input class="w-full" formControlName="interimOfficeContactFirstName" id="enrollment.interimOfficeContactFirstName" pInputText type="text" />
              </div>
            </alimento-ipv-frontend-form-field>
          </div>
          <div class='basis-1/2'>
            <alimento-ipv-frontend-form-field
              [formGroup]='formGroup'
              formField='enrollment.interimOfficeContactLastName'
              fieldKey='enrollments.interimContactLastName'
            >
              <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('enrollment.interimOfficeContactLastName')?.value}}</span>
              <div *ngIf='formGroup.enabled' formGroupName='enrollment'>
                <input class="w-full" formControlName="interimOfficeContactLastName" id="enrollment.interimOfficeContactLastName" pInputText type="text" />
              </div>
            </alimento-ipv-frontend-form-field>
          </div>
        </div>

        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          formField='enrollment.interimOfficeContactEmail'
          fieldKey='enrollments.interimContactEmail'
        >
          <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('enrollment.interimOfficeContactEmail')?.value}}</span>
          <div *ngIf='formGroup.enabled' formGroupName='enrollment'>
            <input class="w-full" formControlName="interimOfficeContactEmail" id="enrollment.interimOfficeContactEmail" pInputText type="text" />
          </div>
        </alimento-ipv-frontend-form-field>
      </div>
    </div>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [required]='true'
      *ngIf='!isInstitution'
      formField='enrollment.restructuringOrDismissal'
      fieldKey='enrollments.restructuringOrDismissal'
    >
      <span *ngIf='formGroup.disabled' class='read-only-field'>{{restructuringOrDismissalReadOnly}}</span>
      <div *ngIf='formGroup.enabled' formGroupName='enrollment'>
        <p-dropdown
          *ngIf="restructuringOrDismissal$ | async as restructuringOrDismissal"
          [filter]="true"
          [options]="restructuringOrDismissal"
          appendTo="body"
          formControlName="restructuringOrDismissal"
          id="enrollment.restructuringOrDismissal"
          optionLabel="label"
          optionValue="data"
          placeholder="{{ 'enrollments.chooseOption' | translate }}"
          styleClass="w-full"
          [showClear]="true"
        ></p-dropdown>
      </div>
    </alimento-ipv-frontend-form-field>

    <div class="field" formGroupName="enrollment" *ngIf='!isInstitution'>
      <p-checkbox
        [binary]="true"
        formControlName="outsideWorkingHours"
        label="{{ 'enrollments.outsideWorkingHours' | translate }}"
      ></p-checkbox>
    </div>

    <div class="field" formGroupName="enrollment" *ngIf="!isInstitution && formGroup.get('enrollment.outsideWorkingHours')?.value">
      <p-checkbox
        [binary]="true"
        formControlName="recuperated"
        label="{{ 'enrollments.recuperated' | translate }}"
      ></p-checkbox>
    </div>
  </div>

  <div formGroupName="employment" *ngIf="branchId && showContactInfo">
    <h2 class="text-xl text-text font-bold my-4">{{ 'enrollments.contactData' | translate }}</h2>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [required]='true'
      [errorTemplate]='emailErrorTemplate'
      formField='employment.email'
      fieldKey='enrollments.email'
    >
      <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('employment.email')?.value}}</span>
      <div *ngIf='formGroup.enabled' formGroupName='employment'>
        <input class="w-full" formControlName="email" id="employment.email" pInputText type="text" />
      </div>
      <ng-template #emailErrorTemplate>
        <small *ngIf="formGroup.get('employment.email')?.errors?.['required']">
          {{ 'validation.isRequired' | translate : { field: 'enrollments.email' | translate } }}
        </small>
        <small *ngIf="formGroup.get('employment.email')?.errors?.['email']">
          {{ 'validation.invalidEmail' | translate }}
        </small>
      </ng-template>
    </alimento-ipv-frontend-form-field>

    <alimento-ipv-frontend-form-field
      [formGroup]='formGroup'
      [errorTemplate]='emailErrorTemplate'
      formField='employment.phoneNumber'
      fieldKey='enrollments.phoneNumber'
    >
      <span *ngIf='formGroup.disabled' class='read-only-field'>{{formGroup.get('employment.phoneNumber')?.value}}</span>
      <div *ngIf='formGroup.enabled' formGroupName='employment'>
        <input class="w-full" formControlName="phoneNumber" id="employment.phoneNumber" pInputText type="text" />
      </div>
    </alimento-ipv-frontend-form-field>
  </div>

  <div formGroupName="enrollment" *ngIf="branchId && showBranchContactPersonInfo">
    <h2 class="text-xl text-text font-bold my-4">{{ 'enrollments.contactPersonForThisTraining' | translate }}</h2>

    <div class="field">
      <p-checkbox
        [binary]="true"
        formControlName="isContact"
        label="{{ 'enrollments.contactPersonIsParticipant' | translate }}"
      ></p-checkbox>
    </div>

    <div class="field" *ngIf="!formGroup.get('enrollment.isContact').value">
      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [required]='true'
        formField='enrollment.contactPerson'
        fieldKey='enrollments.contactPerson'
      >
        <span *ngIf='formGroup.disabled' class='read-only-field'>
          <div *ngIf='contactPersonReadOnly'>
           <span>{{ contactPersonReadOnly.lastName }} {{ contactPersonReadOnly.firstName }}
             <span *ngIf='contactPersonReadOnly.isInactive' class='text-danger italic ml-2'>
                {{"trainings.basicData.inactive" | translate}}
              </span>
            </span>
          </div>
        </span>
        <div *ngIf='formGroup.enabled' formGroupName="enrollment">
          <p-dropdown
            [filter]="true"
            [options]="contactPersons"
            appendTo="body"
            formControlName="contactPerson"
            id="enrollment.contactPerson"
            optionLabel="lastName"
            optionValue="personId"
            styleClass="w-full"

            placeholder="{{ 'enrollments.chooseOption' | translate }}"
            [showClear]="true"
          >
            <ng-template let-contactPerson pTemplate="selectedItem">
              <div *ngIf='contactPerson'>
               <span>{{ contactPerson.lastName }} {{ contactPerson.firstName }}
                 <span *ngIf='contactPerson.isInactive' class='text-danger italic ml-2'>
                    {{"trainings.basicData.inactive" | translate}}
                  </span>
                </span>
              </div>
            </ng-template>
            <ng-template let-contactPerson pTemplate="item">
              <div *ngIf='contactPerson'>
               <span>{{ contactPerson.lastName }} {{ contactPerson.firstName }}
                 <span *ngIf='contactPerson.isInactive' class='text-danger italic ml-2'>
                    {{"trainings.basicData.inactive" | translate}}
                  </span>
                </span>
              </div>
            </ng-template>
          </p-dropdown>
        </div>
      </alimento-ipv-frontend-form-field>
    </div>

    <div
      class="conditional-fields"
      *ngIf="formGroup.get('enrollment.contactPerson')?.value || formGroup.get('enrollment.isContact').value"
    >
      <div [hidden]='isContact'>
        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          [errorTemplate]='contactEmailErrorTemplate'
          formField='enrollment.contactEmail'
          fieldKey='enrollments.email'
        >
          <div formGroupName='enrollment'>
            <span *ngIf='formGroup.disabled || !isContact' class='inputHeight'>{{formGroup.get('enrollment.contactEmail').value}}</span>
            <input *ngIf='formGroup.enabled && isContact' class="w-full" formControlName="contactEmail" id="enrollment.contactEmail" pInputText type="text"/>
          </div>
          <ng-template #contactEmailErrorTemplate>
            <small *ngIf="formGroup.get('enrollment.contactEmail')?.errors?.['required']" class="p-error block">
              {{ 'validation.isRequired' | translate : { field: 'enrollments.email' | translate } }}
            </small>
            <small *ngIf="formGroup.get('enrollment.contactEmail')?.errors?.['email']" class="p-error block">
              {{ 'validation.invalidEmail' | translate }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>

        <alimento-ipv-frontend-form-field
          [formGroup]='formGroup'
          formField='enrollment.contactPhoneNumber'
          fieldKey='enrollments.phoneNumber'
        >
          <div formGroupName='enrollment'>
            <span *ngIf='formGroup.disabled || !isContact' class='inputHeight'>{{formGroup.get('enrollment.contactPhoneNumber').value}}</span>
            <input *ngIf='formGroup.enabled && isContact' class="w-full" formControlName="contactPhoneNumber" id="enrollment.contactPhoneNumber" pInputText type="text"/>
          </div>
        </alimento-ipv-frontend-form-field>
      </div>

      <alimento-ipv-frontend-form-field
        [formGroup]='formGroup'
        [required]="isContact"
        formField='enrollment.contactResponsibilities'
        fieldKey='enrollments.contactResponsibilities'
      >
          <div formGroupName='enrollment'>
            <span *ngIf='formGroup.disabled || !isContact' class='inputHeight'>{{contactResponsibilitiesReadOnly}}</span>
            <p-multiSelect
              *ngIf="formGroup.enabled && isContact"
              [filter]="true"
              [options]="responsibilities$ | async"
              appendTo="body"
              formControlName="contactResponsibilities"
              id="enrollments.contactResponsibilities"
              optionLabel="label"
              optionValue="data"
              placeholder="{{ 'enrollments.chooseOption' | translate }}"
              styleClass="w-full"
              [showClear]="true"
            ></p-multiSelect>
          </div>
      </alimento-ipv-frontend-form-field>
      <div class='mb-2' *ngIf='!isContact'>{{'enrollments.contactDataNotEditable' | translate}}</div>
    </div>
  </div>
</div>
