export const environment = {
  production: false,
  environmentName: 'test',
  color: 'bg-green',
  readApiUrl: 'https://app-dali-read-tst.azurewebsites.net/api/v1',
  writeApiUrl: 'https://app-dali-write-tst.azurewebsites.net/api/v1',
  externalApiUrl: "https://app-dali-external-website-tst.azurewebsites.net/api/v1",
  clientId: '419a76a8-e070-47f2-aa76-57468e280924',
  authority: 'https://login.microsoftonline.com/4791c31c-92a5-4ad3-af20-26f6169384f6',
  scopes: ['api://e5cf7308-49e5-43b2-a073-77d8051cbc80/Api.ReadWrite'],
  redirectUri: 'https://calm-bush-0cb724d03.2.azurestaticapps.net',
  appInsightsConnectionString:
    'InstrumentationKey=59ab946d-e1dd-4b11-88db-73e0f7a7bf31;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/',
};
