<p-confirmDialog [baseZIndex]="10000"></p-confirmDialog>
<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>

<alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                     [readonly]='readOnly'
                                     [linkedEntity]='trainingId'
                                     [type]='TrainingNote'
                                     (hasChanges)='notesHasChanges = $event'
></alimento-ipv-frontend-notes-sidebar>

<div *ngIf='trainingForm' [formGroup]="trainingForm">
  <alimento-ipv-frontend-detail-page-layout
    title="{{ getTitle() | async | translate }}"
    [navigationUrl]="['/trainings']"
    [relatedForm]="trainingForm"
  >
    <ng-container top-action>
      <div class="flex items-center">
        <alimento-ipv-frontend-form-field
          [formGroup]='trainingForm'
          [errorTemplate]='customTitleError'
          formGroupClass='!pb-0'
          formField='customTitle'
          labelClass='hidden'
        >
          <span class='text-xl' *ngIf='readOnly'>{{trainingForm.get("customTitle")?.value}}</span>
          <input
            *ngIf='!readOnly'
            id="customTitle"
            formControlName="customTitle"
            type="text"
            class="min-w-[500px] max-w-[1000px]"
            pInputText
            placeholder="{{ 'trainings.titlePlaceholder' | translate }}"
            data-test="custom-title-input"
            autoWidth
          />
          <ng-template #customTitleError>
            <small *ngIf="trainingForm.get('customTitle')?.hasError('required')">
              {{ 'validation.isRequired' | translate : { field: 'trainings.title' | translate | lowercase } }}
            </small>
            <small *ngIf="trainingForm.get('customTitle')?.hasError('maxlength')">
              {{ 'validation.maxLength' | translate : { number: 200 } }}
            </small>
          </ng-template>
        </alimento-ipv-frontend-form-field>

        <span *ngIf="training()?.trainingAlimentoId" class="text-xl mx-2 whitespace-nowrap text-text">
          - {{ training().trainingAlimentoId }}</span>
      </div>

      <div class="flex gap-4" *ngIf='!readOnly'>
        <span
          [pTooltip]="!trainingForm.get('customTitle')?.value ? ('trainings.saveNotPossibleTooltip' | translate) : ''"
        >
          <button
            pButton
            type="button"
            label="{{ 'trainings.actions.save' | translate }}"
            class="secondary-button uppercase"
            icon="pi pi-save"
            loadingIcon="pi pi-spin pi-spinner"
            (click)="saveTraining()"
            [loading]="savingTraining"
            [disabled]="isActivateDisabled"
            data-test="save-training-btn"
          ></button>
        </span>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        [data]="tabMenuItems"
        [extra]='extraMenuItems'
        [activeTabIndex]="activeTabIndex"
        (activeTabChanged)="setActiveTabIndex($event)"
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <alimento-ipv-frontend-basic-info
        [hidden]="activeTabIndex !== 0"
        *ngIf='type === TRAINING_TYPE.OPEN_TRAINING'
        [type]='type'
        [nrOfSessionsWithDates]='sessionService.nrOfSessionsWithDates'
        formControlName="basicDataForm"
        [showRequiredFieldErrors]="showRequiredFieldErrors"
        #basicInfoComponent
      >
      </alimento-ipv-frontend-basic-info>
      <alimento-ipv-frontend-training-project
        [hidden]="activeTabIndex !== 0"
        *ngIf='isTeacherProjectType(type) || isPupilProjectType(type) || isEventType(type)'
        [trainingType]='type'
        [trainingStatus]='trainingStatus'
        [showRequiredFieldErrors]="isEventType(type) || showRequiredFieldErrors"
        [firstSessionDate]='sessionService.sessions().length > 0 ? sessionService.sessions()[0]?.date : undefined'
        [lastSessionDate]='sessionService.sessions().length > 0 ? sessionService.sessions()[sessionService.sessions().length - 1]?.date : undefined'
        formControlName="basicDataForm"
        #trainingProjectComponent
      >
      </alimento-ipv-frontend-training-project>

      <alimento-ipv-frontend-summary-description
        [hidden]="activeTabIndex !== 1"
        formControlName="descriptionForm"
        [showRequiredFieldErrors]="isEventType(type) || showRequiredFieldErrors"
        [trainingType]='type'
        #summaryDescriptionComponent
      >
      </alimento-ipv-frontend-summary-description>

      <alimento-ipv-frontend-sessions
        [hidden]="activeTabIndex !== 2"
        [trainingId]="trainingId"
        [trainingStatus]="trainingStatus"
        [trainingMethodId]="methodId"
        [trainingType]='type'
        (sessionUpdated)='loadTrainingData()'
        (hasChanges)='onSessionChange($event)'
      >
      </alimento-ipv-frontend-sessions>

      <alimento-ipv-frontend-enrollments
        [hidden]="activeTabIndex !== 3 || isUnsupportedEnrollment()"
        [training]="training"
        [trainingStatus]="trainingStatus"
        [trainingType]="type"
        (hasChange)='onEnrollmentChange($event)'
      ></alimento-ipv-frontend-enrollments>

      <alimento-ipv-frontend-training-communications
        *ngIf='activeTabIndex === 4'
        [hidden]='activeTabIndex !== 4'
        [readOnly]='readOnly'
        [training]='training()'
      ></alimento-ipv-frontend-training-communications>

      <alimento-ipv-frontend-purchase-entries-overview *ngIf='activeTabIndex === 5'
                                                       [readOnly]='readOnly'
                                                       [trainingId]='trainingId'
                                                       [hasCounselor]='!!training()?.counselorId'
                                                       [purchaseEntries]='purchaseEntries'
                                                       (changes)='getPurchaseEntries()'
      >
      </alimento-ipv-frontend-purchase-entries-overview>
    </ng-container>

    <ng-container metadata>
      <alimento-ipv-frontend-training-status-chip
        [status]="getTrainingStatusKey(trainingStatus)"
        [trainingOngoing]="isOngoing()"
        [isCancelled]='training()?.isCancelled'
      ></alimento-ipv-frontend-training-status-chip>

      <div *ngIf='!readOnly && trainingStatus === trainingStatusKeys.Draft && !isEventType(type)' class='flex flex-col gap-4'>
        <button
          pButton
          pRipple
          type="button"
          label="{{ 'trainings.actions.saveAndActivate' | translate }}"
          class="p-button-primary p-button-outlined uppercase"
          loadingIcon="pi pi-spin pi-spinner"
          [loading]="activatingTraining"
          (click)="activateTraining()"
          [disabled]="!trainingReadyToActivate || savingTraining"
        ></button>

        <div *ngIf="!trainingReadyToActivate" >
          <div class="text-[16px]">
            <span [innerHTML]="'trainings.activateNotPossible' | translate"></span>
          </div>

          <div class="link-button link-button-with-icon">
            <p-inputSwitch #showRequiredToggle [formControl]="requiredFieldToggle"></p-inputSwitch>
            <span (click)="showRequiredToggle.writeValue(!showRequiredToggle.checked())">{{ 'trainings.showErrors' | translate }}</span>
          </div>

          <span class="h-[1px] bg-grey-2"></span>
        </div>

        <div
          class="link-button link-button-with-icon link-button--danger"
          *ngIf="trainingId"
          (click)="deleteTraining()"
          data-test="delete-training-btn"
        >
          <i class="pi pi-trash"></i>
          <span>{{ 'trainings.deleteDialog.delete' | translate }}</span>
        </div>
      </div>

      <div *ngIf='!readOnly && trainingStatus === trainingStatusKeys.Cancelled' class='flex flex-col gap-4'>
        <alimento-ipv-frontend-reactivate-training-dialog
          #reactivateTrainingDialog
          [training]='training'
          [firstSessionDate]='firstSessionDate'
          (reactivateTrainingConfirmed)="reactivateTraining($event)"
        >
        </alimento-ipv-frontend-reactivate-training-dialog>

        <button
          pButton
          pRipple
          type="button"
          label="{{ 'trainings.actions.reactivate' | translate }}"
          class="p-button-primary p-button-outlined uppercase"
          loadingIcon="pi pi-spin pi-spinner"
          [loading]="reactivatingTraining"
          (click)="reactivateTrainingDialog.open()"
        ></button>

      </div>

      <div *ngIf='!readOnly && trainingStatus !== trainingStatusKeys.Draft' class='flex flex-col gap-4'>
        <div class='flex flex-col'>
          <alimento-ipv-frontend-close-training-dialog
            #closeTrainingDialog
            [training]='training'
            [firstSessionDate]='firstSessionDate'
            (closeTrainingConfirmed)="closeTraining($event)"
          >
          </alimento-ipv-frontend-close-training-dialog>
          <button
            *ngIf='trainingStatus !== trainingStatusKeys.Completed'
            pButton
            pRipple
            type="button"
            label="{{ 'trainings.actions.close' | translate }}"
            class="p-button-primary p-button-outlined uppercase"
            loadingIcon="pi pi-spin pi-spinner"
            [loading]="closingTraining"
            [disabled]='!canCloseTraining'
            (click)="closeTrainingDialog.open()"
          ></button>

          <span class="text-[16px]" *ngIf='!canCloseTraining'>
            {{'trainings.cannotCloseTraining' | translate}}
          </span>
        </div>

        <div *ngIf='!isEventType(type)'>
          <div [pTooltip]='canPublish() ? "" :
        (trainingStatus === trainingStatusKeys.Completed ? "trainings.canNotPublishCompleted" : "trainings.publishNotAvailableTooltip") | translate'
               class="link-button link-button-with-icon justify-between flex-row-reverse">
            <p-inputSwitch #publishedToggleEl
                           [formControl]="publishedToggle"
            ></p-inputSwitch>
            <span (click)="publishedToggleEl.writeValue(!publishedToggleEl.checked())"
                  (keyup.enter)='publishedToggleEl.writeValue(!publishedToggleEl.checked())'
                  class='focus-visible:outline-0 focus-visible:underline'
                  tabindex='0'
            >
            {{ 'trainings.actions.publish' | translate }}
          </span>
          </div>
        </div>

        <span class="h-[1px] bg-grey-2"></span>

        <div>
          <alimento-ipv-frontend-participants-list-dialog (submitPopup)='requestParticipantsList($event)'
          ></alimento-ipv-frontend-participants-list-dialog>
          <alimento-ipv-frontend-certificates-dialog (submitPopup)='requestCertificates($event)'
                                                     [trainingId]='trainingId'
          ></alimento-ipv-frontend-certificates-dialog>
          <alimento-ipv-frontend-communicate-changes-dialog (submitPopup)='communicateChanges($event)'
          ></alimento-ipv-frontend-communicate-changes-dialog>
          <alimento-ipv-frontend-approve-enrollments-dialog (submitPopup)='approveEnrollments($event)'
          ></alimento-ipv-frontend-approve-enrollments-dialog>

          <div class="link-button link-button-with-icon justify-between" (click)="generateButtonClicked($event, menu)">
            <span tabindex='0'
                  class='focus-visible:outline-0 focus-visible:underline'
                  (keyup.enter)='generateButtonClicked($event, menu)'>
              {{ 'trainings.actions.generate' | translate }}
            </span>
            <i class='pi pi-chevron-down'></i>
          </div>
          <p-menu #menu [model]="generateItems" [popup]="true" >
            <ng-template pTemplate="item" let-item>
              <a class="p-menuitem-link flex gap-2 justify-between items-center p-3">
                <span>{{ item.label }}</span>
                <span class='font-material text-lg'>{{item.icon}}</span>
              </a>
            </ng-template>
          </p-menu>
        </div>

        <div *ngIf='trainingStatus === trainingStatusKeys.Active && trainingId && !isOngoing()'>
          <div class="cursor-pointer hover:underline focus-visible:outline-0 focus-visible:underline link-button--danger"
               tabindex='0'
               (click)="cancelTrainingDialog.open()"
               (keyup.enter)="cancelTrainingDialog.open()"
               [pTooltip]="isOngoing() ? ('trainings.firstSessionAlreadyOver' | translate) : ''"
          >
            <alimento-ipv-frontend-cancel-training-dialog
              #cancelTrainingDialog
              [training]='training()'
              [firstSessionDate]='firstSessionDate'
              (cancelTrainingConfirmed)="cancelTraining($event, cancelTrainingDialog)"
            >
            </alimento-ipv-frontend-cancel-training-dialog>
            <span>{{ 'trainings.cancelDialog.buttons.cancelTraining' | translate }}</span>
          </div>
        </div>
      </div>

      <span class="h-[1px] bg-grey-2" *ngIf="trainingId"></span>

      <ng-container *ngIf="reasonCancelled">
        <alimento-ipv-frontend-metadata-item
          title="{{ 'trainings.reasonCancelled' | translate }}"
          value="{{ reasonCancelled }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf="training()?.cancelledComment">
        <alimento-ipv-frontend-metadata-item
          title="{{ 'trainings.cancelledComment' | translate }}"
          value="{{ training().cancelledComment }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf="training()?.createdBy">
        <alimento-ipv-frontend-metadata-item
          title="{{ 'trainings.createdBy' | translate }}"
          value="{{ training().createdBy }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf="training()?.createdOn">
        <alimento-ipv-frontend-metadata-item
          title="{{ 'trainings.createdOn' | translate }}"
          value="{{ training().createdOn | date : 'dd/MM/yyyy' }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</div>
