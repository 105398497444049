import {
  BehaviorSubject,
  catchError,
  combineLatest, debounce,
  Observable,
  Observer,
  of,
  shareReplay,
  Subscribable, timer,
  Unsubscribable
} from 'rxjs';
import { map, scan, switchMap, tap } from 'rxjs/operators';
import { PaginatedResponse, SearchFilterValue, SearchRequest } from '../types/search.type';

export class InfiniteScrollDataAdapter<T> implements Subscribable<any> {

  _dataSource$: Observable<T[]>;

  subscribe(observer: Partial<Observer<any>>): Unsubscribable {
    // eslint-disable-next-line prefer-rest-params
    return this._dataSource$.subscribe(...arguments);
  }

  public loading$ = new BehaviorSubject(false);
  public loadingSlowly$ = new Observable<boolean>();
  public hasMore$ = new BehaviorSubject(true);
  public initialSearch$ = new BehaviorSubject(true);
  public remainingCount$ = new BehaviorSubject(0);
  public loadedCount$ = new BehaviorSubject(0);
  public totalCount$ = new BehaviorSubject(0);
  private _sessionSearchRequest$!: BehaviorSubject<SearchRequest>;
  private readonly _initialSearchRequest: SearchRequest;

  /***
   *
   * @param _dataSource Give a function tha returns observable that result in {data,totalElement}
   * @param searchRequest parameters for the query
   * @param isInitial
   */
  constructor(private _dataSource: (searchRequest: SearchRequest) => Observable<PaginatedResponse<T>>,
              searchRequest: SearchRequest, isInitial = false) {
    if (isInitial) {
      this._initialSearchRequest = searchRequest;
      this._sessionSearchRequest$ = new BehaviorSubject({} as SearchRequest);
    }
    else {
      this._sessionSearchRequest$ = new BehaviorSubject(searchRequest);
    }

    this.loadingSlowly$ = this.loading$.pipe(debounce(isLoading => {
      if (isLoading) {
        return timer(200);
      }
      else {
        return timer(0);
      }
    }));

    this._dataSource$ = combineLatest([this._sessionSearchRequest$]).pipe(
      tap(() => this.loading$.next(true)),
      switchMap(([queryParameters]) => this._dataSource(this._combineQueryParameters(queryParameters))
        .pipe(
          catchError((err) => {
            console.log('Error in fetching data');
            console.error(err);

            return of(new PaginatedResponse<T>([], 0));
          }),
          map(({ data, totalCount }) => {
            return { queryParameters, data: data, totalCount };
          })
        )),
      tap(({ totalCount, queryParameters }) => {
        const sumSkipTake = queryParameters.first + queryParameters.rows;
        this.loadedCount$.next(totalCount < sumSkipTake ? totalCount : sumSkipTake);
        this.hasMore$.next(totalCount > sumSkipTake);
        this.remainingCount$.next(Math.max(totalCount - sumSkipTake, 0));
        this.totalCount$.next(totalCount);
      }),
      scan((acc: T[], data) => {
        return data.queryParameters.first == 0 ? data.data : [...acc, ...data.data];
      }, []),
      tap(() => this.loading$.next(false)),
      tap(() => this.initialSearch$.next(false)),
      shareReplay(1)
    );
  }

  getCurrentSearchRequest(): SearchRequest {
    return this._combineQueryParameters(this._sessionSearchRequest$.getValue());
  }

  private _combineQueryParameters(queryParameters: SearchRequest): SearchRequest {
    if (this.initialSearch$.getValue() && this._initialSearchRequest) {
      if (this._initialSearchRequest?.filters) {
        const keys: string[] = [];
        queryParameters?.filters.forEach((filter: SearchFilterValue) => {
          keys.push(filter.type);
          filter.values = filter.values
            .concat((this._initialSearchRequest?.filters.find((initFilter: SearchFilterValue) => initFilter.type === filter.type)?.values || [])
              .filter((item: SearchFilterValue) => filter.values.indexOf(item) < 0));
        });
        this._initialSearchRequest?.filters.forEach((initFilter: SearchFilterValue) => {
          if (keys.indexOf(initFilter.type) < 0) {
            queryParameters?.filters.push(initFilter);
          }
        });
      }
      Object.keys(this._initialSearchRequest).filter(a => !Object.keys(queryParameters).includes(a))
        .forEach(param => queryParameters[param] = this._initialSearchRequest[param]);
    }
    return queryParameters;
  }

  /**
   * This method will allow you to load more in the list
   */
  loadMore(first?: number, rows?: number): void {
    if (this.loading$.getValue()) {
      return;
    }
    const searchRequest = this._sessionSearchRequest$.getValue();
    if (first && rows) {
      searchRequest.rows = rows;
      searchRequest.first = first;
    }
    else {
      searchRequest.first = searchRequest.rows + searchRequest.first;
    }
    this._sessionSearchRequest$.next(searchRequest);
  }

  query(searchRequest: SearchRequest): void {
    this.initialSearch$.next(true);
    this._sessionSearchRequest$.next(searchRequest);
  }

  getInitialSearchRequest(): SearchRequest {
    return this._initialSearchRequest;
  }
}
