import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { FilterType, IFilter, NO_BRANCH_ID, SearchParam } from '@alimento-ipv-frontend/ui-lib';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmploymentMapper {

  constructor(private translateService: TranslateService) {
  }

  getEmploymentRoleFilter(): Observable<IFilter[]> {
    return of([FilterType.filterIsContactPerson, FilterType.filterIsTeacher])
      .pipe(map(types => types.map(type =>
        ({
          type: FilterType.employmentRole,
          label: this.translateService.instant("search.filters." + type),
          value: type
        }) as IFilter)));
  }

  setEmploymentRoleFilter(params: SearchParam): void {
    if (params[FilterType.employmentRole]) {
      params[FilterType.filterIsContactPerson] = params[FilterType.employmentRole].includes(FilterType.filterIsContactPerson) ? true : undefined;
      params[FilterType.filterIsTeacher] = params[FilterType.employmentRole].includes(FilterType.filterIsTeacher) ? true : undefined;
      delete params[FilterType.employmentRole];
    }
  }

  setBranchName(employment: {[key: string]: any, branchName: string, branchId: string }): void {
    if (employment.branchId === NO_BRANCH_ID) {
      employment.branchName = this.translateService.instant("persons.employments.noBranch");
    }
  }
}
