<alimento-ipv-frontend-training-plan-popup #trainingPlanPopupComponent
                                           [trainingPlan]='currentTrainingPlan'
                                           (formSubmit)='trainingPlanSubmit($event, trainingPlanPopupComponent)'
></alimento-ipv-frontend-training-plan-popup>
<alimento-ipv-frontend-training-plan-action-dialog [trainingPlan]='currentTrainingPlan'
                                                   [action]='currentTrainingPlanAction'
                                                   (submitDialog)='executeTrainingPlanAction($event)'
></alimento-ipv-frontend-training-plan-action-dialog>

<alimento-ipv-frontend-header>
  <div title class='flex gap-2 items-center'>
    <h2 class="text-xl text-text font-bold uppercase">{{ 'branches.trainingPlansTitle' | translate }}</h2>
    <a *ngIf='yearFilter'
       class='text-primary cursor-pointer'
       [routerLink]='["/branches", branchId, "detail"]'
       [queryParams]='{activeTabIndex:4}'>
      {{'branches.trainingPlans.manage' | translate}}
    </a>
  </div>
  <div *removeIfFeatureOff='TRAINING_PLAN'>
    <p-button
      (onClick)='createNewTrainingPlan(trainingPlanPopupComponent)'
      *ngIf='!readOnly'
      icon='pi pi-plus'
      label="{{ 'branches.trainingPlans.new' | translate | uppercase }}"
    ></p-button>
  </div>
</alimento-ipv-frontend-header>

<div *ngIf='trainingPlans | async as plans' class='flex flex-col gap-2'>
  <div *ngFor='let plan of plans'>
    <alimento-ipv-frontend-training-plan-card
      *ngIf='showTrainingPlan(plan)'
      [trainingPlan]='plan'
      [readOnly]='readOnly'
      (actionClicked)='trainingPlanActionClicked($event, trainingPlanPopupComponent)'
    ></alimento-ipv-frontend-training-plan-card>
  </div>
  <div *ngIf='plans.length === 0'>{{ 'branches.noTrainingPlans' | translate }}</div>
</div>
