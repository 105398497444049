<alimento-ipv-frontend-purchase-entry-create-dialog #createDialog
                                                    [branches]='branches'
                                                    (submitPopup)='executeCreatePurchaseEntries($event, createDialog)'
></alimento-ipv-frontend-purchase-entry-create-dialog>

<alimento-ipv-frontend-header title="{{ 'trainings.purchaseEntries.menuTitle' | translate }}">
  <div>
    <p-button
      *ngIf="showCreatePurchaseEntriesButton"
      (onClick)='createPurchaseEntries(createDialog)'
      label="{{ 'trainings.purchaseEntries.create' | translate | uppercase }}"
    ></p-button>
  </div>
</alimento-ipv-frontend-header>

<div *ngIf='purchaseEntries()'>
  <div class='flex flex-col'>
    <div *ngFor='let purchaseEntry of purchaseEntries()' class='border-b border-b-grey-1 last:border-none'>
      <alimento-ipv-frontend-purchase-entry-card
        [purchaseEntry]='purchaseEntry'
        [readOnly]='readOnly'
      ></alimento-ipv-frontend-purchase-entry-card>
    </div>
  </div>

  <div *ngIf='purchaseEntries().length === 0'>{{ 'trainings.purchaseEntries.noPurchaseEntries' | translate }}</div>
</div>
