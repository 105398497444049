<div *ngIf="trainingProgram">
  <alimento-ipv-frontend-detail-page-layout
    title="{{ 'trainingPrograms.trainingProgram' | translate }}"
    [navigateText]='"trainingPrograms.toBranch" | translate'
    [navigationUrl]="['/branches', trainingProgram.branchId, 'detail']"
  >
    <ng-container top-action>
      <div class="text-xl">
        <span class='text-text'>{{ trainingProgram.name }}</span>
        <span class="mx-2 whitespace-nowrap text-text">- {{ trainingProgram.trainingProgramAlimentoId }}</span>
      </div>

      <div class="buttons" *ngIf='!readOnly && trainingProgramId'>
        <button
          pButton
          type="button"
          label="{{ 'reimbursementRequests.actions.save' | translate }}"
          class="secondary-button uppercase"
          icon="pi pi-save"
          loadingIcon="pi pi-spin pi-spinner"
          (click)="saveReimbursementRequest()"
          [loading]="saving"
        ></button>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        (activeTabChanged)="setActiveTabIndex($event)"
        [activeTabIndex]="activeTabIndex"
        [data]="tabMenuItems"
        [extra]='extraMenuItems'
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <div [hidden]="activeTabIndex !== 0">
        <alimento-ipv-frontend-header title="{{ 'trainingPrograms.basicData.formTitle' | translate }}">
        </alimento-ipv-frontend-header>
        <alimento-ipv-frontend-training-program
          [trainingProgram]='trainingProgram'
        ></alimento-ipv-frontend-training-program>
      </div>

      <div *ngIf="activeTabIndex === 1">
        <alimento-ipv-frontend-header title="{{ 'reimbursementRequests.trainingTitle' | translate }}">
        </alimento-ipv-frontend-header>

        <lib-search [data$]='searchTrainingData$'
                    [filters]='searchTrainingFilters'
                    [cardTemplate]='cardTemplate'
                    [inlineFilters]='true'
                    [activeFiltersOnTop]='false'
                    [showSearchFilter]='false'
                    name='training-program-reimbursement-requests'
                    searchPlaceholderKey='trainings.searchbarPlaceholder'
                    availableResultsTranslateKey='trainings.nrOfTrainings'
        >
          <ng-template #cardTemplate let-item="item">
            <alimento-ipv-frontend-training-card
              [training]='item'
              tabindex="0"
            >
            </alimento-ipv-frontend-training-card>
          </ng-template>
        </lib-search>
      </div>
    </ng-container>

    <ng-container metadata>
<!--      <alimento-ipv-frontend-metadata-item title='trainingPrograms.branch'-->
<!--                                           [value]='trainingProgram.branch'-->
<!--      ></alimento-ipv-frontend-metadata-item>-->
    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</div>
