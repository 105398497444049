import { Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first, Subscription } from 'rxjs';
import { MessageService } from 'primeng/api';
import { validateAllFormFields } from '@alimento-ipv-frontend/ui-lib';
import { TranslateService } from '@ngx-translate/core';
import { RegisterAbsenceEvent, SessionListItem, SessionParticipant } from '../../../types/session.type';
import { AbsenceReason } from '../../../types/reference-data.type';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-register-absence-dialog',
  templateUrl: './register-absence-dialog.component.html',
  styleUrls: ['./register-absence-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegisterAbsenceDialogComponent implements OnDestroy {
  session: SessionListItem;

  @Input()
  readOnly = false;

  @Output()
  submitPopup = new EventEmitter<RegisterAbsenceEvent>();

  dialogVisible = false;
  formGroup: FormGroup;
  absenceReasons: AbsenceReason[] = [];
  nrOfAbsences = 0;
  loading = false;
  searchParticipantsControl = new FormControl<string>('');
  matchingIds: number[] = [];

  private _subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private referenceDataService: ReferenceDataService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    this.referenceDataService
      .getAbsenceReasons()
      .pipe(first())
      .subscribe((data) => (this.absenceReasons = data));
    this._subscriptions.push(
      this.searchParticipantsControl.valueChanges.subscribe(() => {
        this._calculateMatchingIds();
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  open(session: SessionListItem, participants: SessionParticipant[], absenceRegistrationComplete: boolean) {
    this.session = session;
    this._createFormGroup(participants, absenceRegistrationComplete);
    this.dialogVisible = true;
  }

  onSubmit() {
    if (!this.isDataValid()) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('error.fillInRequiredFields')
      });
      return;
    }

    const registerAbsenceBody = JSON.parse(JSON.stringify(this.formGroup.value));
    registerAbsenceBody.absences = registerAbsenceBody.absences.filter((absence: any) => absence.absent);

    this.submitPopup.emit({
      sessionId: this.session.id,
      registerAbsenceBody: registerAbsenceBody,
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.formGroup.reset();
  }

  private isDataValid(): boolean {
    validateAllFormFields(this.formGroup);
    return this.formGroup.valid;
  }

  private _createFormGroup(participants: SessionParticipant[], absenceRegistrationComplete: boolean): void {
    this.formGroup = this.fb.group({
      absences: this.fb.array([]),
      absenceRegistrationComplete: [absenceRegistrationComplete]
    });

    if (this.readOnly) {
      this.formGroup.disable({ emitEvent: false });
    }

    this._subscriptions.push(
      this.formGroup.valueChanges.subscribe((newValue) => {
        this.nrOfAbsences = newValue.absences.filter((absence: any) => absence.absent).length;
      })
    );

    participants.forEach((participant) => {
      const personGroup = this.fb.group({
        personId: participant.personId,
        firstName: participant.firstName,
        lastName: participant.lastName,
        absent: [participant.absent],
        reasonForAbsenceId: [participant?.reasonForAbsence?.data]
      });

      this.participants.push(personGroup);

      if (this.readOnly) {
        personGroup.disable({emitEvent: false});
      }

      this._subscriptions.push(
        personGroup.get('absent').valueChanges.subscribe((absent: boolean) => {
          personGroup.get('reasonForAbsenceId').setValidators(absent ? Validators.required : []);
          personGroup.get('reasonForAbsenceId').reset();
          personGroup.get('reasonForAbsenceId').updateValueAndValidity();
        })
      );
    });
    this._calculateMatchingIds();
  }

  get participants(): FormArray {
    return this.formGroup.controls['absences'] as FormArray;
  }

  clearFilters() {
    this.searchParticipantsControl.setValue('');
  }

  private _calculateMatchingIds() {
    const searchValue = this.searchParticipantsControl.value;
    this.matchingIds = [];
    for (let i = 0; i < this.participants.length; i++) {
      const participant = this.participants.controls[i].value;
      const searchFields = [
        participant.firstName + ' ' + participant.lastName,
        participant.lastName + ' ' + participant.firstName
      ];

      if (searchFields.some((field) => field.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)) {
        this.matchingIds.push(i);
      }
    }
  }

  getReasonLabel(value: string | undefined): string {
    if (value) {
      return this.absenceReasons.filter(reason => reason.data === value)[0]?.label || '';
    }
    return '';
  }
}
