<div class="flex items-center px-2 relative rounded group {{elementClass}}"
     [ngClass]="{
     'p-4 border shadow-md shadow-purple-10 hover:bg-purple-lighter border-purple-10 m-2': styling === 1,
     'p-4 border shadow hover:bg-purple hover:bg-opacity-10 border-purple border-opacity-20 mb-2': styling === 2,
     'pr-10': navigable,
     'cursor-pointer': selectable,
     'border-yellow bg-yellow bg-opacity-20 hover:border-yellow': isSelected
     }"
>
  <div *ngIf="navigable" class="absolute right-3 top-4">
    <i class="fa fa fa-chevron-right text-yellow"></i>
  </div>
  <ng-content></ng-content>
</div>