import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  FilterType,
  MyMessageService,
  SearchFilterValue,
  validateAllFormFields
} from '@alimento-ipv-frontend/ui-lib';
import { delay, first, Subscription } from 'rxjs';
import { EnrollmentRequestParticipant, ParticipantUpdateEvent } from '../../../types/enrollment.type';
import { Person, SearchPersonItem } from '../../../types/person.type';
import { PersonComponent } from '../../../persons/components/person/person.component';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { EmploymentService } from '../../../services/employment.service';
import { PersonService } from '../../../services/person.service';

@Component({
  selector: 'alimento-ipv-frontend-enrollment-request-participant-popup',
  templateUrl: './enrollment-request-participant-popup.component.html'
})
export class EnrollmentRequestParticipantPopupComponent implements OnChanges, OnDestroy {
  @Input()
  participant: EnrollmentRequestParticipant;

  @Input()
  showSearch = true;

  @Input()
  groupId?: string;

  @Input()
  disableUniquePersonValidation = false;

  @Input()
  dataOptional = false;

  @Output()
  formSubmit = new EventEmitter<ParticipantUpdateEvent>();

  popupVisible = false;
  formGroup!: FormGroup;
  loading = false;

  personFormControl = new FormControl<string>('existing');
  searchPersonFormControl = new FormControl<SearchPersonItem>(undefined);
  person?: Person;
  showInterimOfficeFields = false;
  workStatusReadOnly: string;
  interimOfficeReadOnly: string;
  workStatus$ = this.referenceDataService.getWorkStatuses();
  groupIdFilter: SearchFilterValue[] = [];

  private _subscriptions: (Subscription | undefined)[] = [];
  private _formSubscriptions: (Subscription | undefined)[] = [];
  readonly _INTERIM_PC_118 = 'a3bd8f32-ab6d-4749-8b1b-11ef962b5529';
  readonly _INTERIM_PC_220 = '4d8b315e-da9e-445f-ab54-68737fbfd1cb';

  @ViewChild(PersonComponent)
  personForm: PersonComponent;

  constructor(private formBuilder: FormBuilder,
              private messageService: MyMessageService,
              private referenceDataService: ReferenceDataService,
              private employmentService: EmploymentService,
              private personService: PersonService) {
    this._subscriptions.push(
      this.personFormControl.valueChanges.subscribe(() => {
        this.person = undefined;
        this.searchPersonFormControl.setValue(undefined);
      }),
      this.searchPersonFormControl.valueChanges.subscribe(personSearch => {
        if (personSearch) {
          this.personService.getPerson(personSearch.personId).pipe(first())
            .subscribe(person => {
              this.person = person;
              this.formGroup.controls['person'].patchValue({
                'lastName': person.lastName
              });
              this._setProfessionalData();
        });
        }
        else {
          this.person = undefined;
        }
        this._setProfessionalData();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['participant']?.currentValue) {
      this._createFormGroup();
      this.personFormControl.setValue(this.participant.id ? "new" : "existing");
    }
    if (changes['groupId']?.currentValue) {
      this.groupIdFilter = [{type: FilterType.filterEnterpriseGroupId, values: [this.groupId]}];
    }
    if (changes['showSearch']?.currentValue !== undefined) {
      this.personFormControl.setValue(this.showSearch ? "existing" : "new");
    }
    if (changes['dataOptional']?.currentValue !== undefined) {
      this._setValidation();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription?.unsubscribe());
    this._formSubscriptions.forEach(subscription => subscription?.unsubscribe());
  }

  openPopup(): void {
    this.popupVisible = true;
    this._createFormGroup();
  }

  closePopup(): void {
    this.popupVisible = false;
    delete this.formGroup;
    this._formSubscriptions.forEach(sub => sub?.unsubscribe());
    this._formSubscriptions = [];
    this.person = undefined;
    this.searchPersonFormControl.setValue(undefined);
  }

  private _isPersonValid(): boolean {
    if (this.personFormControl.value === 'new') {
      return this.personForm.isValid();
    }
    else {
      return this.person !== undefined;
    }
  }

  isDataValid(): boolean {
    validateAllFormFields(this.formGroup);

    return this._isPersonValid() && this.formGroup.valid;
  }

  getFormData(): EnrollmentRequestParticipant {
    let data = JSON.parse(JSON.stringify(this.formGroup.value));

    delete data.person;
    if (this.personFormControl.value === 'new') {
      data = { ...data, ...this.personForm.getData() };
      data.genderId = data.gender;
      delete data.gender;
      data.educationLevelId = data.educationLevel;
      delete data.educationLevel;
    }
    else {
      data = {
        ...data,
        ...{
          nationalIdentificationNumber: this.person?.nationalIdentificationNumber,
          firstName: this.person?.firstName,
          lastName: this.person?.lastName,
          dateOfBirth: this.person?.dateOfBirth,
          educationLevelId: this.person?.educationLevel?.data,
          genderId: this.person?.gender?.data,
          nationality: this.person?.nationality,
          language: this.person?.language,
          personId: this.person?.personId
        }
      };
    }

    data.employerId = data.employer?.branchId || data.employer;
    delete data.employer;

    data.id = this.participant?.id;

    return data;
  }

  addOrUpdateForm(): void {
    this._submitForm();
  }

  private _submitForm(): void {
    if (!this._isPersonValid()) {
      if(this.personFormControl.value === 'new')
      {
        this.messageService.error('validation.personIncomplete');
      }
      else{
        this.messageService.error('validation.noPersonSelected');
      }
      return;
    }
    else if (!this.isDataValid()) {
      this.messageService.notAllFieldsValid();
      return;
    }

    this.formSubmit.emit({
      participant: this.getFormData(),
      setLoading: (value: boolean) => (this.loading = value)
    });
  }

  private _createFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      person: [{
        nationalIdentificationNumber: this.participant?.nationalIdentificationNumber,
        firstName: this.participant?.firstName,
        lastName: this.participant?.lastName,
        gender: this.participant?.genderId ? { data: this.participant?.genderId } : undefined,
        language: this.participant?.language,
        nationality: this.participant?.nationality,
        educationLevel: this.participant?.educationLevelId ? { data: this.participant?.educationLevelId } : undefined,
        dateOfBirth: this.participant?.dateOfBirth ? new Date(this.participant?.dateOfBirth) : undefined,
        street: undefined,
        houseNumber: undefined,
        mailbox: undefined,
        city: undefined,
        postalCode: undefined,
        country: undefined
      }],
      employer: [this.participant?.employerId, Validators.required],
      function: [this.participant?.function],
      workStatusId: [this.participant?.workStatusId, Validators.required],
      interimOfficeId: [this.participant?.interimOfficeId],
    });

    this._setValidation();
    this._addListeners();
    this._updateInterimRequiredFields();
  }

  private _setValidation(): void {
    this.formGroup?.get("employer").setValidators(this.dataOptional ? [] : [Validators.required]);
    this.formGroup?.get("workStatusId").setValidators(this.dataOptional ? [] : [Validators.required]);
  }

  private _addListeners(): void {
    this._formSubscriptions.forEach((sub) => sub.unsubscribe());
    this._formSubscriptions = [];

    this._formSubscriptions.push(
      this.formGroup.get('workStatusId').valueChanges.pipe(delay(1))
        .subscribe(() => this._updateInterimRequiredFields())
    );

    this._formSubscriptions.push(
      this.formGroup.get("employer").valueChanges.subscribe(() => this._setProfessionalData())
    );
  }

  private _updateInterimRequiredFields(): void {
    const workStatus = this.formGroup.get('workStatusId').value;
    this.showInterimOfficeFields = workStatus === this._INTERIM_PC_118 || workStatus === this._INTERIM_PC_220;
    this.formGroup.get('interimOfficeId').setValidators(this.showInterimOfficeFields ? Validators.required : []);
    this.formGroup.get('interimOfficeId').updateValueAndValidity();
  }

  private _setProfessionalData(): void {
    if (this.formGroup?.get('employer')?.value && this.person) {
      const employerId = this.formGroup.get('employer').value?.branchId || this.formGroup.get('employer').value
      this.employmentService.getEmploymentsOfPersonByBranch(employerId, this.person.personId)
        .pipe(first())
        .subscribe(employment => {
          if (employment) {
            this.formGroup.get('workStatusId').setValue(employment.workStatus?.data);
            this.formGroup.get('function').setValue(employment.function);
            this._updateInterimRequiredFields();
          }
        });
    }
  }
}
