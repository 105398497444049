import { Component } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'alimento-ipv-frontend-jwt-token-page',
  templateUrl: './jwt-token-page.component.html',
  styleUrls: ['./jwt-token-page.component.scss'],
})
export class JwtTokenPageComponent {
  jwtToken = this.authenticationService.getJwtToken();

  constructor(private authenticationService: AuthenticationService) {
  }
}
