<div
  class="flex w-full gap-2 px-5 py-3 text-lg
  {{ !!enrollment ? 'border-b border-b-grey-2 hover:bg-primary-hover' : '' }}
  {{ isCancelled ? 'cursor-pointer' : ''}}"
  (click)='toggleShowExtraInfo(); $event.stopImmediatePropagation();'
>
  <div class='w-4 h-4'>
    <div
      (keyup.enter)='toggleShowExtraInfo()'
      *ngIf='isCancelled'
      class='flex items-center py-2 text-primary outline-primary'
      tabindex='0'
    >
      <i class="pi pi-angle-{{ showExtraInfo ? 'up' : 'down' }}"></i>
    </div>
  </div>
  <div class='flex-1' isEllipsis pTooltip>
    <span *ngIf='enrollment'>{{ (enrollment.lastName || '') + ' ' + (enrollment.firstName || '') }}</span>
    <span *ngIf='!enrollment' class='font-bold'>{{ 'enrollments.name' | translate }}</span>
  </div>
  <div class='flex-1' isEllipsis pTooltip>
    <span *ngIf='enrollment'>{{ enrollment.branch?.label }}</span>
    <span *ngIf='!enrollment' class='font-bold'>{{ 'enrollments.company' | translate }}</span>
  </div>
  <div class='basis-[240px]' isEllipsis pTooltip>
    <div *ngIf='enrollment' class='flex items-center gap-2'>
      <span>{{ enrollment.workStatus?.label }}</span>
      <i *ngIf='enrollment.via === CEVORA'
         pTooltip='{{"enrollments.cevora" | translate}}'
         class='bg-alimento-green bg-opacity-30 px-1 rounded not-italic font-bold'>
        C
      </i>
    </div>
    <span *ngIf='!enrollment' class='font-bold'>{{ 'enrollments.statut' | translate }}</span>
  </div>
  <div class='basis-[150px]' isEllipsis pTooltip>
    <span *ngIf='enrollment'>{{ enrollment.subscribed | date : 'dd/MM/yyyy' }}</span>
    <span *ngIf='!enrollment' class='font-bold'>{{ 'enrollments.date' | translate }}</span>
  </div>
  <div class='basis-[40px] flex flex-col items-end'>
    <div *ngIf='enrollment'>
      <alimento-ipv-frontend-more-actions [actions]='actions'></alimento-ipv-frontend-more-actions>
    </div>
  </div>
</div>
<div *ngIf='showExtraInfo' class='flex w-full conditional-fields !pb-4'>
  <div class='w-1/2 px-4 border-r border-r-grey-2'>
    <alimento-ipv-frontend-metadata-item
      [title]='"enrollments.cancelDialog.reasonCancelled" | translate'
      [value]='reasonCancelled'
    ></alimento-ipv-frontend-metadata-item>
  </div>
  <div class='w-1/2 px-4'>
    <alimento-ipv-frontend-metadata-item
      [title]='"enrollments.cancelDialog.comment" | translate'
      [value]='enrollment?.cancelledComment'
    ></alimento-ipv-frontend-metadata-item>
  </div>
</div>
