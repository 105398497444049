<div *ngIf='editMode || !note?.id' [formGroup]='formGroup' class='bg-white p-4'>
  <alimento-ipv-frontend-form-field [formGroup]='formGroup'
                                    [required]='true'
                                    fieldKey='notes.content'
                                    formField='content'
  >
    <textarea [autofocus]='true'
              class='w-full'
              cols='30'
              formControlName='content'
              id='content'
              pInputTextarea
              rows='5'
    ></textarea>
  </alimento-ipv-frontend-form-field>

  <div class='flex items-center justify-between border-t border-t-grey-2 pt-4'>
    <p-button
      (onClick)="addNote()"
      [loading]='loading'
      icon="pi pi-save"
      iconPos="left"
      label="{{ (note?.id ? 'notes.update' : 'notes.save') | translate }}"
    ></p-button>

    <p-button
      (onClick)="cancelAddNote()"
      icon="pi pi-times"
      iconPos="left"
      label="{{ 'notes.cancel' | translate }}"
      styleClass="inverted-button"
    ></p-button>
  </div>
</div>

<div *ngIf='note && note.id && !editMode' class='flex flex-col gap-2 bg-white p-4 items-start'>
  <div class='flex items-center w-full justify-between'>
    <div class='flex items-center text-grey-4 text-sm gap-4'>
      <span class='border-r border-r-grey-2 pr-4'>{{note.createdOn | date:'dd/MM/yyyy - HH:mm'}}</span>
      <span [pTooltip]='note.createdBy'>{{note.createdBy | initials }}</span>
    </div>
    <alimento-ipv-frontend-more-actions *ngIf='!readonly' [actions]='actions'></alimento-ipv-frontend-more-actions>
  </div>
  <div #content
       class='text-text whitespace-pre-line'
       [ngClass]='{
       "h-full overflow-auto": showMore,
       "max-h-[72px] overflow-hidden": !showMore
       }'>{{note.content}}</div>
  <button *ngIf='showMore || hasOverflow(content)' class='link-button font-bold' (click)="toggleShowMore()">
    {{ (showMore ? 'notes.showLess': 'notes.showMore') | translate }}
  </button>
</div>
