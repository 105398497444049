<div class='mt-4 mx-8'>
  <div class="flex items-center justify-between mb-4">
    <h1 class="uppercase">{{ 'enterprises.menuTitle' | translate }}</h1>
   <p-button
     *removeIfFeatureOff='FEATURE.BRANCH_AND_ENTERPRISE_MANAGEMENT'
      label="{{ 'enterprises.actions.add' | translate }}"
      styleClass="uppercase"
      [routerLink]="['create']"
    ></p-button>
  </div>

  <lib-search [data$]='searchData$'
              [filters]='searchFilters'
              [cardTemplate]='cardTemplate'
              name='enterprises'
              searchPlaceholderKey='enterprises.searchPlaceholder'
              availableResultsTranslateKey='enterprises.menuTitle'
  >
    <ng-template #cardTemplate let-item="item">
      <alimento-ipv-frontend-enterprise-card
        [enterprise]='item'
        [routerLink]='["/enterprises", item.enterpriseId, "detail"]'
        tabindex="0"
      ></alimento-ipv-frontend-enterprise-card>
    </ng-template>
  </lib-search>
</div>
