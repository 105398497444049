export enum EmploymentActionEnum {
  view,
  create,
  edit,
  setAsMainEmployment,
  stopEmployment,
}

export enum EMPLOYMENT_ITEM_VIEW_MODE {
  PERSON = 'PERSON',
  BRANCH = 'BRANCH'
}

export enum TRANSITION_STATE {
  DRAFT = "00884eaf-3162-464d-ba5e-df9c678c1001",
  ACTIVE = "0161f571-ca5c-4b12-b6e4-31e7b6e21778",
  CLOSED = "9858811b-8fd5-41f0-b108-8f80b334de1e",
  REJECTED = "8f900561-0089-4405-a065-b446f0f44c04"
}

export enum TransitionActionEnum {
  view = "view",
  create = "create",
  edit = "edit",
  delete = "delete",
  activate = "activate",
  reject = "reject",
  reopen = "reopen",
  close= "close",
  reactivate = "reactivate"
}

export enum WORK_STATUS {
  PC_118 = "fbff2964-c902-4004-b2dd-9fb1fc24e705",
  PC_220 = "119826bd-4c51-4721-a8e8-12dd957d75e1"
}

export enum TRANSITION_TYPE {
  IN = '1ddf5a42-9460-48ba-a3f9-da2c0dc19a44',
  OUT = '1c7f1b92-b828-4477-a029-b5fbcbe9514b',
  FUNCTION_CHANGE = '0a7514ca-6abb-4ebf-9feb-991750cd576d',
  PERSONAL = "3d7fb168-eea7-4640-9db6-846314865ce8",
  RESUMPTION = "3559fac0-5242-4d2b-b061-e92f3917b325",
  YOUNG = '205ca546-527c-40f5-b8f2-0b12913cb2ea',
  OLD = 'b1e278b4-10bb-4e81-8085-1295ab09bec2'
}
