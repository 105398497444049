import {Injectable, SecurityContext} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";

@Injectable({
  providedIn: "root"
})
export class HtmlUtils {

  public static outerHeight(el: any): any {
    if (!el) {
      return 0;
    }
    let height = el.offsetHeight;
    const style = getComputedStyle(el);

    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
  }

  public static innerHeight(el: any): any {
    if (!el) {
      return 0;
    }
    const style = getComputedStyle(el);
    let height = parseInt(style.height);
    height -= parseInt(style.paddingTop) + parseFloat(style.paddingBottom);

    return height;
  }

  constructor(private sanitizer: DomSanitizer) {
  }

  public sanitize(html: string): string {
    ['img', 'video', 'audio']
      .forEach(tag => {
        const regex = new RegExp('<' + tag + '[^>]*>', "g");
        const regex2 = new RegExp('<' + tag.toUpperCase() + '[^>]*>', "g");
        html = html.replace(regex, "").replace(regex2, "");
      });
    html = html.replace(/<p><\/p>/g, "");
    html = html.replace(/^(<p><br><\/p>)*/g, "");
    html = html.replace(/(<p><br><\/p>)*$/g, "");
    html = html.replace(/class=\\\\'\\\\'/g, "");
    html = html.replace(/class=\\\\"\\\\"/g, "");
    return this.sanitizer.sanitize(SecurityContext.HTML, html);
  }

  public convertQuillListsToHtml(text: string): string {
    const tempEl = window.document.createElement('div');
    tempEl.setAttribute('style', 'display: none;');
    tempEl.innerHTML = text;

    ['ul', 'ol'].forEach((type) => {
      const startTag = `::start${type}::::/start${type}::`;
      const endTag = `::end${type}::::/end${type}::`;

      Array.from(tempEl.querySelectorAll(type)).forEach((outerListEl) => {
        const listChildren = Array.from(outerListEl.children).filter((el) => el.tagName === 'LI');
        if (listChildren.length === 0) {
          return;
        }
        const firstLi = listChildren[0];
        const classNumber = this._getClassAsNumber(firstLi);
        let totalAdd = classNumber;
        firstLi.before(startTag.repeat(classNumber));

        listChildren.forEach((listEl, index) => {
          const currentLiLevel = this._getClassAsNumber(listEl);
          if (index < listChildren.length - 1) {
            const difference = this._getClassAsNumber(listChildren[index + 1]) - currentLiLevel;

            if (difference > 0) {
              listChildren[index + 1].before(startTag.repeat(difference));
              totalAdd += difference;
            }
            else if (difference < 0) {
              listEl.after(endTag.repeat(-difference));
              totalAdd += difference
            }
          }
        });
        outerListEl.after(endTag.repeat(totalAdd));
      });
    });
    let newContent = tempEl.innerHTML;
    newContent = newContent.replace(/::startul::::\/startul::/g, '<ul>');
    newContent = newContent.replace(/::endul::::\/endul::/g, '</ul>');
    newContent = newContent.replace(/::startol::::\/startol::/g, '<ol>');
    newContent = newContent.replace(/::endol::::\/endol::/g, '</ol>');
    newContent = newContent.replace(/ class="ql-indent-[\d]*"/g, '');
    newContent = newContent.replace(/ql-indent-[\d]*/g, '');
    tempEl.remove();
    return newContent;
  }

  public convertHtmlListsToQuill(editorValue: string): string {
    if (document) {
      const tempEditorNode = document.createElement('div');
      tempEditorNode.style.setProperty('display', 'none');
      tempEditorNode.innerHTML = editorValue;
      const olElems = tempEditorNode.querySelectorAll('ol');
      const ulElems = tempEditorNode.querySelectorAll('ul');
      this._moveNestedList(olElems);
      this._moveNestedList(ulElems);

      return tempEditorNode.innerHTML;
    }
    return "";
  }

  private _getClassAsNumber(element: Element): number {
    let first_level = element.getAttribute('class') || '0';
    first_level = first_level.replace(/[^\d]/g, '');
    return Number(first_level);
  }

  private _moveNestedList(ContainerListElems: NodeListOf<any>): void {
    const quillIntentConst = `ql-indent-`;
    let currentParentToInserted = null;
    for (let i = 0; i < ContainerListElems.length; i++) {
      const containerListElem = ContainerListElems[i];
      const listDepth = this._getMaxParents(containerListElem);
      if (listDepth > 0) {
        const containerListElemChilds = containerListElem.childNodes;
        for (let j = 0; j < containerListElemChilds.length; j++) {
          if (containerListElemChilds[j].nodeName === 'LI') {
            containerListElemChilds[j].setAttribute('class', `${quillIntentConst}${listDepth}`);
          }
        }
        this._insertAfter(currentParentToInserted, containerListElem.outerHTML);
        containerListElem.remove();
      }
      else {
        currentParentToInserted = containerListElem;
      }
    }
  }

  private _getMaxParents(elem: any): number {
    let parentNode = elem.parentNode;
    let count = 0;
    if (parentNode !== null && typeof parentNode !== 'undefined') {
      let nodeName = parentNode.nodeName;
      while (typeof parentNode !== 'undefined' && (nodeName === 'UL' || nodeName === 'OL' || nodeName === 'LI')) {
        parentNode = parentNode.parentNode;
        nodeName = parentNode.nodeName;
        if (nodeName === 'UL' || nodeName === 'OL') {
          ++count;
        }
      }
    }
    return count;
  }

  private _insertAfter(newNode: any, referenceNode: any): void {
    newNode.insertAdjacentHTML('afterend', referenceNode);
  }
}
