import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy, Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { first, Observable, Subscription } from 'rxjs';
import {
  TrainingPlan,
  TrainingPlanAction,
  TrainingPlanActionEvent,
  TrainingPlanUpdateEvent
} from '../../../types/branch.type';
import { BranchService } from '../../../services/branch.service';
import { FEATURE, HistoryItem, MyMessageService } from '@alimento-ipv-frontend/ui-lib';
import { TrainingPlanPopupComponent } from '../training-plan-popup/training-plan-popup.component';
import { TrainingPlanActionEnum } from '../../../types/branch.enum';
import {
  TrainingPlanActionDialogComponent
} from '../training-plan-action-dialog/training-plan-action-dialog.component';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'alimento-ipv-frontend-training-plans',
  templateUrl: './training-plans.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TrainingPlansComponent implements OnChanges, OnDestroy {
  @Input()
  branchId?: string;

  @Input()
  readOnly = false;

  @Input()
  yearFilter: Date;

  @Output()
  historyItems: EventEmitter<HistoryItem[]> = new EventEmitter<HistoryItem[]>();

  currentTrainingPlan: TrainingPlan;
  currentTrainingPlanAction: TrainingPlanActionEnum;
  trainingPlans: Observable<TrainingPlan[]>;
  private _subscriptions: Subscription[] = [];
  TRAINING_PLAN = FEATURE.TRAINING_PLAN;

  @ViewChild(TrainingPlanActionDialogComponent)
  planActionDialogComponent: TrainingPlanActionDialogComponent;

  constructor(private branchService: BranchService,
              private messageService: MyMessageService,
              private confirmationService: ConfirmationService,
              private translateService: TranslateService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['branchId']?.currentValue) {
      this._getTrainingPlans();
    }
  }

  ngOnDestroy(): void {
    this._clearSubscriptions();
  }

  private _clearSubscriptions(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this._subscriptions = [];
  }

  private _getTrainingPlans(): void {
    this.trainingPlans = this.branchService.getTrainingPlans(this.branchId);
  }

  trainingPlanActionClicked(event: TrainingPlanAction, trainingPlanPopupComponent: TrainingPlanPopupComponent): void {
    this.currentTrainingPlan = JSON.parse(JSON.stringify(event.trainingPlan));
    this.currentTrainingPlanAction = event.action;
    if (event.action === TrainingPlanActionEnum.edit) {
      trainingPlanPopupComponent.openPopup();
    }
    else if (event.action === TrainingPlanActionEnum.approve) {
      this.planActionDialogComponent.open();
    }
    else if (event.action === TrainingPlanActionEnum.reject) {
      this.planActionDialogComponent.open();
    }
    else if (event.action === TrainingPlanActionEnum.delete) {
      this._deleteTrainingPlan(event.trainingPlan);
    }
    else if (event.action === TrainingPlanActionEnum.history) {
      this.branchService.getTrainingPlanHistory(event.trainingPlan.id).pipe(first())
        .subscribe(historyItems => {
          this.historyItems.emit(historyItems);
        });
    }
  }

  createNewTrainingPlan(trainingPlanPopupComponent: TrainingPlanPopupComponent): void {
    this.currentTrainingPlan = {} as TrainingPlan;
    trainingPlanPopupComponent.openPopup();
  }

  trainingPlanSubmit(event: TrainingPlanUpdateEvent, trainingPlanPopupComponent: TrainingPlanPopupComponent): void {
    event.setLoading(true);

    const createOrUpdateTrainingPlan$ = event.id
      ? this.branchService.updateTrainingPlan(event.id, this.branchId, event.trainingPlan)
      : this.branchService.createTrainingPlan(this.branchId, event.trainingPlan);

    createOrUpdateTrainingPlan$.pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success('branches.trainingPlans.saved');

          this._getTrainingPlans();

          event.setLoading(false);
          trainingPlanPopupComponent.closePopup();
        },
        error: () => {
          event.setLoading(false);
        }
      });
  }

  executeTrainingPlanAction(event: TrainingPlanActionEvent) {
    event.setLoading(true);
    let action$: Observable<any>;
    let successText: string;
    if (this.currentTrainingPlanAction === TrainingPlanActionEnum.approve) {
      action$ = this.branchService.approveTrainingPlan(this.currentTrainingPlan.id, event.data);
      successText = 'approvedSuccess';
    }
    else if (this.currentTrainingPlanAction === TrainingPlanActionEnum.reject) {
      action$ = this.branchService.rejectTrainingPlan(this.currentTrainingPlan.id, event.data);
      successText = 'rejectSuccess';
    }

    action$.pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success('branches.trainingPlans.' + successText);
          event.setLoading(false);
          this.planActionDialogComponent.closeDialog();
          this._getTrainingPlans();
        },
        error: () => {
          event.setLoading(false);
        }
      });
  }

  private _deleteTrainingPlan(trainingPlan: TrainingPlan) {
    this.confirmationService.confirm({
      header: this.translateService.instant('branches.trainingPlans.deleteTitle'),
      message: this.translateService.instant('branches.trainingPlans.deleteMessage'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translateService.instant('branches.trainingPlans.deleteTitle'),
      acceptIcon: 'hidden',
      rejectLabel: this.translateService.instant('trainings.deleteDialog.cancel'),
      rejectButtonStyleClass: 'inverted-button',
      accept: () => {
        this.branchService.removeTrainingPlan(trainingPlan.id).pipe(first())
          .subscribe(() => {
            this.messageService.success('branches.trainingPlans.deleted');
            this._getTrainingPlans();
          });
      }
    });
  }

  showTrainingPlan(plan: TrainingPlan): boolean {
    if (!this.yearFilter) {
      return true;
    }

    return new Date(plan.validFrom).getFullYear() === new Date(this.yearFilter).getFullYear() ||
      new Date(plan.validTo).getFullYear() === new Date(this.yearFilter).getFullYear();
  }
}
