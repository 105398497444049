export enum REIMBURSEMENT_REQUEST_STATUS {
  ACTIVE = '69acb353-6eba-4900-bff5-154cf124eb3d',
  CANCELLED = '32425c6b-831e-43e1-8a37-3276ab8c6cde',
  ARCHIVED = '0978e52a-310a-4384-b936-15273c7568df',
  CLOSED = 'a6905b1a-dfb6-49c4-a445-01bfa44ce49f'
}

export enum REIMBURSEMENT_REQUEST_SORT_FIELDS {
  ALIMENTO_ID = 'alimentoid',
  BRANCH_NAME = 'branchname',
  YEAR = 'year'
}

export enum FINANCIAL_DOCUMENT_ACTION_ENUM {
  edit,
  delete
}

export enum FINANCIAL_TYPE {
  CREDIT_NOTE = "76b8f56a-5605-4e58-9df4-5d38f60abd77",
  PROOF_OF_PAYMENT = "2217f34d-285a-4771-b259-925a30f9a25d",
  INVOICE = "053f7a1a-112c-46fc-a6c7-b88082cb388d",
  ATTEST_PREVENTION = "todo"
}

export enum REIMBURSEMENT_ACTION_ENUM {
  edit,
  delete,
  addCorrection,
  confirmForPayment = 'confirmForPayment'
}

export enum REIMBURSEMENT_TYPE {
  TRAINING = "c5bd7f98-2fa9-481e-a9c6-452e16454330",
  PREPARATION = "8f338503-2ac0-4c41-b80e-a2606c048d8a",
  SME_BONUS = "f018a58b-d22d-40b9-af2d-6c0c8ee057f9",
  TRANSITION = "11b9f641-de5b-4955-9fc1-bfe884d7658a",
  CORRECTION = "135a6b30-6094-46f7-b64b-f5ee45a078db"
}

export enum REIMBURSEMENT_STATUS {
  CALCULATED = '9661735d-137b-4003-9144-fd03c395776a',
  CONFIRMED = '70be180c-853e-451c-8538-b70febbdd0f6',
  PAYED = '3b675175-d733-4fa0-aa02-f2204866a033'
}

export enum REIMBURSEMENT_CORRECTION {
  ADD_EXTRA = "addExtra",
  ADD_ADJUSTMENT = "addAdjustment"
}
