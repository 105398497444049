<div class='mt-4 mx-8'>
  <div class="flex items-center justify-between mb-4">
    <h1 class="title text-text uppercase">{{ 'persons.menuTitle' | translate }}</h1>
    <p-button
      *hideIfAllUnauthorized='[Role.ADMIN, Role.CASE_MANAGER_WRITE, Role.COUNSELOR_WRITE]'
      label="{{ 'persons.actions.add' | translate }}"
      styleClass="uppercase"
      [routerLink]='["/persons/create"]'
    ></p-button>
  </div>

  <lib-search [data$]='searchData$'
              [filters]='searchFilters'
              [cardTemplate]='cardTemplate'
              [expandAll]='false'
              [showToggle]='true'
              [beforeSearchRequest]='beforeSearchRequest'
              [isToggled]='true'
              toggleLabel='persons.toggle'
              name='persons'
              searchPlaceholderKey='persons.searchListPlaceholder'
              availableResultsTranslateKey='persons.menuTitle'
  >
    <ng-template #cardTemplate let-item="item">
      <div class='my-2'>
        <alimento-ipv-frontend-person-card
          [person]='item'
          [showEmployments]='true'
          [routerLink]='["/persons", item.personId, "detail"]'
          tabindex="0"
        ></alimento-ipv-frontend-person-card>
      </div>
    </ng-template>
  </lib-search>
</div>
