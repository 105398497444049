import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { TranslationService } from '@alimento-ipv-frontend/ui-lib';
import { first, Subscription } from 'rxjs';
import { TrainingPlan, TrainingPlanAction } from '../../../types/branch.type';
import { TRAINING_PLAN_STATE, TrainingPlanActionEnum } from '../../../types/branch.enum';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-training-plan-card',
  templateUrl: './training-plan-card.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TrainingPlanCardComponent implements OnChanges, OnDestroy {
  @Input()
  trainingPlan!: TrainingPlan;
  @Input()
  readOnly = false;

  @Output()
  actionClicked = new EventEmitter<TrainingPlanAction>();

  showExtraInfo = false;
  actions: MenuItem[] = [];
  primaryAction?: { action: TrainingPlanActionEnum; icon: string };
  primaryActionTooltip = '';
  trainingPlanState: string;
  canApprove = false;
  canReject = false;
  showDue = false;

  protected readonly TrainingPlanActionEnum = TrainingPlanActionEnum;

  private _subscriptions: Subscription[] = [];

  constructor(private translate: TranslateService,
              private translationService: TranslationService,
              private referenceDataService: ReferenceDataService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['trainingPlan']?.currentValue) {
      this.canApprove = [TRAINING_PLAN_STATE.REJECTED, TRAINING_PLAN_STATE.RECEIVED].includes(this.trainingPlan.stateId);
      this.canReject = [TRAINING_PLAN_STATE.REQUESTED, TRAINING_PLAN_STATE.RECEIVED, TRAINING_PLAN_STATE.APPROVED].includes(this.trainingPlan.stateId);
      this.showDue = this.trainingPlan.due && ![TRAINING_PLAN_STATE.REJECTED, TRAINING_PLAN_STATE.APPROVED].includes(this.trainingPlan.stateId);
      this.showExtraInfo = this.canApprove && this.trainingPlan.stateId !== TRAINING_PLAN_STATE.REJECTED;

      this._clearSubscriptions();
      this._subscriptions.push(
        this.translationService.languageChange$.subscribe(() => this.actions = this.getActions())
      );
      this._setPrimaryAction();

      this.referenceDataService.getTrainingPlanState(this.trainingPlan.stateId).pipe(first())
        .subscribe(trainingPlanState => this.trainingPlanState = trainingPlanState.label);
    }
  }

  ngOnDestroy(): void {
    this._clearSubscriptions();
  }

  private _clearSubscriptions(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this._subscriptions = [];
  }

  toggleShowExtraInfo() {
    this.showExtraInfo = !this.showExtraInfo;
  }

  private _setPrimaryAction(): void {
    if (this.readOnly || this.trainingPlan?.stateId === TRAINING_PLAN_STATE.REJECTED) {
      return;
    }
    else {
      this.primaryAction = { action: TrainingPlanActionEnum.edit, icon: 'Edit' };
      this.primaryActionTooltip = 'branches.trainingPlans.edit';
    }
  }

  toggleAction(action: TrainingPlanActionEnum, event?: any): void {
    this.actionClicked.emit({ action: action, trainingPlan: this.trainingPlan });
    event?.stopImmediatePropagation();
  }

  private _getMenuItem(
    translateKey: string,
    action: TrainingPlanActionEnum,
    disabled = false,
    icon?: string
  ): MenuItem {
    const menuItem: MenuItem = {
      id: translateKey,
      label: this.translate.instant(translateKey),
      icon: icon,
      disabled: disabled,
      command: () => this.toggleAction(action)
    };

    if (icon) {
      menuItem.iconStyle = {
        position: 'absolute',
        right: '2px'
      };
    }

    return menuItem;
  }

  getActions(): MenuItem[] {
    if (this.readOnly) {
      return [];
    }

    const menuItems = [
      this._getMenuItem('branches.trainingPlans.edit', TrainingPlanActionEnum.edit)
    ];

    if (this.canApprove) {
      menuItems.push(this._getMenuItem('branches.trainingPlans.approve', TrainingPlanActionEnum.approve));
    }

    if (this.canReject) {
      menuItems.push(this._getMenuItem('branches.trainingPlans.reject', TrainingPlanActionEnum.reject));
    }

    // menuItems.push(this._getMenuItem('branches.trainingPlans.rappel', TrainingPlanActionEnum.rappel));

    menuItems.push(this._getMenuItem('branches.historyTitle', TrainingPlanActionEnum.history))

    if ([TRAINING_PLAN_STATE.REQUESTED, TRAINING_PLAN_STATE.RECEIVED].includes(this.trainingPlan.stateId)) {
      const deleteItem = this._getMenuItem(
        'branches.trainingPlans.delete',
        TrainingPlanActionEnum.delete,
        false,
        'pi pi-trash'
      );
      deleteItem.styleClass = 'danger';
      menuItems.push(deleteItem);
    }

    return menuItems;
  }

  getStateColor(): string {
    if (this.trainingPlan.stateId === TRAINING_PLAN_STATE.APPROVED) {
      return 'text-alimento-green';
    }
    else if (this.trainingPlan.stateId === TRAINING_PLAN_STATE.REJECTED) {
      return 'text.alimento-red';
    }
    return "text-primary";
  }
}
