import { Injectable } from '@angular/core';

import { FilterType, SearchFilterValue, SearchRequest } from '@alimento-ipv-frontend/ui-lib';
import { MailLogItemState, MailLogState, TaskState } from '../../types/communications.enum';
import { EnrollInTrainingKey, EnrollInTrainingMailType } from '../../types/reference-data.enum';

@Injectable({providedIn: "root"})
export class CommunicationsMapper {

  getMailLogItemStateIcon(mailLogItemState: MailLogItemState): string {
    if (mailLogItemState === MailLogItemState.Success) {
      return "check_circle";
    }
    else if (mailLogItemState === MailLogItemState.Failed) {
      return "emergency_home";
    }
    else if (mailLogItemState === MailLogItemState.Pending ||
      mailLogItemState === MailLogItemState.Processing) {
      return "pending";
    }
    return "";
  }

  getMailLogItemColor(mailLogItemState: MailLogItemState, taskState?: TaskState): string {
    if (mailLogItemState === MailLogItemState.Success) {
      return "success"; //bg-success text-success
    }
    else if (mailLogItemState === MailLogItemState.Failed) {
      if (taskState === TaskState.Complete) {
        return "blue"; //bg-blue text-blue
      }
      else {
        return "danger"; //bg-danger text-danger
      }
    }
    else if (mailLogItemState === MailLogItemState.Pending ||
      mailLogItemState === MailLogItemState.Processing) {
      return "grey-4"; //bg-grey-4 text-grey-4
    }
    return "";
  }

  getMailLogStateIcon(mailLogState: MailLogState): string {
    if (mailLogState === MailLogState.Submitted) {
      return "check_circle";
    }
    else if (mailLogState === MailLogState.Failed) {
      return "emergency_home";
    }
    else if (mailLogState === MailLogState.Pending) {
      return "pending";
    }
    return "";
  }

  getMailLogColor(mailLogState: MailLogState, taskState?: TaskState): string {
    if (mailLogState === MailLogState.Submitted) {
      return "success"; //bg-success text-success
    }
    else if (mailLogState === MailLogState.Failed) {
      if (taskState === TaskState.Complete) {
        return "blue"; //bg-blue text-blue
      }
      else {
        return "danger"; //bg-danger text-danger
      }
    }
    else if (mailLogState === MailLogState.Pending) {
      return "grey-4"; //bg-grey-4 text-grey-4
    }
    return "";
  }

  changeStatusFilter(searchRequest: SearchRequest): void {
    this._changeMailTypeStatusFilter(searchRequest);
    this._changeMailStatusFilterToMailItemStatusFilter(searchRequest);
  }

  private _changeMailStatusFilterToMailItemStatusFilter(searchRequest: SearchRequest): void {
    const filter = searchRequest.filters.filter((filter: SearchFilterValue) => filter.type === FilterType.mailStatus)[0];
    if (filter) {
      const mailItemStatuses: string[] = [];
      filter.values.forEach((mailStatus: MailLogState) => {
        if (mailStatus === MailLogState.Submitted) {
          mailItemStatuses.push(MailLogItemState.Success);
        }
        else if (mailStatus === MailLogState.Failed) {
          mailItemStatuses.push(MailLogItemState.Failed);
        }
        else {
          mailItemStatuses.push(MailLogItemState.Pending);
          mailItemStatuses.push(MailLogItemState.Processing);
        }
      });
      searchRequest.filters.push({ type: FilterType.mailItemStatus, values: mailItemStatuses });
      searchRequest.filters = searchRequest.filters.filter((filter: SearchFilterValue) => filter.type !== FilterType.mailStatus);
    }
  }

  private _changeMailTypeStatusFilter(searchRequest: SearchRequest): void {
    const filter = searchRequest.filters.filter((filter: SearchFilterValue) => filter.type === FilterType.mailTemplate)[0];
    if (filter && filter.values.includes(EnrollInTrainingKey)) {
      filter.values = [
        ...Object.values(EnrollInTrainingMailType),
        ...filter.values.filter((value: string) => value !== EnrollInTrainingKey)
      ]
    }
  }
}
