import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { first } from 'rxjs';
import { Router } from '@angular/router';
import { TrainingProgram } from '../../../types/training-program.type';
import { ReferenceDataService } from '../../../services/reference-data.service';

@Component({
  selector: 'alimento-ipv-frontend-training-program-card-detail',
  templateUrl: './training-program-card.component.html'
})
export class TrainingProgramCardComponent implements OnChanges {

  @Input()
  trainingProgram: TrainingProgram;

  @Input()
  selected = false;

  @Input()
  clickable = false;

  rubric: string;

  constructor(private referenceDataService: ReferenceDataService,
              private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['trainingProgram']?.currentValue) {
      this.referenceDataService.getTrainingRubric(this.trainingProgram.rubricId).pipe(first())
        .subscribe(rubric => this.rubric = rubric.label);
    }
  }

  onClick(event: MouseEvent): void {
    if (this.clickable) {
      return;
    }

    if (event?.ctrlKey) {
      window.open(`/training-programs/${this.trainingProgram.trainingProgramId}`, "_blank");
    }
    else {
      this.router.navigate(["/training-programs", this.trainingProgram.trainingProgramId]);
    }
  }
}
