<div *ngIf="reimbursementRequest"
     class="shadow-grey-2 shadow-md my-2 p-4 bg-white hover:bg-grey-1 relative border border-input-border cursor-pointer"
>

  <div class='flex items-center justify-between'>
    <div class='flex items-center gap-8'>
      <span class='font-bold'>{{reimbursementRequest.alimentoId}}</span>
      <div class='flex items-center gap-2'>
        <span class='font-bold'>{{reimbursementRequest.branchName}}</span>
        <span class='text-grey-3'>{{reimbursementRequest.branchAddress}}</span>
      </div>
      <alimento-ipv-frontend-reimbursement-status-chip [status]='reimbursementRequest.statusId'></alimento-ipv-frontend-reimbursement-status-chip>
    </div>
    <span>{{reimbursementRequest.year}}</span>
  </div>
</div>
