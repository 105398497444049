import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureFlagService} from "../services/feature-flag.service";
import { FEATURE } from '../types/ui-lib.enum';


@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[removeIfFeatureOff]'
})
export class RemoveIfFeatureOffDirective {

  @Input() set removeIfFeatureOff(feature: FEATURE) {
    this.featureFlagService.initialize().subscribe(_ => {
      if (!this.featureFlagService.featureOff(feature)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
      else {
        this.viewContainer.clear();
      }
    });
  }

  constructor(private featureFlagService: FeatureFlagService,
              public templateRef: TemplateRef<any>,
              public viewContainer: ViewContainerRef) {
  }
}
