import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { map, Observable, Subscription } from 'rxjs';
import {
  FilterType,
  IFilter,
  InfiniteScrollDataAdapter,
  PaginatedResponse,
  Role,
  SearchComponent,
  SearchFilter,
  SearchFilterType,
  SearchRequest, TranslationService
} from '@alimento-ipv-frontend/ui-lib';
import { SearchPersonItem } from '../../../types/person.type';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { SearchesService } from '../../../services/searches.service';
import { EmploymentMapper } from '../../../utils/mapper/employment.mapper';
import { BranchService } from '../../../services/branch.service';

@Component({
  selector: 'alimento-ipv-frontend-person-list',
  templateUrl: './person-list.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PersonListComponent implements OnInit, OnDestroy {

  searchData$: InfiniteScrollDataAdapter<SearchPersonItem>;
  searchFilters: SearchFilter[];
  protected readonly Role = Role;
  private _subscription: Subscription;

  constructor(private searchesService: SearchesService,
              private referenceDataService: ReferenceDataService,
              private branchService: BranchService,
              private employmentMapper: EmploymentMapper,
              private translationService: TranslationService) {
  }

  ngOnInit(): void {
    this._subscription = this.translationService.languageChange$.subscribe(() =>
      this._createSearch()
    )
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  private _createSearch(): void {
    const searchRequest: SearchRequest = {
      first: 0,
      rows: 10,
      filters: []
    };

    searchRequest.filters.push({
      type: FilterType.embed,
      values: ['employments']
    });

    this.searchData$ = new InfiniteScrollDataAdapter<SearchPersonItem>((searchRequest: SearchRequest): Observable<PaginatedResponse<SearchPersonItem>> => {
      return this.searchesService.searchPerson(searchRequest);
    }, searchRequest, true);
    this.searchFilters = [
      {
        type: SearchFilterType.multiselect,
        key: FilterType.employmentRole,
        expanded: true,
        label: 'persons.employments.role',
        data: this.employmentMapper.getEmploymentRoleFilter()
      },
      {
        type: SearchFilterType.multiselect,
        label: 'persons.workStatus',
        key: FilterType.filterWorkStatus,
        expanded: false,
        data: this.referenceDataService.getWorkStatuses()
          .pipe(map(types => types.map(type =>
            ({
              type: FilterType.filterWorkStatus,
              label: type.label,
              value: type.data
            }) as IFilter)))
      },
      {
        type: SearchFilterType.lazyLoadItem,
        key: FilterType.filterBranches,
        label: 'persons.employments.branch',
        expanded: false,
        dataSource: (searchRequest: SearchRequest) => {
          return this.searchesService.searchBranches(searchRequest)
            .pipe(map((result: PaginatedResponse<any>) => {
              result.data = result.data.map(item => ({
                value: '' + item.branchId,
                label: item.name,
                type: FilterType.filterBranches
              }));
              return result;
            }));
        },
        getItem: (id: string) => this.branchService.getBranch(id)
          .pipe(map(branch => ({
            value: '' + branch.branchId,
            label: branch.branchName,
            type: FilterType.filterBranches
          }) as IFilter))
      }
    ];
  }

  beforeSearchRequest = (searchComponent: SearchComponent, searchRequest: SearchRequest) => {
    if (searchComponent.isToggled) {
      searchRequest.filters.push({ type: FilterType.onlyActiveEmployment, values: [true] });
    }
  };
}
