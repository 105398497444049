export const TEMPLATE_FILTER_KEY = 'template-filters';
export const TEMPLATE_SORT_KEY = 'template-sort';
export const TEMPLATE_PAGE = 'template-page';
export const TRAINING_FILTER_KEY = 'training-filters';
export const TRAINING_SORT_KEY = 'training-sort';
export const TRAINING_PAGE = 'training-page';
export const BRANCH_FILTER_KEY = 'branch-filters';
export const BRANCH_SORT_KEY = 'branch-sort';
export const BRANCH_PAGE = 'branch-page';
export const PERSON_FILTER_KEY = 'person-filters';
export const PERSON_SORT_KEY = 'person-sort';
export const PERSON_PAGE = 'person-page';
export const LANGUAGE_KEY = 'language';
export const SEARCH_FILTER_KEY = "search-filter-";
