import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BranchesMapper } from '../../../utils/mapper/branches.mapper';
import { BranchListItem } from '../../../types/searches.type';
import { Branch } from '../../../types/branch.type';

@Component({
  selector: 'alimento-ipv-frontend-branch-search-card',
  templateUrl: './branch-search-card.component.html'
})
export class BranchSearchCardComponent implements OnChanges{

  @Input()
  branchListItem: BranchListItem;

  @Input()
  branch: Branch;

  @Input()
  selected = false;

  @Input()
  selectable = true;

  constructor(public branchesMapper: BranchesMapper) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["branch"]?.currentValue) {
      this.branchListItem = this.branchesMapper.getBranchListItem(this.branch);
    }
  }
}
