export enum AccountNumberActionEnum {
  edit,
  approve,
  reject,
  delete,
  rappel,
  setAsDefault
}

export enum AccountNumberState {
  draft = "draft",
  active = "active",
  notActive = "notActive",
}
