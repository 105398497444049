<p-confirmDialog [baseZIndex]="10000"></p-confirmDialog>
<alimento-ipv-frontend-leave-confirm-dialog></alimento-ipv-frontend-leave-confirm-dialog>

<form *ngIf='branch()'>
  <alimento-ipv-frontend-notes-sidebar [(visible)]='showNotes'
                                       [linkedEntity]='branch().branchId'
                                       (hasChanges)='notesHasChanges = $event'
                                       [readonly]='readOnly'
                                       [type]='BranchNote'>
  </alimento-ipv-frontend-notes-sidebar>
  <alimento-ipv-frontend-history [(visible)]="showHistoryItems" [historyItems]="historyItems"></alimento-ipv-frontend-history>

  <alimento-ipv-frontend-detail-page-layout
    [navigationUrl]="['/branches']"
    title="{{ (branchId ? 'branches.branch' : 'branches.newBranch') | translate }}"
  >
    <ng-container top-action>
      <div class='text-xl'>
        <span class='text-text'>{{ branch().branchName }}</span>
        <span *ngIf='branch().branchAlimentoId'
              class='mx-2 whitespace-nowrap text-text'>- {{ branch().branchAlimentoId }}</span>
      </div>

      <div class="buttons" *ngIf='!readOnly'>
        <button
          pButton
          pRipple
          type="button"
          label="{{ 'branches.actions.save' | translate }}"
          class="secondary-button uppercase"
          icon="pi pi-save"
          loadingIcon="pi pi-spin pi-spinner"
          (click)="saveBranch()"
          [loading]="savingBranch"
          [disabled]="!hasChanges"
          *removeIfFeatureOff='FEATURE.BRANCH_AND_ENTERPRISE_MANAGEMENT'
        ></button>
      </div>
    </ng-container>

    <ng-container navigation>
      <alimento-ipv-frontend-tab-menu
        (activeTabChanged)='setActiveTabIndex($event)'
        [activeTabIndex]='activeTabIndex'
        [data]='tabMenuItems'
        [extra]='extraMenuItems'
      ></alimento-ipv-frontend-tab-menu>
    </ng-container>

    <ng-container content>
      <div [hidden]='activeTabIndex !== 0'>
        <alimento-ipv-frontend-header
          title="{{ 'branches.basicDataTitle' | translate | uppercase }}"
        ></alimento-ipv-frontend-header>

        <alimento-ipv-frontend-branch
          (changes)='hasChanges = true'
          [readOnly]='readOnly'
          [branch]="branch()">
        </alimento-ipv-frontend-branch>
      </div>

      <div *ngIf='activeTabIndex === 1'>
        <alimento-ipv-frontend-header title="{{ 'branches.contactTitle' | translate }}"></alimento-ipv-frontend-header>
      </div>

      <div *ngIf='activeTabIndex === 2'>
        <alimento-ipv-frontend-employment-popup #employmentPopupComponent
                                                [employmentAction]='currentEmploymentAction'
                                                [showPersonInfo]='true'
                                                [showBranchInfo]='false'
                                                (formSubmit)='onEmploymentPopupSubmit($event, employmentPopupComponent)'>
        </alimento-ipv-frontend-employment-popup>
        <alimento-ipv-frontend-stop-employment-dialog
          (stopEmploymentConfirmed)="stopEmployment($event)"
        ></alimento-ipv-frontend-stop-employment-dialog>

        <alimento-ipv-frontend-header title="{{ 'branches.employeeTitle' | translate }}">
          <p-button
            (onClick)='addEmployee(employmentPopupComponent)'
            *ngIf='!readOnly'
            icon='pi pi-plus'
            label="{{ 'branches.addEmployee' | translate | uppercase }}"
          ></p-button>
        </alimento-ipv-frontend-header>

        <p-tabView styleClass="employments-tabview">
          <p-tabPanel header="{{ 'branches.employments.current' | translate }} ({{ searchEmployments$.totalCount$ | async }})">
            <div class="mt-2">
              <lib-search #searchEmployments
                          [activeFiltersOnTop]='false'
                          [cardTemplate]='employeeCardTemplate'
                          [data$]='searchEmployments$'
                          [filters]='searchEmploymentsFilters'
                          [inlineFilters]='true'
                          [showSearchFilter]='false'
                          availableResultsTranslateKey='branches.availableEmployees'
                          name='branch-employee-search'
                          searchPlaceholderKey='branches.employeeSearchPlaceholder'
              >
                <ng-template #employeeCardTemplate let-item='item'>
                  <alimento-ipv-frontend-employment-list-item [employment]='item'
                                                              [readOnly]='readOnly'
                                                              [viewMode]='EMPLOYMENT_ITEM_VIEW_MODE.BRANCH'
                                                              (actionClicked)='employmentAction($event, employmentPopupComponent)'
                  ></alimento-ipv-frontend-employment-list-item>
                </ng-template>
              </lib-search>
            </div>
          </p-tabPanel>

          <p-tabPanel header="{{ 'branches.employments.previous' | translate }} ({{ searchPreviousEmployments$.totalCount$ | async }})">
            <div class="mt-2">
              <lib-search #searchPreviousEmployments
                          [activeFiltersOnTop]='false'
                          [cardTemplate]='previousEmployeeCardTemplate'
                          [data$]='searchPreviousEmployments$'
                          [filters]='searchPreviousEmploymentsFilters'
                          [inlineFilters]='true'
                          [showSearchFilter]='false'
                          availableResultsTranslateKey='branches.availableEmployees'
                          name='branch-previous-employee-search'
                          searchPlaceholderKey='branches.employeeSearchPlaceholder'
              >
                <ng-template #previousEmployeeCardTemplate let-item='item'>
                  <alimento-ipv-frontend-employment-list-item [employment]='item'
                                                              [readOnly]='readOnly'
                                                              [viewMode]='EMPLOYMENT_ITEM_VIEW_MODE.BRANCH'
                                                              (actionClicked)='employmentAction($event, employmentPopupComponent)'
                  ></alimento-ipv-frontend-employment-list-item>
                </ng-template>
              </lib-search>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>

      <div *ngIf='activeTabIndex === 3'>
        <alimento-ipv-frontend-header title="{{ 'branches.trainingTitle' | translate }}">
        </alimento-ipv-frontend-header>
      </div>

      <div *ngIf='activeTabIndex === 4'>
        <alimento-ipv-frontend-training-plans [branchId]='branchId'
                                              [readOnly]='readOnly || !trainingPlanFeatureOn'
                                              (historyItems)='showTrainingPlansHistoryItems($event)'
        ></alimento-ipv-frontend-training-plans>
      </div>

      <div *ngIf='activeTabIndex === 5'>
        <div *removeIfFeatureOff='TRAINING_PROGRAM'>
          <alimento-ipv-frontend-header title="{{ 'trainingPrograms.trainingPrograms' | translate }}">
          </alimento-ipv-frontend-header>

          <lib-search [activeFiltersOnTop]='false'
                      [cardTemplate]='cardTemplate'
                      [data$]='searchTrainingProgram$'
                      [filters]='trainingProgramFilters'
                      [inlineFilters]='true'
                      [showSearchFilter]='false'
                      availableResultsTranslateKey='trainingPrograms.availableTrainingPrograms'
                      name='branch-training-program-search'
                      searchPlaceholderKey='trainingPrograms.searchPlaceholder'
          >
            <ng-template #cardTemplate let-item='item'>
              <alimento-ipv-frontend-training-program-card [fullCardClick]='true'
                                                           [trainingProgram]='item'
              >
              </alimento-ipv-frontend-training-program-card>
            </ng-template>
          </lib-search>
        </div>

      </div>
    </ng-container>

    <ng-container metadata>
      <ng-container *ngIf='enterprise?.enterpriseName'>
        <alimento-ipv-frontend-metadata-item
          [isLink]='true'
          title="{{ 'branches.companyName' | translate }}"
          value='{{ enterprise.enterpriseName }}'
          [link]='["/enterprises", enterpriseId, "detail"]'
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf='enterprise?.companyNumber'>
        <alimento-ipv-frontend-metadata-item
          title="{{ 'branches.companyNumber' | translate }}"
          value='{{ enterprise.companyNumber}}'
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf='enterprise?.rszNumber'>
        <alimento-ipv-frontend-metadata-item
          title="{{ 'branches.rszNumber' | translate }}"
          value='{{ enterprise.rszNumber }}'
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf='enterprise?.rszCodes'>
        <alimento-ipv-frontend-metadata-item
          title="{{ 'branches.rszCodes' | translate }}"
          value="{{ enterprise.rszCodes.join(', ') }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

      <ng-container *ngIf='enterprise'>
        <alimento-ipv-frontend-metadata-item
          title="{{ 'branches.numberOfWorkers' | translate }}"
          value="{{ enterprise.numberOfClerksPC220 }}"
        ></alimento-ipv-frontend-metadata-item>
        <alimento-ipv-frontend-metadata-item
          title="{{ 'branches.numberOfClerks' | translate }}"
          value="{{ enterprise.numberOfWorkersPC118 }}"
        ></alimento-ipv-frontend-metadata-item>
        <alimento-ipv-frontend-metadata-item
          title="{{ 'branches.numberOfEmployees' | translate }}"
          value="{{ enterprise.numberOfClerksPC220 + enterprise.numberOfWorkersPC118 }}"
        ></alimento-ipv-frontend-metadata-item>
      </ng-container>

    </ng-container>
  </alimento-ipv-frontend-detail-page-layout>
</form>
