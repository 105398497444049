import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { first } from 'rxjs';
import {
  Person,
  Transition,
  TransitionAction,
  TransitionActionEvent,
  TransitionItem,
  TransitionUpdateEvent
} from '../../../types/person.type';
import { TRANSITION_STATE, TransitionActionEnum } from '../../../types/person.enum';
import { PersonService } from '../../../services/person.service';
import { TransitionPopupComponent } from '../transition-popup/transition-popup.component';
import { MyMessageService } from '@alimento-ipv-frontend/ui-lib';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { TransitionActionDialogComponent } from '../transition-action-dialog/transition-action-dialog.component';
import { EmploymentService } from '../../../services/employment.service';

@Component({
  selector: 'alimento-ipv-frontend-transitions',
  templateUrl: './transitions.component.html'
})
export class TransitionsComponent implements OnChanges {
  @Input({ required: true })
  person: Person;

  @Input()
  readOnly = false;

  currentTransitionAction: TransitionActionEnum;
  currentTransition: TransitionItem;
  groupedTransitions: any;

  collapsed: { [key: string]: boolean } = {};
  protected readonly TRANSITION_STATE = TRANSITION_STATE;

  @ViewChild(TransitionActionDialogComponent)
  transitionActionDialogComponent: TransitionActionDialogComponent;

  constructor(
    private personService: PersonService,
    private messageService: MyMessageService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private employmentService: EmploymentService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['person']?.currentValue) {
      this._reloadTransitions();
    }
  }

  private _reloadTransitions(): void {
    this.personService.getGroupedTransitions(this.person.personId).pipe(first())
      .subscribe(transitions => {
        this.groupedTransitions = transitions;
      });
  }

  onTransitionPopupSubmit(event: TransitionUpdateEvent, transitionPopupComponent: TransitionPopupComponent): void {
    const transition: Transition = event.transition;

    event.setLoading(true);

    const createOrUpdateSession$ = transition.id
      ? this.personService.updateTransition(transition)
      : this.personService.createTransition(transition);

    createOrUpdateSession$.subscribe({
      next: result => {
        if (event.activate) {
          this.personService.executeTransitionAction(result.id, TransitionActionEnum.activate).pipe(first())
            .subscribe({
              next: () => this._afterTransitionAction(event, transitionPopupComponent),
              error: () => event.setLoading(false)
            });
        }
        else {
          this._afterTransitionAction(event, transitionPopupComponent);
        }
      },
      error: () => {
        event.setLoading(false);
      }
    });
  }

  private _afterTransitionAction(event: TransitionUpdateEvent, transitionPopupComponent: TransitionPopupComponent): void {
    event.setLoading(false);
    transitionPopupComponent.closePopup();

    this.messageService.success();

    this._reloadTransitions();
    this.employmentService.reloadEmployments();
  }

  addTransition(transitionPopupComponent: TransitionPopupComponent) {
    this.currentTransitionAction = TransitionActionEnum.create;
    transitionPopupComponent.openPopup({transitionStatusId: TRANSITION_STATE.DRAFT} as TransitionItem);
  }

  transitionAction(transitionAction: TransitionAction, transitionPopupComponent: TransitionPopupComponent) {
    this.currentTransitionAction = transitionAction.action;
    if ([TransitionActionEnum.view, TransitionActionEnum.edit, TransitionActionEnum.activate].includes(transitionAction.action)) {
      this.personService.getTransition(transitionAction.transition.id).pipe(first())
        .subscribe(transition => {
          transitionPopupComponent.openPopup(transition);
        });
    }
    else if (transitionAction.action === TransitionActionEnum.delete) {
      this._deleteTransition(transitionAction.transition);
    }
    else if ([
      TransitionActionEnum.activate,
      TransitionActionEnum.close,
      TransitionActionEnum.reopen,
      TransitionActionEnum.reject,
      TransitionActionEnum.reactivate
    ].includes(transitionAction.action)) {
      this.currentTransition = transitionAction.transition;
      this.transitionActionDialogComponent.open();
    }
  }

  getTransitions(state: TRANSITION_STATE): any[] {
    return this.groupedTransitions.filter((transition: any) => transition.state === state)[0]?.values || [];
  }

  getTransitionsCount(groupedTransitions: any[]): number {
    return groupedTransitions.reduce((previous, current) => previous + current.transitions.length, 0);
  }

  toggleCollapse(branchName: any): void {
    this.collapsed[branchName] = !this.collapsed[branchName];
  }

  private _deleteTransition(transition: TransitionItem): void {
    this.confirmationService.confirm({
      header: this.translateService.instant('persons.transitions.deleteTitle'),
      message: this.translateService.instant('branches.trainingPlans.deleteMessage'),
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: this.translateService.instant('persons.transitions.deleteTitle'),
      acceptIcon: 'hidden',
      rejectLabel: this.translateService.instant('trainings.deleteDialog.cancel'),
      rejectButtonStyleClass: 'inverted-button',
      accept: () => {
        this.personService.removeTransition(transition.id).pipe(first())
          .subscribe(() => {
            this.messageService.success('persons.transitions.deleted');
            this._reloadTransitions();
          });
      }
    });
  }

  executeTransitionAction(event: TransitionActionEvent): void {
    event.setLoading(true);
    const action = this.currentTransitionAction;
    this.personService.executeTransitionAction(this.currentTransition.id, action, event.data).pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success('persons.transitions.' + action + "Success");
          event.setLoading(false);
          this.transitionActionDialogComponent.closeDialog();
          this._reloadTransitions();
        },
        error: () => {
          event.setLoading(false);
        }
      });
  }

}
