import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SearchCommunicationsResult } from '../types/communications.type';
import { APP_CONFIG_TOKEN, AppConfig, SearchMapper, SearchRequest } from '@alimento-ipv-frontend/ui-lib';
import { EnrollInTrainingKey, EnrollInTrainingMailType } from '../types/reference-data.enum';

@Injectable({
  providedIn: 'root'
})
export class CommunicationsService {
  constructor(private http: HttpClient, @Inject(APP_CONFIG_TOKEN) private config: AppConfig) {
  }

  searchCommunications(communicationsParams: SearchRequest): Observable<SearchCommunicationsResult> {
    const params = SearchMapper.getParams(communicationsParams);
    return this.http.post<SearchCommunicationsResult>(`${this.config.readApiUrl}/mailsearches`, params)
      .pipe(map(searchResult => {
        searchResult.data.forEach(item => {
          if (Object.keys(EnrollInTrainingMailType).includes(item.mailTemplate)) {
            item.mailTemplate = EnrollInTrainingKey;
          }
        })
        return searchResult;
      }));
  }

  getCommunicationsCount(communicationsParams: SearchRequest): Observable<number> {
    const params = SearchMapper.getParams(communicationsParams);
    return this.http.post<number>(`${this.config.readApiUrl}/mailsearches/count`, params);
  }

  completeTasks(data: { taskIds: string[]; comments: string }): Observable<void> {
    return this.http.post<void>(`${this.config.writeApiUrl}/task/complete`, data);
  }
}
