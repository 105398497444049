<div class="sticky top-16 z-10 flex bg-grey-1 border-b border-grey-2">
  <div class="grid grid-cols-[250px_minmax(0,_1fr)] lg:grid-cols-[250px_minmax(0,_1fr)_250px] w-full gap-8 py-4 my-0 mx-0 xl:mx-40 px-8 pb-2">
    <div class="flex items-end">
      <div class="link-button link-button-with-icon" (click)="navigateBack()" data-test="back-to-overview-btn">
        <i class="pi pi-arrow-left"></i>
        <span>{{ navigateText | translate }}</span>
      </div>
    </div>

    <div class="flex flex-col flex-grow">
      <h1 class="page-title text-text uppercase">{{ title }}</h1>

      <div class="flex items-start justify-between">
        <ng-content select="[top-action]"></ng-content>
      </div>
    </div>

    <div class="flex items-end">
      <ng-content select="[top-right]"></ng-content>
    </div>
  </div>
</div>

<div class="flex">
  <div class="grid grid-cols-[250px_minmax(0,_1fr)] lg:grid-cols-[250px_minmax(0,_1fr)_250px] w-full gap-8 px-8 py-4 my-0 mx-0 xl:mx-40">
    <div class="flex flex-col gap-4">
      <ng-content select="[navigation]"></ng-content>
      <div *ngIf='smallSize' class='mt-4'>
        <ng-container *ngTemplateOutlet='metaData'></ng-container>
      </div>
    </div>

    <div class="flex flex-col bg-white p-8 pt-0">
      <ng-content select="[content]"></ng-content>
    </div>

    <div class="flex flex-col gap-4" *ngIf='!smallSize'>
      <ng-container *ngTemplateOutlet='metaData'></ng-container>
    </div>
  </div>
</div>

<ng-template #metaData>
  <ng-content select="[metadata]"></ng-content>
</ng-template>

