import { Component, EventEmitter, Input, OnChanges, Output, signal, Signal, SimpleChanges } from '@angular/core';
import { MyMessageService } from '@alimento-ipv-frontend/ui-lib';
import { PurchaseEntry, PurchaseEntryCreateEvent } from '../../../types/training.type';
import { PurchaseEntryService } from '../../../services/purchase-entries.service';
import { first } from 'rxjs';
import { SimpleBranch } from '../../../types/branch.type';
import {
  PurchaseEntryCreateDialogComponent
} from '../purchase-entry-create-dialog/purchase-entry-create-dialog.component';
import { SessionService } from '../../../services/session.service';

@Component({
  selector: 'alimento-ipv-frontend-purchase-entries-overview',
  templateUrl: './purchase-entries-overview.component.html'
})
export class PurchaseEntriesOverviewComponent implements OnChanges {
  @Input()
  trainingId: string;

  @Input()
  readOnly: boolean;

  @Input()
  hasCounselor: boolean;

  @Input()
  purchaseEntries: Signal<PurchaseEntry[]> = signal([]);

  @Output()
  changes: EventEmitter<void> = new EventEmitter<void>();

  branches: SimpleBranch[] = [];
  showCreatePurchaseEntriesButton: boolean;

  constructor(private messageService: MyMessageService,
              private sessionService: SessionService,
              private purchaseEntryService: PurchaseEntryService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['trainingId']?.currentValue) {
      this._getPurchaseEntryBranches();
    }

    this._setShowPurchaseEntryButton();
  }

  createPurchaseEntries(createDialog: PurchaseEntryCreateDialogComponent): void {
    createDialog.open();
  }

  executeCreatePurchaseEntries(event: PurchaseEntryCreateEvent, createDialog: PurchaseEntryCreateDialogComponent): void {
    event.setLoading(true);
    this.purchaseEntryService.createPurchaseEntries(this.trainingId, event.branchIds).pipe(first())
      .subscribe({
        next: () => {
          this.messageService.success("trainings.purchaseEntriesCreated");
          event.setLoading(false);
          createDialog.closeDialog();
          this._getPurchaseEntryBranches();
          this.changes.emit();
        },
        error: () => {
          event.setLoading(false);
          this._getPurchaseEntryBranches();
          createDialog.closeDialog();
          this.changes.emit();
        }
      });
  }

  private _getPurchaseEntryBranches(): void {
    this.showCreatePurchaseEntriesButton = false;
    this.purchaseEntryService.getPurchaseEntryBranches(this.trainingId).pipe(first())
      .subscribe(branches => {
        this.branches = branches;
        this._setShowPurchaseEntryButton();
      });
  }

  private _setShowPurchaseEntryButton(): void {
    this.showCreatePurchaseEntriesButton = !this.readOnly &&
      this.branches?.length > 0 &&
      this.sessionService.nrOfSessions() > 0 &&
      this.hasCounselor;
  }
}
