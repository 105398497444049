import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root"
})
export class GlobalMapper {

  setEditedBy(item: any): any {
    if (item.createdBy && !item.editedBy) {
      item.editedBy = item.createdBy;
      item.editedOn = item.createdOn;
      item.editedById = item.createdById;
    }
    return item;
  }
}
