<lib-lazy-dropdown
  [data$]='data$'
  [template]='itemTemplate'
  [headerTemplate]='showEmployments ? undefined : headerTemplate'
  [formControl]='formControl'
  [showSearchIcon]='false'
  [showClear]='true'
  [displayValue]='getSelectedPersonString'
  dropdownIcon='pi pi-search'
  placeholder="{{ 'persons.searchPlaceholder' | translate }}"
  field='name'
>
  <ng-template #headerTemplate>
    <alimento-ipv-frontend-person-card></alimento-ipv-frontend-person-card>
  </ng-template>
  <ng-template #itemTemplate let-item="item">
    <alimento-ipv-frontend-person-card
      [person]="item"
      [showEmployments]='showEmployments'
      [showExtraInfo]='false'
    ></alimento-ipv-frontend-person-card>
  </ng-template>
</lib-lazy-dropdown>
